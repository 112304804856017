import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';

import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';

import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';

import { LoginRegisterService } from '../../service/loginRegister.service';
@Component({
  selector: 'app-forgot-password-front',
  templateUrl: './forgot-password-front.component.html',
  styleUrls: [],
})
export class ForgotPasswordFrontComponent implements OnInit {
  public user: SocialUser;
  public loggedIn: boolean;
  @ViewChild('emailFocus') public email: ElementRef;
  public userForgotPasswordForm: FormGroup;
  public msgs = [];

  constructor(private socialAuthService: SocialAuthService, private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: LoginRegisterService,
    private authService: AuthService) { }

  public ngOnInit(): void {
    this.setFormInitial();
    this.authService.moveToMyAccountIfUserLoggedIn();

  }

  get f() {
    return this.userForgotPasswordForm.controls;
  }

  public setFormInitial() {
    this.userForgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.maxLength(255),
        ],
      ],
    });
  }

  public onSubmit() {
    this.spinner.show();
    // this.msgs = [];

    console.log(this.userForgotPasswordForm);
    console.log(this.userForgotPasswordForm.invalid);

    if (this.userForgotPasswordForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.forgotpassword(this.userForgotPasswordForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          // window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();
          this.showError('success', res.message);

        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  public showError(type, message) {
    console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

}

