import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';


@Injectable({
   providedIn: 'root'
})

export class MetaService { 
   constructor(@Inject(DOCUMENT) private dom) { }

   public createCanonicalURL(): any {
      // let link: HTMLLinkElement = this.dom.createElement('link');
      // link.setAttribute('rel', 'canonical');
      // this.dom.head.appendChild(link);
      // link.setAttribute('href', this.dom.URL);

      const head = this.dom.getElementsByTagName('head')[0];
      let element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null;
      if (element == null) {
        element = this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', this.dom.URL);
    }
}
