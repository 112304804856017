<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li *ngIf="cmsdata" class="breadcrumb-item active" aria-current="page">{{cmsdata.title}}</li>
        </ol>
    </div>
</nav>

<main class="main-content front login-front mb-0 pb-5">
    <div *ngIf="cmsdata" class="container">
        <div class="section-title">
            <h1 class="mt-0">{{cmsdata.title}}</h1>
            <p>{{cmsdata.shortDescription}}</p>
        </div>
        <div class="fusion-text" [innerHtml]="cmsdata.description">


        </div>
    </div>
</main>

<app-footer-front></app-footer-front>