import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class AdManagementService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllAds(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'postad/getallads', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSingleAd(req): any {
        return this.http.post(global_var.apiUrl + 'postad/getsinglead', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveRecord(data: any): any {
        return this.http.post(global_var.apiUrl + 'postad/saveadmin', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public deleteRecord(data: any): any {
        return this.http.post(global_var.apiUrl + 'postad/deletead', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getAllCategory(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllCategory', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public deleteAdInBulk(data:any):Observable<any>
    {
        return this.http.post(global_var.apiUrl + 'postad/deleteAdInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public featureActionAdInBulk(data:any):Observable<any>
    {
        return this.http.post(global_var.apiUrl + 'postad/featureActionAdInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    
    public publishActionAdInBulk(data:any):Observable<any>
    {
        return this.http.post(global_var.apiUrl + 'postad/publishActionAdInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public soldInBulk(data:any):Observable<any>
    {
        return this.http.post(global_var.apiUrl + 'postad/soldInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getAllUsers(data)
    {
        return this.http.post(global_var.apiUrl + 'users/getSearchedUser', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
