import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }
    // Login Form Validation
    public loginValidation(data: any) {

        return this.http.post(global_var.apiUrl + 'adminlogin', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public forgotpassword(data: any) {

        return this.http.post(global_var.apiUrl + 'forgotpassword', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public checkResetCode(data: any) {

        return this.http.post(global_var.apiUrl + 'checkresetcode', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public changePassword(data: any) {

        return this.http.post(global_var.apiUrl + 'changepassword', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    // Logout active session of user
    public logoutSession(data: any) {
        return this.http.post(global_var.apiUrl + 'logoutsession', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
}
