<app-header-front></app-header-front>
<main class="main-content front login-front register mb-0 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-7 content-col">
        <h2>Why register with Racebuykz?</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <ul class="list-items">
          <li>Lorem ipsum dolor sit amet</li>
          <li>Lorem ipsum dolor sit amet</li>
          <li>Lorem ipsum dolor sit amet</li>
          <li>Lorem ipsum dolor sit amet</li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-5">
        <div class="login-form">
          <div class="form-box">
            <div class="alert-message">
              <p-messages [(value)]="msgs"></p-messages>
            </div>

            <h1>Create Account</h1>
            To create an account, please fill in your details
            <form
              [formGroup]="userRegisterForm"
              (ngSubmit)="onSubmit()"
              id="userRegisterForm"
            >
              <div class="input-row">
                <input
                  #usernamefocus
                  formControlName="username"
                  type="text"
                  name="username"
                  placeholder="Username"
                  class="form-control"
                />
                <div
                  *ngIf="f.username.errors && f.username.dirty"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.username.errors.required"
                    class="error-message-form"
                  >
                    Please enter username.
                  </div>
                  <div
                    *ngIf="f.username.errors.minlength"
                    class="error-message-form"
                  >
                    Username must have atleast 6 characters.
                  </div>
                </div>
              </div>
              <div class="input-row">
                <input
                  type="text"
                  formControlName="firstname"
                  name="firstname"
                  placeholder="First Name"
                  class="form-control"
                />
              </div>
              <div class="input-row">
                <input
                  type="text"
                  formControlName="lastname"
                  name="lastname"
                  placeholder="Last Name"
                  class="form-control"
                />
              </div>
              <div class="input-row">
                <input
                  type="email"
                  formControlName="email"
                  name="email"
                  placeholder="Email"
                  class="form-control"
                />
                <div
                  *ngIf="f.email.errors && f.email.dirty"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.email.errors.required"
                    class="error-message-form"
                  >
                    Please enter email.
                  </div>
                  <div
                    *ngIf="
                      (f.email.errors.email || f.email.invalid) && f.email.value
                    "
                    class="error-message-form"
                  >
                    Please enter valid email.
                  </div>
                </div>
              </div>
              <div class="input-row">
                <input
                  type="tel"
                  formControlName="phone"
                  name="phone"
                  placeholder="Phone"
                  class="form-control"
                />
              </div>
              <div class="input-row">
                <input
                  type="password"
                  formControlName="password"
                  name="password"
                  placeholder="Password"
                  pTooltip="Password must contain at least 6 characters, including UPPER/Lowercase and numbers"
                  tooltipPosition="right"
                  tooltipEvent="focus"
                  tooltipStyleClass="form-tooltip"
                  class="form-control"
                />
                <div
                  *ngIf="f.password.errors && f.password.dirty"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.password.errors.required"
                    class="error-message-form"
                  >
                    Please enter password.
                  </div>
                  <div
                    *ngIf="
                      (f.password.errors.password || f.password.invalid) &&
                      f.password.value
                    "
                    class="error-message-form"
                  >
                    Please enter valid password.
                  </div>
                </div>
              </div>
              <div class="input-row">
                <input
                  type="password"
                  formControlName="confirm_password"
                  name="repeatpassword"
                  placeholder="Repeat Password"
                  class="form-control"
                />
                <div
                  *ngIf="f.confirm_password.errors && f.confirm_password.dirty"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="f.confirm_password.errors.required"
                    class="error-message-form"
                  >
                    Please enter confirm password.
                  </div>
                  <div
                    *ngIf="f.confirm_password.errors"
                    class="error-message-form"
                  >
                    Please enter valid confirm password
                  </div>
                </div>
              </div>
              <div class="google-captcha">
                <!-- Google captcha -->
                <ngx-recaptcha2
                  #captchaElem
                  [siteKey]="'6LdUB7cdAAAAAIw3DXpFAVcJ-8RNxyhKQDMxp8bw'"
                  formControlName="recaptcha"
                >
                </ngx-recaptcha2>
              </div>
              <div class="remember-and-forgot-password">
                <div class="password-remember">
                  <input
                    formControlName="sendCredentials"
                    type="checkbox"
                    id="sendCredentials"
                  />
                  <label for="sendCredentials"
                    >Send these credentials via email.</label
                  >
                </div>
              </div>
              <input
                type="submit"
                value="Register"
                class="btn btn-default"
                [disabled]="!userRegisterForm.valid"
              />
              <div class="login-with-facebook-btn">
                <a href="#" class="btn btn-default"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 96.124 96.123"
                    width="16"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M72.089,0.02L59.624,0C45.62,0,36.57,9.285,36.57,23.656v10.907H24.037c-1.083,0-1.96,0.878-1.96,1.961v15.803	c0,1.083,0.878,1.96,1.96,1.96h12.533v39.876c0,1.083,0.877,1.96,1.96,1.96h16.352c1.083,0,1.96-0.878,1.96-1.96V54.287h14.654
                 c1.083,0,1.96-0.877,1.96-1.96l0.006-15.803c0-0.52-0.207-1.018-0.574-1.386c-0.367-0.368-0.867-0.575-1.387-0.575H56.842v-9.246
                 c0-4.444,1.059-6.7,6.848-6.7l8.397-0.003c1.082,0,1.959-0.878,1.959-1.96V1.98C74.046,0.899,73.17,0.022,72.089,0.02z"
                      />
                    </g>
                  </svg>
                  Register with Facebook</a
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer-front></app-footer-front>
