<main class="main-content">
    <div class="login-form">
        <div class="form-box">
            <div class="logo">
                <img src="../../../assets/images/logo.jpg" alt="logo">
            </div>

            <div class="alert-message">

                <p-messages [(value)]="msgs"></p-messages>
            </div>

            <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" id="forgotPassword">
                <div class="input-group">

                    <div class="input-row">
                        <span class="input-icon">
                            <svg viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg" fill="#cecdcd">
                                <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" /></svg>
                        </span>
                        <input type="password" #passwordFocus name="password" placeholder="Password" formControlName="password" class="form-control">

                        <div *ngIf="f.password.errors && f.password.dirty" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required" class="error-message-form"> Please enter password.</div>
                            <div *ngIf="(f.password.errors.password || f.password.invalid) && f.password.value" class="error-message-form">
                                Please enter valid password.</div>
                        </div>
                    </div>


                </div>

                <div class="input-group">
                    <div class="input-row">
                        <span class="input-icon">
                            <svg viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg" fill="#cecdcd">
                                <path d="m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z" /></svg>
                        </span>
                        <input formControlName="confirm_password" type="password" name="confirm_password" placeholder="Confirm Password" class="form-control">
                        <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                            <div *ngIf="f.confirm_password.errors.required" class="error-message-form">Please enter confirm password.
                            </div>
                            <div *ngIf="f.confirm_password.errors" class="error-message-form">Please enter valid confirm password
                            </div>
                        </div>

                    </div>
                </div>

                <button class="btn btn-default btn-submit" type="submit">
                    Reset Password
                </button>

                <!-- <input onclick="onSubmit()" [style.disabled]="submitted?'false':'true'" type="submit" value="Login" class="btn btn-default btn-submit"> -->
                <div class="lost-password">
                    <a class="btn-text" [routerLink]="'/admin/login'">Back to Login</a>
                </div>
            </form>
        </div>
    </div>
</main>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#BA55D3" type="ball-triangle-path" [fullScreen]="true">
    <p style="color:#BA55D3"> Loading... </p>
</ngx-spinner>