<app-header-front></app-header-front>

<main class="main-content front login-front  mb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12 img-col">
                <h1>My Account</h1>
            </div>

        </div>
    </div>
</main>

<app-footer-front></app-footer-front>