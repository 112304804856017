import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../../myaccount/myaccount.service';

@Component({
  selector: 'app-contact-form',
  styleUrls: [],
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit {
  public dataForm: FormGroup;
  public msgs = [];
  public emailError: any = false;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private myaccountService: MyaccountService,
  ) { }

  public ngOnInit(): void {
    this.setInitialForm();
  }
  public setInitialForm(): void {
    this.dataForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      message: [],
      name: [],
      phone: [],
      postId: [0],
      receiverid: [0],
      senderId: [0],
      subject: [],
    });
  }
  public onSubmit(): void {
    if (this.dataForm.value.email === '' || this.dataForm.value.email === null) {
      this.emailError = true;
      return;
    } else {
      this.emailError = false;
    }

    this.spinner.show();
    this.myaccountService.contactSave(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          this.spinner.hide();
          this.setInitialForm();
          // window.scroll(0, 0);
          this.showError('success', res.message);
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  get f(): any {
    return this.dataForm.controls;
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
