import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../service/data.service';
import { CategoryService } from './category.service';


@Component({
  selector: 'app-category',
  styleUrls: ['./category.component.css'],
  templateUrl: './category.component.html',
})
export class CategoryComponent implements OnInit {
  public users: any[];
  public catList: any[];
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public selectedParentCategory: any = null;
  public categories: any = [];
  public getmessage: any = [];
  // public categories: any[] = [
  //   { title: 'Category 1', Value: 'categoryOne' },
  //   { title: 'Category 2', value: 'categoryTwo' },
  // ];

  constructor(
    private categoryService: CategoryService,
    private spinner: NgxSpinnerService,
    private dataService: DataService) {
    this.spinner.show();
  }

  // fn: show error
  public showError(type, message): any {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public searchTextChange(event): any {
    this.searchText = event;
    const data = {
      searchtext: this.searchText,
    };
    this.getCategory(data);
    // console.log(event,'event');
  }
  public ngOnInit(): void {

    const subscription = this.dataService.currentMessage.subscribe((data) => {
      console.log(data, 'message');
      this.getmessage = data;
      if (this.getmessage.type !== undefined && this.getmessage.type !== '' && this.getmessage.message !== undefined) {

        this.showError(this.getmessage.type, this.getmessage.message);
      }

    });

    setTimeout(() =>
      this.dataService.changeMessage('', ''),
      1000);
    this.getCategory();
  }

  public getCategory(formdata: any = null): any {

    this.categoryService.getAllCategory(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        if (res.status === 1) {
          this.catList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public deletemethod(catId: string): void {
    // alert(catId);
    const data = {
      id: catId,
    };
    if (confirm('Are you sure you want to delete?')) {
      this.spinner.show();
      this.categoryService.deleteCategory(data).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          // this.getCategory();
          if (res.status === 1) {
            this.getCategory();
            this.showError('success', 'Record deleted successfully.');
            // this.catList = res.data;
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            // window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        },
      );

      // alert('yes');
    } else {
      // alert('no');
    }
  }

}
