import { AbstractControl } from '@angular/forms';

// Confirm Password Validatiors
export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirm_password').value;
    if (password !== confirmPassword) {
      control.get('confirm_password').setErrors({ confirm_password: true });
    } else {
      control.get('confirm_password').setErrors(null);
    }
  }
}
