import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../myaccount.service';


@Component({
  selector: 'app-my-wishlist',
  templateUrl: './my-wishlist.component.html',
  styleUrls: ['./my-wishlist.component.css']
})
export class MyWishlistComponent implements OnInit {
  public websiteurl: any;
  public allDataInitial: any;
  public allData: any;
  public msgs: any = [];
  constructor(private myaccountService: MyaccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,) {
    this.authService.isUserLoggedIn();
  }

  ngOnInit(): void {
    this.getAllPostAd();
  }

  public calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours())) / (1000 * 60 * 60));

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes())) / (1000 * 60));

        responseString = res + ' mins';
      }
    }

    return responseString;

  }

  public showError(type, message) {
    // console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public removeFromWishlist(wid: any) {

    // alert(wid);
    const req = {
      id: wid,
    };
    this.spinner.show();
    this.myaccountService.removeFromWishlist(req).subscribe(
      (res: any) => {

        // this.showError('success', 'Ad has been added to wishlist successfully.');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.showError('success', 'Ad has been deleted from wishlist successfully.');
          this.getAllPostAd();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );

  }

  public getAllPostAd() {

    const req = {
      user_id: window.localStorage.getItem('user_id_user'),
    };

    this.websiteurl = global_var.webSiteUrl;

    this.spinner.show();

    this.myaccountService.getWishlistPostAd(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res');

        if (res.status === 1) {

          // this.allData = res.data;
          this.allDataInitial = res.data;
          if (res.data.length > 0) {

            // this.totalPage = Math.ceil(res.data.length / this.adPerPageValue);
            // const AngularThis = this;

            // this.allDataInitial.forEach(function (part, index, theArray) {
            //   theArray[index].images = JSON.parse(part.images);
            //   theArray[index].diff = AngularThis.calculateDiff(part.dateUpdated);
            // });

            const AngularThis = this;

            this.allDataInitial.forEach(function (part, index, theArray) {
              theArray[index].images = JSON.parse(part.images);
              theArray[index].diff = AngularThis.calculateDiff(part.dateCreated);
              // theArray[index].imageObject = [];


              if (theArray[index].title.length > 25) {
                theArray[index].title = theArray[index].title.substring(0, 28) + '...';
              }

              let imgArray;
              const imgArraynew = [];

              theArray[index].images.forEach(function (part, index, theArray) {
                // theArray[index].image = part.image;

                imgArray = {
                  alt: '',
                  image: AngularThis.websiteurl + "image/" + part,
                  thumbImage: AngularThis.websiteurl + "image/" + part,
                  title: '',
                };

                imgArraynew.push(imgArray);
              });
              theArray[index].imageObject = imgArraynew;
              // console.log('imgArraynew', imgArraynew);
            });


            const newarry = this.allDataInitial;
            this.allData = newarry;
            console.log('allData', this.allData);
            // .slice(0, this.adPerPageValue);
          } else {
            this.allData = null;

          }

          console.log(this.allData, 'this.allData');

          // else {
          //   this.allData = null;
          //   this.totalPage = 0;
          // }

          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          // this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );

  }





}
