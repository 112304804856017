import { Component, OnInit } from '@angular/core';
import {InputTextModule} from 'primeng/inputtext';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class AddComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
  }
  selectedParentCategory: any = null;
  categories: any[] = [
      {name: 'Category 1', Value: 'categoryOne'},
      {name: 'Category 2', value: 'categoryTwo'},
  ];
  radiobtn:any = null;
}
