import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { ConfirmPasswordValidator } from '../../../service/confirm_password.validator';
import { DataService } from '../../../service/data.service';
import { UserManagementService } from '../user-management.service';

@Component({
  selector: 'app-add-user',
  styleUrls: ['./add-user.component.css'],
  templateUrl: './add-user.component.html',
})
export class AddUserComponent implements OnInit {
  @ViewChild('firstnamefocus') public firstname: ElementRef;
  public UserForm: FormGroup;
  public submitted = false;
  public radiobtn: any = null;
  public editid: any = 0;
  public msgs = [];
  public editdata = [];
  public adList=[];
  public paramId: any;
  public userTypeList: any = [];
  public selectedUserType: any;
  constructor(
    private userManagementService: UserManagementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private dataService: DataService) {

    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number
    });

  }

  public ngOnInit(): void {
    this.userTypeList = [
      {
        id: 2,
        type: "Seller/Buyer"
      },
      {
        id: 3,
        type: "Service Provider"
      }
    ]
    this.UserForm = this.formBuilder.group({
      firstname: [''],
      id: [''],
      lastname: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      phone: [''],
      websiteUrl: [''],
      companyRegNo: [''],
      companyName: [''],

      password: [
        '',
        [
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      sendCredentials: [],
      isNewsAndUpdates: [],
      usertype: [2],
      isActive: [1],
      username: [''],
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      });

    // this.getCategory();

    if (this.paramId > 0) {

      const req = { id: this.paramId };
      this.spinner.show();
      this.getAllAds();

      this.userManagementService.getSingleUser(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');

          if (res.status === 1) {
            // this.catList = res.data;
            this.editdata = res.data[0];

            this.UserForm = this.formBuilder.group({
              id: [res.data[0].id],
              username: [res.data[0].username],
              firstname: [res.data[0].firstname],
              lastname: [res.data[0].lastname],
              email: [
                res.data[0].email,
                [
                  Validators.required,
                  Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
                  Validators.maxLength(255),
                ],
              ],
              phone: [res.data[0].phone],
              websiteUrl: [res.data[0].websiteUrl],
              companyRegNo: [res.data[0].companyRegNo],
              companyName: [res.data[0].companyName],
              password: [
                '',
                [
                  Validators.maxLength(50),
                  Validators.minLength(6),
                ],
              ],
              confirm_password: [
                '',
                [
                  Validators.maxLength(50),
                  Validators.minLength(6),
                ],
              ],
              sendCredentials: [],
              isNewsAndUpdates: [],
              usertype: [res.data[0].usertype],
              isActive: [res.data[0].isActive],
            },
              {
                validator: ConfirmPasswordValidator.MatchPassword,
              });
            this.selectedUserType = res.data[0].usertype;
            let user_type = this.userTypeList.find(o => o.id == res.data[0].usertype);
            if (user_type) {
              this.UserForm.patchValue({ usertype: user_type });
            }
            // set parent category

            this.spinner.hide();

          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        },
      );
    } else {

    }

  }

  get f(): any {
    return this.UserForm.controls;
  }

  public onSubmit(): any {

    // alert('abc');
    this.submitted = true;
    // this.spinner.show();
    // stop here if loginForm is invalid

    console.log(this.UserForm);
    console.log(this.UserForm.invalid);

    if (this.UserForm.invalid) {
      this.firstname.nativeElement.focus();
      window.scroll(0, 0);
      this.showError('error', 'Please enter the required fields.');
      return;
    }
    // Method to validate user login
    this.spinner.show();
    // this.UserForm.patchValue({ usertype: this.UserForm.get("usertype").value.id })

    this.userManagementService.saveRecord(this.UserForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        this.spinner.hide();
        if (res.status === 1) {
          // save the session in the local storage
          if (this.paramId > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
            this.router.navigate(['admin/user-management']);
          } else {
            this.dataService.changeMessage('success', 'Record added successfully.');
            this.router.navigate(['admin/user-management']);
          }

        } else if (res.status === 2) {
          this.submitted = false;
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.showError('error', res.message);
        } else {
          // this.spinner.hide();
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }
  public userTypeChange(event): any {
    this.selectedUserType = event.value.id;
    if (this.selectedUserType === 2) {
      this.UserForm.patchValue({
        websiteUrl: '',
        companyRegNo: '',
        companyName: '',
      });
    }
  }
  // fn: show error
  public showError(type, message): void {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public goBack(): any {
    this.router.navigate(['admin/user-management/']);

  }
  public getAllAds(formdata: any = null): void {
    this.spinner.show();
    const req = {
      createdBy: this.paramId,
    };

    this.userManagementService.getAllAds(req).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.adList = res.data;
          //this.matsterList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }
  public getDateFormate(date): String {
    if (date == '' || date == undefined)
      return '0000-00-00'
    //return (new Date(date)).toLocaleDateString()
    return date.toLocaleString().split('T')[0]
  }

}
