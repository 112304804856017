import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../service/data.service';
import { PriceManagementService } from './priceManagement.service';

@Component({
  selector: 'app-price-management',
  templateUrl: './price-management.component.html',
  styleUrls: ['./price-management.component.css'],
})
export class PriceManagementComponent implements OnInit {
  public users: any[];
  public priceList: any[];
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public selectedParentCategory: any = null;
  public categories: any = [];
  public getmessage: any = [];
  // public categories: any[] = [
  //   { title: 'Category 1', Value: 'categoryOne' },
  //   { title: 'Category 2', value: 'categoryTwo' },
  // ];

  constructor(
    private service: PriceManagementService,
    private spinner: NgxSpinnerService,
    private dataService: DataService) {
    this.spinner.show();
  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public searchTextChange(event) {
    this.searchText = event;
    const data = {
      searchtext: this.searchText,
    };
    this.getPriceManagement(data);
    // console.log(event,'event');

  }
  public ngOnInit() {

    const subscription = this.dataService.currentMessage.subscribe((data) => {
      console.log(data, 'message');
      this.getmessage = data;
      if (this.getmessage.type != undefined && this.getmessage.type != '' && this.getmessage.message != undefined) {

        this.showError(this.getmessage.type, this.getmessage.message);
        // this.dataService.changeMessage('', '');
      }

    });

    setTimeout(() =>
      this.dataService.changeMessage('', ''),
      1000);

    // this.users = [
    //   { title: 'abc', description: 'cde' },
    //   { title: 'abc2', description: 'cde' },
    //   { title: 'abc3', description: 'cde' },
    //   { title: 'abc4', description: 'cde' },
    //   { title: 'abc5', description: 'cde' },

    // ];

    // console.log(this.service.getAllCategory(), 'test');

    this.getPriceManagement();
  }

  public getPriceManagement(formdata: any = null) {

    this.service.getAll(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res');

        if (res.status === 1) {
          this.priceList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public deletemethod(catId: string) {
    // alert(catId);
    const data = {
      id: catId,
    };
    if (confirm('Are you sure to delete.')) {
      this.spinner.show();
      this.service.delete(data).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');

          // this.getPriceManagement();
          if (res.status === 1) {
            this.getPriceManagement();
            this.showError('success', 'Record deleted successfully.');
            // this.priceList = res.data;
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            // window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        },
      );

      // alert('yes');
    } else {
      // alert('no');
    }
  }

}

