import { ConstantPool } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyaccountService } from '../myaccount/myaccount.service';

@Component({
  selector: 'app-about-us',
  styleUrls: ['./about-us.component.css'],
  templateUrl: './about-us.component.html',
})
export class AboutUsComponent implements OnInit {
  public cmsdata: any;
  constructor(
    private myaccountService: MyaccountService,
    private spinner: NgxSpinnerService,

  ) { }

  public ngOnInit(): void {
    window.scrollTo(0, 0);

    const req = { id: 2 };
    this.spinner.show();

    this.myaccountService.getSinglecms(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'getSinglecms');

        if (res.status === 1) {
          // this.catList = res.data;
          this.cmsdata = res.data;
          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );

  }
}
