import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { AuthService } from '../../../service/auth.service';
@Component({
  selector: 'app-sidemenu',
  styleUrls: ['./sidemenu.component.css'],
  templateUrl: './sidemenu.component.html',
})
export class SidemenuComponent implements OnInit {
  public currentLink: any;
  public userType: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.currentLink = this.router.url;
    this.userType = this.authService.getUserType();
  }

  public ngOnInit(): void {

  }

}
