import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { AuthService } from '../../service/auth.service';
import { MyaccountService } from '../myaccount/myaccount.service';
import { PageDetailService } from '../service/pageDetail.service';
@Component({
  selector: 'app-contact-us',
  styleUrls: ['./contact-us.component.css'],
  templateUrl: './contact-us.component.html',
})
export class ContactUsComponent implements OnInit {
  public dataForm: FormGroup;
  public msgs = [];
  public emailError: any = false;
  public postid: any = 0;

  public sellerid: any = 0;
  public senderId: any = 0;
  public websiteurl: any;
  public postData: any;
  public isContactSeller: any = false;
  public isLoggedIn: any = false;



  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private myaccountService: MyaccountService,
    private pageDetailService: PageDetailService,
  ) {

    // postid/:sellerid
    this.route.params.subscribe((params) => {
      this.postid = +params['postid']; // (+) converts string 'id' to a number
      this.sellerid = +params['sellerid'];

      console.log(typeof this.postid, 'postid');
      console.log(this.postid, 'postid');
      if (this.postid > 0) {
        this.isContactSeller = true;
      }
      // this.getAllPostAd();
    });
    if (window.localStorage.getItem('user_id_user')) {
      this.senderId = window.localStorage.getItem('user_id_user');
    }

  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);

    this.setInitialForm();
    if (this.isContactSeller && this.authService.checkUserLoggedIn()) {
      this.getUserData();
      this.isLoggedIn = true;
    }
    if (this.postid > 0) {
      this.websiteurl = global_var.webSiteUrl;
      const req = { id: this.postid };
      this.spinner.show();
      const AngularThis = this;
      this.pageDetailService.getSinglePostAd(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');

          if (res.status === 1) {
            // this.catList = res.data;
            // this.postData = res.data[0];

            this.dataForm.get('subject').setValue(res.data[0].title);

            this.spinner.hide();

          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 0);
            // this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          // this.showError('error', error);
        },
      );

    }
  }

  public getUserData(): any {
    const req = {
      id: this.authService.getUserId(),
    };
    this.spinner.show();

    this.myaccountService.getuserdata(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data[0], 'getuserdata');
        if (res.status === 1) {

          // res.data[0].email,
          //   phone: [res.data[0].phone],this.dataForm.get('subject').setValue(res.data[0].title);
          this.dataForm.get('email').setValue(res.data[0].email);
          this.dataForm.get('phone').setValue(res.data[0].phone);
          this.dataForm.get('name').setValue(res.data[0].firstname + ' ' + res.data[0].lastname);
          this.dataForm.get('isAgreeTerms').setValue(true);



        } else if (res.status === 2) {
        } else if (res.status === 0) {
        } else {
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
      },
    );

  }


  public setInitialForm(): void {
    this.dataForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      message: [],
      name: [],
      phone: [],
      postId: [this.postid],
      receiverid: [this.sellerid],
      senderId: [this.senderId],
      subject: [],
      isAgreeTerms: ['', Validators.requiredTrue],
    });
  }
  public onSubmit(): void {
    if (this.dataForm.value.email === '' || this.dataForm.value.email === null) {
      this.emailError = true;
      return;
    } else {
      this.emailError = false;
    }

    this.spinner.show();
    this.myaccountService.contactSave(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          this.spinner.hide();
          this.setInitialForm();
          window.scroll(0, 0);
          this.showError('success', res.message);
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', "There is some error. Please try again later.");
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', "There is some error. Please try again later.");
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  get f(): any {
    return this.dataForm.controls;
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }


}
