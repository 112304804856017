<app-header-front></app-header-front>
<nav aria-label="breadcrumb" class="purpleBreadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li *ngIf="cmsdata" class="breadcrumb-item active" aria-current="page">{{cmsdata.title}}</li>
        </ol>
    </div>
</nav>


<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div *ngIf="cmsdata" class="section-title mb-5">
            <h1 class="mt-0">{{cmsdata.subtitle}}</h1>
            <div [innerHTML]="cmsdata.description"></div>
        </div>


    </div>
</main>

<app-footer-front></app-footer-front>