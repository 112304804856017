import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { Lightbox } from 'ngx-lightbox';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { PageDetailService } from '../service/pageDetail.service';
declare const jQuery: any;

@Component({
  selector: 'app-ad-detail',
  styleUrls: [],
  templateUrl: './ad-detail.component.html',
})
export class AdDetailComponent implements OnInit {

  public isLess: any = false;
  public showMoreLess: any = false;
  public paramId: any;
  public getdata: any;
  public imageData: any;
  public websiteurl: any;
  public imageObject: any = [];
  public albums: any = [];
  public description: any;
  public total_ad_visits:any=0;
  public adtitle:any;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private pageDetailService: PageDetailService,
    private lightbox: Lightbox,
  ) {

    this.route.params.subscribe((params) => {
      this.paramId = +params.id; // (+) converts string 'id' to a number
    });

    this.route.params.subscribe((params) => {
      // console.log(params);
      this.adtitle = params.adtitle; // (+) converts string 'id' to a number
      // console.log(this.adtitle);
    });


    if (window.history && window.history.pushState) {
      jQuery(window).on('popstate', () => {
        this.router.events.subscribe((val) => {
          if (val.constructor.name === 'NavigationEnd') {
            let navEnd: any = {};
            navEnd = val;
            if (navEnd.url.includes('category') || navEnd.url.includes('search-ads')) {
              console.log('val ad detail', val);
              myGlobalsfunc.saveLocalstorage('backFromAdDetail', '1');
            }
          }
        });
      });
    }
  }
  
  public open(index: number): void {
    // open lightbox
    console.log(this.lightbox);
    this.lightbox.open(this.albums, 0);
  }

  public close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  public showDescription(): any {
    if (this.isLess) {
      this.isLess = false;
      this.description = this.getdata.description.substring(0, 265) + '...';
      window.scroll(0, 200);
    } else {
      this.isLess = true;
      this.description = this.getdata.description;
    }
  }

  public ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.adtitle !== '' && this.adtitle != null && this.adtitle !== undefined) {
      this.websiteurl = global_var.webSiteUrl;
      let req = {};

      req = { adtitle: this.adtitle };

      this.spinner.show();
      const AngularThis = this;
      this.pageDetailService.getSinglePostAd(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message

          if (res.status === 1) {
            this.getdata = res.data[0];
            if (this.getdata.description.length > 250) {
              this.description = this.getdata.description.substring(0, 265) + '...';
              this.showMoreLess = true;
            } else {
              this.description = this.getdata.description;
              this.showMoreLess = false;
            }

            this.imageData = JSON.parse(res.data[0].images);

            let imgArray;
            this.imageData.forEach((part, index, theArray) => {
              imgArray = {
                alt: '',
                image: AngularThis.websiteurl + "image/" + part,
                thumbImage: AngularThis.websiteurl + "image/" + part + "?thumbnail=400",
                title: '',
              };

              const album = {
                caption: '',
                src: AngularThis.websiteurl + "image/" + part,
                thumb: AngularThis.websiteurl + "image/" + part + "?thumbnail=400",
              };

              AngularThis.albums.push(album);

              AngularThis.imageObject.push(imgArray);
            });
            this.total_ad_visits=res.data[0].total_visits;

            this.spinner.hide();

          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 175);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          // this.showError('error', error);
        },
      );

    }
  }
}
