<app-sidebar></app-sidebar>
<app-header></app-header>


<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <form [formGroup]="UserForm" (ngSubmit)="onSubmit()" id="formAdd">

        <div class="page-title">
            <h1>{{paramId ? 'Edit' : 'Add'}} User</h1>
        </div>

        <div class="form-container">

            <div class="ui-fluid p-formgrid p-grid">

                <div class="p-field col-md-6 input-field">
                    <label for="firstname">First Name</label>
                    <input id="firstname" #firstnamefocus formControlName="firstname" type="text" pInputText>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="lastname">Last Name</label>
                    <input id="lastname" formControlName="lastname" type="text" pInputText>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="email">Email<span class="required">&#42;</span></label>
                    <input id="email" formControlName="email" type="text" pInputText>
                    <div *ngIf="f.email.errors && f.email.dirty" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.</div>
                        <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value"
                            class="error-message-form">
                            Please enter valid email.</div>
                    </div>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="username">Username</label>
                    <input id="username" formControlName="username" type="text" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="username">User Type</label>
                    <p-dropdown [options]="userTypeList" formControlName="usertype" optionLabel="type"
                        (onChange)="userTypeChange($event)" placeholder="Select user type"></p-dropdown>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="phone">Phone</label>
                    <input id="phone" formControlName="phone" type="text" pInputText>
                </div>


                <div class="p-field col-md-6 input-field" *ngIf="selectedUserType==3">
                    <label for="companyName">Company Name</label>
                    <input id="companyName" formControlName="companyName" type="text" pInputText>
                </div>

                <div class="p-field col-md-6 input-field" *ngIf="selectedUserType==3">
                    <label for="websiteUrl">Website Url</label>
                    <input id="websiteUrl" formControlName="websiteUrl" type="text" pInputText>
                </div>


                <div class="p-field col-md-6 input-field" *ngIf="selectedUserType==3">
                    <label for="companyRegNo">Company Reg No.</label>
                    <input id="companyRegNo" formControlName="companyRegNo" type="text" pInputText>
                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="password">Password</label>
                    <input id="password" formControlName="password" type="password" pInputText>
                    <div *ngIf="f.password.errors && f.password.dirty" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required" class="error-message-form"> Please enter password.</div>
                        <div *ngIf="(f.password.errors.password || f.password.invalid) && f.password.value"
                            class="error-message-form">
                            The password must have at least 6 characters.</div>
                    </div>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="confirm_password">Confirm Password</label>
                    <input id="confirm_password" formControlName="confirm_password" type="password" pInputText>
                    <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                        <div *ngIf="f.confirm_password.errors.required" class="error-message-form">Please enter confirm
                            password.
                        </div>
                        <div *ngIf="f.confirm_password.errors" class="error-message-form">Please enter valid confirm
                            password
                        </div>
                    </div>
                </div>



                <div class="p-field col-md-6">
                    <label for="address">Active</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="1" inputId="yes" formControlName="isActive">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="0" inputId="no" formControlName="isActive">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        <div class="form-btn align-right">
            <button pButton type="submit" label="Submit"></button> &nbsp;<button (click)="goBack()" pButton
                type="button" label="Cancel"></button>


        </div>
    </form>
    <div *ngIf="paramId>0" class="responsive-table p-mt-4 ">
        <div class="page-title p-text-center">
            <h1>Transaction Details</h1>
        </div>
        <p-table class="table user-list-table" [responsive]="true" autoLayout="true" [rows]="10" [value]="adList"
            [paginator]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Ad Titlle</th>
                    <th>Ad Price</th>
                    <th>Payment Mode</th>
                    <th>Payment Amount</th>
                    <th>Date Of Payment</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-adList>
                <tr>
                    <td class="name">
                        <div class="content">{{adList.title}}</div>
                    </td>
                    <td class="name">
                        <div class="content">{{adList.price}}</div>
                    </td>
                    <td class="name">
                        <div class="content">{{adList.paymentMode}}</div>
                    </td>
                    <td class="name">
                        <div class="content">{{adList.paymentAmount}}</div>
                    </td>
                    <td class="name">
                        <div class="content">{{getDateFormate(adList.datePayment)}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</main>