import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgControlStatus,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { DataService } from '../../../service/data.service';
import { CategoryService } from '../../category/category.service';
import { PriceManagementService } from '../priceManagement.service';

@Component({
  selector: 'app-add-price-management',
  styleUrls: ['./add-price-management.component.css'],
  templateUrl: './add-price-management.component.html',
})
export class AddPriceManagementComponent implements OnInit {
  public priceManagementForm: FormGroup;
  public submitted = false;
  public showEditPriceRange = false;
  public catList: any = null;
  public radiobtn: any = null;
  public editid: any = 0;
  public msgs = [];
  public editdata = [];
  public bannerImage: any = '';
  public paramId: any;
  public priceRangeArr: any = [
    '0 - 99.99',
    '100.00 - 249.99',
    '250.00 - 499.99',
    '500.00 - 699.99',
    '700.00 - 899.99',
    '900.00 '];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: PriceManagementService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private categoryService: CategoryService) {
    this.route.params.subscribe((params) => {
      this.paramId = +params['id'];
    });

    //this.currentLink = this.router.url;
  }

  public ngOnInit(): void {
    this.spinner.show();
    this.setPriceManagementForm();
    this.EditPriceRange();
    this.getCategory();
  }

  public getCategory(): any {

    this.categoryService.getAllCategory().subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'getAllCategory');

        if (res.status === 1) {
          if (this.paramId) {
            this.getPrice(this.paramId);
          }
          // this.catList = res.data;
          const newArray = [];
          res.data.forEach((part, index, theArray) => {
            if (theArray[index].parentId === 0 && theArray[index].id !== 45) {
              newArray.push(part);
            }
          });
          this.catList = newArray;

          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public goBack(): any {
    this.router.navigate(['admin/price-management/']);
  }

  public getPrice(id: any): any {
    this.spinner.show();
    const req = {
      id,
    };
    this.service.getSingle(req).subscribe((res: any) => {
      if (res.status === 1) {
        this.spinner.hide();
        this.priceManagementForm = this.formBuilder.group({
          day30Cost: [parseInt(res.data.day30Cost), [
            Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
            Validators.required,
          ]],
          day90Cost: [res.data.day30Cost, [
            Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
            Validators.required,
          ]],
          isActive: [1],
          maxRange: [res.data.maxRange, [
            Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
            Validators.required
          ]],
          minRange: [res.data.minRange, [
            Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
            Validators.required,
          ]],
          selectRange: [0],
          id: [res.data.id],
          catId: ['', [
            Validators.required,
          ]],
          rangeEditor: [''],
          untilSoldCost: [res.data.untilSoldCost, [
            Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
            Validators.required,
          ]],
        });
        this.priceManagementForm.patchValue({
          catId: this.catList.find((o: { id: any; }) => o.id === res.data.catId),
        });
      }
    }, (err: any) => {
      this.spinner.hide()
    })
  }

  public EditPriceRange(): any {
    this.priceManagementForm.get('rangeEditor').setValue(this.priceRangeArr[this.priceManagementForm.value.selectRange]);
    const rangeValue = this.priceRangeArr[this.priceManagementForm.value.selectRange];
    if (rangeValue.includes('-')) {
      const splitted = rangeValue.split('-');
      this.priceManagementForm.get('minRange').setValue(splitted[0].trim());
      this.priceManagementForm.get('maxRange').setValue(splitted[1].trim());
    } else {
      this.priceManagementForm.get('minRange').setValue(rangeValue.trim());
      this.priceManagementForm.get('maxRange').setValue('');
    }

  }

  public ShowEditPriceRange(): any {
    this.showEditPriceRange = true;
    console.log(this.priceManagementForm.value.selectRange);
    // this.priceManagementForm.value.selectRange
    this.priceManagementForm.get('rangeEditor').setValue(this.priceRangeArr[this.priceManagementForm.value.selectRange]);

  }
  public HideEditPriceRange(): any {
    this.showEditPriceRange = false;
  }

  public savePriceRange(): any {
    if (this.priceManagementForm.valid) {
      const rangeValue = this.priceManagementForm.value.rangeEditor;
      if (rangeValue.includes('-')) {
        const splitted = rangeValue.split('-');
        this.priceManagementForm.get('minRange').setValue(splitted[0]);
        this.priceManagementForm.get('maxRange').setValue(splitted[1]);
      } else {
        this.priceManagementForm.get('minRange').setValue(rangeValue);
        this.priceManagementForm.get('maxRange').setValue('');

      }
      this.priceRangeArr[this.priceManagementForm.value.selectRange] = rangeValue;
      this.HideEditPriceRange();
    }
  }

  public setPriceManagementForm(): any {
    this.priceManagementForm = this.formBuilder.group({
      day30Cost: ['',
        [
          Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
          Validators.required
        ],
      ],
      day90Cost: ['',
        [
          Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
          Validators.required
        ],
      ],
      isActive: [1],
      maxRange: [],
      minRange: [],
      selectRange: [0],
      id: [0],
      catId: [''],
      rangeEditor: [
        '',
        [
          Validators.min(0),
          Validators.pattern(/^(?=.*[0-9])[-. 0-9]+$/),
        ],
      ],
      untilSoldCost: ['',
        [
          Validators.pattern(/^(?=.*[0-9])[.0-9]+$/),
          Validators.required
        ],
      ],
    });
  }

  get f(): any {
    return this.priceManagementForm.controls;
  }

  public onSubmit(): any {

    // alert('abc');
    this.submitted = true;
    // this.spinner.show();
    // stop here if loginForm is invalid

    console.log(this.priceManagementForm.value);
    console.log(this.priceManagementForm.invalid);

    if (this.priceManagementForm.invalid) {
      window.scroll(0, 0);
      return;
    }
    // Method to validate user login
    this.spinner.show();

    this.service.save(this.priceManagementForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        this.spinner.hide();
        if (res.status === 1) {
          // save the session in the local storage
          if (this.paramId > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
            this.router.navigate(['admin/price-management']);
          } else {

            this.dataService.changeMessage('success', 'Record added successfully.');
            this.router.navigate(['admin/price-management']);
          }

        } else if (res.status === 2) {
          this.submitted = false;
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  // fn: show error
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
