import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { NgxSpinnerService } from 'ngx-spinner';
import { MyProfileService } from './my-profile.service'
import { ConfirmPasswordValidator } from './../../service/confirm_password.validator'
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  public profileForm: FormGroup;
  public msgs: any = [];
  constructor(private formBuilder: FormBuilder, private service: MyProfileService
    , private spinner: NgxSpinnerService) {

  }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      currentPass: ['', [Validators.required]],
      password: ['', Validators.required],
      confirm_password: ['', [Validators.required,Validators.maxLength(50),Validators.minLength(6)]],
      id: [window.localStorage.getItem('user_id')]
    }, { validators: ConfirmPasswordValidator.MatchPassword })
  }
  onSubmit() {
    if (this.profileForm.valid) {
      this.spinner.show();
      this.service.changePassword(this.profileForm.value).subscribe((res: any) => {
        this.spinner.hide();
        console.log(res)
        if (res.status == 1) {
          this.showError('success', 'Password changed successfully')
        }
        else if (res.status == 2) {
          this.showError('error', 'Current password is incoorect')
        }
        else {
          this.showError('error', 'Oops somthing went wrong')
        }
      }, (err: any) => {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong')
      })
    }

  }
  get f() {
    return this.profileForm.controls;
  }
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
