import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../service/data.service';
import { CmsManagementService } from './cms-management.service';

@Component({
  selector: 'app-cms-management',
  styleUrls: ['./cms-management.component.css'],
  templateUrl: './cms-management.component.html',
})

export class CmsManagementComponent implements OnInit {
  public users: any[];
  public dataList: any[];
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public selectedParentcms: any = null;
  public categories: any = [];
  public getmessage: any = [];
  public currentLink: any;
  public pageType: any = 1;
  constructor(
    private cmsManagementService: CmsManagementService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.spinner.show();
    this.currentLink = this.router.url;
  }

  // fn: show error
  public showError(type, message): void {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public searchTextChange(event): void {
    this.searchText = event;
    const data = {
      searchtext: this.searchText,
    };
    this.getcms(data);
    // console.log(event,'event');
  }
  public ngOnInit(): void {

    const subscription = this.dataService.currentMessage.subscribe((data) => {
      console.log(data, 'message');
      this.getmessage = data;
      if (this.getmessage.type !== undefined && this.getmessage.type !== '' && this.getmessage.message !== undefined) {
        this.showError(this.getmessage.type, this.getmessage.message);
      }

    });

    setTimeout(() =>
      this.dataService.changeMessage('', ''),
      1000);
    this.getcms();
  }

  public getcms(formdata: any = null): any {

    if (this.currentLink === '/admin/banner-ads') {
      this.pageType = 2;
    }
    if (this.currentLink === '/admin/faq') {
      this.pageType = 3;
    }
    formdata = {
      pageType: this.pageType,
    };

    this.cmsManagementService.getAllcms(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message

        if (res.status === 1) {
          this.dataList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public deletemethod(catId: string): void {
    // alert(catId);
    const data = {
      id: catId,
    };
    if (confirm('Are you sure to delete.')) {
      this.spinner.show();
      this.cmsManagementService.deletecms(data).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');


          // this.getcms();
          if (res.status === 1) {
            this.getcms();
            this.showError('success', 'Record deleted successfully.');
            // this.dataList = res.data;
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            // window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        },
      );

      // alert('yes');
    } else {
      // alert('no');
    }
  }

}
