import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private messageSource = new BehaviorSubject<Object>({});

  public currentMessage = this.messageSource.asObservable();

  constructor() { }

  public changeMessage(typedata: string, messagedata: string) {

    this.messageSource.next({
      message: messagedata,
      type: typedata,
    });
  }

}
