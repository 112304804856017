// add all api url in this object
const apiUrl_credentials = {
  url_local: 'http://localhost:8043/api/',
};

let apiUrls;
let webSiteUrls;
// the url for the devlopment
if (window.location.hostname === 'localhost') {
  apiUrls = apiUrl_credentials.url_local;
  // webSiteUrls = 'http://localhost:8043/';
  webSiteUrls = 'https://racebuykz.com/';
} else if (window.location.hostname === '127.0.0.1') {
  apiUrls = 'http://127.0.0.1:8043/api/';
} else {
  apiUrls = `${window.location.protocol}//${window.location.host}/api/`;
  webSiteUrls = `${window.location.protocol}//${window.location.host}/`;
}
// the api url of the server
export let apiUrl = apiUrls;
export let webSiteUrl = webSiteUrls;

// export let supportApiUrl = supportUrl;

export let googleMapApi = 'AIzaSyAlT5K5tiuVZkyIAqTCMEgA4eCrrUmNz5Q';
export let loginUserID = window.localStorage.getItem('user_id');

// global varaible for client fund
export let fundTransferOptions = new Array(
  { id: 0, text: 'Bank transfer' },
  { id: 1, text: 'Cheque' },
  { id: 2, text: 'Card' },
  { id: 3, text: 'Cash' }
);
export let adjustmentOptions = new Array(
  { id: '', text: 'Please choose' },
  { id: 1, text: 'Add' },
  { id: 0, text: 'Deduct' }
);

export let clienFundSortOptions = [
  { key: 1, value: 'Surname (A-Z)', type: 'ASC' },
  { key: 2, value: 'Surname (Z-A)', type: 'DESC' },
  { key: 3, value: 'First name (A-Z)', type: 'ASC' },
  { key: 4, value: 'First name (Z-A)', type: 'DESC' },
  { key: 5, value: 'Amount paid in (High to Low)', type: 'DESC' },
  { key: 6, value: 'Amount paid in (Low to High)', type: 'ASC' },
  { key: 7, value: 'Amount paid out (High to Low)', type: 'DESC' },
  { key: 8, value: 'Amount paid out (Low to High)', type: 'ASC' },
];

export let clienFundSortOptionsClient = [
  { key: 2, value: 'Most recent first', type: 'DESC' },
  { key: 1, value: 'Oldest first', type: 'ASC' },
  { key: 3, value: 'Amount paid in (Low to High)', type: 'ASC' },
  { key: 4, value: 'Amount paid in (High to Low)', type: 'DESC' },
  { key: 5, value: 'Amount paid out (Low to High)', type: 'ASC' },
  { key: 6, value: 'Amount paid out (High to Low)', type: 'DESC' },
];
