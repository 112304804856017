<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item">
        <a [routerLink]="'/sp-category/'">{{ MainHeading }}</a>
      </li>
      <li *ngIf="getdata" class="breadcrumb-item">
        <a [routerLink]="'/sp-category/' + getdata.catSlugName">{{
          Heading
        }}</a>
      </li>

      <!-- <li *ngIf="getdata" class="breadcrumb-item">{{Heading}}</li> -->
      <li *ngIf="getdata" class="breadcrumb-item active" aria-current="page">
        {{ getdata.companyName }}
      </li>
    </ol>
  </div>
</nav>
<main *ngIf="getdata" class="main-content front login-front mb-0 pb-5 pt-3">
  <div class="container">
    <div class="featured-img-sec mb-5">
      <div *ngIf="getdata && getdata.bannerImage" class="fetured-img">
        <img
          src="{{ websiteurl + 'image/' + getdata.bannerImage }}"
          alt=""
        />
      </div>
      <div class="follow-us">
        <a
          *ngIf="getdata.facebook"
          target="_blank"
          href="{{ getdata.facebook }}"
        >
          <img
            src="../../../assets/images/fb-icon.svg"
            width="30"
            height="30"
            alt=""
          />
        </a>
        <a *ngIf="getdata.twitter" target="_blank" href="{{ getdata.twitter }}">
          <img
            src="../../../assets/images/twitter-icon.svg"
            width="30"
            height="30"
            alt=""
          />
        </a>
        <a
          *ngIf="getdata.instagram"
          target="_blank"
          href="{{ getdata.instagram }}"
        >
          <img
            src="../../../assets/images/intagram-icon.svg"
            width="30"
            height="30"
            alt=""
          />
        </a>
      </div>
    </div>

    <div class="service-detail-content">
      <div class="row">
        <div class="col-12 col-md-8">
          <h1 class="mt-0">{{ getdata.companyName }}</h1>
          <p style="white-space: pre-wrap">{{ getdata.description }}<br /></p>
        </div>
        <div class="col-12 col-md-4">
          <a
            target="_blank"
            href="{{ getdata.websiteUrl }}"
            class="d-flex flex-column align-items-center"
          >
            <img src="{{ websiteurl + 'image/' + getdata.logoImage }}" />
            <span
              target="_blank"
              href="{{ getdata.websiteUrl }}"
              class="btn btn-default my-3"
              >View Website</span
            ></a
          >
        </div>
        <div class="col-12">
          <div class="address-section py-4 px-4 mb-5">
            <div class="d-flex flex-wrap">
              <div
                class="d-flex flex-column justify-items-center"
                style="flex: 1 1 auto"
              >
                <div class="address mt-1">
                  <h5 class="mt-0">Address</h5>
                  <p *ngIf="getdata.address">
                    {{ getdata.address }}
                    <span *ngIf="getdata.address2"
                      >, {{ getdata.address2 }}</span
                    >
                    <span *ngIf="getdata.town || getdata.county">
                      , {{ getdata.town }} {{ getdata.county }}
                    </span>
                    <span *ngIf="getdata.postcode"
                      >, {{ getdata.postcode }}</span
                    >
                  </p>
                </div>
              </div>
              <div class="email mt-1" style="min-width: 300px">
                <h5 class="mt-0">Email</h5>
                <p>
                  <a href="mailto:{{ getdata.email }}">{{ getdata.email }}</a>
                </p>
              </div>
              <div class="tel mt-1" style="min-width: 200px">
                <h5 class="mt-0">Tel No.</h5>
                <p>
                  <a href="tel:{{ getdata.phone }}"
                    >{{ getdata.dialCode }} {{ getdata.phone }}</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="getSpProductList && getSpProductList.length > 0"
        class="featured-Services mt-0 mb-4"
      >
        <h2 class="mt-0 mb-4">Featured Products / Services / Offers</h2>
        <div class="row">
          <p-carousel
            class="col-12"
            [infiniteLoop]="true"
            [value]="getSpProductList"
            [numScroll]="3"
            [numVisible]="3"
            [circular]="false"
            [responsiveOptions]="responsiveOptions"
          >
            <ng-template let-item pTemplate="item">
              <div class="col-12">
                <div class="featured">
                  <div class="featured-img">
                    <a href="{{ item.link }}" target="_blank">
                      <img
                        src="{{ websiteurl + 'image/' + item.image }}"
                        alt=""
                      />
                    </a>
                  </div>
                  <div class="featured-details">
                    <p>{{ item.title }}</p>
                    <p>
                      <!-- {{(item.price =='' || item.price.toString() == '0') ?'POA': (item.price | currency:'GBP')}} -->
                      {{
                        isNaN(item.price)
                          ? item.price
                          : (item.price | currency: "GBP")
                      }}
                    </p>
                    <span>{{ item.description }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
        <div class="bottom-shadow">
          <img
            src="../../../assets/images/bottom-shadow.png"
            alt="bottom-shadow"
          />
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer-front></app-footer-front>
