<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li *ngIf="!showSpData" class="breadcrumb-item active" aria-current="page">Services</li>

            <li *ngIf="showSpData" class="breadcrumb-item active" aria-current="page"><a [routerLink]="'/sp-category'">Services</a></li>

            <li *ngIf="showSpData" class="breadcrumb-item active" aria-current="page">{{Heading}}</li>

        </ol>
    </div>
</nav>

<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="section-title mb-4">
            <h1 class="mt-0">{{Heading}}</h1>
        </div>
        <div style="width: 100%; text-align: center; margin: 10px 0px;" *ngIf="bannerImage">
            <img src="{{bannerImage}}" />
        </div>


        <div *ngIf="getdata && showSpData && getdata.length > 0" style="margin-bottom:10px; background: #800080; height: 15px;" class="row"></div>


        <div *ngIf="getdata && showSpData" class="row service-brands">
            <div *ngFor="let item of getdata" class="col-12 col-sm-6 col-lg-4">
                <div class="service-brands-inner">
                    <div class="service-logo">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img src="{{websiteurl + 'image/' + item.logoImage}}" alt="">
                            </div>
                            <div class="flip-box-back">
                                <div class="btn-row">
                                    <a [routerLink]="'/service-providers/'+item.slugName" class="btn btn-default">More Details</a>
                                    <br />
                                    <div class="call-mail">
                                        <a href="tel:{{item.phone}}" class="callmail-btn call"></a>
                                        <a href="mailto:{{item.email}}" class="callmail-btn mail"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="service-content">
                        <h6>{{item.companyName}}</h6>
                        <p *ngIf="item.county">{{item.county}} &nbsp;</p>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="childData && childData.length > 0 && !showSpData" class="row service-brands">

            <div *ngFor="let item of childData" class="col-12 col-sm-6 col-lg-4">
                <div class="service-brands-inner">

                    <div class="service-logo">
                        <div class="flip-box-inner">
                            <div class="flip-box-front">
                                <img style="height: 130px; width: 100%; object-fit: cover;" src="{{websiteurl + 'image/' + (item.smallImage ? item.smallImage : item.bannerImage)}}" alt="">
                            </div>
                            <div class="flip-box-back">
                                <div class="btn-row text-center">
                                    <a [routerLink]="'/sp-category/'+item.slugName" class="btn btn-default">See Recommended Providers</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="service-content">
                        <h6>{{item.title}}</h6>
                    </div>
                </div>
            </div>


        </div>

    </div>
</main>
<app-footer-front></app-footer-front>