import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { PageDetailService } from '../../service/pageDetail.service';
@Component({
  selector: 'app-service-detail',
  styleUrls: ['./service-detail.component.css'],
  templateUrl: './service-detail.component.html',
})
export class ServiceDetailComponent implements OnInit {
  public isNaN: Function = isNaN;
  public subcatId: any;
  public id: any;
  public slugName: any;

  public getdata: any;
  public getSpProductList: any;

  public imageData: any;
  public websiteurl: any;
  public defaultImages: any;
  public imageObject: any = [];
  public Heading: any;
  public MainHeading: any;
  public bannerImage: any;

  public currentLink: any;

  public responsiveOptions: any;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private pageDetailService: PageDetailService,
    private authService: AuthService,
  ) {
    this.router.events.subscribe((val) => {
      // see also
      // console.log('router', val);
    });

    this.currentLink = this.router.url;

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numScroll: 3,
        numVisible: 3,
      },
      {
        breakpoint: '991px',
        numScroll: 2,
        numVisible: 2,

      },
      {
        breakpoint: '560px',
        numScroll: 1,
        numVisible: 1,
      },
    ];
  }

  public ngOnInit(): void {
    window.scroll(0, 0);
    this.route.params.subscribe((params) => {
      this.slugName = params.id; // (+) converts string 'id' to a number
      // console.log('this.subcatId', this.subcatId);
      this.websiteurl = global_var.webSiteUrl;
      this.defaultImages = "image/";
      let preview = false;
      if (this.currentLink.indexOf('service-providers-preview') !== -1) {
        preview = true;
      }
      const req = {
        slugName: this.slugName,
        preview,
      };
      this.spinner.show();
      const AngularThis = this;

      this.pageDetailService.getAllServices(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'getAllServices');

          if (res.status === 1) {
            // this.catList = res.data;
            this.getdata = res.data[0];
            // this.spinner.hide();

            const reqNew = { userId: res.data[0].userId };
            this.getSpProductData(reqNew);

            // console.log(this.getdata, 'getdata');

            if (window.localStorage.getItem('categoryList')) {
              const catList = JSON.parse(window.localStorage.getItem('categoryList'));
              const newArr = [];
              catList.forEach((child: any, key) => {
                if (this.getdata.subcatId > 0) {
                  if (child.id === this.getdata.subcatId) {
                    this.Heading = child.title;

                  }
                }

                if (child.id === 45) {
                  this.MainHeading = child.title;
                }
              });
            }

          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 0);
            // this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          // this.showError('error', error);
        },
      );

      if (window.localStorage.getItem('categoryList')) {
        const catList = JSON.parse(window.localStorage.getItem('categoryList'));
      }
    });

  }

  public getSpProductData(req): any {

    this.spinner.show();
    this.pageDetailService.getSpProductData(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'getSpProductData');

        if (res.status === 1) {
          // this.catList = res.data;
          this.getSpProductList = res.data;
          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          // this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      },
    );

  }
  // public isNaN(input)
  // {
  //   if(isNaN(input))
  //     return true;
  //     return false;

  // }
}
