import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getMonitoringMsg(data: any) {
        return this.http.post(global_var.apiUrl + 'contact/getMonitoringMsg', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getNotificationMsg(data: any) {
        return this.http.post(global_var.apiUrl + 'contact/getNotificationMsg', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public deleteMsgsInBulk(data: any) {
        return this.http.post(global_var.apiUrl + 'contact/deleteMsgsInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
