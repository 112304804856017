<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Seller/Buyer Dashboard</li>
        </ol>
    </div>
</nav>
<main class="main-content front login-front  mb-0 pb-5">
    <div class="container">
        <!-- <div class="alert-message">
            <p-messages [(value)]="msgs"></p-messages>
        </div> -->

        <div class="row">
            <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
                <div class="myaccount-links">
                    <ul>
                        <app-sidemenu>

                        </app-sidemenu>
                    </ul>
                </div>
            </div>
            <div class="msg-parent">
                <div *ngFor="let msgObj of msgList">
                    <div>
                        <div *ngIf="msgObj.sender_id==userId" class="msg-box" [ngClass]="(msgObj.sender_id==userId) ?'my-msg':'client-msg'">
                            <div><span>Subject:</span> {{msgObj.subject}}</div>
                            <div><span>To:</span> {{msgObj.sender_name}}</div>
                            <div><span>Email:</span> {{msgObj.sender_email}}</div>


                            <div><span class="msg-label">Message:</span> {{msgObj.message}}</div>
                            <div class="msg-footer">
                                <div class="msg-time">
                                    {{this.calculateDiff(msgObj.datecreated)}} ago
                                </div>
                                <div class="send-icon">
                                    <a href="mailto:{{msgObj.receiver_email}}?subject={{msgObj.subject}}">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
                                            c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
                                            c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
                                            C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
                                            l-74.979-154.599l228.03-228.027L302.883,459.907z" />
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="msgObj.sender_id!=userId" class="msg-box" [ngClass]="(msgObj.sender_id==userId) ?'my-msg':'client-msg'">
                            <div><span>Subject:</span> {{msgObj.subject}}</div>
                            <div><span>From:</span> {{msgObj.sender_name}}</div>
                            <div><span>Email:</span>{{msgObj.contact_email && msgObj.contact_email != '' && msgObj.contact_email != null ? msgObj.contact_email : msgObj.sender_email}}
                            </div>
                            <div> <span class="msg-label">Message: </span>{{msgObj.message}}</div>
                            <div class="msg-footer">
                                <div class="msg-time">
                                    {{this.calculateDiff(msgObj.datecreated)}} ago
                                </div>
                                <div class="send-icon">
                                    <a href="mailto:{{msgObj.sender_email}}?subject={{msgObj.subject}}">
                                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
                                        c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
                                        c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
                                        C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
                                        l-74.979-154.599l228.03-228.027L302.883,459.907z" />
                                                </g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>


                                        </svg>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>
<app-footer-front></app-footer-front>