import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MyaccountService } from '../myaccount/myaccount.service';

@Component({
  selector: 'app-faq',
  styleUrls: ['./faq.component.css'],
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit {
  public cmsdata: any;
  public priceManagementData: any;
  public faqdata: any;
  constructor(
    private myaccountService: MyaccountService,
    private spinner: NgxSpinnerService,

  ) { }

  public ngOnInit(): void {
    window.scrollTo(0, 0);

    const req = { id: 5 };
    this.spinner.show();

    this.myaccountService.getSinglecms(req).subscribe(
      (res: any) => {

        if (res.status === 1) {
          this.cmsdata = res.data;
          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );

    const reqnew = { pageType: 3 };
    this.myaccountService.getAllcms(reqnew).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'getAllcms');

        if (res.status === 1) {
          // this.catList = res.data;
          this.faqdata = res.data;
          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );
  }

}
