import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as global_var from '../../global.variable';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient, private router: Router) { }
  public getPerdayRevenue(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getPerdayRevenue', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getNewUsers(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getNewUsers', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getActiveUsers(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getActiveUsers', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getPublishedAdCount(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getPublishedAdCount', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getNewAdCount(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getNewdAdCount', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getNewestAd(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getNewestAd', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getNewestUsers(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getNewestUsers', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getPublishedAdCountByCat(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getPublishedAdCountByCat', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getNewestNotification(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getNewestNotification', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getExpiringAds(data: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'dashboard/getExpiringAds', data).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
}
