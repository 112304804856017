<main class="main-content">
    <div class="login-form">
        <div class="form-box">
            <div class="logo">
                <img src="../../../assets/images/logo.jpg" alt="logo">
            </div>

            <div class="alert-message">

                <p-messages [(value)]="msgs"></p-messages>
            </div>

            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()" id="forgotPassword">
                <div class="input-group">

                    <div class="input-row">
                        <span class="input-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" fill="#cecdcd">
                                <g>
                                    <g>
                                        <path d="M255.999,0c-74.8043,0-135,60.557-135,135s60.557,135,135,135s135-60.557,135-135S330.442,0,255.999,0z" />
                                    </g>
                                </g>

                                <g>
                                    <g>
                                        <path d="M478.48,398.68C438.124,338.138,370.579,302,297.835,302h-83.672c-72.744,0-140.288,36.138-180.644,96.68l-2.52,3.779V512
                           h450h0.001V402.459L478.48,398.68z" />
                                    </g>
                                </g>
                            </svg>
                        </span>
                        <input type="text" #emailFocus name="email" placeholder="Email" formControlName="email" class="form-control">
                        <!-- Email Validations and Error Message -->
                        <div *ngIf="f.email.errors && f.email.dirty" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email</div>
                            <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                                Please enter valid email</div>
                        </div>
                    </div>
                </div>

                <button class="btn btn-default btn-submit" type="submit">
                    Submit
                </button>

                <!-- <input onclick="onSubmit()" [style.disabled]="submitted?'false':'true'" type="submit" value="Login" class="btn btn-default btn-submit"> -->
                <div class="lost-password">
                    <a class="btn-text" [routerLink]="'/admin/login'">Back to Login</a>
                </div>
            </form>
        </div>
    </div>
</main>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#BA55D3" type="ball-triangle-path" [fullScreen]="true">
    <p style="color:#BA55D3"> Loading... </p>
</ngx-spinner>