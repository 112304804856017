<div class="contact-form">
    <div class="alert-message">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <h2 class="mb-4">Any questions? Contact us</h2>
    <form [formGroup]="dataForm" (ngSubmit)="onSubmit()">
        <div class="form-box p-0">
            <div class="row">
                <div class="p-field col-md-6 input-field">
                    <input formControlName="phone" id="fullName" type="text" placeholder="Full Name" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <input formControlName="email" id="email" type="email" placeholder="Email" pInputText>
                    <div *ngIf="f.email.errors && (f.email.dirty || emailError)" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.</div>
                        <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                            Please enter valid email.</div>
                    </div>
                </div>
                <div class="p-field col-md-12 input-field">
                    <textarea formControlName="message" id="message" placeholder="Message" class="ui-inputtextarea" pInputTextarea></textarea>
                </div>
                <div class="col-md-12 form-btn">
                    <button pButton [disabled]="!(dataForm.valid)" type="submit" class="btn btn-default btn-submit p8-40" label="Submit"></button>
                </div>
            </div>
        </div>
    </form>
</div>