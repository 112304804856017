<header>
  <div class="top-row">
    <div class="container">
      <div class="content-box">
        <div class="email">
          <span class="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="21"
              fill="#fff"
            >
              <g>
                <g>
                  <path
                    d="M377.08,302.227l130.526,138.156C510.42,434.51,512,427.936,512,421V240.644
                                    C496.032,247.932,392.247,295.304,377.08,302.227z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M485.688,149.344l-223.46-101.99c-3.957-1.806-8.5-1.806-12.457,0L26.306,149.347C10.326,156.649,0,172.716,0,190.28
                                    v17.386c2.959,1.351,85.514,39.035,256,116.846l256-116.846V190.28C512,172.715,501.674,156.648,485.688,149.344z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M0,240.644V421c0,6.936,1.58,13.51,4.395,19.383L134.92,302.227C119.731,295.294,15.913,247.907,0,240.644z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M348.243,315.388l-86.014,39.258c-3.956,1.805-8.5,1.806-12.457,0l-86.015-39.258L25.616,461.605
                                    C31.49,464.42,38.063,466,45,466h422c6.937,0,13.51-1.58,19.384-4.395L348.243,315.388z"
                  />
                </g>
              </g>
            </svg>
          </span>
          Email:<a href="mailto:info@racebuykz.com">info@racebuykz.com</a>
        </div>
        <div class="links">
          <div class="link-item" *ngIf="lang == 'fr'">
            <a href="https://racebuykz.com{{ currentLink }}">
              <span class="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30">
                  <clipPath id="a"><path d="M0 0v30h60V0z" /></clipPath>
                  <clipPath id="b">
                    <path d="M30 15h30v15zv15H0zH0V0zV0h30z" />
                  </clipPath>
                  <g clip-path="url(#a)">
                    <path d="M0 0v30h60V0z" fill="#012169" />
                    <path
                      d="m0 0 60 30m0-30L0 30"
                      stroke="#fff"
                      stroke-width="6"
                    />
                    <path
                      d="m0 0 60 30m0-30L0 30"
                      clip-path="url(#b)"
                      stroke="#C8102E"
                      stroke-width="4"
                    />
                    <path
                      d="M30 0v30M0 15h60"
                      stroke="#fff"
                      stroke-width="10"
                    />
                    <path
                      d="M30 0v30M0 15h60"
                      stroke="#C8102E"
                      stroke-width="6"
                    />
                  </g>
                </svg>
              </span>
            </a>
          </div>
          <div class="link-item" *ngIf="lang == 'uk'">
            <a href="https://fr.racebuykz.com{{ currentLink }}">
              <span class="icon">
                <svg viewBox="0 0 900 600" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fill-rule="evenodd">
                    <path fill="#002395" d="M0 0h300v600H0z" />
                    <path fill="#ED2939" d="M600 0h300v600H600z" />
                    <path fill="#FFF" d="M300 0h300v600H300z" />
                  </g>
                </svg>
              </span>
            </a>
          </div>
          <div class="link-item">
            <a [routerLink]="'/adverts/view-wishlist'">
              <span class="icon">
                <svg
                  viewBox="0 -28 512.001 512"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  fill="#fff"
                >
                  <path
                    d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"
                  />
                </svg>
              </span>
              Saved
            </a>
          </div>
          <div class="link-item" *ngIf="checklogin && msgCount > 0">
            <a [routerLink]="'/adverts/view-messages'">
              <span class="icon">
                <!-- <svg id="Capa_1" enable-background="new 0 0 512 512" height="15" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stop-color="#c3ffe8"/><stop offset=".9973" stop-color="#f0fff4"/></linearGradient><linearGradient id="SVGID_2_" gradientTransform="matrix(.707 .707 -.707 .707 3513.242 2186.319)" gradientUnits="userSpaceOnUse" x1="-3668.159" x2="-3668.159" y1="728.754" y2="1043.199"><stop offset="0" stop-color="#00b59c"/><stop offset="1" stop-color="#9cffac"/></linearGradient><g><g><circle cx="256" cy="256" fill="url(#SVGID_1_)" r="256"/></g><g><g><path d="m394.61 105.716-284.636 136.13c-3.015 1.444-4.943 4.484-4.973 7.825-.018 3.354 1.837 6.429 4.84 7.898l88.143 44.072 70.46-70.46c3.42-3.42 8.955-3.42 12.375 0s3.42 8.955 0 12.375l-70.46 70.46 44.072 88.143c1.412 2.866 4.431 4.867 7.898 4.84 3.342-.03 6.381-1.958 7.825-4.973l136.13-284.636c3.203-6.683-4.991-14.877-11.674-11.674z" fill="url(#SVGID_2_)"/></g></g></g></svg>                                 -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Capa_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 512 512"
                  style="enable-background: new 0 0 512 512"
                  xml:space="preserve"
                  width="14"
                  height="16"
                  fill="#ffffff"
                >
                  <g>
                    <g>
                      <path
                        d="M256,0c-37.554,0-68.11,30.556-68.11,68.11v20.55h35.229V68.11c0-18.131,14.755-32.881,32.881-32.881    c18.131,0,32.887,14.749,32.887,32.881v20.55h35.229V68.11C324.116,30.556,293.555,0,256,0z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M304.147,429.205c0,26.228-21.337,47.565-47.56,47.565h-1.174c-26.222,0-47.56-21.337-47.56-47.565h-35.229    c0,45.657,37.138,82.795,82.789,82.795h1.174c45.651,0,82.789-37.138,82.789-82.795H304.147z"
                      />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="M483.952,422.623l-50.043-77.851v-99.928c0-99.071-79.812-179.67-177.908-179.67c-98.102,0-177.908,80.599-177.908,179.67    v99.928l-50.043,77.845c-3.488,5.419-3.734,12.313-0.646,17.967c3.088,5.654,9.013,9.177,15.46,9.177h426.275    c6.447,0,12.371-3.523,15.454-9.171C487.686,434.936,487.44,428.043,483.952,422.623z M75.127,414.532l35.394-55.063    c1.826-2.836,2.801-6.148,2.801-9.524V244.844c0-79.642,64.006-144.44,142.679-144.44c78.679,0,142.679,64.799,142.679,144.44    v105.101c0,3.376,0.969,6.682,2.795,9.524l35.394,55.063H75.127z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <span class="ui-messages-icon-count">{{ msgCount }}</span>
            </a>
          </div>

          <div class="link-item last">
            <a *ngIf="!checklogin" [routerLink]="'/login'">
              <span class="icon">
                <svg
                  viewBox="0 -32 512.016 512"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="m192 213.339844c-58.816406 0-106.667969-47.847656-106.667969-106.664063 0-58.816406 47.851563-106.6679685 106.667969-106.6679685s106.667969 47.8515625 106.667969 106.6679685c0 58.816407-47.851563 106.664063-106.667969 106.664063zm0-181.332032c-41.171875 0-74.667969 33.492188-74.667969 74.667969 0 41.171875 33.496094 74.664063 74.667969 74.664063s74.667969-33.492188 74.667969-74.664063c0-41.175781-33.496094-74.667969-74.667969-74.667969zm0 0"
                  />
                  <path
                    d="m368 448.007812h-352c-8.832031 0-16-7.167968-16-16v-74.667968c0-55.871094 45.460938-101.332032 101.332031-101.332032h181.335938c55.871093 0 101.332031 45.460938 101.332031 101.332032v74.667968c0 8.832032-7.167969 16-16 16zm-336-32h320v-58.667968c0-38.226563-31.105469-69.332032-69.332031-69.332032h-181.335938c-38.226562 0-69.332031 31.105469-69.332031 69.332032zm0 0"
                  />
                  <path
                    d="m496 218.675781h-181.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h181.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                  />
                  <path
                    d="m410.667969 304.007812c-4.097657 0-8.191407-1.558593-11.308594-4.691406-6.25-6.253906-6.25-16.386718 0-22.636718l74.027344-74.027344-74.027344-74.027344c-6.25-6.25-6.25-16.382812 0-22.632812s16.382813-6.25 22.636719 0l85.332031 85.332031c6.25 6.25 6.25 16.386719 0 22.636719l-85.332031 85.332031c-3.136719 3.15625-7.234375 4.714843-11.328125 4.714843zm0 0"
                  />
                </svg>
              </span>
              Login
            </a>

            <a *ngIf="checklogin" (click)="logout()">
              <span class="icon">
                <svg
                  viewBox="0 -32 512.016 512"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                >
                  <path
                    d="m192 213.339844c-58.816406 0-106.667969-47.847656-106.667969-106.664063 0-58.816406 47.851563-106.6679685 106.667969-106.6679685s106.667969 47.8515625 106.667969 106.6679685c0 58.816407-47.851563 106.664063-106.667969 106.664063zm0-181.332032c-41.171875 0-74.667969 33.492188-74.667969 74.667969 0 41.171875 33.496094 74.664063 74.667969 74.664063s74.667969-33.492188 74.667969-74.664063c0-41.175781-33.496094-74.667969-74.667969-74.667969zm0 0"
                  />
                  <path
                    d="m368 448.007812h-352c-8.832031 0-16-7.167968-16-16v-74.667968c0-55.871094 45.460938-101.332032 101.332031-101.332032h181.335938c55.871093 0 101.332031 45.460938 101.332031 101.332032v74.667968c0 8.832032-7.167969 16-16 16zm-336-32h320v-58.667968c0-38.226563-31.105469-69.332032-69.332031-69.332032h-181.335938c-38.226562 0-69.332031 31.105469-69.332031 69.332032zm0 0"
                  />
                  <path
                    d="m496 218.675781h-181.332031c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h181.332031c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
                  />
                  <path
                    d="m410.667969 304.007812c-4.097657 0-8.191407-1.558593-11.308594-4.691406-6.25-6.253906-6.25-16.386718 0-22.636718l74.027344-74.027344-74.027344-74.027344c-6.25-6.25-6.25-16.382812 0-22.632812s16.382813-6.25 22.636719 0l85.332031 85.332031c6.25 6.25 6.25 16.386719 0 22.636719l-85.332031 85.332031c-3.136719 3.15625-7.234375 4.714843-11.328125 4.714843zm0 0"
                  />
                </svg>
              </span>
              Logout
            </a>
          </div>

          <a
            *ngIf="!checklogin"
            [routerLink]="'/adverts/place-ad'"
            class="sell-here-btn"
          >
            <b>SELL HERE</b>
          </a>

          <a
            *ngIf="checklogin && usertype == 2"
            [routerLink]="'/adverts/place-ad'"
            class="sell-here-btn"
          >
            <b>My Account</b>
          </a>

          <a
            *ngIf="checklogin && usertype == 3"
            [routerLink]="'/advertise-here/edit-profile'"
            class="sell-here-btn"
          >
            <b>My Account</b>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="logo-and-main-menu">
      <div class="logo">
        <a [routerLink]="'/'">
          <img src="../../../assets/images/logo.jpg" alt="logo" />
        </a>
      </div>
      <div class="main-navigation">
        <span (click)="toggleMenu()" class="lnr lnr-menu toggle-btn">
          <svg
            aria-hidden="true"
            focusable="false"
            data-icon="bars"
            fill="#fff"
            width="16"
            height="15"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="#fff"
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
            ></path>
          </svg>
        </span>
        <div class="nav-links {{ openClass }}">
          <ul *ngIf="catList">
            <ng-container *ngFor="let item of catList; let i = index">
              <li
                class="{{
                  catId == item.slugName ||
                  (item.id == 45 &&
                    (currentLink == '/sp-category' ||
                      currentLink.includes('sp-category')))
                    ? 'active'
                    : ''
                }}"
                *ngIf="item.parentId == 0"
              >
                <span
                  (click)="subMenuClicked($event)"
                  class="pi pi-fw pi-chevron-down mob-sub-toggle"
                ></span>

                <a
                  *ngIf="item.id != 45"
                  [routerLink]="'/category/' + item.slugName"
                  >{{ item.title }}
                  <!-- {{item.totalPost}} -->
                </a>

                <a *ngIf="item.id == 45" [routerLink]="'/sp-category'"
                  >{{ item.title }}
                  <!-- {{item.totalPost}} -->
                </a>

                <div class="submenu">
                  <ul>
                    <ng-container *ngFor="let child of catList; let i = index">
                      <li
                        class="{{ subCatId == child.slugName ? 'active' : '' }}"
                        *ngIf="child.parentId == item.id"
                      >
                        <a
                          *ngIf="item.id != 45"
                          [routerLink]="
                            '/category/' + item.slugName + '/' + child.slugName
                          "
                          >{{ child.title }}</a
                        >

                        <a
                          *ngIf="item.id == 45"
                          [routerLink]="'/sp-category/' + child.slugName"
                          >{{ child.title }}</a
                        >
                      </li>
                      <li
                        class="{{ catId == 'minibikes' ? 'active' : '' }}"
                        *ngIf="child.id == 88 && item.id == 1"
                      >
                        <a [routerLink]="'/category/minibikes'">Minibikes</a>
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </li>
            </ng-container>
            <!-- <li><a href="#">Parts For Sale</a></li>
                        <li><a href="#">Garage & Pitlane </a></li>
                        <li><a href="#">Transport & Paddock</a></li>
                        <li><a href="#">Services</a></li> -->

            <li
              class="{{
                currentLink.includes('ad-fees') ||
                currentLink.includes('faq') ||
                currentLink.includes('about-us')
                  ? 'active'
                  : ''
              }}"
            >
              <span
                (click)="subMenuClicked($event)"
                class="pi pi-fw pi-chevron-down mob-sub-toggle"
              ></span>
              <a>Info</a>
              <div class="submenu">
                <ul>
                  <li class="{{ currentLink == '/ad-fees' ? 'active' : '' }}">
                    <a [routerLink]="['/ad-fees']">Ad Fees</a>
                  </li>
                  <li class="{{ currentLink == '/faq' ? 'active' : '' }}">
                    <a [routerLink]="['/faq']">FAQ's</a>
                  </li>
                  <li class="{{ currentLink == '/about-us' ? 'active' : '' }}">
                    <a [routerLink]="['/about-us']">History</a>
                  </li>
                </ul>
              </div>
            </li>

            <li class="{{ currentLink == '/contact-us' ? 'active' : '' }}">
              <a [routerLink]="'/contact-us'">Contact Us</a>
            </li>
          </ul>
        </div>
        <div (click)="toggleMenu()" class="nav-bg"></div>
      </div>
    </div>
  </div>
</header>
