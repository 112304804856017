import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllCategory(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllCategory', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSingleCategory(req): any {
        return this.http.post(global_var.apiUrl + 'getSingleCategory', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveCategory(data: any): any {
        return this.http.post(global_var.apiUrl + 'createCategory', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public deleteCategory(data: any): any {
        return this.http.post(global_var.apiUrl + 'deleteCategory', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
