<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="alert-message">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="dataForm">
        <div class="page-title">
            <h1 class="mt-0"> {{paramId ? "Edit" : "Add" }} Service Product</h1>
        </div>
        <div class="form-container">
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-field col-md-6 input-field">
                    <label>Title</label>
                    <input type="text" formControlName="title" placeholder="" value="" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label>Link</label>
                    <input type="text" formControlName="link" placeholder="" value="" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label>Price</label>
                    <input type="text" formControlName="price" placeholder="" value="" pInputText> </div>
                <!-- <div class="p-field col-md-6 input-field">
                    <label>Price On Application (Optional)</label>
                    <input type="text" formControlName="poa" placeholder="" value="" pInputText> </div> -->
                <div class="p-field col-md-6 input-field">
                    <label>Description</label>
                    <textarea id="description" formControlName="description" class="ui-inputtext" rows="7"
                        pinputtext></textarea>
                    <small class="help-block pull-right">250-2000 Characters</small>
                </div>
            </div>
            <div class="row">
                <div class="col-6 col-sm-4 col-md-3 col-lg-2 text-center upphoto">
                    <label class="d-block text-left">Add image</label>
                    <div class="photo-item">
                        <img (click)="openEditor('image')" *ngIf="image" src="{{image}}" />
                        <span>
                            <label class="upload-file">
                                <!-- <p-fileUpload class="fileUploadAd" class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileChangeEvent($event, form,'image')" chooseLabel="Image" accept="image/*"></p-fileUpload> -->


                                <input accept="image/*" #form type="file" id="file"
                                    (change)="fileChangeEvent($event.target,form,'image')">

                            </label>
                        </span>
                        <div class="image-actions">
                            <label (click)="deleteImage('image')" class="delete" title="Delete"><svg aria-hidden="true"
                                    focusable="false" data-prefix="fas" data-icon="minus-square" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor"
                                        d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z">
                                    </path>
                                </svg></label>
                            <label class="edit upload-file" title="Edit">
                                <!-- <p-fileUpload (uploadHandler)="fileChangeEvent($event, form,'image')" #form customUpload="true" auto="auto" mode="basic" accept="image/*"></p-fileUpload> -->
                                <input accept="image/*" #form type="file" id="file"
                                    (change)="fileChangeEvent($event.target,form,'image')">

                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen-square"
                                    role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor"
                                        d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM238.1 177.9L102.4 313.6l-6.3 57.1c-.8 7.6 5.6 14.1 13.3 13.3l57.1-6.3L302.2 242c2.3-2.3 2.3-6.1 0-8.5L246.7 178c-2.5-2.4-6.3-2.4-8.6-.1zM345 165.1L314.9 135c-9.4-9.4-24.6-9.4-33.9 0l-23.1 23.1c-2.3 2.3-2.3 6.1 0 8.5l55.5 55.5c2.3 2.3 6.1 2.3 8.5 0L345 199c9.3-9.3 9.3-24.5 0-33.9z">
                                    </path>
                                </svg>
                            </label>
                        </div>
                    </div>
                    <!-- <small class="help-block text-left d-block pt-2">1170px x 375px</small> -->
                </div>
            </div>
        </div>
    </form>
    <div class="form-btn align-right">
        <button type="submit" [disabled]="!(dataForm.valid)" class="btn btn-primary" (click)="onSubmit()">
            <span class="ui-button-text ui-clickable">{{paramId ? "Update" : "Save" }}</span>
        </button>
    </div>
</main>

<!-- <div id="upload-crop-cover-modal" class="modal upload-crop-cover-modal is-xxl has-light-bg" [ngClass]="{'is-active':showImagePicker}">
    <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
    <div class="modal-content">
        <app-image-picker [(showImagePicker)]="showImagePicker" [(imagePickerImageUrl)]="imagePickerImageUrl" (imagePickerImageUrlChange)="fileUpload()" *ngIf="showImagePicker"></app-image-picker>
    </div>
</div> -->