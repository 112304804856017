import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { DiscountService } from './discount.service';

@Component({
  selector: 'app-discount',
  templateUrl: './discount.component.html',
  styleUrls: ['./discount.component.css']
})
export class DiscountComponent implements OnInit {
  public dataList: any[] = [];
  public matsterList: any[] = [];
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public sortlist: any = [];
  constructor(
    private spinner: NgxSpinnerService,
    private discountService: DiscountService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
  ) {
    this.sortlist =
      [
        { title: 'Date Created Ascending', value: 0 },
        { title: 'Date Created Descending', value: 1 },
        { title: 'Date Expire Ascending', value: 2 },
        { title: 'Date Expire Descending', value: 3 },
        { title: 'Date Start Ascending', value: 4 },
        { title: 'Date Start Descending', value: 5 },
      ];
   }

  ngOnInit(): void {
    this.getAllCodeDetails()
  }
  public getAllCodeDetails() {
    this.spinner.show()
    let req = {}
    this.discountService.getAllCodeDetails(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1) {
        this.matsterList = res.data;
        this.dataList = res.data;
      }
      else {
        this.showError('error', 'Oops somthing went wrong.')
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops somthing went wrong.')
    })
  }
  public searchTextChange() {
    let search_text = this.searchText.toUpperCase()
    this.dataList = this.matsterList.filter(obj =>
      (obj.promo_code.toUpperCase().includes(search_text)))
  }
  public changeSortBy(event)
  {
    if (event.value.value == 0)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.created_time),
        keyB = new Date(b.created_time);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    else if (event.value.value == 1)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.created_time),
        keyB = new Date(b.created_time);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    else if (event.value.value == 2)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.expiry_time),
        keyB = new Date(b.expiry_time);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    else if (event.value.value == 3)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.expiry_time),
        keyB = new Date(b.expiry_time);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    else if (event.value.value == 4)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.start_time),
        keyB = new Date(b.start_time);
      // Compare the 2 dates
      if (keyA < keyB) return -1;
      if (keyA > keyB) return 1;
      return 0;
    });
    else if (event.value.value == 5)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.start_time),
        keyB = new Date(b.start_time);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  }
  public deletemethod(id: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete ?',
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: '',
      accept: () => {
        this.spinner.show();
        let req = {
          id: id
        }
        this.discountService.deleteCode(req).subscribe((res: any) => {
          this.spinner.hide();
          if (res.status == 1) {
            this.getAllCodeDetails();
            this.showError('success', 'Code deleted successfully.')
          }
          else {
            this.showError('error', 'Oops somthing went wrong.')
          }
        }, (err: any) => {
          this.spinner.hide();
          this.showError('error', 'Oops somthing went wrong.')
        })
      },
    });

  }
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }
  public getDateFormate(date): String {
    if (date == '' || date == undefined)
      return ''
    return (new Date(date)).toLocaleDateString()
  }
}
