<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
<div class="alert-message">
    <p-messages [(value)]="msgs"></p-messages>
</div>

<form [formGroup]="DiscountForm" (ngSubmit)="onSubmit()" id="formAdd">

    <div class="page-title">
        <h1>{{paramId ? 'Edit' : 'Add'}} Discount</h1>
    </div>

    <div class="form-container">

        <div class="ui-fluid p-formgrid p-grid">

            <div class="p-field col-md-6 input-field">
                <label for="firstname">Discount Code<span class="required">&#42;</span></label>
                <input id="firstname" #firstnamefocus formControlName="discountCode" type="text" placeholder="Enter Discount Code" pInputText>
            </div>

            <div class="p-field col-md-6 input-field">
                <label for="lastname">Discount Percentage(%)<span class="required">&#42;</span></label>
                <input id="lastname" formControlName="discountPercentage" type="number" placeholder="Enter Discount Percentage" pInputText>
            </div>
            <div class="p-field col-md-6 input-field">
                <label for="email">Discount Minimum Amount<span class="required">&#42;</span></label>
                <input id="email" formControlName="discountMinAmount" type="number" placeholder="Enter Discount Minimum Amount" pInputText>                
            </div>
            <div class="p-field col-md-6 input-field">
                <label for="username">Discount Maximum Amount<span class="required">&#42;</span></label>
                <input id="username" formControlName="discountMaxAmount" type="text" placeholder="Enter Discount Maximum Amount" pInputText>
            </div>
            <div class="p-field col-md-6 input-field">
                <label for="">Valid From<span class="required">&#42;</span></label>
                <input formControlName="startTime" id="postcode" type="date" [min]="currentDate"
                    placeholder="Enter Expiry Date" pInputText>
            </div>
            <div class="p-field col-md-6 input-field">
                <label for="">Valid Till<span class="required">&#42;</span></label>
                <input formControlName="expiryTime" id="postcode" type="date" [min]="currentDate"
                    placeholder="Enter Expiry Date" pInputText>
            </div>
        </div>
    </div>
    <div class="form-btn align-right">
        <button pButton type="submit" label="Submit"></button> &nbsp;<button (click)="onSubmit()" pButton
            type="button" label="Cancel"></button>
    </div>
</form>
</main>