<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" *ngIf="cmsdata" aria-current="page">{{cmsdata.title}}</li>
        </ol>
    </div>
</nav>
<main class="main-content front login-front">
    <div class="container">
        <div class="section-title mb-5">
            <h1 *ngIf="cmsdata" class="mt-0">{{cmsdata.title}}</h1>
        </div>

        <p-accordion *ngIf="faqdata" class="quesAns-sec pb-5">

            <ng-container *ngFor="let item of faqdata">
                <p-accordionTab class="quesAns" header="{{item.title}}">
                    <p>{{item.shortDescription}}</p>
                </p-accordionTab>
            </ng-container>

            <!-- <p-accordionTab class="quesAns" header="WHY SHOULD I ADVERTISE WITH RACEBUYKZ.COM?">
                <p>Racebuykz.com is the only classified ads website focused 100% on Motorcycle Racing and Trackday audiences. This means when you post an ad, you will reach a vast, niche audience who are genuinely interested in what you have to sell. Our site attracts over 15,000 individual visitors per month, and we promote your advert via our social media channels, which exposes you to over 23,000 followers. With modest fees and no sales commission, it offers fantastic value for money…</p>
            </p-accordionTab> -->
            <!--             
            <p-accordionTab class="quesAns" header="HOW MUCH DOES IT COST TO PLACE AN AD?">
                <p>Ads can cost anywhere between £3 and £25 depending on what you have to sell and how long you would like your ad to run for. You can find a table of costs at <a [routerLink]="'/terms-conditions'">‘Ts&Cs’</a>.</p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="Do you take any comission when I sell?">
                <p>We take no sales commission when you sell your item and leave the entire process between Buyer and Seller to you guys. There is one clear up-front payment to place your ad on Racebuykz.com. That’s it!</p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="Can I manage / edit my ad after I post it?">
                <p>Yes! Using the ‘Edit Ad’ feature, you can alter the detail of your Ad, at any time. Including; Description, Images and Price etc.
                </p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="How do I pay for my ad?">
                <p>All payments for Ads are collected for Racebuykz Limited via our established and trustworthy PayPal payments gateway. This enables you to pay your fee via your preferred payment method.</p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="Are there any restrictions to what I can advertise?">
                <p>Once you have successfully registered to advertise on Racebuykz.com, you can advertise any number of relevant items for sale. Please note: In order to maintain the identity and exclusivity of the site, we retain the right to remove and refund any adverts that do not reflect Motorcycle Racing or Trackday application. This would include for example: Road bikes, standard bodywork etc. We do not encourage or allow business advertising or adverts for new or used tyres.
                </p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="How can my business benefit from partnering with racebykz.com?">
                <p>We have created a Service Providers platform, to enable those that supply products and services to the paddock, to advertise. This is split into 9 defined categories, which means that thousands of website visitors can easily find the businesses that will help them go faster on track! </p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="How do I advertise my business to the right audience?">
                <p>Our team will help you quickly produce a bespoke Service Provider Portal, which will extend your digital reach to the right audiences. We will expand your customer contact by replicating links to your website and social media channels. The portal provides you with the opportunity to feature 6 products/services/offers at any given time. We also offer our Service Providers a number of additional advertising opportunities.</p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="What role does racebuykz.com play buying items through the site?">
                <p>Racebuykz.com acts as an advertising platform for those with items to sell. It encourages direct communication between buyers and sellers and takes no part in any negotiation, terms of sale or guarantee of goods.
                </p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="Do I pay through the website when I purchase an item?">
                <p>No. All payments are discussed and agreed privately between buyer and seller. No financial transactions to purchase advertised items, take place on our website.
                </p>
            </p-accordionTab>
            <p-accordionTab class="quesAns" header="How do I contact a seller?">
                <p>A seller’s contact details will always be found at the bottom of their advertisement. We encourage all advertisers to provide a contact telephone number and you can contact them via email by clicking the ‘Send Message’ button!</p>
            </p-accordionTab> -->
        </p-accordion>





    </div>
    <section class="sell-bike mt-5 pb-0 pb-md-5">
        <div class="contact-form-section">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 bike-img">
                        <img src="../../../../assets/images/footer-bike.png" alt="bike">
                    </div>
                    <div class="col-md-7">
                        <app-contact-form></app-contact-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
<app-footer-front></app-footer-front>