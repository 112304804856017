<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Contact
                {{(postid > 0 ? 'Seller' : 'Us')}}</li>
        </ol>
    </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
    <div class="container {{(postid > 0 ? 'position-static' : '')}}">
        <div class="row">
            <div class="col-12 col-lg-7 ">
                <div class="alert-message">
                    <p-messages [(value)]="msgs"></p-messages>
                </div>
                <div class="section-title mb-4">

                    <h1 class="mt-0">Contact {{(postid > 0 ? 'Seller' : 'Us')}}</h1>
                    <p *ngIf="!isContactSeller">
                        For help & questions, please contact us at <a href="mailto:info@racebuykz.com">info@racebuykz.com</a> or fill in the form below:
                    </p>
                </div>
                <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="input-lg">
                    <div class="row">
                        <div class="p-field col-12 col-md-6 input-field mb-4">
                            <input type="text" formControlName="name" placeholder="Name" pInputText>
                        </div>
                        <div class="p-field col-12 col-md-6 input-field required mb-4">
                            <input type="text" placeholder="Phone" formControlName="phone" pInputText>
                        </div>
                        <div class="p-field col-12 col-md-6 input-field required mb-4">
                            <input type="text" formControlName="email" placeholder="Email" pInputText>
                            <div *ngIf="f.email.errors && (f.email.dirty || emailError)" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.</div>
                                <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                                    Please enter valid email.</div>
                            </div>
                        </div>
                        <div class="p-field col-12 col-md-6 input-field required mb-4">
                            <input type="text" formControlName="subject" placeholder="Subject" pInputText>
                        </div>
                        <div class="p-field col-12 input-field mb-4">
                            <textarea formControlName="message" class="ui-inputtext" placeholder="Message" rows="7" pinputtext></textarea>
                        </div>
                        <div *ngIf="!isLoggedIn" class="p-field col-12 input-field mb-4">
                            <div class="form-check">
                                <input style="border: 1px solid #ccc;" formControlName="isAgreeTerms" type="checkbox" class="form-check-input" id="terms-and-conditions">
                                <label for="terms-and-conditions">I agree to Racebuykz Terms and Conditions
                                    and Privacy Policy</label>
                            </div>
                        </div>
                        <div class="col-12">
                            <button [disabled]="!(dataForm.valid)" type="submit" class="btn btn-default pull-left px-30">
                                <span class="ui-button-text ui-clickable">Submit</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-12 col-lg-5 d-none d-lg-block text-right {{(postid > 0 ? 'right-bike-img' : '')}}">
                <!-- <img src="../../../../assets/images/drop-us-mail.jpg" alt="contact us"> -->
                <img src="../../../../assets/images/{{(postid > 0 ? 'contact-seller-img.png' : 'drop-us-mail.jpg')}}" alt="contact seller">

            </div>
        </div>
    </div>
</main>
<app-footer-front></app-footer-front>