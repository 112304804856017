import { Component, OnInit } from '@angular/core';
import { DashboardService } from './dashboard.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  public revenuedata: any;
  public newuserdata: any;
  public activeuserdata: any;
  public publishedaddata: any;
  public newaddata: any;
  public newestAdList: any = [];
  public newestUsersList: any = [];
  public publishedadbycatList: any = [];
  public newestNotificationList: any = [];
  public expiringAdList: any = [];
  public revenueFilterType = { id: 4, value: 'Year' };
  public newUserFilterType = { id: 4, value: 'Year' };
  public activeUserFilterType = { id: 4, value: 'Year' };
  public publishedAdFilterType = { id: 4, value: 'Year' };
  public newAdFilterType = { id: 4, value: 'Year' };
  public expiringAdFilterType = { id: 3, value: 'This Week' };
  public week_days: any = [];
  public month_names: any = [];
  public days_inMonth: any = [];
  public current_day: any = [];
  public filterList: any = [];
  public optionsObject: any;
  public expiringAdFilterList: any = [];
  public revenueLoader = false;
  public newUserLoader = false;
  public activeUserLoader = false;
  public publishedAdLoader = false;
  public newAdLoader = false;
  public current_month_name: string = '';
  public current_year: number = 0;
  constructor(private dashboardService: DashboardService) {
    this.current_month_name = new Date().toLocaleString('default', {
      month: 'long',
    });
    this.current_year = new Date().getFullYear();
    this.current_day = ['Today'];
    this.week_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    this.month_names = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    this.filterList = [
      {
        id: 1,
        value: 'Day',
      },
      {
        id: 2,
        value: 'Week',
      },
      {
        id: 3,
        value: 'Month',
      },
      {
        id: 4,
        value: 'Year',
      },
    ];
    this.expiringAdFilterList = [
      {
        id: 1,
        value: 'Today',
      },
      {
        id: 2,
        value: 'Tomorrow',
      },
      {
        id: 3,
        value: 'This Week',
      },
    ];
  }

  ngOnInit(): void {
    for (let i = 1; i <= this.getDaysInMonthInOrder(); i++) {
      this.days_inMonth.push(i);
    }
    // this.getWeekDays();
    // this.getMonthNames();
    // this.getDaysInMonth();
    this.getRevenueData(this.revenueFilterType.value);
    this.getNewUsersData(this.newUserFilterType.value);
    this.getActiveUsers(this.activeUserFilterType.value);
    this.getPublishedAdCount(this.publishedAdFilterType.value);
    this.getNewAdCount(this.newAdFilterType.value);
    this.getNewestAd();
    this.getNewestUsers();
    this.getPublishedAdCountByCat();
    this.getNewestNotification();
    this.getExpiringAds(this.expiringAdFilterType.value);

    this.optionsObject = {
      legend: {
        display: true,
      },
      title: {
        display: true,
        position: 'center',
      },
      scales: {
        yAxes: [
          {
            display: true,
            scaleLabel: {
              show: false,
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              stepSize: 1,
            },
          },
        ],
        xAxes: [
          {
            ticks: {
              display: true,
              beginAtZero: 0,
            },
          },
        ],
      },
    };
  }
  public getRevenueData(filterType: string) {
    this.revenueLoader = true;
    let req = {
      filterType: filterType,
    };
    this.dashboardService.getPerdayRevenue(req).subscribe(
      (res: any) => {
        this.revenueLoader = false;
        if (res.status == 1) {
          this.revenuedata = {
            labels: this.getLebel(this.revenueFilterType.value),
            datasets: [
              {
                label: 'Sale',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.parseData(res.data, this.revenueFilterType.value),
              },
            ],
          };
        }
      },
      (err: any) => {
        this.revenueLoader = false;
      }
    );
  }
  public getNewUsersData(filterType: string) {
    this.newUserLoader = true;
    let req = {
      filterType: filterType,
    };
    this.dashboardService.getNewUsers(req).subscribe(
      (res: any) => {
        this.newUserLoader = false;
        if (res.status == 1) {
          this.newuserdata = {
            labels: this.getLebel(this.newUserFilterType.value),
            datasets: [
              {
                label: 'Users',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.parseData(res.data, this.newUserFilterType.value),
              },
            ],
            options: {
              scales: {
                yAxes: [
                  {
                    ticks: {
                      // Include a dollar sign in the ticks
                      precision: 0,
                      fixedStepSize: 1,
                      stepSize: 1,
                      beginAtZero: true,
                      callback: function (value, index, values) {
                        debugger;
                        return '$' + value + values + index;
                      },
                    },
                  },
                ],
              },
            },
          };
        }
      },
      (err: any) => {
        this.newUserLoader = false;
      }
    );
  }
  public getActiveUsers(filterType: string) {
    this.activeUserLoader = true;
    let req = {
      filterType: filterType,
    };
    this.dashboardService.getActiveUsers(req).subscribe(
      (res: any) => {
        this.activeUserLoader = false;
        if (res.status == 1) {
          this.activeuserdata = {
            labels: this.getLebel(this.activeUserFilterType.value),
            datasets: [
              {
                label: 'Users',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.parseData(res.data, this.activeUserFilterType.value),
              },
            ],
          };
        }
      },
      (err: any) => {
        this.activeUserLoader = false;
      }
    );
  }
  public getPublishedAdCount(filterType: string) {
    this.publishedAdLoader = true;
    let req = {
      filterType: filterType,
    };
    this.dashboardService.getPublishedAdCount(req).subscribe(
      (res: any) => {
        this.publishedAdLoader = false;
        if (res.status == 1) {
          this.publishedaddata = {
            labels: this.getLebel(this.publishedAdFilterType.value),
            datasets: [
              {
                label: 'Published Ad',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.parseData(
                  res.data,
                  this.publishedAdFilterType.value
                ),
              },
            ],
          };
        }
      },
      (err: any) => {
        this.publishedAdLoader = false;
      }
    );
  }
  public getNewAdCount(filterType: string) {
    this.newAdLoader = true;
    let req = {
      filterType: filterType,
    };
    this.dashboardService.getNewAdCount(req).subscribe(
      (res: any) => {
        this.newAdLoader = false;
        if (res.status == 1) {
          this.newaddata = {
            labels: this.getLebel(this.newAdFilterType.value),
            datasets: [
              {
                label: 'New Ad',
                backgroundColor: '#42A5F5',
                borderColor: '#1E88E5',
                data: this.parseData(res.data, this.newAdFilterType.value),
              },
            ],
          };
        }
      },
      (err: any) => {
        this.newAdLoader = false;
      }
    );
  }
  public getNewestAd() {
    let req = {
      order: 'DESC',
      limit: '6',
    };
    this.dashboardService.getNewestAd(req).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 1) {
          this.newestAdList = res.data;
        }
      },
      (err: any) => {}
    );
  }
  public getNewestUsers() {
    let req = {
      order: 'DESC',
      limit: '6',
    };
    this.dashboardService.getNewestUsers(req).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 1) {
          this.newestUsersList = res.data;
        }
      },
      (err: any) => {}
    );
  }
  public getPublishedAdCountByCat() {
    let req = {
      order: 'DESC',
      limit: '6',
    };
    this.dashboardService.getPublishedAdCountByCat(req).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 1) {
          this.publishedadbycatList = res.data;
        }
      },
      (err: any) => {}
    );
  }
  public getNewestNotification() {
    let req = {
      order: 'DESC',
      limit: '6',
    };
    this.dashboardService.getNewestNotification(req).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 1) {
          this.newestNotificationList = res.data;
        }
      },
      (err: any) => {}
    );
  }
  public getExpiringAds(filterType: string) {
    let req = {
      order: 'DESC',
      limit: '6',
      filterType: filterType,
    };
    this.dashboardService.getExpiringAds(req).subscribe(
      (res: any) => {
        console.log(res);
        if (res.status == 1) {
          this.expiringAdList = res.data;
        }
      },
      (err: any) => {}
    );
  }
  public getLebel(type): any {
    switch (type.toLocaleLowerCase()) {
      case 'day':
        return this.current_day;
      case 'week':
        return this.week_days;
      case 'year':
        return this.month_names;
      case 'month':
        return this.days_inMonth;
      case 'default':
        break;
    }
  }
  public getWeekDays() {
    this.week_days = [];
    for (let i = 6; i >= 0; i--) {
      this.week_days.push(
        new Date(new Date().setDate(new Date().getDate() - i))
          .toString()
          .split(' ')[0]
      );
    }
  }
  public getMonthNames() {
    this.month_names = [];
    for (let i = 11; i >= 0; i--) {
      this.month_names.push(
        new Date(new Date().setMonth(new Date().getMonth() - i))
          .toString()
          .split(' ')[1]
      );
    }
  }
  public parseData(data: any[], filter_type): any[] {
    let parseData: any = [];
    filter_type = filter_type ? filter_type.toLocaleLowerCase() : '';
    if (filter_type == 'week')
      this.week_days.forEach((element) => {
        let res_data = data.find(
          (o) => new Date(o.dateCreated).toDateString().split(' ')[0] == element
        );
        if (res_data) parseData.push(res_data.total);
        else parseData.push(0);
      });
    if (filter_type == 'month') {
      this.days_inMonth.forEach((element) => {
        let res_data = data.find(
          (o) => new Date(o.dateCreated).getDate() == element
        );
        if (res_data) parseData.push(res_data.total);
        else parseData.push(0);
      });
    }
    if (filter_type == 'year')
      this.month_names.forEach((element) => {
        let res_data = data.find(
          (o) => new Date(o.dateCreated).toDateString().split(' ')[1] == element
        );
        if (res_data) parseData.push(res_data.total);
        else parseData.push(0);
      });
    if (filter_type == 'day') {
      if (data.length > 0) parseData.push(data[0].total);
      else parseData.push(0);
    }

    return parseData;
  }
  public revenueFilterClick(event) {
    this.getRevenueData(this.revenueFilterType.value);
  }
  public newUserFilterClick(event) {
    this.getNewUsersData(this.newUserFilterType.value);
  }
  public activeUserFilterClick(event) {
    this.getActiveUsers(this.activeUserFilterType.value);
  }
  public publishedAdFilterClick(event) {
    this.getPublishedAdCount(this.publishedAdFilterType.value);
  }
  public newAdFilterClick(event) {
    this.getNewAdCount(this.newAdFilterType.value);
  }
  public expiringAdFilterClick(event) {
    this.getExpiringAds(this.expiringAdFilterType.value);
  }
  public getDaysInMonth() {
    let d = new Date();
    let day_count = new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
    this.days_inMonth = [];
    for (let i = day_count - 2; i >= 0; i--) {
      this.days_inMonth.push(
        new Date(new Date().setDate(new Date().getDate() - i))
          .toString()
          .split(' ')[2]
      );
    }
  }
  public getDaysInMonthInOrder(): number {
    let d = new Date();
    return new Date(d.getFullYear(), d.getMonth() + 1, 0).getDate();
  }
  public calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);
    let responseString = '';
    let res = Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours()
          )) /
          (1000 * 60 * 60)
      );

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor(
          (Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes()
          ) -
            Date.UTC(
              dateSent.getFullYear(),
              dateSent.getMonth(),
              dateSent.getDate(),
              dateSent.getHours(),
              dateSent.getMinutes()
            )) /
            (1000 * 60)
        );

        responseString = res + ' mins';
      }
    }
    return responseString;
  }
  public calculateExpireTime(dateSent): string {
    let out_put;
    let diff = new Date(dateSent).getDate() - new Date().getDate();
    if (diff == 0) out_put = 'Today';
    else if (diff == 1) out_put == 'Tomorrow';
    else out_put = 'This Week';
    return out_put;
  }
  public getExpiringAdFilterInterval(input: string): string {
    if (input.toLocaleLowerCase() == 'today') return '0 DAY';
    else if (input.toLocaleLowerCase() == 'tomorrow') return '1 DAY';
    else input.toLocaleLowerCase() == 'this week';
    return '1 WEEK';
  }
}
