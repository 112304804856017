import { Component, OnInit } from '@angular/core';
import { Router, Routes, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../service/data.service';
import { AdProductService } from './ad-service-product.service'
import { ConfirmationService } from 'primeng/api';
import { AuthService } from './../../service/auth.service'

@Component({
  selector: 'app-ad-service-product',
  templateUrl: './ad-service-product.component.html',
  styleUrls: ['./ad-service-product.component.css']
})
export class AdServiceProductComponent implements OnInit {
  public msgs: any = [];
  public dataList: any = [];
  public masterDataList:any=[]; 
  public sortlist: any = [];
  public userid: any;
  public searchText:any;
  constructor(private adProductService: AdProductService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private confirmationService:ConfirmationService) {
    this.sortlist =
      [
        { title: 'Date Created', value: 0 },
        { title: ' Recently Edited', value: 1 },
      ];
    this.userid = this.authService.getUserId();
  }

  ngOnInit(): void {
    this.getAllProducts();
  }
 public getAllProducts() {
    this.spinner.show();
    let req = {
      id: window.localStorage.getItem("user_id")
    }
    this.adProductService.getAllProduct(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1) {
        this.masterDataList=res.data;
        this.dataList = res.data;
      }
    }, (err: any) => {
      this.spinner.hide()
    })
  }
 public deleteAdProductClick(id)
  {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      accept: () => {
       this.deleteAdProduct(id);
      }
  });
  }
  public deleteAdProduct(id) {
    this.spinner.show();
    let req = {
      id: id
    }
    this.adProductService.deleteAdProduct(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1) {
        this.showError("success", "Product deleted successfully !")
        this.getAllProducts()
      }
      else {
        this.showError("error", "Oops something went wrong")
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError("error", "Oops something went wrong")
    })
  }
  public searchTextChange(search_text:any)
  {
      search_text=search_text.toUpperCase()
   // let search_text=this.searchText.toUpperCase();
    this.dataList=this.masterDataList.filter(obj=>
       (obj.username.toUpperCase().includes(search_text)
    || obj.email.toUpperCase().includes(search_text)))
  }
  public changeSortBy(event:any)
  {
    if (event.value.value == 0)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.dateCreated),
        keyB = new Date(b.dateCreated);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  else if (event.value.value == 1)
    this.dataList.sort(function (a, b) {
      var keyA = new Date(a.dateUpdated),
        keyB = new Date(b.dateUpdated);
      // Compare the 2 dates
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
