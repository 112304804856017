import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }
  public getAllCodeDetails(formdata: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'discount/getAllCodeDetails', formdata).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public getSingleCode(formdata: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'discount/getSingleCode', formdata).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public saveCode(formdata: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'discount/saveCode', formdata).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  public deleteCode(formdata: any): Observable<any> {
    return this.http.post(global_var.apiUrl + 'discount/deleteCode', formdata).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
}
