<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="page-title">
        <h1>Contact From Notifications</h1>
    </div>
    <div class="bulk-action-parent">
        <div>
            <p-checkbox value="slectedall" title="Select All" [(ngModel)]="selectAllList" label="Select All"
                (click)="selectAll()"></p-checkbox>
        </div>
        <div class="dropdown-box">
            <p-dropdown [(ngModel)]="selectedActionType" inputId="actionId" [options]="actionlist" placeholder="Select"
                optionLabel="title"></p-dropdown>
        </div>
        <div>
            <button pButton type="button" label={{this.selectedActionType.title}} (click)="actionDropdown()"
                [disabled]="(selectedValues.length==0 || selectedActionType.value == -1)"></button>
        </div>
    </div>

    <div class="">
        <p-table class="table user-list-table" [responsive]="true" autoLayout="true" [rows]="10" [value]="msgList"
            [paginator]="true">
            <ng-template pTemplate="body" let-msgList>
                <div class="msg-box-parent">
                    <div class="name" [attr.data-id]="('id')">
                        <p-checkbox value={{msgList.id}} [(ngModel)]="selectedValues" (onChange)="checkboxState($)">
                        </p-checkbox>
                    </div>
                    <div class="msg-box">
                        <div>
                            Subject: {{msgList.subject}}
                        </div>
                        <div>
                            Sender name: {{msgList.sender_name}}
                        </div>
                        <div>
                            Sender email: {{msgList.sender_email}}
                        </div>
                        <div>
                            Date: {{calculateDiff(msgList.dateCreated)}} ago
                        </div>
                        <div>
                            Message: {{msgList.message}}
                        </div>
                        <div>
                            <div class="send-icon">
                                <a href="mailto:{{msgList.sender_email}}?subject={{msgList.subject}}">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;"
                                        xml:space="preserve">
                                        <g>
                                            <g>
                                                <path d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
                                    c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
                                    c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
                                    C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
                                    l-74.979-154.599l228.03-228.027L302.883,459.907z" />
                                            </g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
                                        <g>
                                        </g>
    
    
                                    </svg>
    
                                </a>
                            </div>
                        </div>
                    </div>
    
                </div>
            </ng-template>
        </p-table>

    </div>
</main>