import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from './../message.service';


@Component({
  selector: 'app-contact-noti',
  templateUrl: './contact-noti.component.html',
  styleUrls: ['./contact-noti.component.css']
})
export class ContactNotiComponent implements OnInit {
  public msgs = [];
  public msgList = [];
  public selectedValues = [];;
  public slectedall = [];
  public selectAllList: any[] = [];
  public actionlist: any = [];
  public selectedActionType: any = { title: "Select Action", value: -1 };
  constructor(private spinner: NgxSpinnerService,
    private msgService: MessageService) {
    this.actionlist =
      [
        { title: 'Delete', value: 0 },
      ]
  }

  ngOnInit(): void {
    this.getNotificationMsg()
  }
  public getNotificationMsg() {
    this.spinner.show();
    this.msgService.getNotificationMsg(null).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1) {
        let msgs = res.data;
        msgs.sort((a, b) => {
          const keyA = new Date(a.dateCreated);
          const keyB = new Date(b.dateCreated);
          // Compare the 2 dates
          if (keyA < keyB) { return 1; }
          if (keyA > keyB) { return -1; }
          return 0;
        });
        this.msgList = msgs;
        console.log(res)
      }

    }, err => {
      this.spinner.hide()
    })
  }
  public calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours())) / (1000 * 60 * 60));

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes())) / (1000 * 60));

        responseString = res + ' mins';
      }
    }
    return responseString;
  }
  public checkboxState(event) {
    if (this.selectedValues.length == this.msgList.length)
      this.selectAllList = ["slectedall"];
    else
      this.selectAllList = [];
  }
  public selectAll() {
    this.selectedValues = [];
    if (this.selectAllList.length > 0)
      this.msgList.forEach(o => {
        this.selectedValues.push(o.id.toString());
      })
  }
  public actionDropdown() {
    let value = this.selectedActionType.value;
    switch (value) {
      case 0:
        this.deleteMsgsInBulk()
        break;
    }
  }
  public deleteMsgsInBulk() {
    this.spinner.show();
    let req = {
      data: this.selectedValues
    }
    this.msgService.deleteMsgsInBulk(req).subscribe((res: any) => {
      this.spinner.hide();
      if(res.status==1)
      {
        this.getNotificationMsg()
        this.selectedValues=[];
        this.selectedActionType={ title: "Select Action", value: -1 };
        this.showError('success','Messages deleted successfully')
      }
      else{
        this.showError('error','Oops something went wrong')
      }
    }, (error: any) => {
      this.spinner.hide()
      this.showError('error','Oops something went wrong')
    })
  }
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
