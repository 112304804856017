import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import { AuthService } from '../../service/auth.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  // Function and varuiables Decleration
  @ViewChild('emailFocus') public emailName: ElementRef;
  public loginForm: FormGroup;
  public submitted = false;
  public msgtype: Boolean = false;
  public displayLogout = false;
  public showMessageDiv: any;
  public showmessage: any;
  public contentLogout: any;
  public logoutUserId: any;
  public msgs = [];
  public token: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private spinner: NgxSpinnerService) {
    this.authService.moveToDashboardIfAdminLoggedIn();
    if (window.localStorage.getItem('logout_msg') == '1') {
      this.showError('success', 'You have been logged out successfully.');
      window.localStorage.setItem('logout_msg', '0');
    }

  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      rememberMe : [''],
    });

    setTimeout(() => {
      this.emailName.nativeElement.focus();
    }, 500);
  }

  get f() {
    return this.loginForm.controls;
  }

  // On secessfull form validation invoke this function
  public onSubmit() {
    this.submitted = true;
    // this.spinner.show();
    this.showmessage = '';
    this.showMessageDiv = false;
    this.msgs = [];
    this.msgtype = false;
    // stop here if loginForm is invalid

    console.log(this.loginForm);
    console.log(this.loginForm.invalid);

    if (this.loginForm.invalid) {
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.loginService.loginValidation(this.loginForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        this.spinner.hide();
        if (res.status === 1) {
          // save the session in the local storage
          myGlobalsfunc.saveLocalstorage('access_token', res.token);
          myGlobalsfunc.saveLocalstorage('expires_in', res.user.exp);
          myGlobalsfunc.saveLocalstorage('email', res.user.email);
          myGlobalsfunc.saveLocalstorage('user_id', res.user.id);
          // if (res.user.isSystemAdmin) {
          //   myGlobalsfunc.saveLocalstorage('sadm', 1);
          //   this.router.navigate(['/system-admin']);
          // } else {
          this.router.navigate(['admin']);
          // }
        } else if (res.status === 2) {
          this.displayLogout = true;
          this.logoutUserId = res.user_id;
          this.token = res.token;
          this.submitted = false;
          this.contentLogout = res.message;
          // this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showMessageDiv = true;
          this.submitted = false;
          this.showError('error', res.message);
          this.showmessage = res.message;
          // this.spinner.hide();
        } else {
          // this.spinner.hide();
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

}
