import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { DataService } from '../../../service/data.service';
import { CategoryService } from '../category.service';

@Component({
  selector: 'app-add-category',
  styleUrls: ['./add-category.component.css'],
  templateUrl: './add-category.component.html',

})
export class AddCategoryComponent implements OnInit {
  @ViewChild('titlefocus') public title: ElementRef;
  public categoryForm: FormGroup;
  public submitted = false;
  public categories: any = [];
  public catList: any = null;
  public radiobtn: any = null;
  public uploadedFile: any;
  public uploadedSmallFile: any;

  public editid: any = 0;
  public parentId: any = 0;

  public msgs = [];
  public editdata = [];
  public bannerImage: any = '';
  public smallImage: any = '';

  public paramId: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: CategoryService,
    private route: ActivatedRoute,
    private dataService: DataService) {

    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number

    });

  }

  public ngOnInit(): void {

    this.categoryForm = this.formBuilder.group({
      bannerImage: [],
      smallImage: [],
      description: [],
      id: [this.editid],
      isActive: [1],
      parentId: [0],
      title: [
        '',
        [
          Validators.required,
          Validators.maxLength(255),
        ],
      ],
    });

    this.getCategory();

    if (this.paramId > 0) {

      const req = { id: this.paramId };
      this.spinner.show();

    } else {

    }

  }

  public getCategory(): any {

    this.service.getAllCategory().subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res');

        if (res.status === 1) {
          this.catList = res.data;
          this.spinner.hide();

          if (this.paramId > 0) {

            const req = { id: this.paramId };
            this.spinner.show();

            this.service.getSingleCategory(req).subscribe(
              (res: any) => {
                // if the response status is 1 the user is sucessfully logged in else show error message
                console.log(res.data, 'res');

                if (res.status === 1) {
                  // this.catList = res.data;
                  this.editdata = res.data;

                  if (res.data.bannerImage) {
                    this.bannerImage = global_var.webSiteUrl + "image/" + res.data.bannerImage;
                  }

                  if (res.data.smallImage) {
                    this.smallImage = global_var.webSiteUrl + "image/" + res.data.smallImage;
                  }

                  this.parentId = res.data.parentId;

                  this.categoryForm = this.formBuilder.group({
                    bannerImage: [res.data.bannerImage],
                    smallImage: [res.data.smallImage],

                    description: [res.data.description],
                    id: [this.paramId],
                    isActive: [res.data.isActive],
                    parentId: [res.data.parentId],
                    title: [
                      res.data.title,
                      [
                        Validators.required,
                        Validators.maxLength(255),
                      ],
                    ],
                  });

                  // set parent category
                  if (this.catList) {
                    this.catList.forEach((element) => {
                      if (element.id === res.data.parentId) {
                        this.categoryForm.get('parentId').setValue(element);
                      }
                    });
                  }

                  this.spinner.hide();

                } else if (res.status === 2) {
                  this.spinner.hide();
                } else if (res.status === 0) {
                  window.scroll(0, 0);
                  this.showError('error', res.message);
                  this.spinner.hide();
                } else {
                  this.spinner.hide();
                }
                // this.spinner.hide();
              },
              // Log any error occured in logging in
              (error) => {
                this.spinner.hide();
                this.showError('error', error);
              },
            );


          }

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  get f() {
    return this.categoryForm.controls;
  }

  get fnValue() {
    return this.categoryForm.value;
  }

  public onSubmit() {

    // alert('abc');
    this.submitted = true;
    // this.spinner.show();
    // stop here if loginForm is invalid

    console.log(this.categoryForm);
    console.log(this.categoryForm.invalid);

    if (this.categoryForm.invalid) {
      this.title.nativeElement.focus();
      window.scroll(0, 0);
      this.showError('error', 'Please enter the title.');
      return;
    }
    // Method to validate user login
    this.spinner.show();
    const formidableInput = new FormData();
    if (this.uploadedFile) {
      formidableInput.append('avatar', this.uploadedFile);
    }

    if (this.uploadedSmallFile) {
      formidableInput.append('smallavatar', this.uploadedSmallFile);
    }
    Object.keys(this.categoryForm.value).forEach((key) => {
      formidableInput.append(key, JSON.stringify(this.categoryForm.value[key]));
    });

    this.service.saveCategory(formidableInput).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        this.spinner.hide();
        if (res.status === 1) {
          // save the session in the local storage
          if (this.paramId > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
            this.router.navigate(['admin/category']);
          } else {

            this.dataService.changeMessage('success', 'Record added successfully.');
            this.router.navigate(['admin/category']);
          }

        } else if (res.status === 2) {
          this.submitted = false;
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
         
        } else {
          // this.spinner.hide();
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public fileUploader(event, form, ImageType): void {
    // alert('test');
    if (event.files && event.files.length > 0) {

      console.log(event.files, 'event.files');
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            if (ImageType === 'bannerImage') {
              this.bannerImage = ev.target.result;
            }
            if (ImageType === 'smallImage') {
              this.smallImage = ev.target.result;
            }

          };
          if (ImageType === 'bannerImage') {
            this.uploadedFile = event.files[0];
          }
          if (ImageType === 'smallImage') {
            this.uploadedSmallFile = event.files[0];
          }

        }
      } else {
      }
    }
    form.clear();
  }

  public goBack(): any {
    this.router.navigate(['admin/category']);

  }

}
