import { Component, OnInit, OnChanges, DoCheck } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '../../service/data.service';
import { AdManagementService } from './ad-management.service';
@Component({
  selector: 'app-ads-management',
  templateUrl: './ads-management.component.html',
  styleUrls: ['./ads-management.component.css'],
})
export class AdsManagementComponent implements OnInit, DoCheck {
  first = 0;
  public rowsPerPage = 10;
  public dataList: any[] = [];
  public matsterList: any[] = [];
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public selectedParentCategory: any = null;
  public categories: any = [];
  public getmessage: any = [];
  public userid: any;
  public sortlist: any = [];
  public filterList: any = [];
  public actionlist: any = [];
  public selectedValues: number[] = [];
  public selectAllList: any[] = [];
  public pages: any[] = [];
  public selectedPage = { page: 1, title: '1' };
  public selectedActionType: any = { title: 'Select Action', value: -1 };

  constructor(
    private adManagementService: AdManagementService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
  ) {

    this.route.params.subscribe((params) => {
      this.userid = +params.userid; // (+) converts string 'id' to a number
    });
    this.sortlist =
      [
        { title: 'Date Created', value: 0 },
        { title: 'Most Viewed', value: 1 },
        { title: ' Recently Edited', value: 2 },
      ];
    this.actionlist =
      [
        { title: 'Mark as published', value: 0 },
        { title: 'Mark as unpublished', value: 1 },
        { title: 'Mark as featured', value: 2 },
        { title: 'Mark as unfeatured', value: 3 },
        { title: 'Delete', value: 4 },
        { title: 'Mark as sold', value: 5 },
        { title: 'Mark as unsold', value: 6 },
      ];
    this.filterList = [
      { title: 'Sold Items', value: 0 },
      { title: 'Items For Sale', value: 1 },
      { title: 'Featured Items', value: 2 },
    ];

  }


  public ngOnInit(): void {
    this.getAllRecords();

    const subscription = this.dataService.currentMessage.subscribe((data) => {
      console.log(data, 'message');
      this.getmessage = data;
      if (this.getmessage.type !== undefined && this.getmessage.type !== '' && this.getmessage.message !== undefined) {
        this.showError(this.getmessage.type, this.getmessage.message);
      }
    });
    setTimeout(() =>
      this.dataService.changeMessage('', ''),
      1000);
  }
  public ngDoCheck() {
     if (Math.floor((this.first)/this.rowsPerPage + 1) != this.selectedPage.page)
       this.selectedPage = { page: Math.floor((this.first)/this.rowsPerPage + 1), title: Math.floor((this.first)/this.rowsPerPage + 1).toString() }
  }


  // fn: show error
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  public getAllRecords(formdata: any = null): void {
    this.spinner.show();
    const req = {
      createdBy: this.userid,
    };

    this.adManagementService.getAllAds(req).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.dataList = res.data;
          this.matsterList = res.data;
          this.initilizePageNavigation(this.dataList, this.rowsPerPage)
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public deletemethod(rid: string): void {
    // alert(catId);
    const data = {
      id: rid,
    };
    if (confirm('Are you sure, you want to delete it.')) {
      this.spinner.show();
      this.adManagementService.deleteRecord(data).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');

          // this.getCategory();
          if (res.status === 1) {
            this.getAllRecords();
            this.showError('success', 'Record deleted successfully.');
            // this.catList = res.data;
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            // window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        },
      );

      // alert('yes');
    } else {
      // alert('no');
    }
  }
  public searchTextChange(event) {
    // if(typeof event=="string")
    // this.searchText = event;
    const data = {
      searchtext: this.searchText,
    };
    // if(this.searchText.trim()!='')
    this.getSearchedRecord(data);
    // // console.log(event,'event');

  }

  public getSearchedRecord(formdata): any {
    this.adManagementService.getAllAds(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res');

        if (res.status === 1) {
          const data_List = res.data;
          const search_text = formdata.searchtext ? formdata.searchtext.toUpperCase() : '';
          this.dataList = data_List.filter((obj) => {
            try {
              return (obj.title.toUpperCase().includes(search_text) ||
                obj.email.toUpperCase().includes(search_text) || obj.phone.includes(search_text)
                || (obj.userid.toString()).includes(search_text));
            } catch (e) {
              console.log(e);
              return false;
            }
          });
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }
  public changeSortBy(event): any {

    if (event.value.value === 0) {
      this.dataList.sort((a, b) => {
        const keyA = new Date(a.dateCreated);
        const keyB = new Date(b.dateCreated);
        // Compare the 2 dates
        if (keyA < keyB) { return 1; }
        if (keyA > keyB) { return -1; }
        return 0;
      });
    } else if (event.value.value === 1) {
      this.dataList.sort((a, b) => {
        const keyA = a.visitedTotal;
        const keyB = b.visitedTotal;
        // Compare the 2 values
        if (keyA < keyB) { return 1; }
        if (keyA > keyB) { return -1; }
        return 0;
      });
    } else if (event.value.value === 2) {
      this.dataList.sort((a, b) => {
        const keyA = new Date(a.dateUpdated);
        const keyB = new Date(b.dateUpdated);
        // Compare the 2 dates
        if (keyA < keyB) { return 1; }
        if (keyA > keyB) { return -1; }
        return 0;
      });
    }
  }
  public changeFilterBy(event): any {
    if (event.value.value === 0) {
      this.dataList = this.matsterList.filter((o) => o.isSold === 1);
    } else if (event.value.value === 1) {
      this.dataList = this.matsterList.filter((o) => o.isSale === 1);
    }
    else if(event.value.value===2)
    {
      this.dataList = this.matsterList.filter((o) => o.isFeatured === 1);
    }
  }
  public publishInBuilClick(action: any): any {
    const popup_message = action == 1 ? 'Are you sure that you want publish selected items ?'
      : 'Are you sure that you want unpublish selected items ?';

    this.confirmationService.confirm({
      message: popup_message,
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: '',
      accept: () => {
        this.publishInBulk(action);
      },
    });
  }
  public featureInBuldClick(action: any) {
    const popup_message = action == 1 ? 'Are you sure that you want to mark selected item as featured ?'
      : 'Are you sure that you want to mark selected item as unfeatured ?';

    this.confirmationService.confirm({
      message: popup_message,
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: '',
      accept: () => {
        this.featureInBulk(action);
      },
    });
  }
  public publishInBulk(action: any): any {
    this.spinner.show();
    const req = {
      data: this.selectedValues,
      action,
    };
    this.adManagementService.publishActionAdInBulk(req).subscribe((res: any) => {
      if (res.status == 1) {
        this.selectedValues = [];
        this.getAllRecords();
        this.showError('success', 'Records updated successfully !');
      }
      if (res.status == 0) {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong !');
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops somthing went wrong !');
    });
  }
  public featureInBulk(action: any): any {
    this.spinner.show();
    const req = {
      data: this.selectedValues,
      action,
    };
    this.adManagementService.featureActionAdInBulk(req).subscribe((res: any) => {
      if (res.status === 1) {
        this.selectedValues = [];
        this.getAllRecords();
        this.showError('success', 'Records updated successfully !');
      }
      if (res.status === 0) {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong !');
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops somthing went wrong !');
    });
  }
  public deleteInBulkClick(): any {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected items ?',
      acceptButtonStyleClass: '',
      rejectButtonStyleClass: '',
      accept: () => {
        this.deleteInBulk();
      },
    });
  }
  public deleteInBulk(): any {
    this.spinner.show();
    const req = {
      data: this.selectedValues,
    };
    this.adManagementService.deleteAdInBulk(req).subscribe((res: any) => {
      if (res.status === 1) {
        this.selectedValues = [];
        this.getAllRecords();
        this.showError('success', 'Records deleted successfully !');
      }
      if (res.status === 0) {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong !');
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops somthing went wrong !');
    });
  }
  public soldInBulk(action: any): any {
    this.spinner.show();
    const req = {
      data: this.selectedValues,
      action,
    };
    this.adManagementService.soldInBulk(req).subscribe((res: any) => {
      if (res.status === 1) {
        this.selectedValues = [];
        this.getAllRecords();
        this.showError('success', 'Records updated successfully !');
      }
      if (res.status === 0) {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong !');
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops somthing went wrong !');
    });
  }
  public selectAll(): any {
    this.selectedValues = [];
    if (this.selectAllList.length > 0) {
      this.dataList.forEach((o) => {
        this.selectedValues.push(o.id.toString());
      });
    }
  }
  public actionDropdown(): any {
    const value = this.selectedActionType.value;
    switch (value) {
      case 0:
        this.publishInBuilClick(1);
        break;
      case 1:
        this.publishInBuilClick(0);
        break;
      case 2:
        this.featureInBuldClick(1);
        break;
      case 3:
        this.featureInBuldClick(0);
        break;
      case 4:
        this.deleteInBulkClick();
        break;
      case 5:
        this.soldInBulk(1);
        break;
      case 6:
        this.soldInBulk(0);
        break;
    }
  }
  public checkboxState(): any {
    if (this.selectedValues.length === this.dataList.length) {
      this.selectAllList = ['slectedall'];
    } else {
      this.selectAllList = [];
    }
  }
  public getDateFormate(date): String {
    if (date == '' || date == undefined)
      return ''
    return (new Date(date)).toLocaleDateString()
  }
  public next() {
    this.first = this.dataList.length - 9;
  }

  public prev() {
    this.first = 0;
  }

  public reset() {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.dataList ? this.first === (this.dataList.length - this.rowsPerPage) : true;
  }
  public isFirstPage(): boolean {
    return this.dataList ? this.first === 0 : true;
  }
  public pageNavigation() {
    this.first = this.selectedPage.page * this.rowsPerPage - this.rowsPerPage
  }
  public initilizePageNavigation(list: any[], rows) {
    let count;
    if (list.length % rows == 0) {
      count = list.length / rows
    }
    else {
      count = Math.floor(list.length / rows) + 1
    }
    let arr = [];
    // this.pages = [
    //   { page: 1, title: '1' },
    //   { page: 2, title: '2' },
    //   { page: 3, title: '3' },
    //   { page: 4, title: '4' },
    //   { page: 5, title: '5' },
    //   { page: 6, title: '6' },
    // ]
    for (let i = 1; i <= count; i++) {
      arr.push({ page: i, title: `${i}` })
    }

    this.pages = arr;
  }
  public paginator(e) {
    console.log("called");
  }
}
