

import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import { AuthService } from '../../../service/auth.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  // Function and varuiables Decleration
  @ViewChild('emailFocus') public emailName: ElementRef;
  public forgotPasswordForm: FormGroup;
  public submitted = false;
  public msgtype: Boolean = false;
  public displayLogout = false;
  public showMessageDiv: any;
  public showmessage: any;
  public contentLogout: any;
  public logoutUserId: any;
  public msgs = [];
  public token: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private spinner: NgxSpinnerService) {
    this.authService.moveToDashboardIfAdminLoggedIn();

  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ]
    });

    setTimeout(() => {
      this.emailName.nativeElement.focus();
    }, 500);
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  // On secessfull form validation invoke this function
  public onSubmit() {
    this.submitted = true;
    // this.spinner.show();
    this.showmessage = '';
    this.showMessageDiv = false;
    this.msgs = [];
    this.msgtype = false;

    if (this.forgotPasswordForm.invalid) {
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.loginService.forgotpassword(this.forgotPasswordForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        //this.spinner.hide();
        if (res.status === 1) {
          this.showError('success', res.message);
        } else if (res.status === 2) {
          this.displayLogout = true;
          this.logoutUserId = res.user_id;
          this.token = res.token;
          this.submitted = false;
          this.contentLogout = res.message;
          // this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showMessageDiv = true;
          this.submitted = false;
          this.showError('error', res.message);
          this.showmessage = res.message;
          // this.spinner.hide();
        } else {
          // this.spinner.hide();
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

}

