import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class UserManagementService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllUsers(formdata: any = { searchtext: '' }) {

        return this.http.post(global_var.apiUrl + 'users/getusersadmin', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSingleUser(req) {
        return this.http.post(global_var.apiUrl + 'users/getsingleuser', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveRecord(data: any) {
        return this.http.post(global_var.apiUrl + 'users/createuser', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public deleteRecord(data: any) {
        return this.http.post(global_var.apiUrl + 'users/deleteuser', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public deleteUsersInBulk(data: any) {
        return this.http.post(global_var.apiUrl + 'users/deleteUsersInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public markUserAdminInBulk(data: any) {
        return this.http.post(global_var.apiUrl + 'users/markUserAdminInBulk', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getAllAds(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'postad/getallads', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
