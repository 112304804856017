<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Seller/Buyer Dashboard</li>
        </ol>
    </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="alert-message">
            <p-messages [(value)]="msgs"></p-messages>
        </div>
        <div class="row">
            <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
                <div class="myaccount-links">
                    <ul>
                        <app-sidemenu>

                        </app-sidemenu>


                        <!-- <li class="active"><a href="/">Account Info</a></li>
                        <li><a href="/">Manage My Page</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="pl-0 pl-lg-4">
                    <div class="section-title">
                        <h1 class="mt-0"> Info</h1>
                    </div>
                    <form class="input-lg" [formGroup]="profileForm">
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>User name</label>
                                <input type="text" placeholder="User Name" pInputText formControlName="username">
                                <div *ngIf="f.username.errors && f.username.dirty" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required" class="error-message-form"> Please enter
                                        username</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>First name</label>
                                <input type="text" placeholder="First name" pInputText formControlName="firstName">
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Last name</label>
                                <input type="text" placeholder="Last name" pInputText formControlName="lastName">
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Email address</label>
                                <input type="email" placeholder="Email address" pInputText formControlName="email">
                                <div *ngIf="f.email.errors && f.email.dirty" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.
                                    </div>
                                    <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                                        Please enter valid email.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Phone number</label>
                                <!-- <input type="tel" placeholder="Phone number" pInputText formControlName="phone"> -->


                                <ngx-intl-tel-input [cssClass]="'phonecustom form-control'" [preferredCountries]="['gb']" [selectedCountryISO]="countryCode" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" placeholder="Phone" [searchCountryFlag]="true" [selectFirstCountry]="false" [maxLength]="15" [separateDialCode]="true" name="phone" [phoneValidation]="false" formControlName="phone">
                                </ngx-intl-tel-input>

                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>New Password</label>
                                <input type="password" placeholder="********" pInputText formControlName="password">
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Confirm New Password</label>
                                <input type="password" placeholder="********" pInputText formControlName="confirm_new_password">
                                <div *ngIf="profileForm.hasError('notSame')" class="invalid-feedback">
                                    <div class="error-message-form">
                                        Password doesn't match.</div>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <button type="button" class="btn btn-default pull-left px-30" (click)="updateRecord()">
                                    <span class="ui-button-text ui-clickable">Update</span>
                                </button>
                                <button type="button" class="btn btn-default pull-right btn-black ml-3 px-30" (click)="confirm()">
                                    <span class="ui-button-text ui-clickable">Delete My Account</span>
                                </button>
                                <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
                                </p-confirmDialog>


                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer-front></app-footer-front>