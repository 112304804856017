import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import * as myGlobalsfunc from '../global.function';
import * as myGlobals from '../global.variable';
import { LoginRegisterService } from './../front/service/loginRegister.service';

@Injectable()
export class AuthService {
  private loggedIn = false;

  constructor(
    private router: Router,
    private loginRegisterService: LoginRegisterService
  ) {
    this.loggedIn = !!window.localStorage.getItem('access_token');
  }

  public decodeToken(token?: string): any {
    if (!token) {
      token =
        window.localStorage.getItem('access_token_user') ||
        window.localStorage.getItem('access_token');
    }
    return token ? jwtDecode(token) : null;
  }

  private isTokenExpired(tokenDecoded: any): boolean {
    return tokenDecoded.exp < Date.now() / 1000;
  }

  public adminlogout(): void {
    window.localStorage.removeItem('access_token');
    window.localStorage.removeItem('user_id');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('email');
    window.localStorage.setItem('logout_msg', '1');
    window.localStorage.removeItem('login_msg');
    this.loggedIn = false;
    this.router.navigate(['/admin/login']);
  }

  public userlogout(): void {
    window.localStorage.removeItem('access_token_user');
    window.localStorage.removeItem('user_id_user');
    window.localStorage.removeItem('expires_in');
    window.localStorage.removeItem('email');
    window.localStorage.setItem('logout_msg', '1');
    window.localStorage.removeItem('login_msg');
    this.loggedIn = false;
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    const token = window.localStorage.getItem('access_token');
    if (!token) return false;
    const decoded = this.decodeToken(token);
    if (this.isTokenExpired(decoded)) {
      this.adminlogout();
      return false;
    }
    return true;
  }

  public isUserLoggedIn(): boolean {
    return this.checkUserLoggedIn();
  }

  public checkUserLoggedIn(): boolean {
    const token = window.localStorage.getItem('access_token_user');
    if (!token) return false;
    const decoded = this.decodeToken(token);
    if (this.isTokenExpired(decoded)) {
      this.userlogout();
      return false;
    }
    return true;
  }

  public getUserType(): number {
    const token = window.localStorage.getItem('access_token_user');
    if (!token) return 0;
    const decoded = this.decodeToken(token);
    return decoded.usertype || 0;
  }

  public getUserId(): number {
    const token = window.localStorage.getItem('access_token_user');
    if (!token) return 0;
    const decoded = this.decodeToken(token);
    return decoded.id || 0;
  }

  public moveToDashboardIfAdminLoggedIn(): void {
    if (this.isLoggedIn()) {
      this.router.navigate(['admin']);
    }
  }

  public moveToMyAccountIfUserLoggedIn(): void {
    if (this.isUserLoggedIn()) {
      if (this.getUserType() === 2) {
        this.router.navigate(['advertise-here/edit-info']);
      } else {
        this.router.navigate(['advertise-here/edit-profile']);
      }
    }
  }

  public canActivate(): boolean {
    return this.isLoggedIn();
  }

  public getToken(): string {
    return window.localStorage.getItem('access_token_user');
  }

  public getAdminToken(): string {
    return window.localStorage.getItem('access_token');
  }
}
