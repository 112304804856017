import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
  providedIn: 'root',
})
export class PageDetailService {
  // Constructor
  constructor(
    private http: HttpClient,
    private router: Router,
    private meta: Meta,
    private title: Title
  ) {}
  // Login Form Validation

  public getSpProductData(formdata: any): any {
    return this.http
      .post(global_var.apiUrl + 'getspproductdata', formdata)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public getAllServices(req: any = { preview: false, isHomePage: false }): any {
    return this.http.post(global_var.apiUrl + 'getallservices', req).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getSinglePostAd(req): any {
    return this.http.post(global_var.apiUrl + 'postad/getsinglead', req).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getAllPostAd(req): any {
    return this.http.post(global_var.apiUrl + 'postad/getallad', req).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public setMeta(key: string) {
    const meta = seo[key];
    if (!meta) return;
    this.meta.addTags(meta['tags']);
    console.log(meta);
    this.title.setTitle(meta['title']);
  }
}

const seo = {
  '/': {
    tags: [
      {
        name: 'description',
        content:
          'Racebuykz.com is the only Classified Ads site - to focus 100% on Motorcycle Racing and Trackday audiences. Buy and Sell here.',
      },
    ],
    title: 'Home',
  },
  '/category/bikes': {
    tags: [
      {
        name: 'description',
        content:
          'Buy and Sell Racing and Trackday Motorcycles here. Private classified ads, featuring bikes from all manufacturers and of all capacities.',
      },
    ],
    title: 'Bikes',
  },
  '/category/parts': {
    tags: [
      {
        name: 'description',
        content:
          'Buy and Sell  Motorcycle Performance Parts here. Private classified ads featuring all the things that make your bike handle well and go like hell!',
      },
    ],
    title: 'Parts',
  },
  '/category/garage-pitlane': {
    tags: [
      {
        name: 'description',
        content:
          'Find quality used Garage and Pitlane products here. Private classified ads, featuring the items you need and use at the race circuit.',
      },
    ],
    title: 'Garage & Pitlane',
  },
  '/category/transport-paddock': {
    tags: [
      {
        name: 'description',
        content:
          'Find quality used Race Paddock items and Race Transport advertised here. Get to the circuit and set up at the track in style.',
      },
    ],
    title: 'Transport & Paddock',
  },
  '/sp-category': {
    tags: [
      {
        name: 'description',
        content:
          'Come here to connect with the  best products and services, the motorcycle race paddocks have to offer. Leave it to the professionals!',
      },
    ],
    title: 'Services',
  },
  '/ad-fees': {
    tags: [
      {
        name: 'description',
        content:
          'Need to know how much your Ad is going to cost - then look no further. Thirty, ninety and "until-sold" options for everyone.',
      },
    ],
    title: 'Ad Fees',
  },
  '/faq': {
    tags: [
      {
        name: 'description',
        content:
          'All the answers to the most important questions. Let us assure you why using Racebuykz.com is safe and effective, whilst offering great value.',
      },
    ],
    title: 'FAQs',
  },
  '/about-us': {
    tags: [
      {
        name: 'description',
        content:
          'With a long history in Motorcycle Racing, we are proud to serve those who share the same passion. Read About Us to understand more',
      },
    ],
    title: 'History',
  },
  '/category/bikes/race-bikes': {
    tags: [
      {
        name: 'description',
        content:
          'Buy and Sell Racing Motorcycles here. Private classified ads, featuring bikes from all manufacturers and of all capacities.',
      },
    ],
    title: 'Race Bikes',
  },
  '/category/bikes/track-bikes': {
    tags: [
      {
        name: 'description',
        content:
          'Buy and Sell Trackday Motorcycles here. Private classified ads, featuring bikes from all manufacturers and of all capacities.',
      },
    ],
    title: 'Track Bikes',
  },
  '/category/bikes/classic-bikes': {
    tags: [
      {
        name: 'description',
        content:
          'Buy and Sell Classic Motorcycles here. Private classified ads, featuring bikes from all manufacturers and of all capacities.',
      },
    ],
    title: 'Classic Bikes',
  },
  '/category/minibikes': {
    tags: [
      {
        name: 'description',
        content:
          "Mini Race Bikes for Sale here - From Minimotos, through Pit Bikes and Mini GP's, to the latest Ohvale's! Junior to Adult - find it here.",
      },
    ],
    title: 'Classic Bikes',
  },
  '/category/parts/chassis': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Chassis enhancing items for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Chassis',
  },
  '/category/parts/bodywork': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Race Bodywork items for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Bodywork',
  },
  '/category/parts/brakes-wheels': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Brakes items and Wheels for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Brakes & Wheels',
  },
  '/category/parts/electronics': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Electronic items for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Electronics',
  },
  '/category/parts/engine-and-performance': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Engine and Performance related items for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Engine and Performance',
  },
  '/category/parts/suspension': {
    tags: [
      {
        name: 'description',
        content:
          'Find all kinds of Race Suspension items for sale here. Private classified ads, featuring the things you need to mprove your Bikes performance.',
      },
    ],
    title: 'Suspension',
  },
  '/category/garage-pitlane/gantries-lighting-and-power': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Gantries, Lighting and Power. Improve your trackside capabilities.',
      },
    ],
    title: 'Gantries, Lighting and Power',
  },
  '/category/garage-pitlane/tools-equipment-and-cabinets': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Tools, Equipment and Cabinets.  Improve your trackside capabilities.',
      },
    ],
    title: 'Tools, Equipment and Cabinets',
  },
  '/category/garage-pitlane/stands-racks-and-monitors': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Tools, Equipment and Cabinets.  Improve your trackside capabilities.',
      },
    ],
    title: 'Stands, Racks and Monitors',
  },
  '/category/garage-pitlane/trolleys-generators-and-tyre-warmers': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Trolleys, Generators and Tyre Warmers. Improve your trackside capabilites.',
      },
    ],
    title: 'Trolleys, Generators and Tyre-Warmers',
  },
  '/category/garage-pitlane/perches-pitboards-and-timing-equipment': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Pit Perches, Pitboards and Timing Equipment. Improve your trackside capabilites.',
      },
    ],
    title: 'Perches, Pitboards and Timing Equipment',
  },
  '/category/garage-pitlane/walling-flooring-and-barriers': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Walling, Flooring and Barriers. Improve your trackside capabilites.',
      },
    ],
    title: 'Walling, Flooring and Barriers',
  },
  '/category/transport-paddock/awnings': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Awnings. Buy and sell the things we need at the Race Circuits here',
      },
    ],
    title: 'Awnings',
  },
  '/category/transport-paddock/hospitality-units': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Hospitality Units. Buy and sell the things we need at the Race Circuits here',
      },
    ],
    title: 'Hospitality Units',
  },
  '/category/transport-paddock/motorhomes': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Motorhomes. Buy and sell the things we need at the Race Circuits here',
      },
    ],
    title: 'Motorhomes',
  },
  '/category/transport-paddock/trailers': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Motorcycle Trailers. Buy and sell the things we need to comfortably get to the Track here.',
      },
    ],
    title: 'Trailers',
  },
  '/category/transport-paddock/trucks': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Race Trucks. Buy and sell the things we need to comfortably get to the Track here.',
      },
    ],
    title: 'Trucks',
  },
  '/category/transport-paddock/vans': {
    tags: [
      {
        name: 'description',
        content:
          'Private classified ads, featuring Vans for sale. Buy and sell the things we need to comfortably get to the Track here.',
      },
    ],
    title: 'Vans',
  },
  '/sp-category/transport-and-paddock': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Race Transport & Paddock product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Transport & Paddock',
  },
  '/sp-category/bodyworks': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Race Bodywork product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Bodywork',
  },
  '/sp-category/brakes-wheels': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Brake and Wheel product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Brakes & Wheels',
  },
  '/sp-category/chassis-service': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Frame and Race Chassis product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Chassis Service',
  },
  '/sp-category/electronic': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Electronics and Wiring Loom product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Electronics',
  },
  '/sp-category/engines-tuning': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Engine Tuners and Performance Part product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Engines & Tuning',
  },
  '/sp-category/garage-and-pitlane': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Garage & Pitlane product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Garage & Pitlane',
  },
  '/sp-category/parts-accessories': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Performance Parts & Accessories product providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Parts & Accessories',
  },
  '/sp-category/suspensions': {
    tags: [
      {
        name: 'description',
        content:
          'Connect with Race Suspension product and service providers here. If its important - speak to the Professionals!',
      },
    ],
    title: 'Suspension',
  },
  '/contact-us': {
    tags: [
      {
        name: 'description',
        content:
          'Please feel to Contact Us if you have any questions or queries. We are always happy to help.',
      },
    ],
    title: 'Contact Us',
  },
};
