<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="page-title">
        <h1>Add Price Management</h1>
    </div>
    <div class="form-container price-management">
        <form [formGroup]="priceManagementForm" (ngSubmit)="onSubmit()" id="priceManagementAdd">
            <div class="p-field col-md-6 input-field">
                <label for="category">Select Parent category</label>
                <p-dropdown optionLabel="title" [options]="catList" [tabindex]="11" placeholder="Select category" formControlName="catId" class="select-small"></p-dropdown>
            </div>
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-field col-md-6 input-field">
                    <label for="">Price min range</label>
                    <input type="number" formControlName="minRange" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="">Price max range</label>
                    <input type="number" formControlName="maxRange" pInputText>
                </div>
            </div>
           
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-field col-md-6 input-field">
                    <label for="soldCost">Until Sold Cost</label>
                    <input formControlName="untilSoldCost" id="soldCost" type="number" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="30dayCost">30 Day Cost</label>
                    <input formControlName="day30Cost" id="30dayCost" type="number" pInputText>
                </div>
            </div>
            <div class="ui-fluid p-formgrid p-grid">
                <div class="p-field col-md-6 input-field">
                    <label for="90dayCost">90 Day Cost</label>
                    <input formControlName="day90Cost" id="90dayCost" type="number" pInputText>
                </div>
            </div>
        </form>
    </div>
    <div class="form-btn align-right">
        <button [disabled]="!(priceManagementForm.valid)" (click)="onSubmit()" pButton type="button" label="Submit"></button>
        &nbsp;<button (click)="goBack()" pButton type="button" label="Cancel"></button>
    </div>
</main>