import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../myaccount.service';


@Component({
  selector: 'app-info',
  styleUrls: ['./info.component.css'],
  templateUrl: './info.component.html',
})
export class InfoComponent implements OnInit {
  public userInfo: any;
  public msgs = [];
  public countryCode: any = 'gb';
  constructor(
    private myaccountService: MyaccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    private confirmationService: ConfirmationService) {
    this.authService.isUserLoggedIn();
  }
  public profileForm = new FormGroup({
    userid: new FormControl(''),
    username: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirm_new_password: new FormControl(''),
  }, { validators: this.passwordCheck.bind(this) });
  public ngOnInit(): void {
    this.getUserdata();
  }
  public passwordCheck(control: FormGroup): any {
    const pass = control.get('password').value;
    const confpass = control.get('confirm_new_password').value;
    return pass === confpass ? null : { notSame: true };
  }
  public getUserdata(): any {
    const req = {
      id: this.authService.getUserId(),
    };
    this.spinner.show();
    this.myaccountService.getuserdata(req).subscribe((res: any) => {
      this.spinner.hide();
      console.log('getuserdata', res.data[0])
      if (res.status === 1) {
        const user_data = res.data[0];
        if (user_data.countryCode && user_data.countryCode !== '' && user_data.countryCode != null) {
          this.countryCode = user_data.countryCode;
        }

        this.profileForm.setValue({
          userid: user_data.id,
          username: user_data.username,
          firstName: user_data.firstname,
          lastName: user_data.lastname,
          email: user_data.email,
          phone: user_data.phone,
          password: '',
          confirm_new_password: '',
        });
      }
    });
  }

  public updateRecord(): any {
    if (this.profileForm.valid) {
      this.spinner.show();
      this.profileForm.value.password = btoa(this.profileForm.value.password);
      this.myaccountService.updateUserInfo(this.profileForm.value).subscribe((res: any) => {
        console.log(res)
        this.spinner.hide();
        if (res.status === 3) {
          this.showError('info', res.message);
        }
        if (res.status === 1) {
          window.scrollTo(0, 0);
          this.showError('success', 'Your details has been updated successfully.');
        }
      }, err => {
        this.spinner.hide();
        this.showError('error', 'Some error occurred');
      });
    }
    // console.log(this.profileForm);
  }
  public confirm(): any {
    this.confirmationService.confirm({
      acceptButtonStyleClass: 'popupbutton',
      message: 'Are you sure that you want deactive your account ?',
      rejectButtonStyleClass: 'popupbutton',
      accept: () => {
        this.deactivateUser();
      },
    });
  }
  public deactivateUser(): any {
    this.spinner.show();
    const req = {
      id: this.authService.getUserId(),
    };
    this.myaccountService.deactivateFrontUser(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 1) {
        // this.showError("success", "User deactivated successfully");
        this.authService.userlogout();
      } else if (res.status === 0 || res.status === 2) {
        this.showError('error', 'There is some error. Please try again later.');
      }
    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'There is some error. Please try again later.');
    });
  }
  get f(): any {
    return this.profileForm.controls;
  }
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }
}
