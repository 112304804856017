import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';

import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';

import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmPasswordValidator } from '../../service/confirm_password.validator';
import { LoginRegisterService } from '../service/loginRegister.service';
@Component({
  selector: 'app-loginfront',
  styleUrls: [],
  templateUrl: './loginfront.component.html',
})
export class LoginfrontComponent implements OnInit {
  public user: SocialUser;
  // public user: any = [];
  public loggedIn: boolean;
  @ViewChild('emailFocus') public email: ElementRef;
  public userLoginForm: FormGroup;
  public msgs = [];
  public showRegisterForm: any = false;
  public userRegisterForm: FormGroup;

  constructor(
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: LoginRegisterService,
    private authService: AuthService
  ) {
    this.socialAuthService.authState.subscribe((abc) => {
      console.log(abc, 'fb user 2');
    });
    console.log(this.user, 'fb user');
  }

  public viewRegisterForm(): any {
    this.showRegisterForm = true;
  }

  public ngOnInit(): void {
    this.setFormInitial();

    if (this.authService.checkUserLoggedIn()) {
      this.authService.moveToMyAccountIfUserLoggedIn();
    }

    if (window.localStorage.getItem('logout_msg') == '1') {
      this.showError('success', 'You have been logged out successfully.');
      window.localStorage.setItem('logout_msg', '0');
    }
  }

  // public signInWithGoogle(): void {
  //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  public signInWithFB(): void {
    const fbLoginOptions = {
      scope: 'email',
    }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

    this.socialAuthService.signIn(
      FacebookLoginProvider.PROVIDER_ID,
      fbLoginOptions
    );
  }

  public signOut(): void {
    this.socialAuthService.signOut();
  }

  get fn(): any {
    return this.userLoginForm.controls;
  }

  get f() {
    return this.userRegisterForm.controls;
  }

  public fieldChange(): any {
    // console.log('test 123');
    if (this.userRegisterForm.value.username === '') {
      if (this.userRegisterForm.value.firstname.length > 4) {
        const lastname = this.userRegisterForm.value.lastname.charAt(0);
        this.userRegisterForm
          .get('username')
          .setValue(this.userRegisterForm.value.firstname + lastname + '123');
      }
    }
  }
  public setFormInitial(): any {
    this.userLoginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.maxLength(255)]],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      rememberMe: [],
    });

    this.userRegisterForm = this.formBuilder.group(
      {
        username: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(6),
          ],
        ],
        firstname: ['', [Validators.required, Validators.maxLength(100)]],
        lastname: ['', [Validators.required, Validators.maxLength(100)]],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)[.]([a-zA-Z]{2,5})$'
            ),
            Validators.maxLength(255),
          ],
        ],
        phone: ['', [Validators.required]],
        password: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(6),
            Validators.pattern(
              '^(?=.*\\d)(?=.*[a-z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{6,255}$'
            ),
          ],
        ],
        confirm_password: [
          '',
          [
            Validators.required,
            Validators.maxLength(50),
            Validators.minLength(6),
          ],
        ],
        recaptcha: ['', Validators.required],
        sendCredentials: [],
        isAgreeTerms: ['', Validators.requiredTrue],
        isNewsAndUpdates: [],
        usertype: [2],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  public onSubmit(): any {
    this.spinner.show();

    if (this.userLoginForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.loginValidation(this.userLoginForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          // window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();
          // this.showError('success', res.message);

          myGlobalsfunc.saveLocalstorage('access_token_user', res.token);
          myGlobalsfunc.saveLocalstorage('expires_in', res.user.exp);
          myGlobalsfunc.saveLocalstorage('email', res.user.email);
          myGlobalsfunc.saveLocalstorage('user_id_user', res.user.id);
          if (this.authService.getUserType() === 2) {
            this.router.navigate(['advertise-here/edit-info']);
          } else {
            this.router.navigate(['advertise-here/edit-profile']);
          }
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again later.');
      }
    );
  }

  public onRegisterSubmit(): any {
    this.spinner.show();

    if (this.userRegisterForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.createUser(this.userRegisterForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res, 'res');
        if (res.status === 1) {
          // window.scroll(0, 0);
          // this.spinner.hide();
          // this.setFormInitial();
          // this.showError('success', res.message);
          window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();

          myGlobalsfunc.saveLocalstorage('access_token_user', res.token);
          myGlobalsfunc.saveLocalstorage('expires_in', res.user.exp);
          myGlobalsfunc.saveLocalstorage('email', res.user.email);
          myGlobalsfunc.saveLocalstorage('user_id_user', res.user.id);
          if (this.authService.getUserType() === 2) {
            this.router.navigate(['adverts/place-ad']);
          } else {
            this.router.navigate(['advertise-here/edit-profile']);
          }
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      }
    );
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }
}
