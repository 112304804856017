import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { AdProductService } from '../ad-service-product.service';
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {
  public dataForm: FormGroup;
  public msgs = [];

  public catList: any;
  public serviceList: any;
  public spUserdata: any;
  // public expireDate: any;

  public bannerImage: any;
  public logoImage: any;


  public expireDate: any = null;
  public base64Image: any;
  public imagePickerImageUrl = '';

  public imageChangedEvent: any;
  public imageURL: any;
  public imageBase64: any;
  public base64: any;
  public showImagePicker = false;
  public payPalConfig?: IPayPalConfig;
  public showPaymentForm: any = false;
  public currentEditKey: any;
  public amountPost: any = 250.00;
  public paramId: any;

  constructor(
    private adProductService: AdProductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
  ) {
    //this.authService.isUserLoggedIn();
    const datenow = new Date();
    this.expireDate = formatDate(datenow.setDate(datenow.getDate() + 365), 'dd/MM/yyyy', 'en-gb');
    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number

    });
  }

  public ngOnInit(): void {

    this.setFormInitial();
    //this.initConfig();


    if (window.localStorage.getItem('categoryList')) {
      this.catList = JSON.parse(window.localStorage.getItem('categoryList'));
      const newArr = [];
      this.catList.forEach((child: any, key) => {
        if ((child.parentId === 45)) {
          newArr.push(child);
        }
      });
      this.serviceList = newArr;

    } else {
      this.getCategory();
    }
    if (this.paramId)
      this.getSingleAdProduct();

  }

  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.adProductService.getAllCategory(formdata).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.catList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );
  }

  public setFormInitial(): any {
    this.dataForm = this.formBuilder.group({
      id: [''],
      bannerImage: [''],
      description: [''],
      facebook: [''],
      logoImage: [''],
      instagram: [''],
      subCatId: [''],
      tagline: [''],
      twitter: [''],
      userId: [window.localStorage.getItem("user_id")],
    });
  }


  public getSingleAdProduct(): any {
    const req = {
      id: this.paramId,
    };
    this.spinner.show();

    this.adProductService.getSingleAdProduct(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log(res.data, 'getAllProduct');
        if (res.status === 1) {

          if (res.data && res.data[0]) {
            this.spUserdata = res.data[0];
            this.dataForm = this.formBuilder.group({
              id: [this.paramId],
              bannerImage: [res.data[0].bannerImage],
              description: [res.data[0].description],
              facebook: [res.data[0].facebook],
              logoImage: [res.data[0].logoImage],
              instagram: [res.data[0].instagram],
              subCatId: [res.data[0].subCatId],
              tagline: [res.data[0].tagline],
              twitter: [res.data[0].twitter],
              userId: [window.localStorage.getItem("user_id")],
            });
            if (res.data[0].bannerImage) {
              this.bannerImage = global_var.webSiteUrl + "image/" + res.data[0].bannerImage;

            }
            if (res.data[0].logoImage) {
              this.logoImage = global_var.webSiteUrl + "image/" + res.data[0].logoImage;
            }
            if (this.serviceList && res.data[0].subCatId) {
              this.serviceList.forEach((element) => {
                if (element.id === res.data[0].subCatId) {
                  this.dataForm.get('subCatId').setValue(element);
                }
              });
            }
          } else {

          }
        } else if (res.status === 2) {
        } else if (res.status === 0) {
        } else {
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
      },
    );
  }



  public onSubmit(num = null): any {
    this.spinner.show();

    if (this.dataForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();

    this.dataForm.get('bannerImage').setValue(this.bannerImage);
    this.dataForm.get('logoImage').setValue(this.logoImage);

    this.adProductService.saveSpDataDetail(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.router.navigate(['admin/service-product'])
          if (num === 1) {
            window.open(global_var.webSiteUrl + 'service-providers/' + res.data.id);
          } else {
            if (this.spUserdata && this.spUserdata.isPaymentDone === 1) {
              this.showError('success', 'Your data has been updated successfully.');
            } else {
              this.showPaymentForm = true;
            }
          }

          // this.setFormInitial();

        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );

  }
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  get f(): any {
    return this.dataForm.controls;
  }



  private initConfig(): void {
    this.payPalConfig = {
      currency: 'GBP',
      clientId: 'AWQF8j_X2VK8fH46VbPkDkDtuCgrglqziKtOkLohMthE8XuEcxYOKT1Mc8qS0-uThhEfYRF9YcCo_MMH',
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [
          {
            amount: {
              currency_code: 'GBP',
              value: this.amountPost,
              breakdown: {
                item_total: {
                  currency_code: 'GBP',
                  value: this.amountPost
                },
              },
            },
            items: [
              {
                name: 'Enterprise Subscription',
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'GBP',
                  value: this.amountPost,
                },
              }
            ]
          }
        ]
      },
      advanced: {
        commit: 'true',
      },
      style: {

      },
      onApprove: (data, actions) => {
        this.spinner.show();
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);

          // this.showThankyouForm = true;
          const formdata = {
            expireDate: this.expireDate,
            paymentAmount: 250,
            paymentId: details.id,
            paymentMode: 'paypal',
            userId: this.authService.getUserId(),
          };

          this.adProductService.updateSpUserData(formdata).subscribe(
            (res: any) => {
              // if the response status is 1 the user is sucessfully logged in else show error message
              console.log(res, 'updateAd');

            },
            // Log any error occured in logging in
            (error) => {
              this.showError('error', error);
            },
          );


          this.spinner.hide();
        });
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.showPaymentForm = false;
        this.showError('success', 'Your payment has been processed successfully.');

        this.spinner.hide();
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.spinner.hide();
      },
      onError: err => {
        console.log('OnError', err);
        this.spinner.hide();
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);

      },
    };
  }



  public imageCropped(event: CroppedEvent): any {
    this.base64 = event.base64;

    if (this.currentEditKey === 'bannerImage') {
      this.bannerImage = event.base64;
    }

    if (this.currentEditKey === 'logoImage') {
      this.logoImage = event.base64;
    }

    // this.allImages[this.currentEditKey] = event.base64;
    // this.postAdForm.get('images').setValue(this.allImages);
  }

  public deleteImage(key): any {
    if (key === 'bannerImage') {
      this.bannerImage = null;
    }

    if (key === 'logoImage') {
      this.logoImage = null;
    }
  }
  public fileChangeEvent(event, form, key): void {
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            // Angularthis.allImages[key] = ev.target.result;
            // console.log(ev.target.result);
            if (key === 'bannerImage') {
              this.bannerImage = ev.target.result;
            }

            if (key === 'logoImage') {
              this.logoImage = ev.target.result;
            }
          };

        }
      } else {
      }
    }
  }


  public openImagePicker(): any {

    this.showImagePicker = true;
  }

  public closeImagePicker(): any {
    this.showImagePicker = false;
    this.imagePickerImageUrl = '';
  }

  public clickedOut(event): any {
    console.log('clickedOut', event);
    if (event.target.className === 'modal-background') {
      this.closeImagePicker();
    }
  }

  public fileUpload(): any {

    this.base64Image = this.sanitizer.bypassSecurityTrustUrl(this.imagePickerImageUrl);
    // IF IMAGE SELECTED
    if (this.base64Image.changingThisBreaksApplicationSecurity) {
      fetch(this.imagePickerImageUrl).then((res) => res.blob()).then(blob => {


        if (this.currentEditKey === 'bannerImage') {
          this.bannerImage = this.base64Image.changingThisBreaksApplicationSecurity;
        }

        if (this.currentEditKey === 'logoImage') {
          this.logoImage = this.base64Image.changingThisBreaksApplicationSecurity;
        }


        // this.allImages[this.currentEditKey] = this.base64Image.changingThisBreaksApplicationSecurity;

      });
    }
  }

  public openEditor(key: any): any {

    this.openImagePicker();

    this.imagePickerImageUrl = '';

    // this.imagePickerImageUrl =
    this.currentEditKey = key;
    if (key === 'bannerImage') {
      this.imagePickerImageUrl = this.bannerImage;
    }

    if (key === 'logoImage') {
      this.imagePickerImageUrl = this.logoImage;
    }

    // this.imagePickerImageUrl = this.allImages[key];

  }

  public fileUploader(event, form): void {
    // alert('test');

    const Angularthis = this;
    if (event.files && event.files.length > 0) {

      // console.log(event.files, 'event.files');
      // console.log(form, 'event.form');

      event.files.forEach((child: any, key) => {
        // console.log(child);
        // console.log(key);
        if (myGlobalsfunc.isImage(child.type) && key < 12) {
          if (child.size > 1000000000) {
            // this.uploadedFile = null;
            this.showError('Image should be less then 10 MB', 'error');
            window.scroll(0, 0);
          } else {
            // this.preview(event.files);
            // this.msgs = [];

            const reader = new FileReader();

            reader.readAsDataURL(child); // read file as data url
            const thisimage = null;
            reader.onload = (ev) => { // called once readAsDataURL is completed
              // Angularthis.allImages.push(ev.target.result);

            };

          }
        } else {
          // this.uploadedFile = null;
          // this.showError(text['validFormat'], 'error');
          // window.scroll(0, 0);
        }
      });

    }

    form.clear();
  }

  public onPaymentSubmit(): any {

  }

}
