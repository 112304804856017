import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class LoginRegisterService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }
    // Login Form Validation
    public loginValidation(data: any): any {

        return this.http.post(global_var.apiUrl + 'userlogin', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public loginConfirm(data: any): any {

        return this.http.post(global_var.apiUrl + 'userconfirmlogin', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public forgotpassword(data: any): any {

        return this.http.post(global_var.apiUrl + 'userforgotpassword', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public checkResetCode(data: any): any {

        return this.http.post(global_var.apiUrl + 'usercheckresetcode', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public changePassword(data: any): any {

        return this.http.post(global_var.apiUrl + 'userchangepassword', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    // Logout active session of user
    public logoutSession(data: any): any {
        return this.http.post(global_var.apiUrl + 'userlogoutsession', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public createUser(data: any): any {
        return this.http.post(global_var.apiUrl + 'createuserfront', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
}
