<li
  *ngIf="userType == 3"
  class="{{ currentLink == '/advertise-here/edit-profile' ? 'active' : '' }}"
>
  <a [routerLink]="'/advertise-here/edit-profile'">Account Info</a>
</li>

<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/advertise-here/edit-info' ? 'active' : '' }}"
>
  <a [routerLink]="'/advertise-here/edit-info'">Info</a>
</li>

<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/place-ad' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/place-ad'">Make A New Ad</a>
</li>
<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/draft-ad' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/draft-ad'">Draft Ads</a>
</li>
<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/current-ad' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/current-ad'">Current Ads</a>
</li>
<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/expire-ad' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/expire-ad'">Expired Ads</a>
</li>

<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/view-messages' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/view-messages'">Messages</a>
</li>
<li
  *ngIf="userType == 2"
  class="{{ currentLink == '/adverts/view-wishlist' ? 'active' : '' }}"
>
  <a [routerLink]="'/adverts/view-wishlist'">My Wishlist</a>
</li>
<li *ngIf="userType == 2"><a [routerLink]="'/'">Go to Home Page</a></li>

<li
  *ngIf="userType == 3"
  class="{{ currentLink == '/advertise-here/manage-my-page' ? 'active' : '' }}"
>
  <a [routerLink]="'/advertise-here/manage-my-page'">Manage My Page</a>
</li>

<li
  *ngIf="userType == 3"
  class="{{ currentLink == '/advertise-here/service-product' ? 'active' : '' }}"
>
  <a [routerLink]="'/advertise-here/service-product'">Add My Products</a>
</li>
