import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';

import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';

import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { ConfirmPasswordValidator } from '../../../service/confirm_password.validator';

import { LoginRegisterService } from '../../service/loginRegister.service';
@Component({
  selector: 'app-reset-password-front',
  templateUrl: './reset-password-front.component.html',
  styleUrls: [],
})
export class ResetPasswordFrontComponent implements OnInit {
  public user: SocialUser;
  public loggedIn: boolean;
  @ViewChild('emailFocus') public email: ElementRef;
  public userResetPasswordForm: FormGroup;
  public msgs = [];
  public paramCode = '';
  constructor(private socialAuthService: SocialAuthService, private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: LoginRegisterService,
    private authService: AuthService) { }

  public ngOnInit(): void {

    this.route.params.subscribe((params) => {
      this.paramCode = params['code']; // (+) converts string 'id' to a number

    });
    console.log(this.paramCode);
    this.setFormInitial();
    this.authService.moveToMyAccountIfUserLoggedIn();
    this.checkIfCodeExist();

  }


  public checkIfCodeExist() {
    this.spinner.show();
    this.service.checkResetCode({ resetcode: this.paramCode }).subscribe(
      (res: any) => {
        // this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        // this.spinner.hide();
        if (res.status === 1) {
        } else if (res.status === 2) {
        } else if (res.status === 0) {
          this.spinner.hide();
          this.router.navigate(['login']);
        } else {
          // this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  get f() {
    return this.userResetPasswordForm.controls;
  }

  public setFormInitial() {
    this.userResetPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      resetcode: [this.paramCode],
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      },
    );

  }

  public onSubmit() {
    this.spinner.show();
    // this.msgs = [];

    console.log(this.userResetPasswordForm);
    console.log(this.userResetPasswordForm.invalid);

    if (this.userResetPasswordForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.changePassword(this.userResetPasswordForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          // window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();
          this.showError('success', res.message);

        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  public showError(type, message) {
    console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

}

