import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ALERT_CONFIG } from 'ngx-alerts/lib/alert.config';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { AuthService } from '../../service/auth.service';
import { MyaccountService } from '../myaccount/myaccount.service';
import { PageDetailService } from '../service/pageDetail.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../../../assets/style/style.css'],
})
export class HomeComponent implements OnInit {
  public item: any[];
  public searchForm: FormGroup;
  public catList: any = [];
  public parentCatList: any = [];
  public featuredcats: any = [];
  public msgs = [];
  public subCatList: any = [];
  public subSubCatList: any = [];
  public bikeSelected: any = true;
  public manufacturerOthers: any = false;
  public websiteurl: any;
  public imageurl: any;
  public cmsdata: any;
  public bannerData: any;
  public manufacturerList = [
    { name: 'Aprilia' },
    { name: 'BMW' },
    { name: 'Ducati' },
    { name: 'Honda' },
    { name: 'Kawasaki' },
    { name: 'KTM' },
    { name: 'Suzuki' },
    { name: 'Triumph' },
    { name: 'Yamaha' },
    { name: 'Other' },
  ];
  public responsiveOptions: any;
  public allFeaturedData: any;
  public servicesData: any;
  constructor(
    private myaccountService: MyaccountService,
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pageDetailService: PageDetailService
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numScroll: 3,
        numVisible: 3,
      },
      {
        breakpoint: '991px',
        numScroll: 2,
        numVisible: 2,
      },
      {
        breakpoint: '560px',
        numScroll: 1,
        numVisible: 1,
      },
    ];
  }

  public ngOnInit(): void {
    this.setInitialForm();
    this.getCategory();
    this.getFeaturedAd();
    this.getServices();

    let req = { id: 1 };
    this.myaccountService.getSinglecms(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'getSinglecms');

        if (res.status === 1) {
          // this.catList = res.data;
          this.cmsdata = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again later.');
      }
    );

    req = { id: 6 };
    this.myaccountService.getSinglecms(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'getSinglecms');

        if (res.status === 1) {
          // this.catList = res.data;
          this.bannerData = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again later.');
      }
    );

    this.websiteurl = global_var.webSiteUrl;
    this.imageurl = global_var.webSiteUrl + 'image/';
    window.scrollTo(0, 0);
  }

  public getServices(): any {
    const req = {
      isHomePage: true,
    };
    this.spinner.show();
    const AngularThis = this;

    this.pageDetailService.getAllServices(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'getAllServices home');

        if (res.status === 1) {
          this.servicesData = res.data;

          myGlobalsfunc.saveLocalstorage(
            'serviceList',
            JSON.stringify(res.data)
          );

          // this.servicesData = res.data[0];
          // this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          // this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      }
    );
  }

  public onlyNumberKey(evt): boolean {
    // Only ASCII charactar in that range allowed
    const ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      return false;
    }
    return true;
  }

  public changemanufacturer(event): any {
    if (event.value.name === 'Other') {
      this.manufacturerOthers = true;
    } else {
      this.manufacturerOthers = false;
    }
  }

  public allowInteger(event: KeyboardEvent): any {
    const pattern = /[0-9]/;

    // console.log(event);
    const inputChar = event.key;
    //  String.fromCharCode(event).charCode;
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public changeSubCatList(event): void {
    // console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;
    this.searchForm.get('subSubCategoryId').setValue('');
    // console.log(this.subCatList, 'subCatList');
  }
  public changeParentList(event): any {
    // console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });

    if (event.value.id === 1) {
      this.bikeSelected = true;
      // this.SubcategoryName = 'Sub Category';
    } else {
      this.bikeSelected = false;
      // this.SubcategoryName = 'Sub Category';
    }
    this.subCatList = newArr;
    this.subSubCatList = null;
    this.searchForm.get('subSubCategoryId').setValue('');
    this.searchForm.get('subCategoryId').setValue('');
    // console.log(this.subCatList, 'subCatList');
  }

  public setInitialForm(): any {
    this.searchForm = this.formBuilder.group({
      categoryId: ['', [Validators.required]],
      manufacturer: [''],
      manufacturertext: [''],

      maxPrice: [''],
      minPrice: [''],
      subCategoryId: ['', [Validators.required]],
      subSubCategoryId: [''],
    });
  }

  public getFeaturedAd(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.myaccountService.getFeaturedAd(formdata).subscribe(
      (res: any) => {
        // console.log(res.data, 'getFeaturedAd');

        if (res.status === 1) {
          this.allFeaturedData = res.data;
          if (res.data.length > 0) {
            this.allFeaturedData.forEach((part, index, theArray) => {
              theArray[index].images = JSON.parse(part.images);
              theArray[index].diff = AngularThis.calculateDiff(
                part.dateCreated
              );

              if (theArray[index].title.length > 25) {
                theArray[index].title =
                  theArray[index].title.substring(0, 28) + '...';
              }
              let imgArray;
              const imgArraynew = [];

              if (theArray[index].images && theArray[index].images.length > 0) {
                theArray[index].images.forEach((part, index, theArray) => {
                  imgArray = {
                    alt: '',
                    image: AngularThis.websiteurl + 'image/' + part,
                    manageImageRatio: true,
                    thumbImage:
                      AngularThis.websiteurl +
                      'image/' +
                      part +
                      '?thumbnail=200',
                    title: '',
                  };

                  imgArraynew.push(imgArray);
                });
              }

              theArray[index].imageObject = imgArraynew;
            });
          } else {
            this.allFeaturedData = null;
          }

          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again later.');
      }
    );
  }

  public calculateDiff(dateSent): any {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours()
          )) /
          (1000 * 60 * 60)
      );

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor(
          (Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes()
          ) -
            Date.UTC(
              dateSent.getFullYear(),
              dateSent.getMonth(),
              dateSent.getDate(),
              dateSent.getHours(),
              dateSent.getMinutes()
            )) /
            (1000 * 60)
        );

        responseString = res + ' mins';
      }
    }

    return responseString;
  }

  public showSearch(): any {
    document
      .getElementById('mobile-hero-nav')
      .setAttribute('style', 'display:none !important');
    document
      .getElementById('hero-header')
      .setAttribute('style', 'display:none !important');
    document
      .getElementById('hero-search-form')
      .setAttribute('style', 'display:block !important');
  }
  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.myaccountService.getAllCategory(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'res');

        if (res.status === 1) {
          this.catList = res.data;
          // Show some categories on the homepage in the following order.
          const homepageCategories = [
            'Race Bikes',
            'Track Bikes',
            'Classic Bikes',
            'Minibikes',
            'Parts',
            'Garage & Pitlane',
            'Transport & Paddock',
            'Services',
          ];
          this.featuredcats = homepageCategories
            .map((c) => {
              return this.catList.find((cat) => c == cat.title);
            })
            .filter(Boolean);
          const newArr = [];
          this.catList.forEach((child: any, key) => {
            // console.log(child, child.parentId);
            if (child.parentId === 0 && child.id !== 45) {
              newArr.push(child);
            }
          });
          this.parentCatList = newArr;
          // console.log(this.parentCatList);
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      }
    );
  }

  public showError(type, message): any {
    // console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public onSearchSubmit(): any {
    myGlobalsfunc.saveLocalstorage(
      'searchItem',
      JSON.stringify(this.searchForm.value)
    );
    this.router.navigate(['adverts/search-ads']);
  }
}
