import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../myaccount.service';

@Component({
  selector: 'app-service-product',
  styleUrls: ['./service-product.component.css'],
  templateUrl: './service-product.component.html',
})
export class ServiceProductComponent implements OnInit {

  public dataForm: FormGroup;
  public msgs = [];

  public catList: any;
  public serviceList: any;
  public spUserdata: any;

  public image: any;
  public base64Image: any;
  public imagePickerImageUrl = '';

  public imageChangedEvent: any;
  public imageURL: any;
  public imageBase64: any;
  public base64: any;
  public showImagePicker = false;
  public currentEditKey: any;
  public dataList: any[];


  public linkError: any = false;
  public descriptionError: any = false;

  constructor(
    private myaccountService: MyaccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
  ) {
    this.authService.isUserLoggedIn();
    const datenow = new Date();
  }

  public ngOnInit(): void {

    this.setFormInitial();
    // this.getSpProductData();
    this.getAllProduct();

  }

  public setFormInitial(): any {
    this.dataForm = this.formBuilder.group({
      description: ['', Validators.required],
      id: [0],
      title: ['', Validators.required],
      price: ['', Validators.required],
      poa:[''],
      image: [''],
      link: ['', Validators.required],
      userId: [this.authService.getUserId()],
    });
  }

  public getSpProductData(): any {
    const req = {
      id: this.authService.getUserId(),
    };
    this.spinner.show();

    this.myaccountService.getSpProductData(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'getSpProductData');
        if (res.status === 1) {

          if (res.data && res.data[0]) {
            this.spUserdata = res.data[0];
            this.dataForm = this.formBuilder.group({
              description: [res.data[0].descriptio, Validators.required],
              id: [0],
              image: [res.data[0].image, Validators.required],
              link: ['', Validators.required],
              userId: [this.authService.getUserId()],
            });

            if (res.data[0].image) {
              this.image = global_var.webSiteUrl + "image/" + res.data[0].image;
            }

            if (this.serviceList && res.data[0].subCatId) {
              this.serviceList.forEach((element) => {
                if (element.id === res.data[0].subCatId) {
                  this.dataForm.get('subCatId').setValue(element);
                }
              });
            }
          } else {

          }
        } else if (res.status === 2) {
        } else if (res.status === 0) {
        } else {
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
      },
    );
  }

  public onSubmit(): any {
    this.spinner.show();

    if (this.dataForm.value.link === '' || this.dataForm.value.link === null) {
      this.linkError = true;
    }

    if (this.dataForm.value.description === '' || this.dataForm.value.description === null) {
      this.descriptionError = true;
    }

    if (this.dataForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.dataForm.get('image').setValue(this.image);

    this.myaccountService.saveSpProductDetail(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          window.scrollTo({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth',
          })
          this.spinner.hide();

          // if (this.spUserdata && this.spUserdata.isPaymentDone === 1) {
          //   this.showError('success', 'Your data has been updated successfully.');
          // } else {
          // }

          this.showError('success', 'Your data has been added successfully.');
          this.image = null;
          // let obj = this.dataList.find(o => o.id == this.dataForm.value["id"])
          // obj.description = this.dataForm.value["description"];
          // obj.title = this.dataForm.value["title"];
          // obj.link = this.dataForm.value["link"];
          // obj.price = this.dataForm.value["price"];
          this.setFormInitial();
          this.getAllProduct();

        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );

  }
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  get f(): any {
    return this.dataForm.controls;
  }

  public imageCropped(event: CroppedEvent): any {
    this.base64 = event.base64;

    if (this.currentEditKey === 'image') {
      this.image = event.base64;
    }
  }

  public deleteImage(key): any {
    if (key === 'image') {
      this.image = null;
    }

  }
  public fileChangeEvent(event, form, key): void {

    console.log('fileChangeEvent', event);
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            // Angularthis.allImages[key] = ev.target.result;
            // console.log(ev.target.result);
            if (key === 'image') {
              this.image = ev.target.result;
            }


          };

        }
      } else {
      }
    }
  }


  public openImagePicker(): any {

    this.showImagePicker = true;
  }

  public closeImagePicker(): any {
    this.showImagePicker = false;
    this.imagePickerImageUrl = '';
  }

  public clickedOut(event): any {
    console.log('clickedOut', event);
    if (event.target.className === 'modal-background') {
      this.closeImagePicker();
    }
  }

  public fileUpload(): any {

    this.base64Image = this.sanitizer.bypassSecurityTrustUrl(this.imagePickerImageUrl);
    // IF IMAGE SELECTED
    if (this.base64Image.changingThisBreaksApplicationSecurity) {
      fetch(this.imagePickerImageUrl).then((res) => res.blob()).then(blob => {
        if (this.currentEditKey === 'image') {
          this.image = this.base64Image.changingThisBreaksApplicationSecurity;
        }

      });
    }
  }

  public openEditor(key: any): any {

    this.openImagePicker();

    this.imagePickerImageUrl = '';

    // this.imagePickerImageUrl =
    this.currentEditKey = key;
    if (key === 'image') {
      this.imagePickerImageUrl = this.image;
    }

  }

  public fileUploader(event, form): void {
    const Angularthis = this;
    if (event.files && event.files.length > 0) {

      event.files.forEach((child: any, key) => {
        if (myGlobalsfunc.isImage(child.type) && key < 12) {
          if (child.size > 1000000000) {
            this.showError('Image should be less then 10 MB', 'error');
            window.scroll(0, 0);
          } else {
            const reader = new FileReader();

            reader.readAsDataURL(child); // read file as data url
            const thisimage = null;
            reader.onload = (ev) => { // called once readAsDataURL is completed
            };

          }
        } else {

        }
      });

    }
    form.clear();
  }
  public getAllProduct(): any {
    this.spinner.show();
    const req = {
      id: this.authService.getUserId(),
    };
    this.myaccountService.getAllProducts(req).subscribe((res: any) => {
      if (res.status === 1) {
        this.dataList = res.data;
        console.log(this.dataList);
        this.spinner.hide();
      }

    }, (err: any) => {
      this.showError('error', 'Oops something went wrong.');
      this.spinner.hide();
    });
  }

  public editProduct(id: any): any {
    const item = this.dataList.find((o) => o.id === id);
    // this.dataForm.setValue({
    //   description: [item.description],
    //   id: [item.id],
    //   title: [item.title],
    //   price: [item.price],
    //   image: [''],
    //   link: [item.link],
    //   userId: [this.authService.getUserId()],
    // });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    console.log(item, 'item');

    this.dataForm = this.formBuilder.group({
      description: [item.description],
      id: [item.id],
      title: [item.title],
      price: [item.price],
      poa:[item.poa],
      image: [''],
      link: [item.link],
      userId: [this.authService.getUserId()],
    });

    console.log(this.dataForm.value);
    window.scroll(0, 0);
  }

  public deleteProduct(id: any): any {
    this.spinner.show();
    const req = {
      id,
    };
    this.myaccountService.deleteProduct(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status === 1) {
        this.showError('success', 'Product deleted successfully');
        const idx = this.dataList.findIndex((o) => o.id === id);
        this.dataList = this.dataList.slice(idx + 1);
      }

      if (res.status === 0) {
        this.showError('error', 'Oops something went wrong.');
      }

    }, (err: any) => {
      this.spinner.hide();
      this.showError('error', 'Oops something went wrong.');
    });
  }
}
