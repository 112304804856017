import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../../global.function';
import * as global_var from '../../../../global.variable';
import { AuthService } from '../../../../service/auth.service';
import { AdProductService } from '../../ad-service-product.service';

@Component({
  selector: 'app-add-service-product',
  templateUrl: './add-service-product.component.html',
  styleUrls: ['./add-service-product.component.css']
})
export class AddServiceProductComponent implements OnInit {
  public dataForm: FormGroup;
  public msgs = [];

  public catList: any;
  public serviceList: any;
  public spUserdata: any;
  // public expireDate: any;

  public bannerImage: any;
  public logoImage: any;


  public expireDate: any = null;
  public base64Image: any;
  public imagePickerImageUrl = '';

  public imageChangedEvent: any;
  public imageURL: any;
  public imageBase64: any;
  public base64: any;
  public showImagePicker = false;
  public payPalConfig?: IPayPalConfig;
  public showPaymentForm: any = false;
  public currentEditKey: any;
  public amountPost: any = 250.00;
  public paramId: any;
  public userId: any;
  public image: any;
  public target_param

  constructor(
    private adProductService: AdProductService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
  ) {
    //this.authService.isUserLoggedIn();
    const datenow = new Date();
    this.expireDate = formatDate(datenow.setDate(datenow.getDate() + 365), 'dd/MM/yyyy', 'en-gb');
    this.route.params.subscribe((params) => {
      this.paramId = +params['productId']; // (+) converts string 'id' to a number
      this.userId = +params['id'];
    });
  }

  public ngOnInit(): void {

    this.setFormInitial();
    if (this.paramId)
    {
      this.getSingleProductService();
      this.target_param=window.location.pathname.split('/edit-product/')[0]
    }
    else
    this.target_param=window.location.pathname.split('/add-product')[0]
      

  }

  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.adProductService.getAllCategory(formdata).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.catList = res.data;
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );
  }

  public setFormInitial(): any {
    this.dataForm = this.formBuilder.group({
      id: [''],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      link: [''],
      image: [''],
      price: ['', Validators.required],
      poa:[''],
      userId: [this.userId],
    });
  }


  public getSingleProductService(): any {
    const req = {
      id: this.paramId,
    };
    this.spinner.show();

    this.adProductService.getSingleProductService(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status === 1) {

          if (res.data && res.data[0]) {
            this.spUserdata = res.data[0];
            this.dataForm = this.formBuilder.group({
              id: [this.paramId],
              title: [res.data[0].title],
              price: [res.data[0].price],
              poa:[res.data[0].poa],
              link: [res.data[0].link],
              image: [res.data[0].image],
              userId: [this.userId],
              description: [res.data[0].description],
            });
            if (res.data[0].image) {
              this.image = global_var.webSiteUrl + "image/" + res.data[0].image;

            }
          }
        } else if (res.status === 2) {
        } else if (res.status === 0) {
        } else {
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
      },
    );
  }



  public onSubmit(num = null): any {
    this.spinner.show();

    if (this.dataForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.dataForm.get('image').setValue(this.image);
    this.adProductService.saveServiceProduct(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.router.navigate([this.target_param])
          // this.setFormInitial();
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );

  }
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  get f(): any {
    return this.dataForm.controls;
  }
  public imageCropped(event: CroppedEvent): any {
    this.base64 = event.base64;

    if (this.currentEditKey === 'bannerImage') {
      this.bannerImage = event.base64;
    }

    if (this.currentEditKey === 'logoImage') {
      this.logoImage = event.base64;
    }

    // this.allImages[this.currentEditKey] = event.base64;
    // this.postAdForm.get('images').setValue(this.allImages);
  }

  public deleteImage(key): any {
    this.image = null;
  }
  public fileChangeEvent(event, form, key): void {
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            this.image = ev.target.result;
          };

        }
      } else {
      }
    }
  }


  public openImagePicker(): any {

    this.showImagePicker = true;
  }

  public closeImagePicker(): any {
    this.showImagePicker = false;
    this.imagePickerImageUrl = '';
  }

  public clickedOut(event): any {
    console.log('clickedOut', event);
    if (event.target.className === 'modal-background') {
      this.closeImagePicker();
    }
  }

  public fileUpload(): any {

    this.base64Image = this.sanitizer.bypassSecurityTrustUrl(this.imagePickerImageUrl);
    // IF IMAGE SELECTED
    if (this.base64Image.changingThisBreaksApplicationSecurity) {
      fetch(this.imagePickerImageUrl).then((res) => res.blob()).then(blob => {
       this.image = this.base64Image.changingThisBreaksApplicationSecurity
      });
    }
  }

  public openEditor(key: any): any {

    this.openImagePicker();

    this.imagePickerImageUrl = '';

    // this.imagePickerImageUrl =
    this.currentEditKey = key;
    if (key === 'image') {
      this.imagePickerImageUrl = this.image;
    }

  }

  public fileUploader(event, form): void {
    // alert('test');

    const Angularthis = this;
    if (event.files && event.files.length > 0) {

      // console.log(event.files, 'event.files');
      // console.log(form, 'event.form');

      event.files.forEach((child: any, key) => {
        // console.log(child);
        // console.log(key);
        if (myGlobalsfunc.isImage(child.type) && key < 12) {
          if (child.size > 1000000000) {
            // this.uploadedFile = null;
            this.showError('Image should be less then 10 MB', 'error');
            window.scroll(0, 0);
          } else {
            // this.preview(event.files);
            // this.msgs = [];

            const reader = new FileReader();

            reader.readAsDataURL(child); // read file as data url
            const thisimage = null;
            reader.onload = (ev) => { // called once readAsDataURL is completed
              // Angularthis.allImages.push(ev.target.result);

            };

          }
        } else {
          // this.uploadedFile = null;
          // this.showError(text['validFormat'], 'error');
          // window.scroll(0, 0);
        }
      });

    }

    form.clear();
  }

}
