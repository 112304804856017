<app-sidebar></app-sidebar>
<app-header></app-header>


<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <form [formGroup]="DataForm" (ngSubmit)="onSubmit()" id="formAdd">

        <div class="page-title">
            <h1>{{paramId ? 'Edit' : 'Add'}} Ad</h1>
        </div>
        <div class="form-container">

            <div class="ui-fluid p-formgrid p-grid">

                <div class="p-field col-md-6 input-field">
                    <label for="">Category</label>
                    <p-dropdown formControlName="categoryId" inputId="category" placeholder="Select Category"
                        optionLabel="title" (onChange)="changeParentList($event)" [options]="parentCatList">
                    </p-dropdown>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="">Sub Category</label>
                    <p-dropdown formControlName="subCategoryId" (onChange)="changeSubCatList($event)" inputId="capacity"
                        placeholder="Select Sub Category" optionLabel="title" [options]="subCatList"></p-dropdown>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="">Capacity</label>
                    <p-dropdown formControlName="subSubCategoryId" inputId="capacity" placeholder="Select Capacity"
                        optionLabel="title" [options]="subSubCatList"></p-dropdown>

                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="">Manufacturer</label>
                    <!-- <input formControlName="manufacturer" id="manufacturer" type="text" placeholder="Manufacturer" pInputText> -->
                    <p-dropdown (onChange)="changemanufacturer($event)" formControlName="manufacturer"
                        placeholder="Select Manufacture" optionLabel="name" [options]="manufacturerList"></p-dropdown>
                </div>


                <div *ngIf="manufacturerOthers" class="p-field col-md-6 input-field">
                    <label>Make</label>
                    <input formControlName="manufacturertext" id="manufacturertext" type="text" placeholder="Make"
                        pInputText>

                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="">Age</label>
                    <input maxlength="4" formControlName="age" id="age" type="text" placeholder="Year" pInputText>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="">Title</label>
                    <input formControlName="title" id="adTitle" type="text" placeholder="Race Bike" pInputText>
                    <!-- <div *ngIf="f.title.errors && f.title.dirty" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required" class="error-message-form">Please enter Ad Title.</div>
                    </div> -->
                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="">Price</label>
                    <input formControlName="price" id="adPrice" type="text" placeholder="£5,000" pInputText>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="">Postcode</label>
                    <input formControlName="postcode" id="postcode" type="text" placeholder="Enter Postcode" pInputText>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="">User Email</label>
                    <p-dropdown (onChange)="changeAssignedUser($event)" (keyup)="filterBy($event)"
                        (onFocus)="userDropdownClick($event)" formControlName="assigned_user_email" optionLabel="name"
                        [options]="userList" placeholder="Enter Email" editable="true" [style]="{'width':'100%'}"
                        scrollHeight="400px" filter="true" filterBy="value,label" resetFilterOnHide="true">
                    </p-dropdown>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="">Expiry Date</label>
                    <input formControlName="expireDate" id="postcode" type="date" [min]="currentDate"
                        placeholder="Enter Expiry Date" pInputText>
                </div>
                <div class="p-field col-md-12 input-field">
                    <label for="">Description</label>
                    <textarea formControlName="description" id="description" class="ui-inputtext" rows="7"
                        pinputtext></textarea>
                </div>

                <div class="p-field col-md-12">
                </div>
                <div class="p-field col-md-12 input-field">
                    <label for="">Images</label>
                    <p-fileUpload class="fileUploadAd" multiple="multiple" size="4" class="file-upload cancel" #form
                        customUpload="true" auto="auto" mode="basic" name="avatar"
                        (uploadHandler)="fileUploader($event, form)" chooseLabel="Image" accept="image/*">
                    </p-fileUpload>
                </div>
                <ngx-photo-editor [imageBase64]="imageBase64" [imageURL]="imageURL"
                    [imageChanedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)" [aspectRatio]="4/3"
                    [viewMode]="1"></ngx-photo-editor>


                <div class="p-field col-md-12">
                    <ng-container *ngFor="let item of arrayOne(12); let i = index;" class="">
                        <div *ngIf="allImages[i]" style="padding:10px; float:left;">
                            <div>
                                <p-radioButton class="purpletext" *ngIf="allImages[i]" name="groupname"
                                    formControlName="featuredImageNo" value={{i+1}} label="Featured"></p-radioButton>
                            </div>
                            <img style="height: 100px; display: block; width: auto" width="100" *ngIf="allImages[i]"
                                src="{{allImages[i]}}" />

                            <span style="cursor: pointer;" (click)="openEditor(i)">Edit </span> |
                            <span style="cursor: pointer;" (click)="deleteImage(i)"> Remove</span>

                        </div>
                        <!-- {{i}} -->
                        <!-- <span>
                            <label class="upload-file">

                                <p-fileUpload class="fileUploadAd" class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileUploader($event, form)" chooseLabel="Image" accept="image/*"></p-fileUpload>
                            </label>
                        </span> -->

                        <!-- <div class="image-actions">
                                <label (click)="deleteImage(i)" class="delete" title="Delete"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="minus-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                        <path fill="currentColor" d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"></path>
                                    </svg></label>
                                <label class="edit upload-file" title="Edit">

                                    <p-fileUpload #form customUpload="true" auto="auto" mode="basic" (uploadHandler)="fileChangeEvent($event, form, i)" accept="image/*"></p-fileUpload>


                                </label>
                            </div> -->

                        <!-- <small *ngIf="allImages[i]" class="purpletext">Click on photo to edit</small> -->
                    </ng-container>
                </div>

                <div class="p-field col-md-12">

                </div>






                <div class="p-field col-md-6 input-field">
                    <label for="address">Published</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnPublished" value="1" inputId="yes"
                                formControlName="isPublished"></p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnPublished" value="0" inputId="no"
                                formControlName="isPublished"></p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="address">Active</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="1" inputId="yes" formControlName="isActive">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="0" inputId="no" formControlName="isActive">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>


                <div class="p-field col-md-6 input-field">
                    <label for="address">Featured</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnFeatured" value="1" inputId="yes" formControlName="isFeatured">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnFeatured" value="0" inputId="no" formControlName="isFeatured">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>

                <div class="p-field col-md-6 input-field">
                    <label for="address">Sold</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnSold" value="1" inputId="yes" formControlName="isSold">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnSold" value="0" inputId="no" formControlName="isSold">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="address">Sale</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnSale" value="1" inputId="yes" formControlName="isSale">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnSale" value="0" inputId="no" formControlName="isSale">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>
                <div *ngIf='showDeleteOption' class="p-field col-md-6 input-field" >
                    <label for="address">Deleted</label>
                    <div class="p-formgroup-inline" >
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtnDelete" value="1" inputId="yes" formControlName="isDeleted">
                            </p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div  class="p-field-checkbox">
                            <p-radioButton name="radiobtnDelete" value="0" inputId="no" formControlName="isDeleted">
                            </p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>
                <div class="p-field col-md-12">

                </div>

                <div *ngIf="editdata" class="p-field col-md-6 input-field">
                    <label *ngIf="editdata.paymentAmount" for="">Amount Paid : {{editdata.paymentAmount}}</label>
                    <label *ngIf="editdata.paymentId" for="">Payment Id : {{editdata.paymentId}}</label>
                    <!-- <label for="">Expire Date : {{editdata.expireDate}}</label> -->

                </div>
                <div class="p-field col-md-12 input-field">
                </div>
                <div *ngIf="editdata && imageObject && imageObject != undefined && imageObject.length > 0"
                    class="p-field col-md-12 input-field">
                    <ng-image-slider [imageSize]="{width: '31.8%', height: '95px', space:3.3}" [images]="imageObject"
                        #nav></ng-image-slider>
                </div>



            </div>
        </div>
        <div class="form-btn align-left">
        </div>
        <div class="form-btn align-right">
            <button pButton type="submit" label="Submit"></button> &nbsp;
            <button (click)="goBack()" pButton type="button" label="Cancel"></button>
        </div>
    </form>
</main>

<!-- Modal Starts-->
<div id="upload-crop-cover-modal" class="modal upload-crop-cover-modal is-xxl has-light-bg"
    [ngClass]="{'is-active':showImagePicker}">
    <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
    <div class="modal-content">
        <app-image-picker [(showImagePicker)]="showImagePicker" [(imagePickerImageUrl)]="imagePickerImageUrl"
            (imagePickerImageUrlChange)="fileUpload()" *ngIf="showImagePicker"></app-image-picker>
    </div>
</div>