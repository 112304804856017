<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Service Provider Dashboard</li>
        </ol>
    </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
                <div class="myaccount-links">
                    <ul>
                        <app-sidemenu>

                        </app-sidemenu>


                        <!-- <li class="active"><a href="/">Account Info</a></li>
                        <li><a href="/">Manage My Page</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-12 col-md-8 col-lg-9">
                <div class="alert-message">
                    <p-messages [(value)]="msgs"></p-messages>
                </div>
                <div class="pl-0 pl-lg-4">
                    <div class="section-title">
                        <h1 class="mt-0">Account Info</h1>
                    </div>
                    <form [formGroup]="dataForm" (ngSubmit)="onSubmit()" class="input-lg">
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Company name</label>
                                <input formControlName="companyName" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Website URL</label>
                                <input formControlName="websiteUrl" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Company registration number</label>
                                <input formControlName="companyRegNo" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Email address</label>
                                <input formControlName="email" type="email" placeholder="" pInputText>
                                <div *ngIf="f.email.errors && f.email.dirty" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter email.</div>
                                    <div *ngIf="(f.email.errors.email || f.email.invalid) && f.email.value" class="error-message-form">
                                        Please enter valid email.</div>
                                </div>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Phone number</label>
                                <!-- <input formControlName="phone" type="text" placeholder="" pInputText> -->

                                <ngx-intl-tel-input [cssClass]="'phonecustom form-control'" [preferredCountries]="['gb']" [selectedCountryISO]="countryCode" [enableAutoCountrySelect]="false" [enablePlaceholder]="true" placeholder="Phone" [searchCountryFlag]="true" [selectFirstCountry]="false" [maxLength]="15" [separateDialCode]="true" name="phone" [phoneValidation]="false" formControlName="phone">
                                </ngx-intl-tel-input>

                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Password</label>
                                <input formControlName="password" type="Password" placeholder="********" pInputText>
                                <div *ngIf="f.password.errors && f.password.dirty" class="invalid-feedback">
                                    <div *ngIf="(f.password.errors.password || f.password.invalid) && f.password.value" class="error-message-form">
                                        Please enter valid password.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Confirm Password</label>
                                <input formControlName="confirm_password" type="Password" placeholder="********" pInputText>
                                <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                                    <div *ngIf="(f.confirm_password.errors.confirm_password || f.confirm_password.invalid) && f.confirm_password.value" class="error-message-form">
                                        Please enter valid password.</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Address 1</label>
                                <input formControlName="address" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Address 2</label>
                                <input formControlName="address2" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Town</label>
                                <input formControlName="town" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>County</label>
                                <input formControlName="county" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="p-field col-12 col-lg-6 input-field mb-4">
                                <label>Postcode</label>
                                <input formControlName="postcode" type="text" placeholder="" pInputText>
                                <!-- <button pButton type="button" label="Change" class="btn btn-default link-btn absolute-btn"></button> -->
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <button type="submit" [disabled]="!(dataForm.valid)" class="btn btn-default pull-left px-30">
                                    <span class="ui-button-text ui-clickable">Update</span>
                                </button>
                                <!-- <button type="button" class="btn btn-default pull-right btn-black ml-3 px-30">
                                    <span class="ui-button-text ui-clickable">Delete My Account</span>
                                </button> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main>
<app-footer-front></app-footer-front>