
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { DataService } from '../../../service/data.service';
import { CmsManagementService } from '../cms-management.service';

@Component({
  selector: 'app-add-cms',
  styleUrls: ['./add-cms.component.css'],
  templateUrl: './add-cms.component.html',
})

export class AddCmsComponent implements OnInit {
  @ViewChild('titlefocus') public title: ElementRef;
  public cmsForm: FormGroup;
  public submitted = false;
  public categories: any = [];
  public catList: any = null;
  public radiobtn: any = null;
  public uploadedFile: any;
  public editid: any = 0;
  public msgs = [];
  public editdata = [];
  public image: any = '';
  public paramId: any;
  public currentLink: any;
  public pageType: any = 1;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: CmsManagementService,
    private route: ActivatedRoute,
    private dataService: DataService) {

    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number

    });

    this.currentLink = this.router.url;
    // if (this.currentLink === '/admin/banner-ads') {
    //   this.pageType = 2;
    // }

    if (this.currentLink.indexOf('banner-ads') !== -1) {
      this.pageType = 2;
    }
    if (this.currentLink.indexOf('faq') !== -1) {
      this.pageType = 3;
    }
  }

  public ngOnInit(): void {

    this.cmsForm = this.formBuilder.group({
      description: [],
      shortDescription: [],
      subtitle: [],
      pageType: [this.pageType],
      image: [],
      id: [this.editid],
      isActive: [1],
      title: [
        '',
        [
          Validators.required,
          Validators.maxLength(255),
        ],
      ],
    });

    this.getcms();

    if (this.paramId > 0) {

      const req = { id: this.paramId };
      this.spinner.show();

    } else {

    }

  }

  public getcms(): any {

    this.service.getAllcms().subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res');

        if (res.status === 1) {
          this.catList = res.data;
          this.spinner.hide();

          if (this.paramId > 0) {

            const req = { id: this.paramId };
            this.spinner.show();

            this.service.getSinglecms(req).subscribe(
              (res: any) => {
                // if the response status is 1 the user is sucessfully logged in else show error message
                console.log(res.data, 'res');

                if (res.status === 1) {
                  // this.catList = res.data;
                  this.editdata = res.data;

                  if (res.data.image) {
                    this.image = global_var.webSiteUrl + "image/" + res.data.image;
                  }
                  this.cmsForm = this.formBuilder.group({
                    description: [res.data.description],
                    shortDescription: [res.data.shortDescription],
                    pageType: [res.data.pageType],
                    id: [this.paramId],
                    subtitle: [res.data.subtitle],
                    image: [],
                    isActive: [res.data.isActive],
                    title: [
                      res.data.title,
                      [
                        Validators.required,
                        Validators.maxLength(255),
                      ],
                    ],
                  });

                  // set parent cms
                  // if (this.catList) {
                  //   this.catList.forEach((element) => {
                  //     if (element.id === res.data.parentId) {
                  //       this.cmsForm.get('parentId').setValue(element);
                  //     }
                  //   });
                  // }

                  this.spinner.hide();

                } else if (res.status === 2) {
                  this.spinner.hide();
                } else if (res.status === 0) {
                  window.scroll(0, 0);
                  this.showError('error', res.message);
                  this.spinner.hide();
                } else {
                  this.spinner.hide();
                }
                // this.spinner.hide();
              },
              // Log any error occured in logging in
              (error) => {
                this.spinner.hide();
                this.showError('error', error);
              },
            );


          }

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  get f(): any {
    return this.cmsForm.controls;
  }


  public goBack(): any {
    if (this.pageType === 1) {
      this.router.navigate(['admin/cms-management']);
    } else if (this.pageType === 2) {
      this.router.navigate(['admin/banner-ads']);
    } else if (this.pageType === 3) {
      this.router.navigate(['admin/faq']);
    }
  }
  public onSubmit(): any {

    // alert('abc');
    this.submitted = true;
    console.log(this.cmsForm);
    console.log(this.cmsForm.invalid);

    if (this.cmsForm.invalid) {
      this.title.nativeElement.focus();
      window.scroll(0, 0);
      this.showError('error', 'Please enter the title.');
      return;
    }
    // Method to validate user login
    this.spinner.show();

    // const formidableInput = new FormData();
    // if (this.uploadedFile) {
    //   formidableInput.append('avatar', this.uploadedFile);
    // }
    // Object.keys(this.cmsForm.value).forEach((key) => {
    //   formidableInput.append(key, JSON.stringify(this.cmsForm.value[key]));
    // });

    this.service.savecms(this.cmsForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        this.spinner.hide();
        if (res.status === 1) {
          // save the session in the local storage
          if (this.paramId > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
          } else {
            this.dataService.changeMessage('success', 'Record added successfully.');
          }

          if (this.pageType === 1) {
            this.router.navigate(['admin/cms-management']);
          } else if (this.pageType === 2) {
            this.router.navigate(['admin/banner-ads']);
          } else if (this.pageType === 3) {
            this.router.navigate(['admin/faq']);
          }

        } else if (res.status === 2) {
          this.submitted = false;
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          // this.spinner.hide();
        }
        this.submitted = false;
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }



  public fileChangeEvent(event, form, key): void {
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            // Angularthis.allImages[key] = ev.target.result;
            // console.log(ev.target.result);
            if (key === 'image') {
              this.image = ev.target.result;
              this.cmsForm.get('image').setValue(this.image);
            }


          };

        }
      } else {
      }
    }
  }

  public fileUploader(event, form): void {
    // alert('test');
    if (event.files && event.files.length > 0) {

      console.log(event.files, 'event.files');
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          this.uploadedFile = null;
          // this.showError(text['fileSize'], 'error');
          // window.scroll(0, 0);
        } else {
          // this.preview(event.files);
          // this.msgs = [];

          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url

          reader.onload = (event) => { // called once readAsDataURL is completed
            this.image = event.target.result;
          };

          this.uploadedFile = event.files[0];
        }
      } else {
        // this.uploadedFile = null;
        // this.showError(text['validFormat'], 'error');
        // window.scroll(0, 0);
      }
    }
    console.log('this.uploadedFile', this.uploadedFile);
    form.clear();
  }

}

