
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmPasswordValidator } from '../../service/confirm_password.validator';
import { LoginRegisterService } from '../service/loginRegister.service';
// import { CountryISO } from 'projects/ngx-intl-tel-input/src/lib/enums/country-iso.enum';
@Component({
  selector: 'app-service-provider',
  styleUrls: [],
  templateUrl: './service-provider.component.html',
})
export class ServiceProviderComponent implements OnInit {
  public user: any = [];
  public loggedIn: boolean;
  @ViewChild('emailFocus') public email: ElementRef;
  public msgs = [];
  public userRegisterForm: FormGroup;
  public changePhone: any = false;
  // public CountryISO = CountryISO;
  public preferredCountries = [
    'gb',
  ];
  constructor(
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: LoginRegisterService,
    private authService: AuthService) { }

  public ngOnInit(): void {
    this.setFormInitial();
    this.authService.moveToMyAccountIfUserLoggedIn();
    this.changePhone = false;
  }

  get f(): any {
    return this.userRegisterForm.controls;
  }

  public fieldChange(this): any {
    console.log(this, 'abc');

  }

  public setFormInitial(): any {

    this.userRegisterForm = this.formBuilder.group({
      companyName: [
        '',
        [
          Validators.required,
        ],
      ],
      websiteUrl:
        [
          '',
          [
            Validators.required,
          ],
        ],
      companyRegNo: [
        '',
        [
          Validators.required,
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      phone: [
        '',
        [
          Validators.required,
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
          Validators.pattern(
            '^(?=.*\\d)(?=.*[a-z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{6,255}$',
          ),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      sendCredentials: [],
      isAgreeTerms: ['', Validators.requiredTrue],
      isNewsAndUpdates: [],
      usertype: [3],
      recaptcha: ['', Validators.required],
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      });
  }

  public onRegisterSubmit(): any {
    this.spinner.show();
    // this.msgs = [];

    console.log(this.userRegisterForm);
    console.log(this.userRegisterForm.invalid);

    if (this.userRegisterForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.createUser(this.userRegisterForm.value).subscribe(
      (res: any) => {
        if (res.status === 1) {

          window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();

          myGlobalsfunc.saveLocalstorage('access_token_user', res.token);
          myGlobalsfunc.saveLocalstorage('expires_in', res.user.exp);
          myGlobalsfunc.saveLocalstorage('email', res.user.email);
          myGlobalsfunc.saveLocalstorage('user_id_user', res.user.id);
          if (this.authService.getUserType() === 2) {
            this.router.navigate(['adverts/place-ad']);
          } else {
            this.router.navigate(['advertise-here/edit-profile']);
          }
          // this.showError('success', res.message);

        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }



}
