import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class CmsManagementService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllcms(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllcms', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSinglecms(req): any {
        return this.http.post(global_var.apiUrl + 'getSinglecms', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public savecms(data: any) {
        return this.http.post(global_var.apiUrl + 'createcms', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public deletecms(data: any) {
        return this.http.post(global_var.apiUrl + 'deletecms', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
