import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { PageDetailService } from '../../service/pageDetail.service';
import { MyaccountService } from '../myaccount.service';
declare const jQuery: any;

@Component({
  selector: 'app-post-ad',
  styleUrls: [],
  templateUrl: './post-ad.component.html',
})
export class PostAdComponent implements OnInit {
  public postAdForm: FormGroup;
  public submitted = false;
  public categories: any = [];
  public showIframe: any = false;

  public catList: any = null;
  public parentCatList: any = [];
  public subCatList: any = [];
  public subSubCatList: any = [];
  public imgError: any = false;
  public categoryError: any = false;
  public titleError: any = false;
  public priceError: any = false;
  public postcodeError: any = false;
  public expireText: any = '';
  public showPaymentForm: any = false;
  public postId: any = 0;
  public slugName: any;

  public priceRangeData: any;
  public amountPost: any = 7.0;
  public radiobtn: any = null;
  public uploadedFile: any;
  public editid: any = 0;
  public msgs = [];
  public editdata = [];
  public bannerImage: any = '';
  public paramId: any;
  public allImages: any = new Array();

  public showImagePicker = false;
  // public allImagesEv: any = new Array();
  public showThankyouForm: any = false;
  public currentEditKey: any;
  public payPalConfig?: IPayPalConfig;
  public bikeSelected: any = true;
  public showManufacturer: any = true;
  public SubcategoryName: any = 'Sub Category';

  public descriptionError: any = false;
  public subCategoryIdError: any = false;
  public featuredImage: any = 1;

  public expireDate: any = null;
  public base64Image: any;
  public imagePickerImageUrl = '';

  public imageChangedEvent: any;
  public imageURL: any;
  public imageBase64: any;
  public base64: any;

  public untilSoldCost: any = 10;
  public day30Cost: any = 5;
  public day90Cost: any = 7.5;
  public olduntilSoldCost: any;
  public oldday30Cost: any;
  public oldday90Cost: any;
  public promoCode: any;
  public selectedAmount: any = 1;
  public manufacturerOthers: any = false;
  public hideCategory: any = false;
  public manufacturerList = [
    { name: 'Aprilia' },
    { name: 'BMW' },
    { name: 'Ducati' },
    { name: 'Honda' },
    { name: 'Kawasaki' },
    { name: 'KTM' },
    { name: 'Suzuki' },
    { name: 'Triumph' },
    { name: 'Yamaha' },
    { name: 'Other' },
  ];

  public websiteurl: any;
  public adtitle: any;

  constructor(
    private myaccountService: MyaccountService,
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    public pageDetailService: PageDetailService
  ) {
    this.authService.isUserLoggedIn();
    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number
      // this.adtitle = params.adtitle; // (+) converts string 'id' to a number
    });
  }

  public ngOnInit(): void {
    this.setFormInitial();
    this.getCategory();
    this.initConfig();
    const datenow = new Date();
    this.expireDate = formatDate(
      datenow.setDate(datenow.getDate() + 5475),
      'dd/MM/yyyy',
      'en-gb'
    );
    this.amountPost = this.untilSoldCost;
    // this.getPostData();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'GBP',
      clientId:
        'AZnduMM8hKkjrW5eYRCIc-wr2_R52QqQsqshFOVB3oZVMzm5CDLyxIlo9HyP-JEDjFSLQ3d_AweFtNw8',
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'GBP',
                value: this.amountPost,
                breakdown: {
                  item_total: {
                    currency_code: 'GBP',
                    value: this.amountPost,
                  },
                },
              },
              items: [
                {
                  name: 'Racebuykz',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'GBP',
                    value: this.amountPost,
                  },
                },
              ],
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {},
      onApprove: (data, actions) => {
        this.spinner.show();
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((details) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            details
          );

          this.showThankyouForm = true;
          const formdata = {
            expireDate: this.expireDate,
            id: this.postId,
            paymentAmount: this.amountPost,
            paymentId: details.id,
            paymentMode: 'paypal',
          };
          this.myaccountService.updateAd(formdata).subscribe(
            (res: any) => {
              // if the response status is 1 the user is sucessfully logged in else show error message
              console.log(res, 'updateAd');
            },
            // Log any error occured in logging in
            (error) => {
              this.showError(
                'error',
                'There is some error. Please try again later.'
              );
            }
          );

          this.spinner.hide();
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        this.showThankyouForm = true;
        this.spinner.hide();
        // this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        this.spinner.hide();
      },
      onError: (err) => {
        console.log('OnError', err);
        this.spinner.hide();
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

  public getPostData(): any {
    if (this.paramId > 0) {
      // let req = {};
      //  req = {
      //    adtitle: this.adtitle,
      //   };

      this.websiteurl = global_var.webSiteUrl;
      const req = { id: this.paramId };
      this.spinner.show();
      const AngularThis = this;
      this.pageDetailService.getSinglePostAd(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          // console.log('getSinglePostAd', res.data[0]);
          if (res.status === 1) {
            // this.catList = res.data;
            // this.getdata = res.data[0];

            this.editdata = res.data[0];

            this.postAdForm
              .get('age')
              .setValue(res.data[0].age ? res.data[0].age : '');
            this.postAdForm
              .get('createdBy')
              .setValue(
                res.data[0].createdBy
                  ? res.data[0].createdBy
                  : window.localStorage.getItem('user_id_user')
              );
            this.postAdForm
              .get('description')
              .setValue(res.data[0].description);
            this.postAdForm.get('id').setValue(res.data[0].id);
            this.postAdForm.get('postcode').setValue(res.data[0].postcode);
            this.postAdForm.get('price').setValue(res.data[0].price);
            this.postAdForm.get('title').setValue(res.data[0].title);
            this.postAdForm
              .get('featuredImageNo')
              .setValue(res.data[0].featuredImageSeq);

            const item = this.parentCatList.find(
              (o) => o.id === res.data[0].categoryId
            );

            if (item && item.id) {
              this.changeParentList({ value: { id: item.id } });
            }

            const subitem = this.subCatList.find(
              (o) => o.id === res.data[0].subCategoryId
            );

            if (subitem && subitem.id) {
              this.changeSubCatList({ value: { id: subitem.id } });
            }
            const subSubitem = this.subSubCatList.find(
              (o) => o.id === res.data[0].subSubCategoryId
            );

            const manufacturer = this.manufacturerList.find(
              (o) => o.name === res.data[0].manufacturer
            );

            // console.log(manufacturer, 'manufacturer');
            if (
              manufacturer &&
              manufacturer !== undefined &&
              manufacturer.name !== ''
            ) {
              this.postAdForm.get('manufacturer').setValue(manufacturer);
            } else {
              if (
                res.data[0].manufacturer !== '' &&
                res.data[0].manufacturer !== null &&
                res.data[0].manufacturer !== undefined
              ) {
                this.manufacturerOthers = true;
                this.postAdForm
                  .get('manufacturertext')
                  .setValue(res.data[0].manufacturer);
                this.postAdForm.get('manufacturer').setValue({ name: 'Other' });
              }
            }

            this.postAdForm.get('categoryId').setValue(item);
            this.postAdForm.get('subCategoryId').setValue(subitem);
            this.postAdForm.get('subSubCategoryId').setValue(subSubitem);

            if (
              res.data[0].isPaymentDone === 1 &&
              res.data[0].isPublished === 1 &&
              new Date(res.data[0].expireDate) > new Date()
            ) {
              this.hideCategory = true;
            }

            // console.log(new Date(res.data[0].expireDate));
            // console.log(new Date());

            // allImages

            /*
              manufacturertext: [res.data[0].manufacturer],
              subCategoryId: [''],
              subSubCategoryId: [''],
              title: [
              res.data[0].title,
              [
              Validators.required,
              ],
              ],
              });
            */
            const imageData = JSON.parse(res.data[0].images);
            const imgArray = [];
            imageData.forEach((part, index, theArray) => {
              imgArray.push(this.websiteurl + 'image/' + part);
            });
            this.allImages = imgArray;

            this.spinner.hide();
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 0);
            // this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          // this.showError('error', error);
        }
      );
    }
  }

  public getRadioValue(event): any {
    // console.log(event);
    const datenow = new Date();
    // datenow = datenow.setDate(datenow.getDate() + 3);

    this.selectedAmount = event;
    if (event === 1 || event === '1') {
      this.expireText = '';
      this.expireDate = formatDate(
        datenow.setDate(datenow.getDate() + 5475),
        'dd/MM/yyyy',
        'en-gb'
      );
      this.amountPost = this.untilSoldCost;
    }

    if (event === 2 || event === '2') {
      this.expireDate = formatDate(
        datenow.setDate(datenow.getDate() + 90),
        'dd/MM/yyyy',
        'en-gb'
      );

      this.expireText = 'Your ad will expire on ' + this.expireDate;
      this.amountPost = this.day90Cost;
    }

    if (event === 4 || event === '4') {
      this.expireDate = formatDate(
        datenow.setDate(datenow.getDate() + 30),
        'dd/MM/yyyy',
        'en-gb'
      );
      this.expireText = 'Your ad will expire on ' + this.expireDate;
      this.amountPost = this.day30Cost;
    }
    console.log('this.expireDate ', this.expireDate);
  }

  public changeParentList(event): any {
    console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    if (event.value.id === 1 || event.value.id === 2) {
      this.showManufacturer = true;
    } else {
      this.showManufacturer = false;
    }

    if (event.value.id === 1) {
      this.bikeSelected = true;
      // this.SubcategoryName = 'Sub Category';
    } else {
      this.bikeSelected = false;
      this.postAdForm.get('age').setValue(0);
      // this.postAdForm = this.formBuilder.group({
      //   age: [''],
      // });
      // this.SubcategoryName = 'Sub Category';
    }

    this.subCatList = newArr;
    // console.log(this.subCatList, 'subCatList');
  }

  public test(): any {
    // alert('test');
  }

  public changeSubCatList(event): any {
    // console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;
  }

  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.myaccountService.getAllCategory(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'res');

        if (res.status === 1) {
          this.catList = res.data;
          const newArr = [];
          this.catList.forEach((child: any, key) => {
            // console.log(child, child.parentId);
            if (child.parentId === 0 && child.id !== 45) {
              newArr.push(child);
            }
          });
          this.parentCatList = newArr;
          // console.log(this.parentCatList);
          this.spinner.hide();
          this.getPostData();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', 'There is some error. Please try again.');
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again.');
      }
    );
  }

  public changemanufacturer(event): any {
    if (event.value.name === 'Other') {
      this.manufacturerOthers = true;
    } else {
      this.manufacturerOthers = false;
    }
  }

  public allowInteger(event: KeyboardEvent): any {
    myGlobalsfunc.allowInteger(event);
  }

  public arrayOne(n: number): any[] {
    return Array(n);
  }

  get f(): any {
    return this.postAdForm.controls;
  }

  public setFormInitial(): any {
    // ^[1-9]\d*(\.\d+)?$

    this.postAdForm = this.formBuilder.group({
      age: [
        '',
        [
          Validators.pattern('^[0-9]{1,4}[:]?$'),
          Validators.required,
          Validators.maxLength(4),
        ],
      ],
      categoryId: ['', [Validators.required]],
      createdBy: [window.localStorage.getItem('user_id_user')],
      description: [
        '',
        [
          Validators.required,
          Validators.minLength(50),
          Validators.maxLength(2000),
        ],
      ],
      id: [0],
      images: [],
      manufacturer: [''],
      manufacturertext: [''],
      month: ['', [Validators.pattern('^[0-9]{1,2}[:.,-]?$')]],
      postcode: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(9)],
      ],
      price: [
        '',
        [Validators.required, Validators.pattern('^[0-9]+([,.][0-9]+)?$')],
      ],
      featuredImageNo: ['1'],
      subCategoryId: ['', [Validators.required]],
      subSubCategoryId: [''],
      title: ['', [Validators.required]],
    });
    this.allImages = [];
  }

  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  public imageCropped(event: CroppedEvent): any {
    this.base64 = event.base64;
    this.allImages[this.currentEditKey] = event.base64;
    this.postAdForm.get('images').setValue(this.allImages);
  }

  public deleteImage(i): any {
    // alert(i);
    let featuredSeq = this.postAdForm.get('featuredImageNo').value;
    this.allImages.splice(i, 1);
    if (i + 1 == featuredSeq || this.allImages.length == 1)
      this.postAdForm.get('featuredImageNo').setValue('1');
  }

  public fileChangeEvent(event, form, key): void {
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => {
            // called once readAsDataURL is completed
            Angularthis.allImages[key] = ev.target.result;
            // console.log(ev.target.result);
          };
        }
      } else {
      }
    }
  }

  public openImagePicker(): any {
    this.showImagePicker = true;
  }

  public closeImagePicker(): any {
    this.showImagePicker = false;
    this.imagePickerImageUrl = '';
  }

  public clickedOut(event): any {
    // console.log('clickedOut', event);
    this.showIframe = false;

    if (event.target.className === 'modal-background') {
      this.closeImagePicker();
    }
  }

  public fileUpload(): any {
    this.base64Image = this.sanitizer.bypassSecurityTrustUrl(
      this.imagePickerImageUrl
    );
    // IF IMAGE SELECTED
    if (this.base64Image.changingThisBreaksApplicationSecurity) {
      fetch(this.imagePickerImageUrl)
        .then((res) => res.blob())
        .then((blob) => {
          this.allImages[this.currentEditKey] =
            this.base64Image.changingThisBreaksApplicationSecurity;
        });
    }
  }

  public openEditor(key: any): any {
    this.openImagePicker();

    this.imagePickerImageUrl = '';
    this.currentEditKey = key;
    this.imagePickerImageUrl = this.allImages[key];
  }

  public fileUploaderSingle(event, form): void {
    // console.log('fileChangeEvent', event);
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => {
            // called once readAsDataURL is completed
            Angularthis.allImages.push(ev.target.result);
          };
        }
      } else {
      }
    }
  }

  public fileUploader(event, form): void {
    // alert('test');

    const Angularthis = this;
    if (this.allImages.length < 13 && event.files && event.files.length > 0) {
      // console.log(event.files, 'event.files');
      // console.log(typeof event.files, 'event.files');

      // console.log(form, 'event.form');

      event.files.forEach((child: any, key) => {
        if (myGlobalsfunc.isImage(child.type) && key < 12) {
          if (child.size > 1000000000) {
            this.uploadedFile = null;
            this.showError('Image should be less then 10 MB', 'error');
            window.scroll(0, 0);
          } else {
            // this.preview(event.files);
            // this.msgs = [];

            const reader = new FileReader();

            reader.readAsDataURL(child); // read file as data url
            const thisimage = null;
            reader.onload = (ev) => {
              // called once readAsDataURL is completed
              Angularthis.allImages.push(ev.target.result);
            };

            // this.uploadedFile = event.files[0];
          }
        } else {
          // this.uploadedFile = null;
          // this.showError(text['validFormat'], 'error');
          // window.scroll(0, 0);
        }
      });
    }

    // console.log(this.allImages);
    this.postAdForm.get('images').setValue(this.allImages);
    form.clear();
  }

  public onPaymentSubmit(): any {}

  public onSubmit(num: any): any {
    this.spinner.show();
    console.log(this.postAdForm.invalid, 'this.postAdForm.invalid');
    console.log(this.postAdForm, 'this.postAdForm.invalid');

    this.postAdForm.get('images').setValue(this.allImages);

    if (
      this.postAdForm.value.categoryId === '' ||
      this.postAdForm.value.categoryId === null
    ) {
      this.categoryError = true;
    }

    if (
      this.postAdForm.value.subCategoryId === '' ||
      this.postAdForm.value.subCategoryId === null
    ) {
      this.subCategoryIdError = true;
    }
    if (
      this.postAdForm.value.title === '' ||
      this.postAdForm.value.title === null
    ) {
      this.titleError = true;
    }
    if (
      this.postAdForm.value.price === '' ||
      this.postAdForm.value.price === null
    ) {
      this.priceError = true;
    }
    if (
      this.postAdForm.value.postcode === '' ||
      this.postAdForm.value.postcode === null
    ) {
      this.postcodeError = true;
    }

    if (
      this.postAdForm.value.description === '' ||
      this.postAdForm.value.description === null ||
      this.postAdForm.value.description.length < 100 ||
      this.postAdForm.value.description.length > 2000
    ) {
      this.descriptionError = true;
    }

    // categoryError
    if (this.allImages.length < 2) {
      this.imgError = true;
      this.spinner.hide();
      return;
    }
    if (this.postAdForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.myaccountService.saveAd(this.postAdForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res, 'res');
        if (res.status === 1) {
          this.postAdForm.get('id').setValue(res.data.id);
          this.postId = res.data.id;
          this.slugName = res.data.slugName;

          this.priceRangeData = res.priceRangeData[0];
          this.untilSoldCost = this.priceRangeData.untilSoldCost;
          this.day30Cost = this.priceRangeData.day30Cost;
          this.day90Cost = this.priceRangeData.day90Cost;

          this.spinner.hide();
          if (num === 1) {
            window.scroll(0, 0);
            this.showPaymentForm = true;
          } else if (num === 2) {
            this.showIframe = true;
            console.log(global_var.webSiteUrl + 'ad-detail/' + this.slugName);
            jQuery('#result').attr(
              'src',
              global_var.webSiteUrl + 'ad-detail/' + this.slugName
            );
          } else if (num === 3) {
            window.scroll(0, 0);
            this.showError(
              'success',
              'Your post ad has been saved successfully.'
            );
          } else if (num === 4) {
            window.scroll(0, 0);
            this.showError(
              'success',
              'Your post ad has been drafted successfully.'
            );
            this.setFormInitial();
            this.router.navigate(['adverts/place-ad']);
          }

          // this.setFormInitial();
          // this.showError('success', res.message);
        } else if (res.status === 2) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', 'There is some error. Please try again.');
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError(
          'error',
          'Please check your images are less than 100mb in total and try again. If this error persists, please contact info@racebuykz.com.'
        );
      }
    );
  }

  public applyPromo(): void {
    if (this.promoCode === '' || this.promoCode === undefined) {
      this.showError('error', 'Please enter valid promocode.');
    } else {
      this.spinner.show();
      const req = {
        id: this.authService.getUserId(),
        promo_code: this.promoCode,
      };
      this.myaccountService.checkPromo(req).subscribe(
        (res: any) => {
          this.spinner.hide();

          if (res.status === 1) {
            this.showError(
              'success',
              'Congratulations promocode applied successfully.'
            );

            const getData = res.data[0];
            // discount_percentage

            this.olduntilSoldCost = this.untilSoldCost;
            this.oldday30Cost = this.day30Cost;
            this.oldday90Cost = this.day90Cost;

            this.untilSoldCost =
              this.untilSoldCost -
              (this.untilSoldCost * getData.discount_percentage) / 100;

            this.day30Cost =
              this.day30Cost -
              (this.day30Cost * getData.discount_percentage) / 100;

            this.day90Cost =
              this.day90Cost -
              (this.day90Cost * getData.discount_percentage) / 100;

            if (this.selectedAmount == 1) {
              this.amountPost = this.untilSoldCost;
            }
            if (this.selectedAmount == 2) {
              this.amountPost = this.day90Cost;
            }
            if (this.selectedAmount == 4) {
              this.amountPost = this.day30Cost;
            }

            // console.log(this.amountPost,'amountPost');
            // console.log(this.selectedAmount,'selectedAmount');
            // console.log(this.untilSoldCost,'untilSoldCost');
            // console.log(this.day90Cost,'day90Cost');
            // console.log(this.day30Cost,'day30Cost');

            if (
              this.amountPost == 0 ||
              this.amountPost == 0.0 ||
              this.amountPost == '0'
            ) {
              this.showThankyouForm = true;
              const formdata = {
                expireDate: this.expireDate,
                id: this.postId,
                paymentAmount: this.amountPost,
                paymentId: '100 % disocunt',
                paymentMode: '100 % disocunt',
              };
              this.myaccountService.updateAd(formdata).subscribe(
                (res: any) => {
                  // if the response status is 1 the user is sucessfully logged in else show error message
                  console.log(res, 'updateAd');
                },
                // Log any error occured in logging in
                (error) => {
                  this.showError(
                    'error',
                    'There is some error. Please try again later.'
                  );
                }
              );
            }
            // this.priceRangeData.day90Cost;

            // do opertion on success
          } else if (res.status === 2) {
            this.showError('error', 'Oops promocode is invalid.');
            this.promoCode = '';
          } else if (res.status === 3) {
            this.showError('error', 'Oops this promocode is expired.');
            this.promoCode = '';
          }
        },
        (err) => {
          this.spinner.hide();
          this.showError('error', 'Oops something went.');
        }
      );
    }
  }
}
