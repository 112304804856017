<app-header-front></app-header-front>

<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-7 img-col">
                <div class="bike-img">
                    <img src="../../../../assets/images/bike.jpg" alt="bike">
                </div>
            </div>
            <div class="col-md-6 col-lg-5">
                <div class="login-form">
                    <div class="form-box">
                        <div class="alert-message">
                            <p-messages [(value)]="msgs"></p-messages>
                        </div>

                        <h1>Reset-Password</h1>
                        <form [formGroup]="userResetPasswordForm" (ngSubmit)="onSubmit()" id="userResetPasswordForm">
                            <div class="input-row">
                                <input type="password" #passwordFocus name="password" placeholder="Password" formControlName="password" class="form-control">

                                <div *ngIf="f.password.errors && f.password.dirty" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required" class="error-message-form"> Please enter password.</div>
                                    <div *ngIf="(f.password.errors.password || f.password.invalid) && f.password.value" class="error-message-form">
                                        Please enter valid password.</div>
                                </div>

                            </div>

                            <div class="input-row">

                                <input formControlName="confirm_password" type="password" name="confirm_password" placeholder="Confirm Password" class="form-control">
                                <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                                    <div *ngIf="f.confirm_password.errors.required" class="error-message-form">Please enter confirm password.
                                    </div>
                                    <div *ngIf="f.confirm_password.errors" class="error-message-form">Please enter valid confirm password
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="remember-and-forgot-password">

                                <div class="forgot-password-link">
                                    <a [routerLink]="'/login'">Login</a>
                                </div> 
                    </div> -->
                            <input type="submit" [disabled]="!(userResetPasswordForm.valid)" value="Submit" class="btn btn-default">




                        </form>

                        <div class="register-link">
                            Don't have an account? <a [routerLink]="'/login'">Register here</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>

<app-footer-front></app-footer-front>