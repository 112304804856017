import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.css']
})
export class ListingComponent implements OnInit {
  users: any[];

  cols: any[];
  ngOnInit() {
    this.users = [
      { name: '', email: '',phone:'', description:'', action:''},
  ];
}
  constructor() { }

 selectedParentCategory: any = null;
  categories: any[] = [
      {name: 'Category 1', Value: 'categoryOne'},
      {name: 'Category 2', value: 'categoryTwo'},
  ];
}
