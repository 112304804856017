// import moment
import * as moment from 'moment';

export function saveLocalstorage(id, value) {
    window.localStorage.setItem(id, value);
}

export function allowInteger(event: KeyboardEvent) {
    const pattern = /[0-9]/;

    console.log(event);
    const inputChar = event.key;
    //  String.fromCharCode(event).charCode;
    if (!pattern.test(inputChar)) {
        // invalid character, prevent input
        event.preventDefault();
    }
}

export function convertDate(date, timeBool) {
    let dateString: any = '';
    dateString =
        ('0' + date.getDate()).slice(-2) +
        '/' +
        ('0' + (date.getMonth() + 1)).slice(-2) +
        '/' +
        date.getFullYear();
    if (timeBool) {
        dateString =
            dateString +
            ' ' +
            ('0' + date.getHours()).slice(-2) +
            ':' +
            ('0' + date.getMinutes()).slice(-2);
    }
    return dateString;
}

/*
 * fn : check date is in DST or not and then convert
 * @params date
 * returns moment
 */
export function convertDateToDST(date) {
    if (date) {
        if (moment().isDST() && !moment(date).isDST()) {
            return moment(date).subtract('1', 'hours').toISOString();
        }
        if (!moment().isDST() && moment(date).isDST()) {
            return moment(date).add('1', 'hours').toISOString();
        }
        return moment(date).toISOString();
    } else {
        return date;
    }
}

/*
 * fn : convert UTC date to local
 * @params list
 * date: date string (UTC)
 * format: moment supported format eg DD-MM-YYYY , YYYY-MM-DD HH:mm
 */
export function convertDateLocal(date, format) {
    let formattedDate;
    if (moment(date).isValid()) {
        formattedDate = moment(date + ' UTC').format(format);
    } else {
        formattedDate = '';
    }
    return formattedDate;
}

/*
 * fn : convert UTC date to local
 * @params list
 * date: date string (UTC)
 * format: moment supported format eg DD-MM-YYYY , YYYY-MM-DD HH:mm
 */
export function convertDateLocalWithoutUTC(date, format) {
    let formattedDate;
    if (moment(date).isValid()) {
        formattedDate = moment(date).format(format);
    } else {
        formattedDate = '';
    }
    return formattedDate;
}

/*
 * fn : convert minutes to hour min format
 * @params iMins
 * @params asArray
 * returns a HH:MM format
 */
export function formatToHHMM(iMins, asArray = false) {
    const iHours = parseInt((iMins / 60).toString(), 10);
    iMins = iMins - iHours * 60;
    const hourlen = String(10).length - String(iHours).length + 1;
    const hours = hourlen > 0 ? new Array(hourlen).join('0') + iHours : iHours;
    const minlen = String(10).length - String(iMins).length + 1;
    const mins = minlen > 0 ? new Array(minlen).join('0') + iMins : iMins;

    if (asArray) {
        return [hours, mins];
    } else {
        return hours + ':' + mins;
    }
}

/**
 * Checks mime type is an allowed type.
 */
export function isFile(sType) {
    const allowed = {
        values: [
            'application/octet-stream',
            'application/msword',
            'application/vnd.ms-excel',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
            'application/pdf',
            'application/rtf',
            'application/zip',
            'application/x-zip',
            'application/x-compress',
            'application/zip-compressed',
            'application/x-zip-compressed',
            'application/dicom',
            'application/dcm',
            'text/plain',
            'text/rtf',
            'application/dot',
            'application/x-dot',
            'application/doc',
            'application/microsoft_word',
            'application/mswor2c sc2',
            'application/x-msword',
            'message/rfc822',
            'zz-application/zz-winassoc-dot',
        ],
        patterns: ['^image/[^/]+$', '^video/[^/]+$', '^audio/[^/]+$'],
    };

    // check if type matches a value exactly
    const valid = allowed.values.includes(sType);

    let isPatternValid = true;
    // if not already matched, check if type matches a pattern
    if (!valid) {
        const imagematch = sType.match(allowed.patterns[0]);

        if (!imagematch) {
            isPatternValid = false;
        } else {
            return true;
        }

        const videomatch = sType.match(allowed.patterns[1]);

        if (!videomatch) {
            isPatternValid = false;
        } else {
            return true;
        }

        const audiomatch = sType.match(allowed.patterns[2]);

        if (!audiomatch) {
            isPatternValid = false;
        } else {
            return true;
        }

        return isPatternValid;
    } else {
        return true;
    }
}

export function isImage(sType) {
    const allowed = {
        patterns: ['^image/[^/]+$'],
    };
    // if not already matched, check if type matches a pattern
    const imagematch = sType.match(allowed.patterns[0]);

    if (!imagematch) {
        return false;
    }
    return true;
}

export function convertToDecimal(value) {
    if (isNaN(value)) {
        return '0.00';
    }
    return value.toFixed(2);
}

/**** Function to convert bytes to kb/MB/GB  ***/
export function convertFilesize(bytes, decimals = 2) {
    const size = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const factor = Math.floor((bytes.toString().length - 1) / 3);
    return (
        (bytes / Math.pow(1024, factor)).toFixed(decimals) + ' ' + size[factor]
    );
}
/* fn: slice string to certain length and append '...'
 * Params: text {String}
 * Params: count {Number}
 * return: { String }
 */

export function restrictString(text, count) {
    return text.slice(0, count) + '...';
}

// calculate age from date of birth
export function calculateAge(date) {
    const years = moment().diff(date, 'years');
    return years;
}

// format address
export function formatAddress(
    sAddress1 = '',
    sAddress2 = '',
    sAddress3 = '',
    sTown = '',
    sCounty = '',
    sPostcode = '',
    sCountry = '',
    sDelimeter = '<br />\n',
    showMarker = false,
    iMode = 0,
) {
    const aClean = [];

    if (sAddress1) {
        aClean.push(capitalizeFirstLetter(sAddress1.trim()));
    }

    if (sAddress2) {
        aClean.push(capitalizeFirstLetter(sAddress2.trim()));
    }

    if (sAddress3) {
        aClean.push(capitalizeFirstLetter(sAddress3.trim()));
    }

    const sTownOut = sTown ? capitalizeFirstLetter(sTown.trim()) : '';

    const sCountyOut = sCounty ? capitalizeFirstLetter(sCounty.trim()) : '';

    const sPostcodeOut = sPostcode ? sPostcode.trim().toUpperCase() : '';

    const sCountryOut = sCountry ? capitalizeFirstLetter(sCountry.trim()) : '';

    switch (iMode) {
        case 1:
            if (sTownOut) {
                aClean.push(sTownOut);
            }

            const aSameLine = [];
            if (sCountyOut) {
                aSameLine.push(sCountyOut);
            }
            if (sPostcodeOut) {
                aSameLine.push(sPostcodeOut);
            }
            if (aSameLine && aSameLine.length > 0) {
                aClean.push(aSameLine.join(', '));
            }

            if (sCountryOut) {
                aClean.push(sCountryOut);
            }
            break;

        default:
            if (sTownOut) {
                aClean.push(sTownOut);
            }
            if (sCountyOut) {
                aClean.push(sCountyOut);
            }
            if (sPostcodeOut) {
                aClean.push(sPostcodeOut);
            }
            if (sCountryOut) {
                aClean.push(sCountryOut);
            }
            break;
    }

    return aClean.join(sDelimeter);
}

/**
 * capitalises first letter of a string
 * @param string
 * @returns string
 */
export function capitalizeFirstLetter(string) {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
}

//  format date for display
export function formatDate(date) {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    return formattedDate;
}

//  format date for report display
export function formatReportDate(date) {
    const formattedDate = moment(date).format('DD/MM/YYYY');
    return formattedDate;
}

//  format date and time for display
export function formatDateTime(date) {
    const formattedDate = moment(date).format('DD/MM/YYYY HH:mm');
    return formattedDate;
}

export function checkTrainingExpiry(date) {
    if (date !== '0000-00-00 00:00:00') {
        const momentA = moment(new Date()).format('MM-DD-YYYY');
        const momentB = moment(new Date(`${date} UTC`)).format('MM-DD-YYYY');
        if (moment(momentB).isBefore(momentA)) {
            return 1;
        } else if (moment(momentB).isSameOrAfter(momentA)) {
            return 0;
        }
    } else {
        return 0;
    }
}

// verify day & month for hr record leaves
export function verifyDate(day, month) {
    const response = moment(`${day}-${month}`, 'DD-MM').isValid();
    return response;
}

/**
 * Function for allow the user to change the format of the displayed name
 * @param firstName
 * @param lastName
 * @param bBoldLastName
 * @param type
 * @param bArray
 * @param bBelongsTo
 */
export function formatName(
    currentUser,
    firstName,
    lastName,
    bBoldLastName = false,
    type = '',
    bArray = false,
    bBelongsTo = false,
) {
    const aOut = [];
    const displayNameFormat = currentUser.display_name_format
        ? currentUser.display_name_format
        : 1;
    const iType = type || displayNameFormat;

    const sFirstName = capitalizeFirstLetter(firstName);
    let sLastName = capitalizeFirstLetter(lastName);
    let sFormatted = '';

    switch (iType) {
        // Example : J Doe
        case 2:
            if (bBoldLastName) {
                sLastName = `<strong>${sLastName}</strong>`;
            }
            sFormatted += `${sFirstName.substr(0, 1)} ${sLastName}`;
            break;
        // Example : Doe, John
        case 3:
            if (bBoldLastName) {
                sLastName = `<strong>${sLastName}</strong>`;
            }
            sFormatted += `${sLastName}, ${sFirstName}`;
            break;
        // Example : Doe, J
        case 4:
            if (bBoldLastName) {
                sLastName = `<strong>${sLastName}</strong>`;
            }
            sFormatted += `${sLastName}, ${sFirstName.substr(0, 1)}`;
            break;
        // Example : John D
        case 5:
            sFormatted += sFirstName;
            if (bBoldLastName) {
                sFormatted += ` <strong>${sLastName.substr(0, 1)}</strong>`;
            } else {
                sFormatted += ` ${sLastName.substr(0, 1)}`;
            }
            break;
        // Example : John Doe
        default:
            if (bBoldLastName) {
                sLastName = `<strong>${sLastName}</strong>`;
            }
            sFormatted += `${sFirstName} ${sLastName}`;
            break;
    }

    if (bBelongsTo) {
        sFormatted += sFormatted.substr(-1) === 's' ? '\'' : '\'s';
    }

    if (bArray) {
        aOut.push('John Doe');
        aOut.push('J Doe');
        aOut.push('Doe, John');
        aOut.push('Doe, J');
        aOut.push('John D');
        return aOut;
    }
    return sFormatted;
} // formatName

// calculate periods on basis of day and month selected
export function calculateReviewPeriod(day, month, createdDate) {
    const prevYear = moment(createdDate, 'YYYY/MM/DD').year() - 1;
    const currentYear = moment().year();
    const periodArray = [];
    for (let i = prevYear; i <= currentYear + 1; i++) {
        // let reviewValue = `${i}-${i + 1}`;
        const selected = i === currentYear ? 1 : 0;
        // if (day !== 1 || month !== 1) {
        const startDate = moment([i, month - 1, day]).format('DD/MM/YYYY');
        const endDate = moment([i + 1, month - 1, day])
            .subtract(1, 'days')
            .format('DD/MM/YYYY');

        const startDatePeriod = moment([i, month - 1, day]).format('YYYY-MM-DD');
        const endDatePeriod = moment([i + 1, month - 1, day])
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

        const reviewLabel = `${startDate} - ${endDate}`;
        const reviewValue = `${startDate} - ${endDate}|${startDatePeriod}|${endDatePeriod}`;
        // }
        const periodObj = {};
        periodObj['label'] = reviewLabel;
        periodObj['value'] = reviewLabel;
        periodObj['selected'] = selected;
        periodArray.push(periodObj);
    }
    return periodArray;
}

// function to select current periods
export function selectedPeriod(reviewPeriods) {
    const currentDate = moment().format('DD/MM/YYYY');
    let selectedValue = 0;
    reviewPeriods.map((item, index) => {
        const dateArray1 = item.value.split('|');
        const dateArray = dateArray1[0].split(' - ');
        const startDate = moment(dateArray[0], 'DD/MM/YYYY');
        const endDate = moment(dateArray[1], 'DD/MM/YYYY');
        if (
            moment(currentDate, 'DD/MM/YYYY').isBetween(
                startDate,
                endDate,
                null,
                '[)',
            )
        ) {
            selectedValue = item.value;
        }
    });
    return selectedValue;
}

//  format date and time for search filters
export function formatDateFilters(date) {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return formattedDate;
}

//  format date for display
export function formatConvertDate(date) {
    const utcDate = new Date(date + ' UTC');
    const formattedDate = moment(utcDate).format('DD/MM/YYYY');
    return formattedDate;
}

// calculate absence records review periods
export function calculateAbsenceReviewPeriod() {
    const periodArray = [];

    // current month
    const monthBegin = moment().format('YYYY-MM-01');
    const monthEnd = moment().format('YYYY-MM-') + moment().daysInMonth();
    const monthObj = { label: '', value: { filter: '', index: null } };
    monthObj['label'] = 'Current month';
    monthObj['value']['index'] = 0;
    monthObj['value']['filter'] = `${monthBegin}|${monthEnd}`;
    periodArray.push(monthObj);

    // current year
    const yearBegin = moment().format('YYYY-01-01');
    const yearEnd = moment().format('YYYY-12-31');
    const yearObj = { label: '', value: { filter: '', index: null } };
    yearObj['label'] = 'Current year';
    yearObj['value']['index'] = 1;
    yearObj['value']['filter'] = `${yearBegin}|${yearEnd}`;
    periodArray.push(yearObj);

    // last 6 months
    const today = moment().format('YYYY-MM-DD');
    const last6Months = moment()
        .subtract(6, 'months')
        .format('YYYY-MM-DD');
    const last6Obj = { label: '', value: { filter: '', index: null } };
    last6Obj['label'] = 'Last 6 months';
    last6Obj['value']['index'] = 2;
    last6Obj['value']['filter'] = `${last6Months}|${today}`;
    periodArray.push(last6Obj);

    // last 12 months
    const last12Months = moment()
        .subtract(12, 'months')
        .format('YYYY-MM-DD');
    const last12Obj = { label: '', value: { filter: '', index: null } };
    last12Obj['label'] = 'Last 12 months';
    last12Obj['value']['index'] = 3;
    last12Obj['value']['filter'] = `${last12Months}|${today}`;
    periodArray.push(last12Obj);

    return periodArray;
}

// function to select current absence review periods
export function selectedAbsencePeriod() {
    let selectedValue = '';
    // current month
    const monthBegin = moment().format('YYYY-MM-01');
    const monthEnd = moment().format('YYYY-MM-') + moment().daysInMonth();
    selectedValue = `${monthBegin}|${monthEnd}`;
    return selectedValue;
}

export function lineBreak2HtmlLineBreak(str, isXhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    // eslint-disable-next-line no-useless-concat
    const breakTag =
        isXhtml || typeof isXhtml === 'undefined' ? '<br ' + '/>' : '<br>';
    return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}

export function isValidDate(date) {
    let val = 0;
    if (moment(date).isValid()) {
        val = 1;
    }
    return val;
}

export function stripOtherTagsFromEditor(html) {
    if (html) {
        html = html.replace(/<strong[^>]*>/g, '<b>');
        html = html.replace(/<STRONG[^>]*>/g, '<b>');
        html = html.replace(/<\/strong>/g, '</b>');
        html = html.replace(/<\/STRONG>/g, '</b>');
        html = html.replace(/<em[^>]*>/g, '<i>');
        html = html.replace(/<EM[^>]*>/g, '<i>');
        html = html.replace(/<\/em>/g, '</i>');
        html = html.replace(/<\/EM>/g, '</i>');
        html = html.replace(
            /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\s*\/\s*style>/gi,
            '',
        );
        html = html.replace(
            /<STYLE\b[^<]*(?:(?!<\/STYLE>)<[^<]*)*<\s*\/\s*STYLE>/gi,
            '',
        );
        html = html.replace(/<style>/g, '');
        html = html.replace(/<STYLE>/g, '');
        html = html.replace(/<\/style>/g, '');
        html = html.replace(/<\/STYLE>/g, '');
        html = html.replace(
            /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\s*\/\s*script>/gi,
            '',
        );
        html = html.replace(
            /<SCRIPT\b[^<]*(?:(?!<\/SCRIPT>)<[^<]*)*<\s*\/\s*SCRIPT>/gi,
            '',
        );
        html = html.replace(/<script>/g, '');
        html = html.replace(/<SCRIPT>/g, '');
        html = html.replace(/<\/script>/g, '');
        html = html.replace(/<\/SCRIPT>/g, '');
        html = html.replace(
            /&lt;script\b[^<]*(?:(?!&lt;\/script&gt;)<[^<]*)*&lt;\s*\/\s*script&gt;/gi,
            '',
        );
        html = html.replace(
            /&lt;SCRIPT\b[^<]*(?:(?!&lt;\/SCRIPT&gt;)<[^<]*)*&lt;\s*\/\s*SCRIPT&gt;/gi,
            '',
        );
        html = html.replace(/<blockquote[^>]*>/g, '<div>');
        html = html.replace(/<BLOCKQUOTE[^>]*>/g, '<div>');
        html = html.replace(/<\/blockquote>/g, '</div>');
        html = html.replace(/<\/BLOCKQUOTE>/g, '</div>');
        html = html.replace(/&lt;script&gt;/g, '');
        html = html.replace(/&lt;SCRIPT&gt;/g, '');
        html = html.replace(/&lt;\/script&gt;/g, '');
        html = html.replace(/&lt;\/SCRIPT&gt;/g, '');
        html = html.replace(/ style="[^"]*"/g, '');
        html = html.replace(/ STYLE="[^"]*"/g, '');
        html = html.replace(/ class="[^"]*"/g, '');
        html = html.replace(/ CLASS="[^"]*"/g, '');
        html = html.replace(/ href="[^"]*"/g, '');
        html = html.replace(/ HREF="[^"]*"/g, '');
        html = html.replace(/ src="[^"]*"/g, '');
        html = html.replace(/ SRC="[^"]*"/g, '');
        html = html.replace(/<img[^>]*>/g, '');
        html = html.replace(/<IMG[^>]*>/g, '');
        html = html.replace(/<input[^>]*>/g, '');
        html = html.replace(/<INPUT[^>]*>/g, '');
        html = html.replace(/<iframe[^>]*>/g, '');
        html = html.replace(/<IFRAME[^>]*>/g, '');
        html = html.replace(/<source[^>]*>/g, '');
        html = html.replace(/<SOURCE[^>]*>/g, '');
        html = html.replace(/<embed[^>]*>/g, '');
        html = html.replace(/<EMBED[^>]*>/g, '');
        // tslint:disable:max-line-length
        html = html.replace(
            /<(?!\/?BR\s*\/?)(?!\/?DIV\s*\/?)(?!\/?SPAN\s*\/?)(?!\/?B\s*\/?)(?!\/?I\s*\/?)(?!\/?U\s*\/?)(?!\/?UL\s*\/?)(?!\/?OL\s*\/?)(?!\/?LI\s*\/?)(?!\/?P\s*\/?)(?!\/?br\s*\/?)(?!\/?div\s*\/?)(?!\/?span\s*\/?)(?!\/?b\s*\/?)(?!\/?i\s*\/?)(?!\/?u\s*\/?)(?!\/?ul\s*\/?)(?!\/?ol\s*\/?)(?!\/?li\s*\/?)(?!\/?p\s*\/?)[^>]+>/g,
            '',
        );
        // tslint:enable:max-line-length
    }
    return html;
}

export function roundValue(currentUser, iMins) {
    // eslint-disable-next-line no-param-reassign
    iMins = Number(iMins);
    if (
        currentUser &&
        currentUser.invoicing_round &&
        currentUser.invoicing_round !== '0'
    ) {
        const iAccuracy = currentUser.invoicing_round_by
            ? Number(currentUser.invoicing_round_by)
            : 0;
        let sRoundingMethod = currentUser.invoicing_rounding
            ? currentUser.invoicing_rounding
            : '';
        if (iAccuracy <= 0) {
            sRoundingMethod = '';
        }
        let returnMins = 0;
        switch (sRoundingMethod) {
            case 'UP':
                returnMins = Math.ceil(iMins / iAccuracy) * iAccuracy;
                break;
            case 'DOWN':
                returnMins = iMins - (iMins % iAccuracy);
                break;
            case 'NEAR':
                returnMins = Math.round(iMins / iAccuracy) * iAccuracy;
                break;
            default:
                returnMins = iMins;
                break;
        }
        return returnMins;
    }
    return iMins;
}

declare global {
    interface Number {
        toFixedNoRounding(n: any);
    }
}

Number.prototype.toFixedNoRounding = function (n) {
    const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${n}})?`, 'g');
    const a = this.toString().match(reg)[0];
    const dot = a.indexOf('.');
    if (dot === -1) {
        // integer, insert decimal dot and pad up zeros
        return `${a}.${'0'.repeat(n)}`;
    }
    const b = n - (a.length - dot) + 1;
    return b > 0 ? a + '0'.repeat(b) : a;
};
