<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="page-title">
        <h1>Dashboard</h1>
    </div>
    <div class="p-grid">
        <div class="p-col-6 item-margin-bottom">
            <div *ngIf="revenueLoader" class="loading">
                <div class="loading-1"></div>
                <div class="loading-2"></div>
                <div class="loading-3"></div>
                <div class="loading-4"></div>
            </div>
            <div class="title p-text-center">
                Per Day Revenue
            </div>
            <div class="p-grid p-ai-center p-jc-md-center">
                <div class="p-grid p-ai-center">
                    <label class="p-col-5" for="">Filter By:</label>
                    <p-dropdown class="p-col-3" (onChange)="revenueFilterClick($event)" [options]="filterList"
                        [(ngModel)]="revenueFilterType" optionLabel="value"></p-dropdown>
                </div>
            </div>
            <div [ngClass]="{'dimmer' : revenueLoader==true }">
                <p-chart type="line" [options]="optionsObject" [data]="revenuedata"></p-chart>
            </div>
            <div class="p-text-center">
                {{
                    revenueFilterType.id==1 ? "Total Revenue On Today" : 
                    revenueFilterType.id==2 ? "Total Revenue In This Week" :
                    revenueFilterType.id==3 ? "Total Revenue In "+current_month_name+" "+ current_year :
                    revenueFilterType.id==4 ? "Total Revenue In Year " + current_year:""
                }}
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div *ngIf="newUserLoader" class="loading">
                <div class="loading-1"></div>
                <div class="loading-2"></div>
                <div class="loading-3"></div>
                <div class="loading-4"></div>
            </div>
            <div class="title p-text-center">
                New Users
            </div>
            <div class="p-grid p-ai-center p-jc-md-center">
                <div class="p-grid p-ai-center">
                    <label class="p-col-5" for="">Filter By:</label>
                    <p-dropdown class="p-col-3" (onChange)="newUserFilterClick($event)" [options]="filterList"
                        [(ngModel)]="newUserFilterType" optionLabel="value"></p-dropdown>
                </div>
            </div>
            <div [ngClass]="{'dimmer' : newUserLoader==true }">
                <p-chart type="bar" [options]="optionsObject" [data]="newuserdata"></p-chart>
            </div>
            <div class="p-text-center">    
             {{
                newUserFilterType.id==1 ? "Total Users Registered On Today" : 
                newUserFilterType.id==2 ? "Total Users Registered In This Week" :
                newUserFilterType.id==3 ? "Total Users Registered In "+current_month_name+" "+ current_year :
                newUserFilterType.id==4 ? "Total Users Registered In Year " + current_year:""
             }}
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div *ngIf="activeUserLoader" class="loading">
                <div class="loading-1"></div>
                <div class="loading-2"></div>
                <div class="loading-3"></div>
                <div class="loading-4"></div>
            </div>
            <div class="title p-text-center">
                Active Users
            </div>
            <div class="p-grid p-ai-center p-jc-md-center">
                <div class="p-grid p-ai-center">
                    <label class="p-col-5" for="">Filter By:</label>
                    <p-dropdown class="p-col-3" (onChange)="activeUserFilterClick($event)" [options]="filterList"
                        [(ngModel)]="activeUserFilterType" optionLabel="value"></p-dropdown>
                </div>
            </div>
            <div [ngClass]="{'dimmer' : activeUserLoader==true }">
                <p-chart type="line" [options]="optionsObject" [data]="activeuserdata"></p-chart>
            </div>
            <div class="p-text-center">                
             {{
                activeUserFilterType.id==1 ? "Total Users Logged-In On Today" : 
                activeUserFilterType.id==2 ? "Total Users Logged-In In This Week" :
                activeUserFilterType.id==3 ? "Total Users Logged-In In "+current_month_name+" "+ current_year :
                activeUserFilterType.id==4 ? "Total Users Logged-In In Year " + current_year:""
             }}
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div *ngIf="publishedAdLoader" class="loading">
                <div class="loading-1"></div>
                <div class="loading-2"></div>
                <div class="loading-3"></div>
                <div class="loading-4"></div>
            </div>
            <div class="title p-text-center">
                Published Ads
            </div>
            <div class="p-grid p-ai-center p-jc-md-center">
                <div class="p-grid p-ai-center">
                    <label class="p-col-5" for="">Filter By:</label>
                    <p-dropdown class="p-col-3" (onChange)="publishedAdFilterClick($event)" [options]="filterList"
                        [(ngModel)]="publishedAdFilterType" optionLabel="value"></p-dropdown>
                </div>
            </div>
            <div [ngClass]="{'dimmer' : publishedAdLoader==true }">
                <p-chart [options]="optionsObject" type="line" [data]="publishedaddata"></p-chart>
            </div>
            <div class="p-text-center">                
             {{
                publishedAdFilterType.id==1 ? "Total Ads Published On Today" : 
                publishedAdFilterType.id==2 ? "Total Ads Published In This Week" :
                publishedAdFilterType.id==3 ? "Total Ads Published In "+current_month_name+" "+ current_year :
                publishedAdFilterType.id==4 ? "Total Ads Published In Year " + current_year:""
             }}
             
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div *ngIf="newAdLoader" class="loading">
                <div class="loading-1"></div>
                <div class="loading-2"></div>
                <div class="loading-3"></div>
                <div class="loading-4"></div>
            </div>
            <div class="title p-text-center">
                New Ads
            </div>
            <div class="p-grid p-ai-center p-jc-md-center">
                <div class="p-grid p-ai-center">
                    <label class="p-col-5" for="">Filter By:</label>
                    <p-dropdown class="p-col-3" (onChange)="newAdFilterClick($event)" [options]="filterList"
                        [(ngModel)]="newAdFilterType" optionLabel="value"></p-dropdown>
                </div>
            </div>
            <div [ngClass]="{'dimmer' : newAdLoader==true }">
                <p-chart [options]="optionsObject" type="bar" [data]="newaddata"></p-chart>
            </div>
            <div class="p-text-center">               
             {{
                newAdFilterType.id==1 ? "Total Ads Created On Today" : 
                newAdFilterType.id==2 ? "Total Ads Created In This Week" :
                newAdFilterType.id==3 ? "Total Ads Created In "+current_month_name+" "+ current_year :
                newAdFilterType.id==4 ? "Total Ads Created In Year " + current_year:""
             }} (Including draft ads)
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div class="title p-text-center">
                Latest Ads
            </div>
            <p-table p-table class="table user-list-table" [responsive]="true" autoLayout="true" [value]="newestAdList">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Ad title</th>
                        <!-- <th>Published</th>  -->
                        <th>Category</th>
                        <th>Action</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-newestAdList>
                    <tr>
                        <td class="description">
                            <div class="content">{{newestAdList.title}}</div>
                        </td>
                        <!-- <td class="description">
                             <div class="content">{{newestAdList.isPublished==1?'Yes':'No'}}</div>
                         </td> -->
                        <td class="description">
                            <div class="content">{{newestAdList.category}}</div>
                        </td>
                        <td>
                            <a [routerLink]="'/admin/ad-management/edit/' + newestAdList.id" class="btn-link edit">
                                View Ad
                            </a>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="p-col-6 item-margin-bottom">
            <div class="title p-text-center">
                Latest Users Added
            </div>
            <div class="table-height1">
                <p-table p-table class="table user-list-table" [responsive]="true" autoLayout="true"
                    [value]="newestUsersList">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Name</th>
                            <!-- <th>User Name</th> -->
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-newestUsersList>
                        <tr>
                            <td class="description">
                                <div class="content">{{newestUsersList.name}}</div>
                            </td>
                            <!-- <td class="description">
                             <div class="content">{{newestUsersList.username}}</div>
                         </td> -->
                            <td class="description">
                                <div class="content">{{newestUsersList.email}}</div>
                            </td>
                            <td>
                                <a [routerLink]="'/admin/user-management/edit/' + newestUsersList.id"
                                    class="btn-link edit">
                                    View User
                                </a>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="p-col-6 item-margin-bottom ">
            <div class="title p-text-center">
                Total Published Ads
            </div>
            <div style="max-height:380px;" class="table-height">
                <p-table [ngClass]="{'abc': true}" p-table class="table user-list-table" [responsive]="true"
                    autoLayout="true" [value]="publishedadbycatList">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th width="33%">Published Ad</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-publishedadbycatList>
                        <tr>
                            <td class="description">
                                <div class="content">{{publishedadbycatList.category}}</div>
                            </td>
                            <td class="description">
                                <div class="content">{{publishedadbycatList.sub_category}}</div>
                            </td>
                            <td class="description">
                                <div class="content">{{publishedadbycatList.total}}</div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div>
        <div class="title p-text-center">
            Latest Contact -Us Notifications
        </div>
        <p-table class="table user-list-table" [responsive]="true" autoLayout="true" [value]="newestNotificationList">
            <ng-template pTemplate="body" let-newestNotificationList>
                <div class="msg-box">
                    <div>
                        Subject: {{newestNotificationList.subject}}
                    </div>
                    <div>
                        Sender name: {{newestNotificationList.sender_name}}
                    </div>
                    <div>
                        Sender email: {{newestNotificationList.email}}
                    </div>
                    <div>
                        Date: {{calculateDiff(newestNotificationList.dateCreated)}} ago
                    </div>
                    <div>
                        Message: {{newestNotificationList.message}}
                    </div>
                    <div>
                        <div class="send-icon">
                            <a
                                href="mailto:{{newestNotificationList.email}}?subject={{newestNotificationList.subject}}">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;"
                                    xml:space="preserve">
                                    <g>
                                        <g>
                                            <path d="M507.608,4.395c-4.243-4.244-10.609-5.549-16.177-3.321L9.43,193.872c-5.515,2.206-9.208,7.458-9.42,13.395
                            c-0.211,5.936,3.101,11.437,8.445,14.029l190.068,92.181l92.182,190.068c2.514,5.184,7.764,8.455,13.493,8.455
                            c0.178,0,0.357-0.003,0.536-0.01c5.935-0.211,11.189-3.904,13.394-9.419l192.8-481.998
                            C513.156,15.001,511.851,8.638,507.608,4.395z M52.094,209.118L434.72,56.069L206.691,284.096L52.094,209.118z M302.883,459.907
                            l-74.979-154.599l228.03-228.027L302.883,459.907z" />
                                        </g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>


                                </svg>

                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-table>
    </div>
    <div>
        <div class="title p-text-center">
            Expiring Ads
        </div>
        <div class="p-grid p-ai-center p-jc-md-center">
            <div class="p-grid p-ai-center">
                <label class="p-col-5" for="">Filter By:</label>
                <p-dropdown class="p-col-3" (onChange)="expiringAdFilterClick($event)" [options]="expiringAdFilterList"
                    [(ngModel)]="expiringAdFilterType" optionLabel="value"></p-dropdown>
            </div>
        </div>
        <p-table class="table user-list-table" [responsive]="true" [value]="expiringAdList">
            <ng-template pTemplate="header">
                <tr>
                    <th>Title</th>
                    <th>Expiring On</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-expiringAdList>
                <tr>
                    <td class="description">
                        <div class="content">{{expiringAdList.title}}</div>
                    </td>
                    <td class="description">
                        <div class="content">{{calculateExpireTime(expiringAdList.expireDate)}}</div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</main>