import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DiscountService } from './../discount.service'
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-discount',
  templateUrl: './add-discount.component.html',
  styleUrls: ['./add-discount.component.css']
})
export class AddDiscountComponent implements OnInit {
  public msgs = [];
  public DiscountForm: FormGroup;
  public paramId: any;
  public currentDate: Date;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private discountService: DiscountService
  ) {
    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number
    });
  }


  ngOnInit(): void {
    this.currentDate = new Date()
    this.DiscountForm = this.formBuilder.group({
      id:[0],
      discountCode: ['', [Validators.required]],
      discountPercentage: [0, [Validators.required]],
      discountMinAmount: [0, [Validators.required]],
      discountMaxAmount: [0, [Validators.required]],
      expiryTime: ['', [Validators.required]],
      startTime: [new Date(), [Validators.required]]
    })
    if (this.paramId > 0)
      this.getEditDiscount()
  }
  public getEditDiscount(): void {
    this.spinner.show();
    let req = {
      id: this.paramId
    }
    this.discountService.getSingleCode(req).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1) {
        this.DiscountForm.patchValue({
          id: res.data.id,
          discountCode: res.data.promo_code,
          discountPercentage: res.data.discount_percentage,
          discountMinAmount: res.data.discount_min_amount,
          discountMaxAmount: res.data.discount_max_amount,
          expiryTime: this.formatDate(res.data.expiry_time),
          startTime: this.formatDate(res.data.start_time)
        })
      }
      else {
        this.showError('error', 'Oops somthing went wrong.')
      }
    }, (err: any) => {
      this.showError('error', 'Oops somthing went wrong.')
      this.spinner.hide();
    })
  }
  public onSubmit(): void {
    if (this.DiscountForm.valid) {
      this.spinner.show();
      this.discountService.saveCode(this.DiscountForm.value).subscribe((res: any) => {
        this.spinner.hide();
        if (res.status == 1) {
         this.showError('success','Data saved successfully.')
         this.router.navigate(['admin/discount/']);
        }
      }, (err: any) => {
        this.spinner.hide();
        this.showError('error','Oops somthing went wrong.')
      })
    }
  }
  public formatDate(date) {
    if(date==null || date=='')
       return ''
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

}
