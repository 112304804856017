<app-sidebar></app-sidebar>
<app-header></app-header>


<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <form [formGroup]="cmsForm" (ngSubmit)="onSubmit()" id="cmsAdd">

        <div class="page-title">
            <h1>{{paramId ? 'Edit' : 'Add'}} {{pageType == 1 ? 'Cms' : ''}} {{pageType == 2 ? 'Banner' : ''}} {{pageType == 3 ? 'Faq' : ''}}</h1>
        </div>

        <div class="form-container">

            <div class="ui-fluid p-formgrid p-grid">

                <div class="p-field col-md-6 input-field">
                    <label for="title">Title<span class="required">&#42;</span></label>
                    <input id="title" #titlefocus formControlName="title" type="text" pInputText>

                    <div *ngIf="f.title.errors && f.title.dirty" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required" class="error-message-form">Please enter title.</div>

                    </div>
                </div>

                <div *ngIf="pageType == 3" class="p-field col-md-6 input-field">
                    &nbsp;
                </div>


                <div *ngIf="pageType != 3" class="p-field col-md-6 input-field">
                    <label for="subtitle">Subtitle</label>
                    <input id="subtitle" formControlName="subtitle" type="text" pInputText>
                </div>

                <div *ngIf="pageType == 1 || pageType == 3" class="p-field col-md-12 input-field">
                    <label for="address">Short Description</label>
                    <textarea formControlName="shortDescription" [style]="{'height':'160px','width':'100%','border':'1px solid rgb(222 222 222)','transition':'border-color 0.2s, box-shadow 0.2s','font-size': '15px',
                    'padding': '10px' }"></textarea>
                </div>

                <div *ngIf="paramId != 1 && pageType == 1 && pageType != 3" class=" p-field col-md-12">
                    <label for="address">Description</label>
                    <p-editor formControlName="description" [style]="{'height':'320px'}"></p-editor>
                </div>
                <div *ngIf="pageType == 2" class="p-field col-md-6 input-field">
                    <label for="address">Image Upload</label>
                    <!-- <p-fileUpload class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileUploader($event, form)" chooseLabel="Image" accept="image/*"></p-fileUpload> -->

                    <p-fileUpload class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileChangeEvent($event, form,'image')" chooseLabel="Image" accept="image/*"></p-fileUpload>

                    <img *ngIf="image" src="{{image}}" style="height:100px">
                </div>
            </div>
        </div>
        <div class="form-btn align-right">
            <button pButton type="submit" label="Submit"></button> &nbsp;<button (click)="goBack()" pButton type="button" label="Cancel"></button>



        </div>
    </form>
</main>