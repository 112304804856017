import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { ConfirmPasswordValidator } from '../../service/confirm_password.validator';
import { LoginRegisterService } from '../service/loginRegister.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [],
})
export class RegisterComponent implements OnInit {
  @ViewChild('usernamefocus') public username: ElementRef;
  @ViewChild('captchaElem') public capcta: ReCaptcha2Component;
  public userRegisterForm: FormGroup;
  public msgs = [];

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: LoginRegisterService) { }

  public ngOnInit(): void {
    this.setFormInitial();
  }

  get f() {
    return this.userRegisterForm.controls;
  }

  public setFormInitial() {
    this.userRegisterForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      firstname: [''],
      lastname: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
          Validators.maxLength(255),
        ],
      ],
      phone: [''],
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
          Validators.pattern(
            '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])[\\w~@#$%^&*+=`|{}:;!.?\\"()\\[\\]-]{6,255}$',
          ),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      recaptcha: ['', Validators.required],
      sendCredentials: [],

    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      });
  }

  public onSubmit() {
    this.spinner.show();
    // this.msgs = [];

    console.log(this.userRegisterForm);
    console.log(this.userRegisterForm.invalid);

    if (this.userRegisterForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.service.createUser(this.userRegisterForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          this.setFormInitial();
          this.showError('success', res.message);
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );
  }

  public showError(type, message) {
    console.log(type, message);
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

}