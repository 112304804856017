import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: [],
})
export class MyaccountComponent implements OnInit {

  constructor(
    private authService: AuthService) {
    this.authService.isUserLoggedIn();
  }

  ngOnInit(): void {
  }

}
