<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li *ngIf="cmsdata" class="breadcrumb-item active" aria-current="page">{{cmsdata.title}}</li>
        </ol>
    </div>
</nav>

<main class="main-content front login-front mb-0 pb-5">
    <div *ngIf="cmsdata" class="container">
        <div class="section-title mb-5">
            <h1 class="mt-0">{{cmsdata.title}}</h1>
            <p>{{cmsdata.shortDescription}}</p>
        </div>

        <div class="table-responsive-outer mb-4">
            <div class="mx-n30">
                <div class="table-responsive">
                    <table class="table table-striped border-spacing-30">
                        <thead>
                            <tr>
                                <th>Race Bikes, Track Bikes and Transport</th>
                                <th>
                                    <h3>30<br><span>DAY</span></h3>
                                </th>
                                <th>
                                    <h3>90<br><span>DAY</span></h3>
                                </th>
                                <th>
                                    <h3>Until Sold<br><span></span></h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="priceManagementData">
                            <ng-container *ngFor="let item of priceManagementData">
                                <tr *ngIf="item.catId == 1">
                                    <th *ngIf="item.maxRange > 0"><strong>Up to {{item.maxRange | currency:'GBP'}}</strong></th>

                                    <th *ngIf="item.maxRange == 0"><strong>Above {{item.minRange | currency:'GBP'}}</strong></th>

                                    <td>{{item.day30Cost | currency:'GBP'}}</td>
                                    <td>{{item.day90Cost | currency:'GBP'}}</td>
                                    <td>{{item.untilSoldCost | currency:'GBP'}}</td>
                                </tr>
                            </ng-container>

                            <!-- <tr>
                                <th><strong>Up to £10000</strong></th>
                                <td>£5.00</td>
                                <td>£7.50</td>
                                <td>£10.00</td>
                            </tr>
                            <tr>
                                <th><strong>Up to £15000</strong></th>
                                <td>£7.50</td>
                                <td>£11.25</td>
                                <td>£15.00</td>
                            </tr>
                            <tr>
                                <th><strong>Up to £20000</strong></th>
                                <td>£10.00</td>
                                <td>£15.00</td>
                                <td>£20.00</td>
                            </tr>
                            <tr>
                                <th><strong>£20000+</strong></th>
                                <td>£12.50</td>
                                <td>£18.75</td>
                                <td>£25.00</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="table-responsive-outer mb-4">
            <div class="mx-n30">
                <div class="table-responsive">
                    <table class="table table-striped border-spacing-30">
                        <thead>
                            <tr>
                                <th>Parts and Garage & Pitlane</th>
                                <th>
                                    <h3>30<br><span>DAY</span></h3>
                                </th>
                                <th>
                                    <h3>90<br><span>DAY</span></h3>
                                </th>
                                <th>
                                    <h3>Until Sold<br><span></span></h3>
                                </th>
                            </tr>
                        </thead>
                        <tbody *ngIf="priceManagementData">


                            <ng-container *ngFor="let item of priceManagementData">
                                <tr *ngIf="item.catId == 2">
                                    <th *ngIf="item.maxRange > 0"><strong>Up to {{item.maxRange | currency:'GBP'}}</strong></th>

                                    <th *ngIf="item.maxRange == 0"><strong>Above {{item.minRange | currency:'GBP'}}</strong></th>

                                    <td>{{item.day30Cost | currency:'GBP'}}</td>
                                    <td>{{item.day90Cost | currency:'GBP'}}</td>
                                    <td>{{item.untilSoldCost | currency:'GBP'}}</td>
                                </tr>
                            </ng-container>

                            <!-- <tr>
                                <th><strong>Up to £5000</strong></th>
                                <td>£3.50</td>
                                <td>£3.25</td>
                                <td>£7.00</td>
                            </tr>
                            <tr>
                                <th><strong>Up to £10000</strong></th>
                                <td>£5.00</td>
                                <td>£7.50</td>
                                <td>£10.00</td>
                            </tr>
                            <tr>
                                <th><strong>Up to £15000</strong></th>
                                <td>£7.50</td>
                                <td>£11.25</td>
                                <td>£15.00</td>
                            </tr>
                            <tr>
                                <th><strong>Up to £20000</strong></th>
                                <td>£10.00</td>
                                <td>£15.00</td>
                                <td>£20.00</td>
                            </tr>
                            <tr>
                                <th><strong>£20000+</strong></th>
                                <td>£12.50</td>
                                <td>£18.75</td>
                                <td>£25.00</td>
                            </tr>
                            <tr>
                                <th></th>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr> -->
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="fusion-text" [innerHtml]="cmsdata.description">


        </div>
    </div>
</main>

<app-footer-front></app-footer-front>