import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { AuthService } from '../../service/auth.service';
import { MyaccountService } from '../myaccount/myaccount.service';
import { PageDetailService } from '../service/pageDetail.service';
import { MetaService } from '../../service/MetaService';

declare const jQuery: any;
@Component({
  selector: 'app-search-result',
  styleUrls: [],
  templateUrl: './search-result.component.html',
})
export class SearchResultComponent implements OnInit {
  public item: any[];
  public searchForm: FormGroup;
  public catList: any = [];
  public parentCatList: any = [];
  public msgs = [];
  public subCatList: any = [];
  public subSubCatList: any = [];
  public bikeSelected: any = true;
  public websiteurl: any;
  public allData: any;
  public allDataInitial: any;
  public adPerPageValue: any;
  public totalPage: any = 0;
  public currentPage: any = 0;
  public showNoResult: any = false;
  public servicesData: any;
  public pageTitle: any;

  public adPerPageList = [
    { name: '10' },
    { name: '20' },
    { name: '50' },
    { name: '100' },
    { name: 'All' },
  ];

  public sortByList = [
    { name: 'Price low to high', value: 0 },
    { name: 'Price high to low', value: 1 },
    { name: 'Newest first', value: 2 },
    { name: 'Oldest first', value: 3 },
    { name: 'Show For Sale Items First', value: 5 },
    { name: 'Show Sold Items First', value: 4 },
  ];

  public manufacturerOthers: any = false;

  public manufacturerList = [
    { name: 'Aprilia' },
    { name: 'BMW' },
    { name: 'Ducati' },
    { name: 'Honda' },
    { name: 'Kawasaki' },
    { name: 'KTM' },
    { name: 'Suzuki' },
    { name: 'Triumph' },
    { name: 'Yamaha' },
    { name: 'Other' },
  ];

  public isCategoryPage = false;
  public catId = 0;
  public subCatId = 0;

  public catSlug = '';
  public subCatSlug = '';

  public pageLongDescription = '';

  public openClass: any = '';
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private pageDetailService: PageDetailService,
    private authService: AuthService,
    private metaService: MetaService,
    private myaccountService: MyaccountService
  ) {
    // console.log(1);
    this.router.events.subscribe((val) => {
      if (val.constructor.name === 'NavigationEnd') {
        // console.log('val search', val);
      }
    });
  }

  public categoryClicked(): void {
    if (this.openClass === '') {
      this.openClass = 'open';
    } else {
      this.openClass = '';
    }
  }

  public ngOnInit(): void {
    this.metaService.createCanonicalURL();
    this.setInitialForm();

    // console.log(3);

    this.route.params.subscribe((params) => {
      // console.log(4);

      // console.log('params', params);

      this.msgs = [];

      this.catSlug = params['catid']; // (+) converts string 'id' to a number
      this.subCatSlug = params['subcatid'];
      // this.getAllPostAd();

      this.adPerPageValue = this.adPerPageList[0].name;
      if (this.router.url.indexOf('category') > 0) {
        this.isCategoryPage = true;
        window.localStorage.removeItem('searchItem');
      }

      if (window.localStorage.getItem('categoryList')) {
        this.catList = JSON.parse(window.localStorage.getItem('categoryList'));

        if (
          this.catSlug !== '' &&
          this.catSlug !== undefined &&
          this.catSlug != null
        ) {
          const itemCat = this.catList.find((o) => o.slugName === this.catSlug);
          this.catId = itemCat.id;
        }

        if (
          this.subCatSlug !== '' &&
          this.subCatSlug !== undefined &&
          this.subCatSlug != null
        ) {
          const itemSubCat = this.catList.find(
            (o) =>
              o.parentslugName == this.catSlug && o.slugName === this.subCatSlug
          );
          this.subCatId = itemSubCat.id;
        }

        if (this.subCatId > 0) {
          const item = this.catList.find((o) => o.id === this.subCatId);
          this.pageTitle = item.title;
          this.pageLongDescription = item.longDescription;
        } else {
          if (this.catId > 0) {
            const item = this.catList.find((o) => o.id === this.catId);
            this.pageTitle = item.title;
            this.pageLongDescription = item.longDescription;
          } else {
          }
        }

        const newArr = [];
        this.catList.forEach((child: any, key) => {
          if (child.parentId === 0 && child.id !== 45) {
            newArr.push(child);
          }
        });
        this.parentCatList = newArr;

        // set form data
        let req;
        if (window.localStorage.getItem('searchItem')) {
          req = JSON.parse(window.localStorage.getItem('searchItem'));
          this.searchForm.get('categoryId').setValue(req.categoryId);

          if (
            req.categoryId &&
            req.categoryId !== undefined &&
            req.categoryId.id
          ) {
            this.setSubcatlist(req.categoryId);
          }
          this.searchForm.get('subCategoryId').setValue(req.subCategoryId);
          if (
            req.subCategoryId &&
            req.subCategoryId !== undefined &&
            req.subCategoryId.id
          ) {
            this.setSubSubcatlist(req.subCategoryId);
          }

          this.searchForm
            .get('subSubCategoryId')
            .setValue(req.subSubCategoryId);
          this.searchForm.get('minPrice').setValue(req.minPrice);
          this.searchForm.get('maxPrice').setValue(req.maxPrice);
          this.searchForm.get('manufacturer').setValue(req.manufacturer);

          if (req.manufacturer && req.manufacturer.name === 'Other') {
            this.manufacturerOthers = true;
          }
          this.searchForm
            .get('manufacturertext')
            .setValue(req.manufacturertext);
        }
      } else {
        this.getCategory();
      }

      this.getAllPostAd();
      window.scrollTo(0, 0);

      if (window.localStorage.getItem('categoryList')) {
        this.catList = JSON.parse(window.localStorage.getItem('categoryList'));

        if (
          this.catSlug !== '' &&
          this.catSlug !== undefined &&
          this.catSlug != null
        ) {
          const itemCat = this.catList.find((o) => o.slugName === this.catSlug);
          this.catId = itemCat.id;
        }

        if (
          this.subCatSlug !== '' &&
          this.subCatSlug !== undefined &&
          this.subCatSlug != null
        ) {
          const itemSubCat = this.catList.find(
            (o) =>
              o.parentslugName == this.catSlug && o.slugName === this.subCatSlug
          );
          this.subCatId = itemSubCat.id;
        }

        if (this.subCatId > 0) {
          const item = this.catList.find((o) => o.id === this.subCatId);
          this.pageTitle = item.title;
          this.pageLongDescription = item.longDescription;
        } else {
          if (this.catId > 0) {
            const item = this.catList.find((o) => o.id === this.catId);
            this.pageTitle = item.title;
            this.pageLongDescription = item.longDescription;
          } else {
          }
        }

        const newArr = [];
        this.catList.forEach((child: any, key) => {
          if (child.parentId === 0 && child.id !== 45) {
            newArr.push(child);
          }
        });
        this.parentCatList = newArr;

        // set form data
        let req;
        if (window.localStorage.getItem('searchItem')) {
          req = JSON.parse(window.localStorage.getItem('searchItem'));
          this.searchForm.get('categoryId').setValue(req.categoryId);

          if (
            req.categoryId &&
            req.categoryId !== undefined &&
            req.categoryId.id
          ) {
            this.setSubcatlist(req.categoryId);
          }
          this.searchForm.get('subCategoryId').setValue(req.subCategoryId);
          if (
            req.subCategoryId &&
            req.subCategoryId !== undefined &&
            req.subCategoryId.id
          ) {
            this.setSubSubcatlist(req.subCategoryId);
          }

          this.searchForm
            .get('subSubCategoryId')
            .setValue(req.subSubCategoryId);
          this.searchForm.get('minPrice').setValue(req.minPrice);
          this.searchForm.get('maxPrice').setValue(req.maxPrice);
          this.searchForm.get('manufacturer').setValue(req.manufacturer);

          if (req.manufacturer && req.manufacturer.name === 'Other') {
            this.manufacturerOthers = true;
          }
          this.searchForm
            .get('manufacturertext')
            .setValue(req.manufacturertext);
        }
      } else {
        this.getCategory();
      }

      if (window.localStorage.getItem('serviceList')) {
        this.servicesData = JSON.parse(
          window.localStorage.getItem('serviceList')
        );
      } else {
        this.getServices();
      }
    });
  }

  public getServices(): any {
    const req = {
      isHomePage: true,
    };
    this.spinner.show();
    const AngularThis = this;

    this.pageDetailService.getAllServices(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message

        if (res.status === 1) {
          this.servicesData = res.data;

          myGlobalsfunc.saveLocalstorage(
            'serviceList',
            JSON.stringify(res.data)
          );
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  public addToWishlist(id): any {
    if (!this.authService.checkUserLoggedIn()) {
      this.showError('error', 'Please login to add this to your wishlist.');
      return;
    }
    const req = {
      post_ad_id: id,
      user_id: window.localStorage.getItem('user_id_user'),
    };

    this.spinner.show();
    this.myaccountService.createWishlist(req).subscribe(
      (res: any) => {
        window.scroll(0, 0);
        this.showError(
          'success',
          'Ad has been added to wishlist successfully.'
        );
        if (res.status === 1) {
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  public calculateDiff(dateSent): any {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor(
      (Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      ) -
        Date.UTC(
          dateSent.getFullYear(),
          dateSent.getMonth(),
          dateSent.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );

    if (res > 90) {
      responseString = '90+ days';
    } else {
      responseString = res + ' days';
    }

    if (res === 0) {
      res = Math.floor(
        (Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours()
        ) -
          Date.UTC(
            dateSent.getFullYear(),
            dateSent.getMonth(),
            dateSent.getDate(),
            dateSent.getHours()
          )) /
          (1000 * 60 * 60)
      );

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor(
          (Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate(),
            currentDate.getHours(),
            currentDate.getMinutes()
          ) -
            Date.UTC(
              dateSent.getFullYear(),
              dateSent.getMonth(),
              dateSent.getDate(),
              dateSent.getHours(),
              dateSent.getMinutes()
            )) /
            (1000 * 60)
        );

        responseString = res + ' mins';
      }
    }

    return responseString;
  }

  public arrayOne(n: number): any[] {
    return Array(n);
  }

  public changePage(num: any): any {
    this.spinner.show();
    this.currentPage = num;
    myGlobalsfunc.saveLocalstorage('currentPage', this.currentPage);

    const newarr = this.allDataInitial;
    this.allData = newarr.slice(
      num * this.adPerPageValue,
      Number(num * this.adPerPageValue + Number(this.adPerPageValue))
    );
    this.spinner.hide();
  }

  public changeSortBy(event): any {
    // console.log(event.value.value);
    this.searchForm.get('sortBy').setValue(event.value.value);
    const req = null;
    this.currentPage = 0;
    myGlobalsfunc.saveLocalstorage('currentPage', this.currentPage);

    if (this.isCategoryPage) {
      this.getCatId();
    } else {
      myGlobalsfunc.saveLocalstorage(
        'searchItem',
        JSON.stringify(this.searchForm.value)
      );
      this.getAllPostAd();
    }
  }

  /** changeAdperPage */
  public changeAdperPage(event): any {
    this.spinner.show();
    if (event.value.name !== 'All') {
      this.adPerPageValue = Number(event.value.name);
      this.totalPage = Math.ceil(
        this.allDataInitial.length / this.adPerPageValue
      );
    } else {
      this.adPerPageValue = Number(this.allDataInitial.length);
      this.totalPage = 1;
    }
    const newarry = this.allDataInitial;
    this.allData = newarry.slice(0, this.adPerPageValue);
    this.spinner.hide();
  }

  public goTolastPage(): any {
    this.spinner.show();
    const num = Number(this.totalPage - 1);
    this.currentPage = num;
    myGlobalsfunc.saveLocalstorage('currentPage', this.currentPage);

    const newarr = this.allDataInitial;
    this.allData = newarr.slice(
      num * this.adPerPageValue,
      Number(num * this.adPerPageValue + Number(this.adPerPageValue))
    );
    this.spinner.hide();
  }

  public goTofirstPage(): any {
    this.spinner.show();
    const num = 0;
    this.currentPage = 0;
    myGlobalsfunc.saveLocalstorage('currentPage', this.currentPage);

    const newarr = this.allDataInitial;
    this.allData = newarr.slice(
      num * this.adPerPageValue,
      Number(num * this.adPerPageValue + Number(this.adPerPageValue))
    );
    this.spinner.hide();
  }

  public getAllPostAd(requestFrom: any = null): any {
    let req;
    if (!this.isCategoryPage && window.localStorage.getItem('searchItem')) {
      req = JSON.parse(window.localStorage.getItem('searchItem'));
    } else if (requestFrom) {
      req = requestFrom;
      req.sortBy = this.searchForm.value.sortBy;
    } else {
      req = {
        catid: this.catId,
        sortBy: this.searchForm.value.sortBy,
        subcatid: this.subCatId,
      };
    }
    this.websiteurl = global_var.webSiteUrl;

    this.spinner.show();

    // console.log('getAllPostAd', req);

    this.pageDetailService.getAllPostAd(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message

        if (res.status === 1) {
          this.allDataInitial = res.data;
          if (res.data.length > 0) {
            this.totalPage = Math.ceil(res.data.length / this.adPerPageValue);
            const AngularThis = this;

            this.allDataInitial.forEach((part, index, theArray) => {
              theArray[index].images = JSON.parse(part.images);
              theArray[index].diff = AngularThis.calculateDiff(
                part.dateCreated
              );

              if (theArray[index].title.length > 25) {
                theArray[index].title =
                  theArray[index].title.substring(0, 28) + '...';
              }
              let imgArray;
              const imgArraynew = [];

              if (theArray[index].images && theArray[index].images != null) {
                theArray[index].images.forEach((part, index, theArray) => {
                  imgArray = {
                    alt: '',
                    image: this.websiteurl + 'image/' + part,
                    manageImageRatio: true,
                    thumbImage:
                      this.websiteurl + 'image/' + part + '?thumbnail=200',
                    title: '',
                  };

                  imgArraynew.push(imgArray);
                });
              }
              theArray[index].imageObject = imgArraynew;
            });

            const newarry = this.allDataInitial;
            if (
              window.localStorage.getItem('backFromAdDetail') &&
              window.localStorage.getItem('backFromAdDetail') === '1'
            ) {
              this.currentPage = window.localStorage.getItem('currentPage');
              console.log(Number(this.currentPage * this.adPerPageValue), 'a');
              this.allData = newarry.slice(
                Number(this.currentPage * this.adPerPageValue),
                Number(
                  this.currentPage * this.adPerPageValue +
                    Number(this.adPerPageValue)
                )
              );
              myGlobalsfunc.saveLocalstorage('backFromAdDetail', '0');

              console.log(newarry, 'newarry');

              console.log(this.allData, 'allData');
            } else {
              console.log(Number(this.currentPage * this.adPerPageValue), 'b');
              this.allData = newarry.slice(0, this.adPerPageValue);
              myGlobalsfunc.saveLocalstorage('currentPage', 0);
            }
          } else {
            this.allData = null;
            this.totalPage = 0;
            this.showNoResult = true;
          }

          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  public allowInteger(event: KeyboardEvent): any {
    myGlobalsfunc.allowInteger(event);
  }

  public changemanufacturer(event): any {
    this.manufacturerOthers = event.value.name === 'Other' ? true : false;
  }

  public changeSubCatList(event): void {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;
    this.searchForm.get('subSubCategoryId').setValue('');
  }
  public changeParentList(event): void {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });

    if (event.value.id === 1) {
      this.bikeSelected = true;
    } else {
      this.bikeSelected = false;
    }
    this.subCatList = newArr;
    this.subSubCatList = null;
    this.searchForm.get('subSubCategoryId').setValue('');
    this.searchForm.get('subCategoryId').setValue('');
  }

  public setSubcatlist(event): void {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.id) {
        newArr.push(child);
      }
    });
    if (event.id === 1) {
      this.bikeSelected = true;
    } else {
      this.bikeSelected = false;
    }
    this.subCatList = newArr;
  }

  public setSubSubcatlist(event): void {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;
  }

  public setInitialForm(): void {
    this.searchForm = this.formBuilder.group({
      adsPerPage: [10],
      categoryId: ['', [Validators.required]],
      manufacturer: [''],
      manufacturertext: [''],
      maxPrice: [''],
      minPrice: [''],
      subCategoryId: ['', [Validators.required]],
      subSubCategoryId: [''],

      sortBy: [2],
    });
  }

  public onSearchSubmit(): void {
    myGlobalsfunc.saveLocalstorage(
      'searchItem',
      JSON.stringify(this.searchForm.value)
    );
    if (this.isCategoryPage) {
      this.router.navigate(['adverts/search-ads']);
    } else {
      this.getAllPostAd();
    }
  }

  public getCatId(): any {
    const arrSubsubcat = [];
    const arrSubcat = [];
    const arrCat = [];
    let req;

    jQuery.each(jQuery('input[name="subsubcat"]:checked'), (index, element) => {
      arrSubsubcat.push(parseInt(jQuery(element).attr('value')));
    });

    jQuery.each(jQuery('input[name="subcat"]:checked'), (index, element) => {
      arrSubcat.push(parseInt(jQuery(element).attr('value')));
    });

    // jQuery('input:checkbox[name="subsubcat"]').each(() => {
    //   arrSubsubcat.push(jQuery(this).val());
    // });

    // jQuery('input:checkbox[name=subcat]:checked').each(() => {
    //   arrSubcat.push(jQuery(this).val());
    // });

    console.log(arrSubsubcat, 'arrSubsubcat');
    console.log(arrSubcat, 'arrSubcat');

    // return;

    if (arrSubcat.length > 0 || arrSubsubcat.length > 0) {
      if (this.subCatId && this.subCatId > 0) {
        arrSubcat.push(this.subCatId);
      }
      if (this.catId && this.catId > 0) {
        arrCat.push(this.catId);
      }
      myGlobalsfunc.saveLocalstorage(
        'searchItem',
        JSON.stringify({
          arrCat,
          arrSubcat,
          arrSubsubcat,
        })
      );
      req = {
        arrCat,
        arrSubcat,
        arrSubsubcat,
      };
    } else {
    }

    this.getAllPostAd(req);
  }

  public getCategory(formdata: any = null): void {
    const AngularThis = this;
    this.spinner.show();
    this.myaccountService.getAllCategory(formdata).subscribe(
      (res: any) => {
        if (res.status === 1) {
          this.catList = res.data;

          if (
            this.catSlug !== '' &&
            this.catSlug !== undefined &&
            this.catSlug != null
          ) {
            const itemCat = this.catList.find(
              (o) => o.slugName === this.catSlug
            );
            this.catId = itemCat.id;
          }

          if (
            this.subCatSlug !== '' &&
            this.subCatSlug !== undefined &&
            this.subCatSlug != null
          ) {
            const itemSubCat = this.catList.find(
              (o) =>
                o.parentslugName == this.catSlug &&
                o.slugName === this.subCatSlug
            );
            this.subCatId = itemSubCat.id;
          }

          const newArr = [];
          this.catList.forEach((child: any, key) => {
            if (child.parentId === 0 && child.id !== 45) {
              newArr.push(child);
            }
          });
          this.parentCatList = newArr;

          // set form data
          let req;
          if (window.localStorage.getItem('searchItem')) {
            req = JSON.parse(window.localStorage.getItem('searchItem'));
            this.searchForm.get('categoryId').setValue(req.categoryId);

            if (
              req.categoryId &&
              req.categoryId !== undefined &&
              req.categoryId.id
            ) {
              this.setSubcatlist(req.categoryId);
            }
            this.searchForm.get('subCategoryId').setValue(req.subCategoryId);
            if (
              req.subCategoryId &&
              req.subCategoryId !== undefined &&
              req.subCategoryId.id
            ) {
              this.setSubSubcatlist(req.subCategoryId);
            }

            this.searchForm
              .get('subSubCategoryId')
              .setValue(req.subSubCategoryId);
            this.searchForm.get('minPrice').setValue(req.minPrice);
            this.searchForm.get('maxPrice').setValue(req.maxPrice);
            this.searchForm.get('manufacturer').setValue(req.manufacturer);

            if (req.manufacturer && req.manufacturer.name === 'Other') {
              this.manufacturerOthers = true;
            }
            this.searchForm
              .get('manufacturertext')
              .setValue(req.manufacturertext);
          }
          // end set form data
          this.spinner.hide();
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', 'There is some error. Please try again later.');
      }
    );
  }

  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }
}
