<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Seller/Buyer Dashboard
      </li>
    </ol>
  </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
  <div class="container">
    <div class="alert-message">
      <p-messages [(value)]="msgs"></p-messages>
    </div>

    <div class="row">
      <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
        <div class="myaccount-links">
          <ul>
            <app-sidemenu> </app-sidemenu>

            <!--                         
                        <li><a href="/">Account Info</a></li>
                        <li><a [routerLink]="'/adverts/place-ad'">Post Ads</a></li>
                        <li>
                            <a href="/">View Ads</a>
                            <div class="submenu">
                                <ul>
                                    <li><a href="/">Published Ads</a></li>
                                    <li><a href="/">Draft Ads</a></li>
                                    <li><a href="/">Expired Ads</a></li>
                                </ul>
                            </div>
                        </li>

                        <li><a href="/">Messages</a></li>
                        <li class="active"><a [routerLink]="'/adverts/view-wishlist'">My Wishlist</a></li>
                        <li><a [routerLink]="'/'">Go to Home Page</a></li> -->
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-9">
        <div class="pl-0 pl-lg-4">
          <div class="section-title">
            <h1 class="mt-0">My Wishlist Ads</h1>
          </div>
          <div class="row product-grid">
            <div
              *ngFor="let item of allData; let i = index"
              class="col-12 col-sm-6 col-md-6 my-3"
            >
              <div class="product-item">
                <div class="product-img-main">
                  <div (click)="removeFromWishlist(item.wid)" class="wishlist">
                    <svg
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 512.001 512.001"
                      style="enable-background: new 0 0 512.001 512.001"
                      width="20"
                      height="18"
                      fill="#fff"
                      xml:space="preserve"
                    >
                      <path
                        d="M284.286,256.002L506.143,34.144c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.81-20.475-7.811-28.285,0L256,227.717
			L34.143,5.859c-7.811-7.811-20.475-7.811-28.285,0c-7.81,7.811-7.811,20.475,0,28.285l221.857,221.857L5.858,477.859
			c-7.811,7.811-7.811,20.475,0,28.285c3.905,3.905,9.024,5.857,14.143,5.857c5.119,0,10.237-1.952,14.143-5.857L256,284.287
			l221.857,221.857c3.905,3.905,9.024,5.857,14.143,5.857s10.237-1.952,14.143-5.857c7.811-7.811,7.811-20.475,0-28.285
			L284.286,256.002z"
                      />
                    </svg>
                  </div>

                  <div *ngIf="item.images" class="product-img">
                    <a [routerLink]="'/ad-detail/' + item.slugName">
                      <img
                        src="{{ websiteurl + 'image/' + item.images[0] }}"
                        alt=""
                      />
                    </a>
                  </div>

                  <div class="product-thumb">
                    <ng-image-slider
                      [imageSize]="{
                        width: '31.2%',
                        height: '80px',
                        space: 3.2
                      }"
                      [images]="item.imageObject"
                      #nav
                    ></ng-image-slider>

                    <div class="slider-nav">
                      <span class="prev">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-chevron-left"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          />
                        </svg>
                      </span>
                      <span class="next">
                        <svg
                          width="1em"
                          height="1em"
                          viewBox="0 0 16 16"
                          class="bi bi-chevron-right"
                          fill="currentColor"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="product-info">
                  <div class="product-name">
                    <span class="name pull-left no-translate">{{
                      item.title
                    }}</span>
                    <span class="ad-date pull-right no-translate"
                      >{{ item.diff }} ago</span
                    >
                  </div>
                  <div class="product-price">
                    <span class="price pull-left"
                      ><span class="no-translate">Price:</span>
                      {{ item.price | currency: "GBP" }}
                    </span>
                    <span class="share pull-right">
                      <svg
                        enable-background="new 0 0 482.239 482.239"
                        height="100%"
                        viewBox="0 0 482.239 482.239"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <!-- <div class="product-disc d-flex">

                                <div class="pro-options age">Age:<br>{{item.age}} </div>
                                <div class="pro-options race">Type:<br>{{item.maincatname}}</div>
                                <div class="pro-options postcode">Postcode:<br>{{item.postcode.substring(0, 4)}}</div>
                            </div> -->

                <!-- <div class="product-disc d-flex">
                                    <div *ngIf="item.age" class="pro-options age">Year:<br><small>{{item.age}}</small></div>
                                    <div *ngIf="item.maincatname" class="pro-options race">Type:<br><small>{{item.maincatname}}</small></div>
                                    <div *ngIf="item.postcode" class="pro-options postcode">
                                        Postcode:<br><small>{{item.postcode.substring(0, 4)}}</small>
                                    </div>
                                </div> -->
                <!-- <div class="product-disc d-flex">
                                    <div *ngIf="item.age" class="pro-options age"><br>{{item.age}}</div>
                                    <div *ngIf="item.maincatname" class="pro-options race">Type:<br>{{item.maincatname}}</div>
                                    <div *ngIf="item.postcode" class="pro-options postcode">
                                        <br>{{item.postcode.substring(0, 4)}}
                                    </div>
                                </div> -->

                <div class="product-disc new-d d-flex">
                  <div *ngIf="item.age" class="pro-options age">
                    <span>{{ item.age }}</span>
                  </div>
                  <div *ngIf="item.maincatname" class="pro-options race">
                    <span>{{ item.maincatname }}</span>
                  </div>
                  <div *ngIf="item.postcode" class="pro-options postcode">
                    <span>{{ item.postcode.substring(0, 4) }}</span>
                  </div>
                </div>

                <div class="btns-row mb-3">
                  <a
                    *ngIf="item.isSold == 1"
                    class="btn btn-default btn-gray pull-left"
                    >Sold</a
                  >

                  <a
                    *ngIf="item.isSold == 0"
                    [routerLink]="
                      '/contact-us/' + item.id + '/' + item.createdBy
                    "
                    class="btn btn-default btn-gray pull-left"
                    >Contact Seller</a
                  >

                  <a
                    [routerLink]="'/ad-detail/' + item.slugName"
                    class="btn btn-default btn-black pull-right reviews"
                    >View Ad</a
                  >
                </div>
              </div>
            </div>

            <div *ngIf="allData == null" class="col-12 col-sm-6 col-md-6 my-3">
              <h4>No result found.</h4>
            </div>

            <!--                         
                        <div class="col-12 col-sm-6 col-md-6 my-3">
                            <div class="product-item">
                                <div class="product-img-main">
                                    <div class="wishlist saved">
                                        <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff">
                                            <path _ngcontent-pdv-c117="" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"></path>
                                        </svg>
                                    </div>
                                    <div class="product-img"><img src="../../../assets/images/product-img-01.jpg" alt=""></div>
                                    <div class="product-thumb">
                                        <ul>
                                            <li><img src="../../../assets/images/product-thumb-01.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-02.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-03.jpg" alt="thumb image"></li>
                                        </ul>
                                        <div class="slider-nav">
                                            <span class="prev">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </span>
                                            <span class="next">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-info">
                                    <div class="product-name">
                                        <span class="name pull-left">CBR600 F3 Steelie</span>
                                        <span class="ad-date pull-right">45 mins ago</span>
                                    </div>
                                    <div class="product-price">
                                        <span class="price pull-left">Price: £ 7,000.00</span>
                                        <span class="share pull-right">
                                            <svg enable-background="new 0 0 482.239 482.239" height="100%" viewBox="0 0 482.239 482.239" width="100%" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z" /></svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="product-disc d-flex">
                                    <div class="pro-options gear">2.4</div>
                                    <div class="pro-options miles">36k miles</div>
                                </div>
                                <div class="btns-row mb-3">
                                    <a href="#" class="btn btn-default btn-gray pull-left">Contact Seller</a>
                                    <a href="#" class="btn btn-default btn-black pull-right reviews">View Ad</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 my-3">
                            <div class="product-item">
                                <div class="product-img-main">
                                    <div class="wishlist saved">
                                        <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff">
                                            <path _ngcontent-pdv-c117="" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"></path>
                                        </svg>
                                    </div>
                                    <div class="product-img"><img src="../../../assets/images/product-img-02.jpg" alt=""></div>
                                    <div class="product-thumb">
                                        <ul>
                                            <li><img src="../../../assets/images/product-thumb-01.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-02.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-03.jpg" alt="thumb image"></li>
                                        </ul>
                                        <div class="slider-nav">
                                            <span class="prev">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </span>
                                            <span class="next">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-info">
                                    <div class="product-name">
                                        <span class="name pull-left">Suzuki GSXR600</span>
                                        <span class="ad-date pull-right">4 hours ago</span>
                                    </div>
                                    <div class="product-price">
                                        <span class="price pull-left">Price: £ 7,000.00</span>
                                        <span class="share pull-right">
                                            <svg enable-background="new 0 0 482.239 482.239" height="100%" viewBox="0 0 482.239 482.239" width="100%" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z" /></svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="product-disc d-flex">
                                    <div class="pro-options gear">2.4</div>
                                    <div class="pro-options miles">36k miles</div>
                                </div>
                                <div class="btns-row mb-3">
                                    <a href="#" class="btn btn-default btn-gray pull-left">Contact Seller</a>
                                    <a href="#" class="btn btn-default btn-black pull-right reviews">View Ad</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 my-3">
                            <div class="product-item">
                                <div class="product-img-main">
                                    <div class="wishlist saved">
                                        <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff">
                                            <path _ngcontent-pdv-c117="" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"></path>
                                        </svg>
                                    </div>
                                    <div class="product-img"><img src="../../../assets/images/product-img-01.jpg" alt=""></div>
                                    <div class="product-thumb">
                                        <ul>
                                            <li><img src="../../../assets/images/product-thumb-01.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-02.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-03.jpg" alt="thumb image"></li>
                                        </ul>
                                        <div class="slider-nav">
                                            <span class="prev">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </span>
                                            <span class="next">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-info">
                                    <div class="product-name">
                                        <span class="name pull-left">CBR600 F3 Steelie</span>
                                        <span class="ad-date pull-right">45 mins ago</span>
                                    </div>
                                    <div class="product-price">
                                        <span class="price pull-left">Price: £ 7,000.00</span>
                                        <span class="share pull-right">
                                            <svg enable-background="new 0 0 482.239 482.239" height="100%" viewBox="0 0 482.239 482.239" width="100%" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z" /></svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="product-disc d-flex">
                                    <div class="pro-options gear">2.4</div>
                                    <div class="pro-options miles">36k miles</div>
                                </div>
                                <div class="btns-row mb-3">
                                    <a href="#" class="btn btn-default btn-gray pull-left">Contact Seller</a>
                                    <a href="#" class="btn btn-default btn-black pull-right reviews">View Ad</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-6 my-3">
                            <div class="product-item">
                                <div class="product-img-main">
                                    <div class="wishlist saved">
                                        <svg viewBox="0 -28 512.001 512" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#fff">
                                            <path _ngcontent-pdv-c117="" d="m256 455.515625c-7.289062 0-14.316406-2.640625-19.792969-7.4375-20.683593-18.085937-40.625-35.082031-58.21875-50.074219l-.089843-.078125c-51.582032-43.957031-96.125-81.917969-127.117188-119.3125-34.644531-41.804687-50.78125-81.441406-50.78125-124.742187 0-42.070313 14.425781-80.882813 40.617188-109.292969 26.503906-28.746094 62.871093-44.578125 102.414062-44.578125 29.554688 0 56.621094 9.34375 80.445312 27.769531 12.023438 9.300781 22.921876 20.683594 32.523438 33.960938 9.605469-13.277344 20.5-24.660157 32.527344-33.960938 23.824218-18.425781 50.890625-27.769531 80.445312-27.769531 39.539063 0 75.910156 15.832031 102.414063 44.578125 26.191406 28.410156 40.613281 67.222656 40.613281 109.292969 0 43.300781-16.132812 82.9375-50.777344 124.738281-30.992187 37.398437-75.53125 75.355469-127.105468 119.308594-17.625 15.015625-37.597657 32.039062-58.328126 50.167969-5.472656 4.789062-12.503906 7.429687-19.789062 7.429687zm-112.96875-425.523437c-31.066406 0-59.605469 12.398437-80.367188 34.914062-21.070312 22.855469-32.675781 54.449219-32.675781 88.964844 0 36.417968 13.535157 68.988281 43.882813 105.605468 29.332031 35.394532 72.960937 72.574219 123.476562 115.625l.09375.078126c17.660156 15.050781 37.679688 32.113281 58.515625 50.332031 20.960938-18.253907 41.011719-35.34375 58.707031-50.417969 50.511719-43.050781 94.136719-80.222656 123.46875-115.617188 30.34375-36.617187 43.878907-69.1875 43.878907-105.605468 0-34.515625-11.605469-66.109375-32.675781-88.964844-20.757813-22.515625-49.300782-34.914062-80.363282-34.914062-22.757812 0-43.652344 7.234374-62.101562 21.5-16.441406 12.71875-27.894532 28.796874-34.609375 40.046874-3.453125 5.785157-9.53125 9.238282-16.261719 9.238282s-12.808594-3.453125-16.261719-9.238282c-6.710937-11.25-18.164062-27.328124-34.609375-40.046874-18.449218-14.265626-39.34375-21.5-62.097656-21.5zm0 0"></path>
                                        </svg>
                                    </div>
                                    <div class="product-img"><img src="../../../assets/images/product-img-02.jpg" alt=""></div>
                                    <div class="product-thumb">
                                        <ul>
                                            <li><img src="../../../assets/images/product-thumb-01.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-02.jpg" alt="thumb image"></li>
                                            <li><img src="../../../assets/images/product-thumb-03.jpg" alt="thumb image"></li>
                                        </ul>
                                        <div class="slider-nav">
                                            <span class="prev">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                </svg>
                                            </span>
                                            <span class="next">
                                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-info">
                                    <div class="product-name">
                                        <span class="name pull-left">Suzuki GSXR600</span>
                                        <span class="ad-date pull-right">4 hours ago</span>
                                    </div>
                                    <div class="product-price">
                                        <span class="price pull-left">Price: £ 7,000.00</span>
                                        <span class="share pull-right">
                                            <svg enable-background="new 0 0 482.239 482.239" height="100%" viewBox="0 0 482.239 482.239" width="100%" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m378.902 275.565c-34.183 0-64.306 16.874-83.127 42.521l-94.044-47.033c2.912-9.539 4.943-19.456 4.943-29.934s-2.031-20.397-4.943-29.934l94.044-47.033c18.821 25.647 48.944 42.521 83.127 42.521 56.983 0 103.337-46.354 103.337-103.337s-46.353-103.336-103.337-103.336-103.337 46.354-103.337 103.337c0 10.478 2.033 20.395 4.945 29.932l-94.047 47.035c-18.821-25.647-48.944-42.521-83.127-42.521-56.983 0-103.336 46.353-103.336 103.337s46.353 103.337 103.337 103.337c34.183 0 64.306-16.874 83.127-42.521l94.044 47.035c-2.912 9.536-4.943 19.453-4.943 29.932 0 56.983 46.353 103.337 103.337 103.337s103.337-46.354 103.337-103.337-46.354-103.338-103.337-103.338zm0-241.119c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.897 68.891-68.891 68.891-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891zm-275.565 275.565c-37.995 0-68.891-30.914-68.891-68.891 0-37.978 30.897-68.891 68.891-68.891 37.995 0 68.891 30.914 68.891 68.891s-30.896 68.891-68.891 68.891zm275.565 137.782c-37.995 0-68.891-30.914-68.891-68.891s30.897-68.891 68.891-68.891c37.995 0 68.891 30.914 68.891 68.891 0 37.978-30.896 68.891-68.891 68.891z" /></svg>
                                        </span>
                                    </div>
                                </div>
                                <div class="product-disc d-flex">
                                    <div class="pro-options gear">2.4</div>
                                    <div class="pro-options miles">36k miles</div>
                                </div>
                                <div class="btns-row mb-3">
                                    <a href="#" class="btn btn-default btn-gray pull-left">Contact Seller</a>
                                    <a href="#" class="btn btn-default btn-black pull-right reviews">View Ad</a>
                                </div>
                            </div>
                        </div> -->

            <!-- <div class="col-12 mt-3 mb-5">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#"><svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                            </svg></a></li>
                                    <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#"> <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                            </svg></a></li>
                                </ul>
                            </nav>
                        </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer-front></app-footer-front>
