import { Component } from '@angular/core';
import { MetaService } from './service/MetaService';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.component.html',
})
export class AppComponent {
  public title = 'racebuykz';
  constructor(
    private metaService: MetaService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {
    angulartics2GoogleAnalytics.startTracking();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  public ngOnInit(): any {
    this.metaService.createCanonicalURL();
  }
}
