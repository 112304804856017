import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../global.function';
import * as global_var from '../../global.variable';
import { PageDetailService } from '../service/pageDetail.service';
@Component({
  selector: 'app-services-list',
  styleUrls: ['./services-list.component.css'],
  templateUrl: './services-list.component.html',
})
export class ServicesListComponent implements OnInit {
  public subcatId: any;
  public subSlugName: any = '';

  public getdata: any;
  public childData: any;

  public imageData: any;
  public websiteurl: any;
  public imageurl: any;
  public imageObject: any = [];
  public Heading: any;
  public showSpData: any = false;
  public bannerImage: any;
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private pageDetailService: PageDetailService
  ) {
    this.router.events.subscribe((val) => {
      // see also
      // console.log('router', val);
    });
  }

  public ngOnInit(): void {
    window.scroll(0, 0);
    this.route.params.subscribe((params) => {
      this.subSlugName = params['subcatid']; // (+) converts string 'id' to a number

      if (window.localStorage.getItem('categoryList')) {
        const catList = JSON.parse(window.localStorage.getItem('categoryList'));
        if (
          this.subSlugName !== '' &&
          this.subSlugName !== undefined &&
          this.subSlugName != null
        ) {
          const itemSubCat = catList.find(
            (o) =>
              o.slugName === this.subSlugName && o.parentTitle == 'Services'
          );
          this.subcatId = itemSubCat.id;
        }

        const newArr = [];
        catList.forEach((child: any, key) => {
          if (this.subcatId > 0) {
            if (child.id === this.subcatId) {
              this.Heading = child.title;
              this.bannerImage =
                global_var.webSiteUrl + 'image/' + child.bannerImage;
            }
          } else {
            if (child.id === 45) {
              this.Heading = child.title;
            }
          }

          if (child.parentId === 45) {
            newArr.push(child);
          }
        });
        this.childData = newArr;
      }

      // console.log('this.subcatId', this.subcatId);

      if (this.subcatId > 0) {
        this.showSpData = true;
      } else {
        this.showSpData = false;
      }
      this.websiteurl = global_var.webSiteUrl;
      this.imageurl = 'image/';

      const req = { subcatId: this.subcatId };
      this.spinner.show();
      const AngularThis = this;
      this.pageDetailService.getAllServices(req).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'getAllServices');

          if (res.status === 1) {
            // this.catList = res.data;
            this.getdata = res.data;
            this.spinner.hide();

            console.log(this.getdata, 'getdata');
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            window.scroll(0, 0);
            // this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          // this.showError('error', error);
        }
      );
    });
  }
}
