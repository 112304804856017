import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class AdProductService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllProduct(formdata: any): Observable<any> {

        return this.http.post(global_var.apiUrl + 'postad/getAllProduct', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public saveSpProductDetail(formdata: any = { searchtext: '' }): Observable<any> {
        return this.http.post(global_var.apiUrl + 'postad/saveAdProductDetail', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getSingleAdProduct(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'postad/getSingleAdProduct', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public deleteAdProduct(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'postad/deleteAdProduct', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    
    public getAllCategory(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllCategory', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getSpData(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'users/getspdata', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public saveSpDataDetail(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'postad/saveAdProductDetail', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public updateSpUserData(formdata: any = { searchtext: '' }): any {
        return this.http.post(global_var.apiUrl + 'updatepaymentspuserdata', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getSingleServiceProduct(formdata: any = { searchtext: '' }): any {
        return this.http.post(global_var.apiUrl + 'postad/getSingleServiceProduct', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getSingleProductService(formdata: any = { searchtext: '' }): any {
        return this.http.post(global_var.apiUrl + 'postad/getSingleProductService', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public saveServiceProduct(formdata: any = { searchtext: '' }): any {
        return this.http.post(global_var.apiUrl + 'postad/saveServiceProduct', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public deleteServiceProduct(formdata: any = { searchtext: '' }): any {
        return this.http.post(global_var.apiUrl + 'postad/deleteServiceProduct', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
