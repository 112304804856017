<app-sidebar></app-sidebar>
<app-header></app-header>


<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>

    <form [formGroup]="categoryForm" (ngSubmit)="onSubmit()" id="CategoryAdd">

        <div class="page-title">
            <h1>{{paramId ? 'Edit' : 'Add'}} Category</h1>
        </div>

        <div class="form-container">

            <div class="ui-fluid p-formgrid p-grid">

                <div class="p-field col-md-6 input-field">
                    <label for="title">Title<span class="required">&#42;</span></label>
                    <input id="title" #titlefocus formControlName="title" type="text" pInputText>

                    <div *ngIf="f.title.errors && f.title.dirty" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required" class="error-message-form">Please enter title.</div>

                    </div>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="category">Select Parent category</label>
                    <!-- <p-dropdown inputId="category" [options]="categories" [(ngModel)]="selectedParentCategory" placeholder="Select" optionLabel="name"></p-dropdown> -->

                    <p-dropdown optionLabel="title" [options]="catList" [tabindex]="11" placeholder="Select category" formControlName="parentId" class="select-small"></p-dropdown>
                </div>
                <div class="p-field col-md-6 input-field">
                    <label for="address">Image Upload</label>

                    <p-fileUpload class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileUploader($event, form, 'bannerImage')" chooseLabel="Image" accept="image/*"></p-fileUpload>

                    <img *ngIf="bannerImage" src="{{bannerImage}}" style="height:100px">
                </div>

                <div *ngIf="editdata && parentId == 45" class="p-field col-md-6 input-field">
                    <label for="address">Small Image Upload</label>

                    <p-fileUpload class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="smallavatar" (uploadHandler)="fileUploader($event, form, 'smallImage')" chooseLabel="Image" accept="image/*"></p-fileUpload>

                    <img *ngIf="smallImage" src="{{smallImage}}" style="height:100px">

                </div>

                <div class="p-field col-md-6">
                    <label for="address">Active</label>
                    <div class="p-formgroup-inline">
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="1" inputId="yes" formControlName="isActive"></p-radioButton>
                            <label for="yes">Yes</label>
                        </div>
                        <div class="p-field-checkbox">
                            <p-radioButton name="radiobtn" value="0" inputId="no" formControlName="isActive"></p-radioButton>
                            <label for="no">No</label>
                        </div>
                    </div>
                </div>

                <div class="p-field col-md-12">
                    <label for="address">Description</label>
                    <p-editor formControlName="description" [style]="{'height':'320px'}"></p-editor>
                </div>
            </div>
        </div>
        <div class="form-btn align-right">
            <button pButton type="submit" label="Submit"></button>
            &nbsp;<button (click)="goBack()" pButton type="button" label="Cancel"></button>

        </div>
    </form>
</main>