<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Service Provider Dashboard
      </li>
    </ol>
  </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
        <div class="myaccount-links">
          <ul>
            <app-sidemenu> </app-sidemenu>

            <!-- <li class="active"><a href="/">Account Info</a></li>
                        <li><a href="/">Manage My Page</a></li> -->
          </ul>
        </div>
      </div>
      <div class="col-12 col-md-8 col-lg-9">
        <div class="alert-message">
          <p-messages [(value)]="msgs"></p-messages>
        </div>

        <div class="pl-0 pl-lg-4">
          <div
            [ngClass]="{
              'd-none': showPaymentForm,
              '': !showPaymentForm
            }"
            class="manage-my-page"
          >
            <div class="section-title">
              <h1 class="mt-0">Manage My Page</h1>
            </div>
            <form
              (ngSubmit)="onSubmit()"
              [formGroup]="dataForm"
              class="input-lg"
            >
              <div class="row">
                <div class="p-field col-12 col-lg-6 input-field mb-4">
                  <label>Service category</label>
                  <p-dropdown
                    optionLabel="title"
                    [options]="serviceList"
                    formControlName="subCatId"
                    placeholder="Select a service category"
                  ></p-dropdown>
                </div>
              </div>
              <div class="row">
                <div class="p-field col-12 col-lg-6 input-field mb-4">
                  <label>Tagline</label>
                  <input
                    type="text"
                    formControlName="tagline"
                    placeholder=""
                    value=""
                    pInputText
                  />
                </div>
              </div>
              <div class="row">
                <div class="p-field col-12 col-lg-6 input-field mb-4">
                  <label>Facebook</label>
                  <input
                    type="text"
                    formControlName="facebook"
                    placeholder=""
                    value="59862"
                    pInputText
                  />
                </div>
              </div>
              <div class="row">
                <div class="p-field col-12 col-lg-6 input-field mb-4">
                  <label>Twitter</label>
                  <input
                    type="text"
                    formControlName="twitter"
                    placeholder=""
                    value=""
                    pInputText
                  />
                </div>
              </div>
              <div class="row">
                <div class="p-field col-12 col-lg-6 input-field mb-4">
                  <label>Instagram</label>
                  <input
                    type="text"
                    formControlName="instagram"
                    placeholder=""
                    value=""
                    pInputText
                  />
                </div>
              </div>
              <div class="row">
                <div class="p-field col-12 input-field mb-4">
                  <label>Description</label>
                  <textarea
                    id="description"
                    formControlName="description"
                    class="ui-inputtext"
                    rows="7"
                    pinputtext
                  ></textarea>
                  <small class="help-block pull-right"
                    >50-2000 Characters</small
                  >
                </div>
              </div>
              <div class="row">
                <div
                  class="col-6 col-sm-4 col-md-3 col-lg-2 text-center upphoto"
                >
                  <label class="d-block text-left">Add banner image</label>
                  <div class="photo-item">
                    <img
                      (click)="openEditor('bannerImage')"
                      *ngIf="bannerImage"
                      src="{{ bannerImage }}"
                    />
                    <span>
                      <label class="upload-file">
                        <!-- <p-fileUpload class="fileUploadAd" class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileChangeEvent($event, form,'bannerImage')" chooseLabel="Image" accept="image/*"></p-fileUpload> -->

                        <input
                          accept="image/*"
                          #form
                          type="file"
                          id="file"
                          (change)="
                            fileChangeEvent($event.target, form, 'bannerImage')
                          "
                        />
                      </label>
                    </span>
                    <div class="image-actions">
                      <label
                        (click)="deleteImage('bannerImage')"
                        class="delete"
                        title="Delete"
                        ><svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus-square"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"
                          ></path></svg
                      ></label>
                      <label class="edit upload-file" title="Edit">
                        <!-- <p-fileUpload (uploadHandler)="fileChangeEvent($event, form,'bannerImage')" #form customUpload="true" auto="auto" mode="basic" accept="image/*"></p-fileUpload> -->

                        <input
                          accept="image/*"
                          #form
                          type="file"
                          id="file"
                          (change)="
                            fileChangeEvent($event.target, form, 'bannerImage')
                          "
                        />

                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="pen-square"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM238.1 177.9L102.4 313.6l-6.3 57.1c-.8 7.6 5.6 14.1 13.3 13.3l57.1-6.3L302.2 242c2.3-2.3 2.3-6.1 0-8.5L246.7 178c-2.5-2.4-6.3-2.4-8.6-.1zM345 165.1L314.9 135c-9.4-9.4-24.6-9.4-33.9 0l-23.1 23.1c-2.3 2.3-2.3 6.1 0 8.5l55.5 55.5c2.3 2.3 6.1 2.3 8.5 0L345 199c9.3-9.3 9.3-24.5 0-33.9z"
                          ></path>
                        </svg>
                      </label>
                    </div>
                  </div>
                  <small class="help-block text-left d-block pt-2"
                    >1170px x 375px</small
                  >
                </div>
                <div
                  class="col-6 col-sm-4 col-md-3 col-lg-2 text-center upphoto"
                >
                  <label class="d-block text-left">Add logo</label>
                  <div class="photo-item">
                    <img
                      (click)="openEditor('logoImage')"
                      *ngIf="logoImage"
                      src="{{ logoImage }}"
                    />

                    <span>
                      <label class="upload-file">
                        <!-- <p-fileUpload class="fileUploadAd" class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileChangeEvent($event, form,'logoImage')" chooseLabel="Image" accept="image/*"></p-fileUpload> -->

                        <input
                          class="fileUploadAd"
                          class="file-upload cancel"
                          accept="image/*"
                          #form
                          type="file"
                          id="file"
                          (change)="
                            fileChangeEvent($event.target, form, 'logoImage')
                          "
                        />
                      </label>
                    </span>
                    <div class="image-actions">
                      <label
                        class="delete"
                        (click)="deleteImage('logoImage')"
                        title="Delete"
                        ><svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="minus-square"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"
                          ></path></svg
                      ></label>
                      <label class="edit upload-file" title="Edit">
                        <!-- <p-fileUpload (uploadHandler)="fileChangeEvent($event, form,'logoImage')" #form customUpload="true" auto="auto" mode="basic" accept="image/*"></p-fileUpload> -->

                        <input
                          accept="image/*"
                          #form
                          type="file"
                          id="file"
                          (change)="
                            fileChangeEvent($event.target, form, 'logoImage')
                          "
                        />

                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="pen-square"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM238.1 177.9L102.4 313.6l-6.3 57.1c-.8 7.6 5.6 14.1 13.3 13.3l57.1-6.3L302.2 242c2.3-2.3 2.3-6.1 0-8.5L246.7 178c-2.5-2.4-6.3-2.4-8.6-.1zM345 165.1L314.9 135c-9.4-9.4-24.6-9.4-33.9 0l-23.1 23.1c-2.3 2.3-2.3 6.1 0 8.5l55.5 55.5c2.3 2.3 6.1 2.3 8.5 0L345 199c9.3-9.3 9.3-24.5 0-33.9z"
                          ></path>
                        </svg>
                      </label>
                    </div>
                  </div>
                  <small class="help-block text-left d-block pt-2"
                    >370px x 325px</small
                  >
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-lg-6">
                  <button
                    type="submit"
                    [disabled]="!dataForm.valid"
                    class="btn btn-default pull-right px-30"
                  >
                    <span
                      *ngIf="
                        !this.spUserdata || this.spUserdata.isPaymentDone !== 1
                      "
                      class="ui-button-text ui-clickable"
                      >Checkout</span
                    >

                    <span
                      *ngIf="
                        this.spUserdata && this.spUserdata.isPaymentDone === 1
                      "
                      class="ui-button-text ui-clickable"
                      >Save Changes</span
                    >

                    <span
                      *ngIf="
                        this.spUserdata && this.spUserdata.isPaymentDone === 1
                      "
                      class="ui-button-text ui-clickable"
                      >Save Changes</span
                    >
                  </button>
                  <button
                    type="button"
                    (click)="onSubmit(1)"
                    class="btn btn-default pull-left btn-black px-30"
                  >
                    <span class="ui-button-text ui-clickable"
                      >Preview My Page</span
                    >
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            [ngClass]="{
              'd-none': !showPaymentForm,
              '': showPaymentForm
            }"
            class="payment-sec"
          >
            <div class="section-title">
              <h1 class="mt-0">Payment</h1>
            </div>
            <form action="" method="post" class="input-lg">
              <div class="row">
                <div class="p-field col-12 mb-4">
                  <label class="w-100 mb-4">Submission packages</label>
                  <div class="form-check form-check-inline">
                    <input
                      value="1"
                      class="form-check-input"
                      type="radio"
                      name="payment-option"
                      [checked]="true"
                      id="untilSoldCost"
                    />
                    <label class="form-check-label" for="untilSoldCost">
                      (Annual Fee)
                      <span class="label-price">
                        <del *ngIf="oldamountPost">{{
                          oldamountPost | currency: "GBP"
                        }}</del
                        >&nbsp;

                        {{ amountPost | currency: "GBP" }}
                      </span></label
                    >
                  </div>
                  <small class="form-text text-success mt-3"
                    ><span>
                      Your Service Provider Page will run until {{ expireDate }}
                    </span></small
                  >
                </div>

                <div class="row promo_parent">
                  <div class="promo_child">
                    <input
                      type="text"
                      placeholder="Enter Promocode"
                      class="p-inputtext-sm promo_input"
                      style="width: 165px"
                      pInputText
                      [(ngModel)]="promoCode"
                      name="promocode"
                    />
                    <!-- <div class="error-message-form invalid-feedback">Please Enter Promocode</div> -->
                  </div>
                  <div class="promo_child">
                    <button
                      pButton
                      pRipple
                      type="button"
                      label="Apply"
                      class="p-button-rounded"
                      (click)="applyPromo()"
                      [disabled]="promoCode == '' || promoCode == undefined"
                    ></button>
                  </div>
                </div>

                <div class="col-12 mt-2">
                  <ngx-paypal [config]="payPalConfig"></ngx-paypal>

                  <!-- <button type="button" class="btn btn-default btn-paypal mr-5 pull-left px-30">
                                        <span class="ui-button-text ui-clickable"><img src="../../../../assets/images/paypal.png" alt="paypal" /></span>
                                    </button>
                                    <button type="button" class="btn btn-default pull-left px-30">
                                        <span class="ui-button-text ui-clickable">Pay by Credit or Debit Card</span>
                                    </button> -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<div
  class="modal upload-crop-cover-modal is-xxl has-light-bg"
  [ngClass]="{ 'is-active': showIframe }"
>
  <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
  <div style="height: 85%; width: 85%" class="modal-content">
    <div class="modal-header bg-dark py-2 px-3">
      <h6 class="modal-title text-white mt-0">Preview Here</h6>
      <button
        type="button"
        (click)="clickedOut($event)"
        class="close text-white"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <embed id="result" src="" width="100%" style="height: calc(100vh - 70px)" />
  </div>
</div>

<div
  id="upload-crop-cover-modal"
  class="modal upload-crop-cover-modal is-xxl has-light-bg"
  [ngClass]="{ 'is-active': showImagePicker }"
>
  <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
  <div class="modal-content">
    <app-image-picker
      [(showImagePicker)]="showImagePicker"
      [(imagePickerImageUrl)]="imagePickerImageUrl"
      (imagePickerImageUrlChange)="fileUpload()"
      *ngIf="showImagePicker"
    ></app-image-picker>
  </div>
</div>
<app-footer-front></app-footer-front>
