import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from './../message.service';

@Component({
  selector: 'app-contact-monitoring',
  templateUrl: './contact-monitoring.component.html',
  styleUrls: ['./contact-monitoring.component.css']
})
export class ContactMonitoringComponent implements OnInit {
  public msgs = [];
  public msgList = []
  constructor(private spinner: NgxSpinnerService,
    private msgService: MessageService) { }

  ngOnInit(): void {
    this.getMonitoringMessage();
  }
  public getMonitoringMessage() {
    this.spinner.show();
    this.msgService.getMonitoringMsg(null).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 1)
      {
        let msgs=res.data;
        msgs.sort((a, b) => {
          const keyA = new Date(a.dateCreated);
          const keyB = new Date(b.dateCreated);
          // Compare the 2 dates
          if (keyA < keyB) { return 1; }
          if (keyA > keyB) { return -1; }
          return 0;
        });
        this.msgList = msgs;
        console.log(res)
      }
        
    }, err => {
      this.spinner.hide()
    })
  }
  public calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours())) / (1000 * 60 * 60));

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes())) / (1000 * 60));

        responseString = res + ' mins';
      }
    }
    return responseString;
  }
  public getDateFormate(date): String {
    if (date == '' || date == undefined)
      return ''
    return (new Date(date)).toLocaleDateString()
  }
}
