<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="alert-message">

        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="page-title">
        <h1>Contact Monitoring</h1>
    </div>
    <div class="">
        <p-table class="table user-list-table" [responsive]="true" autoLayout="true" [rows]="10" [value]="msgList" [paginator]="true">
            <ng-template pTemplate="body" let-msgList>
                    <div  class="msg-box">
                    <div>
                        Subject: {{msgList.subject}}
                    </div>
                    <div>
                        Sender name: {{msgList.sender_name}}
                    </div>
                    <div>
                        Sender email: {{msgList.sender_email}}
                    </div>
                    <div>
                        Receiver name: {{msgList.receiver_name}}
                    </div>
                    <div>
                        Receiver email: {{msgList.receiver_email}}
                    </div>
                    <div>
                        Date: {{getDateFormate(msgList.dateCreated)}}
                    </div>
                    <div>
                        Message: {{msgList.message}}
                    </div>
                </div>
            </ng-template>
        </p-table>
      
    </div>
</main>