import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { CroppedEvent } from 'ngx-photo-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { ConfirmPasswordValidator } from '../../../service/confirm_password.validator';
import { DataService } from '../../../service/data.service';
import { AdManagementService } from '../ad-management.service';

@Component({
  selector: 'app-add-ads',
  styleUrls: ['./add-ads.component.css'],
  templateUrl: './add-ads.component.html',
})
export class AddAdsComponent implements OnInit {
  @ViewChild('titlefocus') public title: ElementRef;
  public DataForm: FormGroup;
  public submitted = false;
  public radiobtn: any = null;
  public editid: any = 0;
  public msgs = [];
  public editdata: any;
  public isPaymentDone: any = 1;
  public isDeleted: any = 0;
  public paramId: any;
  public userid: any;
  public manufacturerOthers: any = false;
  public userList: any = [];
  public assignedUserEmail: any;
  public assignedUserId: any;
  public masterUserList: any = [];
  public currentDate: Date;
  public websiteurl: any;
  public showDeleteOption: boolean = false;
  // public selectedCar: any;


  public manufacturerList = [
    { name: 'Aprilia' },
    { name: 'BMW' },
    { name: 'Ducati' },
    { name: 'Honda' },
    { name: 'Kawasaki' },
    { name: 'KTM' },
    { name: 'Suzuki' },
    { name: 'Triumph' },
    { name: 'Yamaha' },
    { name: 'Other' },
  ];

  public base64Image: any;
  public imagePickerImageUrl = '';
  public imageChangedEvent: any;
  public imageURL: any;
  public imageBase64: any;
  public base64: any;
  public allImages: any = new Array();
  public showImagePicker = false;
  public currentEditKey: any;

  public catList: any = null;
  public parentCatList: any = [];
  public subCatList: any = [];
  public subSubCatList: any = [];
  public imageData: any;
  public imageObject: any = [];
  public imgError: any;

  constructor(
    private adManagementService: AdManagementService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private dataService: DataService,
    public sanitizer: DomSanitizer) {
    this.currentDate = new Date();
    this.route.params.subscribe((params) => {
      this.userid = +params['userid']; // (+) converts string 'id' to a number
    });
    this.route.params.subscribe((params) => {
      this.paramId = +params['id']; // (+) converts string 'id' to a number
    });
    this.websiteurl = global_var.webSiteUrl

    this.DataForm = this.formBuilder.group({
      age: [''],
      categoryId: [],
      createdBy: [window.localStorage.getItem('user_id')],
      description: [''],
      expireDate: [],
      id: [''],
      images: [],
      isActive: [1],
      isFeatured: [0],
      isPaymentDone: [0],
      isPublished: [0],
      isSold: [0],
      isSale: [0],
      isDeleted: [0],
      manufacturer: [''],
      manufacturertext: [''],
      month: [''],
      paymentAmount: [''],
      paymentId: [''],
      paymentMode: [''],
      postcode: [''],
      price: [''],
      subCategoryId: [''],
      subSubCategoryId: [''],
      title: [
        '',
        [
          Validators.required,
        ],
      ],
      assigned_user_email: [''],
      assigned_user_id: [''],
      featuredImageNo: ['1']
    });


  }

  public ngOnInit(): void {

    this.getCategory();
    // this.getCategory();

    // if (this.paramId > 0) {
    //   this.getSingleAd();
    // } else {

    // }
    this.getAllUsers('');
  }


  public setSubcatlist(event): any {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.id) {
        newArr.push(child);
      }
    });
    this.subCatList = newArr;

    if (this.subCatList && this.editdata && this.editdata.subCategoryId) {
      this.subCatList.forEach((element) => {

        if (element.id === this.editdata.subCategoryId) {
          this.DataForm.get('subCategoryId').setValue(element);
          this.setSubSubcatlist(element);
        }
      });
    }
  }

  public setSubSubcatlist(event): any {
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;

    if (this.subSubCatList && this.editdata && this.editdata.subSubCategoryId) {
      this.subSubCatList.forEach((element) => {

        if (element.id === this.editdata.subSubCategoryId) {
          this.DataForm.get('subSubCategoryId').setValue(element);
        }
      });
    }
  }

  get f(): any {
    return this.DataForm.controls;
  }

  public goBack(): any {
    this.router.navigate(['admin/ad-management/']);

  }
  public onSubmit(): any {

    this.spinner.show();
    this.adManagementService.saveRecord(this.DataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          // this.postAdForm.get('id').setValue(res.data.id);
          // this.postId = res.data.id;
          this.spinner.hide();
          

          if (this.paramId > 0 && this.userid > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
            this.router.navigate(['admin/ad-management/' + this.userid]);
          } else if (this.paramId > 0) {
            this.dataService.changeMessage('success', 'Record updated successfully.');
            this.router.navigate(['admin/ad-management/']);
          } else {
            this.dataService.changeMessage('success', 'Record added successfully.');

            if (this.userid > 0) {
              this.router.navigate(['admin/ad-management/' + this.userid]);
            } else {
              this.router.navigate(['admin/ad-management/']);
            }

          }

          // this.setFormInitial();
          // this.showError('success', res.message);
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );

  }

  // fn: show error
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  public changeParentList(event): any {
    // console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    this.subCatList = newArr;
  }

  public changeSubCatList(event): any {
    console.log('test', event);
    const AngularThis = this;
    const newArr = [];
    this.catList.forEach((child: any) => {
      if (child.parentId === event.value.id) {
        newArr.push(child);
      }
    });
    this.subSubCatList = newArr;
  }

  public getCategory(formdata: any = null): any {
    const AngularThis = this;
    this.spinner.show();
    this.adManagementService.getAllCategory(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message

        if (res.status === 1) {
          this.catList = res.data;
          const newArr = [];
          this.catList.forEach((child: any, key) => {
            if ((child.parentId === 0) && child.id !== 45) {
              newArr.push(child);
            }
          });
          this.parentCatList = newArr;
          if (this.paramId > 0) {
            this.getSingleAd();
          } else {
            this.spinner.hide();
          }
        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error','Opps somthing went wrong, please try again later.')
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

  public getSingleAd() {
    const req = { id: this.paramId };
    this.spinner.show();

    this.adManagementService.getSingleAd(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res getSingleAd');

        if (res.status === 1) {
          // this.getAllUsers()
          // this.catList = res.data;
          this.editdata = res.data[0];
          this.assignedUserId = res.data[0].assined_userid;

          // correct the string array on model by prependending image URL prefix.
          // the allows the form builder library to send up the correct
          // representation.
          if (res.data[0].images !== '' && typeof res.data[0].images === 'string')
          {
            res.data[0].images = JSON.parse(res.data[0].images);
            if (res.data[0].images && res.data[0].images !== '')
            {
              res.data[0].images.forEach((element) => {
                this.allImages.push(this.websiteurl + "image/" + element)
              });
            }
          }

          this.DataForm = this.formBuilder.group({
            age: [(res.data[0].age ? res.data[0].age : '')],
            categoryId: [],
            createdBy: [window.localStorage.getItem('user_id')],
            description: [res.data[0].description],
            expireDate: [this.formatDate(res.data[0].expireDate)],
            id: [res.data[0].id],
            images: [this.allImages],
            isActive: [res.data[0].isActive],
            isFeatured: [res.data[0].isFeatured],
            featuredImageNo: [res.data[0].featuredImageSeq],
            isPaymentDone: [res.data[0].isPaymentDone],
            isPublished: [res.data[0].isPublished],
            isSold: [res.data[0].isSold],
            isSale: [res.data[0].isSale],
            manufacturer: [{name:res.data[0].manufacturer}],
            manufacturertext: [''],
            month: [''],
            paymentAmount: [res.data[0].paymentAmount],
            paymentId: [res.data[0].paymentId],
            paymentMode: [res.data[0].paymentMode],
            postcode: [res.data[0].postcode],
            price: [res.data[0].price],
            subCategoryId: [''],
            subSubCategoryId: [''],
            title: [
              res.data[0].title,
              [
                Validators.required,
              ],
            ],
            assigned_user_email: [res.data[0].assigned_email],
            assigned_user_id: [res.data[0].assigned_userId],
            isDeleted: [res.data[0].isDeleted],
          });
          

          this.isPaymentDone = res.data[0].isPaymentDone;
          this.isDeleted = res.data[0].isDeleted;
          // set parent category
          // console.log(this.editdata.categoryId, 'this.editdata.categoryId');
          // console.log(this.parentCatList, 'this.parentCatList');
          const AngularThis = this;

          if (this.parentCatList && this.editdata && this.editdata.categoryId) {
            this.parentCatList.forEach((element) => {
              // console.log(element, 'element');

              if (element.id === AngularThis.editdata.categoryId) {
                console.log(element.id, 'element');
                AngularThis.DataForm.get('categoryId').setValue(element);
                this.setSubcatlist(element);
              }
            });
          }

          if (res.data[0].images !== ''){
            this.imageData = res.data[0].images;
          }
          


          let imgArray;
          if (this.imageData && this.imageData !== undefined && this.imageData.length > 0) {
            this.imageData.forEach((part, index, theArray) => {
              imgArray = {
                alt: '',
                image: global_var.webSiteUrl + "image/" + part,
                thumbImage: global_var.webSiteUrl + "image/" + part,
                title: '',
              };
              AngularThis.imageObject.push(imgArray);
            });
          }

          //  if(res.data[0].isDeleted==1)
          this.showDeleteOption = true

          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }
  public changemanufacturer(event): any {
    if (event.value.name === 'Other') {
      this.manufacturerOthers = true;
    } else {
      this.manufacturerOthers = false;
    }
  }

  public arrayOne(n: number): any[] {
    return Array(n);
  }

  public imageCropped(event: CroppedEvent): any {
    this.base64 = event.base64;
    this.allImages[this.currentEditKey] = event.base64;
    this.DataForm.get('images').setValue(this.allImages);
  }

  public deleteImage(i): any {
    // alert(i);
    this.allImages.splice(i, 1);
    this.DataForm.patchValue({ images: this.allImages })
    let featuredSeq = this.DataForm.get("featuredImageNo").value;
    if (i + 1 == featuredSeq || this.allImages.length == 1)
      this.DataForm.get("featuredImageNo").setValue("1");
  }

  public fileChangeEvent(event, _form, key): void {
    const Angularthis = this;

    if (event.files && event.files.length > 0) {
      if (myGlobalsfunc.isImage(event.files[0].type)) {
        if (event.files[0].size > 1000000000) {
          // this.uploadedFile = null;
          this.showError('Image should be less then 10 MB', 'error');
          window.scroll(0, 0);
        } else {
          const reader = new FileReader();

          reader.readAsDataURL(event.files[0]); // read file as data url
          reader.onload = (ev) => { // called once readAsDataURL is completed
            Angularthis.allImages[key] = ev.target.result;
            // console.log(ev.target.result);
          };

        }
      } else {
      }
    }
  }

  public openImagePicker(): any {

    this.showImagePicker = true;
  }

  public closeImagePicker(): any {
    this.showImagePicker = false;
    this.imagePickerImageUrl = '';
  }

  public clickedOut(event): any {
    if (event.target.className === 'modal-background') {
      this.closeImagePicker();
    }
  }

  public fileUpload(): any {
    // console.log('fileUpload');
    // console.log('this.imagePickerImageUrl', this.imagePickerImageUrl);
    // console.log('this.sanitizer', this.sanitizer.bypassSecurityTrustUrl(this.imagePickerImageUrl));


    this.base64Image = this.sanitizer.bypassSecurityTrustUrl(this.imagePickerImageUrl);
    // console.log('this.base64Image.changingThisBreaksApplicationSecurity', this.base64Image.changingThisBreaksApplicationSecurity);

    // this.allImages[this.currentEditKey] = this.base64Image.changingThisBreaksApplicationSecurity;

    // IF IMAGE SELECTED
    if (this.base64Image.changingThisBreaksApplicationSecurity) {
      fetch(this.imagePickerImageUrl).then((res) => res.blob()).then((_blob) => {

        this.allImages[this.currentEditKey] = this.base64Image.changingThisBreaksApplicationSecurity;

      });
    }
    this.DataForm.get('images').setValue(this.allImages);
  }

  public openEditor(key: any): any {
    // alert('test');
    this.openImagePicker();
    this.imagePickerImageUrl = '';
    this.currentEditKey = key;
    this.imagePickerImageUrl = this.allImages[key];

  }

  public fileUploader(event, form): void {

    const Angularthis = this;
    if (this.allImages.length < 13 && event.files && event.files.length > 0) {

      event.files.forEach((child: any, key) => {
        if (myGlobalsfunc.isImage(child.type) && key < 12) {
          if (child.size > 1000000000) {
            // this.uploadedFile = null;
            this.showError('Image should be less then 10 MB', 'error');
            window.scroll(0, 0);
          } else {

            const reader = new FileReader();
            reader.readAsDataURL(child); // read file as data url
            const thisimage = null;
            reader.onload = (ev) => { // called once readAsDataURL is completed
              Angularthis.allImages.push(ev.target.result);

            };

          }
        } else {
        }
      });

    }
    this.DataForm.get('images').setValue(this.allImages);
    form.clear();
  }

  public getAllUsers(searchedText) {
    let req = {
      searchedText: searchedText
    }
    this.adManagementService.getAllUsers(req).subscribe((res: any) => {
      this.masterUserList = res.data;
      // this.userList = res.data;
      this.userList = this.masterUserList.filter(o => o.name.toUpperCase().includes(searchedText))
      console.log(this.userList);
    }, (err: any) => { })
  }
  public userDropdownClick(event) {
    // if(event.path[0].ariaExpanded !=="true")
    // event.currentTarget.parentElement.nextElementSibling.click();
    // event.currentTarget.querySelector(".ui-dropdown-trigger").click();
  }
  public changeAssignedUser(event) {
    // console.log(event.value)
    this.assignedUserEmail = event.value.name;
    this.assignedUserId = this.userList.find(o => o.name == this.assignedUserEmail).user_id;
    this.DataForm.patchValue({ assigned_user_id: this.assignedUserId, assigned_user_email: this.assignedUserEmail });
    // this.selectedUserEmail=this.assignedUserEmail;
  }
  public filterBy(event) {
    let searchedText = event.target.value.toUpperCase();
    this.getAllUsers(searchedText);
    //this.userList = this.masterUserList.filter(o => o.name.toUpperCase().includes(searchedText))
  }
  public formatDate(date) {
    if(date==null || date=='')
       return ''
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
}