<app-header-front></app-header-front>
<nav aria-label="breadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Advertise your services
      </li>
    </ol>
  </div>
</nav>
<div class="main-content front login-front mb-0 pb-5">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-12">
        <div class="section-title">
          <h1 class="mt-0">Advertise your services</h1>
        </div>
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="pt-5">
              <h6 class="mt-0 mb-3">Reasons to advertise</h6>

              <p>
                There are so may reasons to use Racebuykz, to advertise your
                business, products and brands - that we thought we might just as
                well list them!
              </p>

              <ul class="dot-list">
                <li>
                  Reaches huge, focussed Motorcycle Racing and Trackday
                  audiences...
                </li>
                <li>
                  Extends your existing digital reach and encourages customer
                  contact...
                </li>
                <li>
                  Allows you to feature and rotate 6 products or Service
                  offerings...
                </li>
                <li>
                  Partnership is supported by Racebuykz own social media...
                </li>
                <li>
                  Content and imagery remain 'in your control' - fully
                  editable...
                </li>
                <li>
                  One simple Annual Fee - with further advertising options
                </li>
                <li>
                  Customisable contact e-mail - contact info@racebuykz.com if
                  you wish to change the contact email address displayed on your
                  page
                </li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-lg-5">
            <div class="alert-message">
              <p-messages [(value)]="msgs"></p-messages>
            </div>
            <div class="py-5 px-5 form-box border-radius-0">
              <h4 class="mt-0 mb-3">Register</h4>
              <form
                autocomplete="off"
                [formGroup]="userRegisterForm"
                (ngSubmit)="onRegisterSubmit()"
                id="userRegisterForm"
              >
                <div class="row mx-n2">
                  <div class="col-12 px-2">
                    <div class="input-row position-relative">
                      <!-- <div class="info-popover info-popover-right info-popover-force-left pull-right">
                                                <img src="../../../assets/images/info-icon.png" class="position-absolute info-icon" alt="info">
                                                <div class="info-popover-content position-absolute">
                                                    <p>This will be you username for login.</p>
                                                </div>
                                            </div> -->

                      <input
                        type="text"
                        formControlName="companyName"
                        name="companyName"
                        placeholder="Company Name"
                        class="form-control"
                      />
                      <div
                        *ngIf="f.companyName.errors && f.companyName.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.companyName.errors.required"
                          class="error-message-form"
                        >
                          Please enter Company Name.
                        </div>
                      </div>

                      <!-- <input type="text" formControlName="username" name="username" placeholder="Username" class="form-control form-control-info">
                                            <div *ngIf="f.username.errors && f.username.dirty" class="invalid-feedback">
                                                <div *ngIf="f.username.errors.required" class="error-message-form">Please enter username.</div>
                                                <div *ngIf="f.username.errors.minlength" class="error-message-form">Username must have atleast 6 characters.
                                                </div>

                                            </div> -->
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <input
                        type="text"
                        formControlName="websiteUrl"
                        name="websiteUrl"
                        placeholder="Website Url"
                        class="form-control"
                      />
                      <div
                        *ngIf="f.websiteUrl.errors && f.websiteUrl.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.websiteUrl.errors.required"
                          class="error-message-form"
                        >
                          Please enter Website Url.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <input
                        type="text"
                        formControlName="companyRegNo"
                        name="companyRegNo"
                        placeholder="Company Registration Number"
                        class="form-control"
                      />
                      <div
                        *ngIf="f.companyRegNo.errors && f.companyRegNo.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.companyRegNo.errors.required"
                          class="error-message-form"
                        >
                          Please enter Company Registration Number.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <input
                        type="email"
                        formControlName="email"
                        name="email"
                        placeholder="Email"
                        class="form-control"
                      />
                      <div
                        *ngIf="f.email.errors && f.email.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.email.errors.required"
                          class="error-message-form"
                        >
                          Please enter email.
                        </div>
                        <div
                          *ngIf="
                            (f.email.errors.email || f.email.invalid) &&
                            f.email.value
                          "
                          class="error-message-form"
                        >
                          Please enter valid email.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <!-- <input type="text" formControlName="phone" name="phone" placeholder="Mobile Phone" class="form-control"> -->

                      <ngx-intl-tel-input
                        [cssClass]="'form-control'"
                        [preferredCountries]="['gb']"
                        [selectedCountryISO]="'gb'"
                        [enableAutoCountrySelect]="false"
                        [enablePlaceholder]="true"
                        placeholder="Phone"
                        [searchCountryFlag]="true"
                        [selectFirstCountry]="false"
                        [maxLength]="15"
                        [separateDialCode]="true"
                        name="phone"
                        [phoneValidation]="false"
                        formControlName="phone"
                      >
                      </ngx-intl-tel-input>

                      <div
                        *ngIf="f.phone.errors && f.phone.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.phone.errors.required"
                          class="error-message-form"
                        >
                          Please enter phone.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <input
                        type="password"
                        formControlName="password"
                        name="password"
                        placeholder="Password"
                        class="form-control"
                      />
                      <div
                        *ngIf="f.password.errors && f.password.dirty"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.password.errors.required"
                          class="error-message-form"
                        >
                          Please enter password.
                        </div>
                        <div
                          *ngIf="
                            (f.password.errors.password ||
                              f.password.invalid) &&
                            f.password.value
                          "
                          class="error-message-form"
                        >
                          The password must have at least 6 characters and a mix
                          of letters and numbers..
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <input
                        type="password"
                        formControlName="confirm_password"
                        name="repeatpassword"
                        placeholder="Confirm Password"
                        class="form-control"
                      />
                      <div
                        *ngIf="
                          f.confirm_password.errors && f.confirm_password.dirty
                        "
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="f.confirm_password.errors.required"
                          class="error-message-form"
                        >
                          Please enter confirm password.
                        </div>
                        <div
                          *ngIf="f.confirm_password.errors"
                          class="error-message-form"
                        >
                          Please enter valid confirm password
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="input-row">
                      <div class="google-captcha">
                        <!-- Google captcha -->
                        <ngx-recaptcha2
                          #captchaElem
                          [siteKey]="'6LdUB7cdAAAAAIw3DXpFAVcJ-8RNxyhKQDMxp8bw'"
                          formControlName="recaptcha"
                        >
                        </ngx-recaptcha2>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 px-2">
                    <div class="form-check">
                      <input
                        formControlName="isAgreeTerms"
                        type="checkbox"
                        class="form-check-input"
                        id="terms-and-conditions"
                      />
                      <label for="terms-and-conditions"
                        >I agree to Racebuykz Terms and Conditions and Privacy
                        Policy</label
                      >
                    </div>
                  </div>
                  <div class="col-12 mt-3 px-2">
                    <input
                      type="submit"
                      [disabled]="!userRegisterForm.valid"
                      value="Register"
                      class="btn btn-default px-5 float-left"
                    />

                    <!-- <input type="submit" [disabled]="!(userRegisterForm.valid)" value="Register" class="btn btn-default px-5 float-left">
                                                 -->
                    <!-- <input type="submit" value="Register" class="btn btn-default px-5 float-left"> -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer-front></app-footer-front>
