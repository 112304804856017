<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
<div class="page-title">
    <h1>Add/Edit Section</h1>
</div>
    <div class="form-container">
        <div class="ui-fluid p-formgrid p-grid">
            <div class="p-field col-md-6 input-field">
                <label for="title">Title</label>
                <input id="title" type="text" pInputText> 
            </div>
            <div class="p-field col-md-6 input-field">
                <label for="category">Select Parent category</label>
                <p-dropdown inputId="category" [options]="categories" [(ngModel)]="selectedParentCategory" placeholder="Select" optionLabel="name"></p-dropdown>
            </div>
            <div class="p-field col-md-12">
                <label for="address">Image Upload</label>
                <p-fileUpload name="myfile[]" url="./upload.php" multiple="multiple"
                accept="image/*" maxFileSize="1000000"></p-fileUpload>
            </div>
            <div class="p-field col-md-12">
                <label for="address">Description</label>
                <p-editor formControlName="description" [style]="{'height':'320px'}"></p-editor>
            </div>
            <div class="p-field col-md-12">
                <label for="address">Active</label>
                <div class="p-formgroup-inline">
                    <div class="p-field-checkbox">
                    <p-radioButton name="radiobtn" value="Yes" [(ngModel)]="radiobtn" inputId="yes"></p-radioButton>
                    <label for="yes">Yes</label>
                    </div>
                    <div class="p-field-checkbox">
                        <p-radioButton name="radiobtn" value="No" [(ngModel)]="radiobtn" inputId="no"></p-radioButton>
                        <label for="no">No</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-btn align-right">
        <button pButton type="button" label="Submit"></button>
    </div>
</main>
