import { Component, OnInit } from '@angular/core';

import { NgxSpinnerService } from 'ngx-spinner';
import { MyaccountService } from '../../myaccount/myaccount.service';

@Component({
  selector: 'app-footer-front',
  styleUrls: [],
  templateUrl: './footer-front.component.html',

})
export class FooterFrontComponent implements OnInit {
  public catList: any;
  public cmsdata: any;
  constructor(
    private myaccountService: MyaccountService,
    private spinner: NgxSpinnerService,

  ) { }

  public ngOnInit(): void {
    if (window.localStorage.getItem('categoryList')) {
      this.catList = JSON.parse(window.localStorage.getItem('categoryList'));
    } else {
    }

    const req = { pageType: 1 };
    this.myaccountService.getAllcms(req).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        // console.log(res.data, 'getAllcms');

        if (res.status === 1) {
          // this.catList = res.data;
          this.cmsdata = res.data;
          this.spinner.hide();

        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        // this.showError('error', error);
      },
    );


  }

}
