import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class PriceManagementService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAll(formdata: any = { searchtext: '' }) {

        return this.http.post(global_var.apiUrl + 'pricemanagement/getAll', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSingle(req) {
        return this.http.post(global_var.apiUrl + 'pricemanagement/getsingle', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public save(data: any) {
        return this.http.post(global_var.apiUrl + 'pricemanagement/create', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public delete(data: any) {
        return this.http.post(global_var.apiUrl + 'pricemanagement/delete', data).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

}
