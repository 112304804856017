import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  styleUrls: ['./sidebar.component.css'],
  templateUrl: './sidebar.component.html',

})
export class SidebarComponent implements OnInit {
  public toggleButtonMobile = false;
  public currentLink: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.currentLink = this.router.url;
  }

  public ngOnInit(): void {
  }

  public expand(type): any {
    if (type === 'Togglebutton') {
      this.toggleButtonMobile = !this.toggleButtonMobile;
    }
  }
}
