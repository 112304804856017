<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="alert-message">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="page-title">
        <h1>Change Password</h1>
    </div>
    <div>
        <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
            <div class="form-container">
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-field col-md-6 input-field">
                        <label for="title">Current Password<span class="required">&#42;</span></label>
                        <input id="title" #titlefocus formControlName="currentPass" type="password" pInputText>
                    </div>
                </div>
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-field col-md-6 input-field">
                        <label for="title">New Password<span class="required">&#42;</span></label>
                        <input id="title" #titlefocus formControlName="password" type="password" pInputText>
                    </div>
                </div>
                <div class="ui-fluid p-formgrid p-grid">
                    <div class="p-field col-md-6 input-field">
                        <label for="title">Confirm New Password<span class="required">&#42;</span></label>
                        <input id="title" #titlefocus formControlName="confirm_password" type="password" pInputText>
                    </div>
                    <div *ngIf="f.confirm_password.errors && f.confirm_password.dirty" class="invalid-feedback">
                        <div *ngIf="f.confirm_password.errors.required" class="error-message-form">Please enter confirm password.
                        </div>
                        <div *ngIf="f.confirm_password.errors" class="error-message-form">Please enter valid confirm password
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-btn align-right">
                <button pButton type="submit" label="Submit"></button>
            </div>
        </form>
    </div>
</main>