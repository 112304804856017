<app-header-front></app-header-front>
<nav aria-label="breadcrumb" class="purpleBreadcrumb">
    <div class="container">
        <ol class="breadcrumb border-bottom">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">Page Not Found</li>
        </ol>
    </div>
</nav>


<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="section-title mb-5">
            <h1 class="mt-0">404 Page Not Found</h1>
        
        </div>
    </div>
</main>

<app-footer-front></app-footer-front>