import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Fuse from 'fuse.js';
import { DataService } from '../../service/data.service';
import { UserManagementService } from './user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
export class UserManagementComponent implements OnInit {
  public dataList: any[];
  public masterList: Fuse<any>;
  public searchText: any = '';
  public cols: any[];
  public msgs = [];
  public selectedParentCategory: any = null;
  public categories: any = [];
  public getmessage: any = [];
  public sortlist: any = [];
  public selectedSortpattern: any = null;
  public selectedValues: number[] = [];
  public selectAllList: any[] = [];
  public selectedActionType: any = { title: 'Select Action', value: -1 };
  public actionlist: any = [];
  first = 0;
  public rowsPerPage = 10;

  public pages: any[] = [];
  public selectedPage = { page: 1, title: '1' };

  constructor(
    private userManagementService: UserManagementService,
    private spinner: NgxSpinnerService,
    private dataService: DataService
  ) {
    this.sortlist = [
      { title: 'Date Registered Descending', value: 0 },
      { title: 'Date Registered Ascending', value: 1 },
      { title: 'Active First', value: 2 },
      { title: 'Non-Active First', value: 3 },
    ];
    this.actionlist = [
      { title: 'Delete', value: 0 },
      { title: 'Make Administrator', value: 1 },
    ];
  }
  public ngOnInit(): void {
    this.getAllUsers();

    const subscription = this.dataService.currentMessage.subscribe((data) => {
      console.log(data, 'message');
      this.getmessage = data;
      if (
        this.getmessage.type !== undefined &&
        this.getmessage.type !== '' &&
        this.getmessage.message !== undefined
      ) {
        this.showError(this.getmessage.type, this.getmessage.message);
      }
    });
    setTimeout(() => this.dataService.changeMessage('', ''), 1000);
  }
  public ngDoCheck() {
    if (Math.floor(this.first / this.rowsPerPage + 1) != this.selectedPage.page)
      this.selectedPage = {
        page: Math.floor(this.first / this.rowsPerPage + 1),
        title: Math.floor(this.first / this.rowsPerPage + 1).toString(),
      };
  }

  public changeSortBy(event): any {
    console.log(event, 'changeSortBy');
    if (event.value.value == 1)
      this.dataList.sort(function (a, b) {
        var keyA = new Date(a.item.dateUpdated),
          keyB = new Date(b.item.dateUpdated);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    else if (event.value.value == 0)
      this.dataList.sort(function (a, b) {
        var keyA = new Date(a.item.dateUpdated),
          keyB = new Date(b.item.dateUpdated);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    else if (event.value.value == 2)
      this.dataList.sort(function (a, b) {
        var keyA = a.item.isActive,
          keyB = b.item.isActive;
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
    else if (event.value.value == 3)
      this.dataList.sort(function (a, b) {
        var keyA = a.item.isActive,
          keyB = b.item.isActive;
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
  }

  // fn: show error
  public showError(type, message): void {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }

  public getAllUsers(formdata: any = null): void {
    const fuseOptions = {
      keys: ['username', 'firstname', 'lastname', 'email', 'phone'],
    };

    this.spinner.show();
    this.userManagementService.getAllUsers(formdata).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data, 'res getAllUsers');

        if (res.status === 1) {
          this.masterList = new Fuse(res.data, fuseOptions);
          this.dataList = res.data.map((r) => ({
            item: r,
          }));

          this.spinner.hide();
          this.initilizePageNavigation(this.dataList, this.rowsPerPage);
        } else if (res.status === 2) {
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      }
    );
  }

  public deletemethod(catId: string): void {
    // alert(catId);
    const data = {
      id: catId,
    };
    if (confirm('Are you sure, you want to delete it.')) {
      this.spinner.show();
      this.userManagementService.deleteRecord(data).subscribe(
        (res: any) => {
          // if the response status is 1 the user is sucessfully logged in else show error message
          console.log(res.data, 'res');

          // this.getCategory();
          if (res.status === 1) {
            this.getAllUsers();
            this.showError('success', 'Record deleted successfully.');
            // this.catList = res.data;
          } else if (res.status === 2) {
            this.spinner.hide();
          } else if (res.status === 0) {
            // window.scroll(0, 0);
            this.showError('error', res.message);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
          // this.spinner.hide();
        },
        // Log any error occured in logging in
        (error) => {
          this.spinner.hide();
          this.showError('error', error);
        }
      );

      // alert('yes');
    } else {
      // alert('no');
    }
  }
  public searchTextChange(event) {
    // if(typeof event==='object')
    // this.searchText = event;
    // const data = {
    //   searchtext: this.searchText
    // };
    // this.getUser(data);
    // console.log(event,'event');
    // const searchText = this.searchText.toUpperCase();
    // this.dataList =  this.masterList.filter(obj => (obj.username.toUpperCase().includes(searchText)
    // || obj.email.toUpperCase().includes(searchText) || obj.phone.includes(searchText)));
    
    if (this.searchText) {
      this.dataList = this.masterList.search(this.searchText);
    } else {
      const index = this.masterList.getIndex() as any
      this.dataList = index.docs.map(d => ({item: d}))
    }

  }

  public checkboxState() {
    if (this.selectedValues.length == this.dataList.length)
      this.selectAllList = ['selectall'];
    else this.selectAllList = [];
  }
  public selectAll() {
    this.selectedValues = [];
    if (this.selectAllList.length > 0)
      this.dataList.forEach((o) => {
        this.selectedValues.push(o.item.id.toString());
      });
  }
  public actionDropdown() {
    let value = this.selectedActionType.value;
    switch (value) {
      case 0:
        this.deleteUsersInBulk();
        break;
      case 1:
        this.markUserAdminInBulk();
        break;
    }
  }
  public deleteUsersInBulk() {
    this.spinner.show();
    let req = {
      data: this.selectedValues,
    };
    this.userManagementService.deleteUsersInBulk(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status == 1) {
          this.showError('success', 'Users deleted successfully');
          this.getAllUsers();
          this.selectedActionType = { title: 'Select Action', value: -1 };
          this.selectedValues = [];
        } else {
          this.showError('error', 'Oops somthing went wrong');
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong');
      }
    );
  }
  public markUserAdminInBulk() {
    this.spinner.show();
    let req = {
      data: this.selectedValues,
    };
    this.userManagementService.markUserAdminInBulk(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status == 1) {
          this.showError(
            'success',
            'Selected users marked as administrator successfully'
          );
          this.getAllUsers();
          this.selectedValues = [];
          this.selectedActionType = { title: 'Select Action', value: -1 };
        } else {
          this.showError('error', 'Oops somthing went wrong');
        }
      },
      (error: any) => {
        this.spinner.hide();
        this.showError('error', 'Oops somthing went wrong');
      }
    );
  }
  public getDateFormate(date): String {
    if (date == '' || date == undefined) return '';
    //return (new Date(date)).toLocaleDateString()
    return date.toLocaleString().split('T')[0];
  }
  public next() {
    this.first = this.dataList.length - 9;
  }

  public prev() {
    this.first = 0;
  }

  public reset() {
    this.first = 0;
  }

  public isLastPage(): boolean {
    return this.dataList
      ? this.first === this.dataList.length - this.rowsPerPage
      : true;
  }
  public isFirstPage(): boolean {
    return this.dataList ? this.first === 0 : true;
  }
  public pageNavigation() {
    this.first = this.selectedPage.page * this.rowsPerPage - this.rowsPerPage;
  }
  public initilizePageNavigation(list: any[], rows) {
    let count;
    if (list.length % rows == 0) {
      count = list.length / rows;
    } else {
      count = Math.floor(list.length / rows) + 1;
    }
    let arr = [];
    // this.pages = [
    //   { page: 1, title: '1' },
    //   { page: 2, title: '2' },
    //   { page: 3, title: '3' },
    //   { page: 4, title: '4' },
    //   { page: 5, title: '5' },
    //   { page: 6, title: '6' },
    // ]
    for (let i = 1; i <= count; i++) {
      arr.push({ page: i, title: `${i}` });
    }

    this.pages = arr;
  }
  public paginator(e) {
    console.log('called');
  }
}
