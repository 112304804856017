<aside class="sidebar" [ngClass]="{'open': toggleButtonMobile}">
    <nav class="sidebar-nav">
        <ul>
            <li class="toggle-btn-box show-mobile" (click)="expand('Togglebutton')"><a> <span class="nav-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 20 20" class="toggle-btn">
                            <path
                                d="M17.5 6h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                            </path>
                            <path
                                d="M17.5 11h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                            </path>
                            <path
                                d="M17.5 16h-15c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h15c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5z">
                            </path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 20 20"
                            class="toggle-btn btn-close">
                            <path
                                d="M10.707 10.5l5.646-5.646c0.195-0.195 0.195-0.512 0-0.707s-0.512-0.195-0.707 0l-5.646 5.646-5.646-5.646c-0.195-0.195-0.512-0.195-0.707 0s-0.195 0.512 0 0.707l5.646 5.646-5.646 5.646c-0.195 0.195-0.195 0.512 0 0.707 0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146l5.646-5.646 5.646 5.646c0.098 0.098 0.226 0.146 0.354 0.146s0.256-0.049 0.354-0.146c0.195-0.195 0.195-0.512 0-0.707l-5.646-5.646z">
                            </path>
                        </svg></span></a></li>
            <li><a [routerLink]="'/admin'" title="Dashboard"> <span class="nav-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.146 425.146" class="icon" width="23">
                            <g>
                                <g>
                                    <g>
                                        <path d="M212.573,296.841c-29.446-0.011-58.514,6.633-85.03,19.437c-24.203,11.63-45.866,27.934-63.74,47.973
                       c82.539,81.17,214.908,81.197,297.479,0.059C323.802,321.373,269.567,296.767,212.573,296.841z" />
                                        <circle cx="212.573" cy="212.573" r="13.874" />
                                        <path d="M212.573,0C95.36,0,0,95.36,0,212.573C-0.042,264.114,18.722,313.9,52.773,352.592
                       c19.113-21.158,42.161-38.393,67.859-50.744c85.82-41.236,188.584-20.491,251.688,50.807
                       c34.084-38.701,52.868-88.512,52.826-140.082C425.146,95.36,329.786,0,212.573,0z M204.573,43.894c0-4.418,3.582-8,8-8
                       s8,3.582,8,8v60.208c0,4.418-3.582,8-8,8s-8-3.582-8-8V43.894z M104.103,220.573H43.894c-4.418,0-8-3.582-8-8s3.582-8,8-8h60.209
                       c4.418,0,8,3.582,8,8S108.521,220.573,104.103,220.573z M141.773,141.773c-3.124,3.124-8.189,3.124-11.314,0c0,0,0,0,0,0
                       l-43.004-43c-3.125-3.125-3.125-8.193,0-11.318s8.193-3.125,11.318,0l43,43.006C144.896,133.585,144.896,138.649,141.773,141.773
                       z M227.301,238.573c-4.492,2.543-9.566,3.878-14.728,3.874c-16.499,0.015-29.886-13.349-29.9-29.848
                       c-0.015-16.499,13.349-29.886,29.848-29.9c5.165-0.005,10.243,1.33,14.739,3.874l99.114-99.118c3.125-3.125,8.193-3.125,11.318,0
                       c3.125,3.125,3.125,8.193,0,11.318l-99.118,99.114C246.696,212.235,241.649,230.451,227.301,238.573z M381.252,220.573h-60.209
                       c-4.418,0-8-3.582-8-8s3.582-8,8-8h60.209c4.418,0,8,3.582,8,8S385.67,220.573,381.252,220.573z" />
                                    </g>
                                </g>
                            </g>
                        </svg></span> <span class="nav-text">Dashboard</span></a></li>
            <li>
                <a [routerLink]="'/admin/category'" title="Category"><span class="nav-icon"><svg
                            viewBox="0 0 511.938 511.938" xmlns="http://www.w3.org/2000/svg" width="22">
                            <g>
                                <path d="m217.941 11.378h73.482v88.591h-73.482z" />
                                <path d="m217.941 134.067h73.482v45.448h-73.482z" />
                                <path
                                    d="m27.275 134.067v162.625c10.57 2.002 20.58 7.315 28.39 15.793l56.29 61.1c.144.157.298.303.445.458v-12.434c0-26.147 21.272-47.418 47.418-47.418h78.825c9.787 1.106 35.702-13.721 44.638-17.274 37.94-17.638 81.122-16.941 118.472 1.912l22.715 11.538c6.361-20.427 24.622-35.225 46.112-35.225h13.471v-141.075h-158.53v62.497c0 9.416-7.633 17.049-17.049 17.049h-107.58c-9.416 0-17.049-7.633-17.049-17.049v-62.497z" />
                                <path
                                    d="m325.521 99.969h158.529v-64.943c0-13.061-8.798-23.648-19.651-23.648h-138.878z" />
                                <path
                                    d="m470.578 309.24c-7.956 0-14.428 7.637-14.428 17.024v152.404c0 9.387 6.473 17.024 14.428 17.024h26.931c7.956 0 14.429-7.637 14.429-17.024v-152.404c0-9.387-6.473-17.024-14.429-17.024z" />
                                <path
                                    d="m183.844 11.378h-140.238c-9.019 0-16.331 8.799-16.331 19.653v68.938h156.569z" />
                                <path
                                    d="m30.587 335.588c-15.064-15.064-40.707 4.627-26.378 22.147l76.792 93.094c26.07 31.604 64.519 49.73 105.488 49.73h86.856c32.255 0 64.519-3.788 95.895-11.259l52.813-12.631v-129.284l-35.704-18.136c-27.936-14.101-60.279-14.623-88.693-1.412l-30.367 14.118c-8.912 4.143-18.818 6.333-28.646 6.333h-78.825c-7.345 0-13.321 5.975-13.321 13.321v25.827h153.113c9.416 0 17.049 7.633 17.049 17.049s-7.633 17.049-17.049 17.049h-156.062c-19.78.239-42.502-9.027-56.671-24.845z" />
                            </g>
                        </svg></span><span class="nav-text">Category</span></a> </li>

            <li> <a [routerLink]="'/admin/price-management'" title="Price Management"><span class="nav-icon"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" width="25">
                            <g>
                                <g>
                                    <path
                                        d="M271.029,0c-33.091,0-61,27.909-61,61s27.909,61,61,61s60-27.909,60-61S304.12,0,271.029,0z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M337.621,122c-16.485,18.279-40.096,30-66.592,30c-26.496,0-51.107-11.721-67.592-30
                        c-14.392,15.959-23.408,36.866-23.408,60v15c0,8.291,6.709,15,15,15h151c8.291,0,15-6.709,15-15v-15
                        C361.029,158.866,352.013,137.959,337.621,122z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M144.946,460.404L68.505,307.149c-7.381-14.799-25.345-20.834-40.162-13.493l-19.979,9.897
                        c-7.439,3.689-10.466,12.73-6.753,20.156l90,180c3.701,7.423,12.704,10.377,20.083,6.738l19.722-9.771
                        C146.291,493.308,152.354,475.259,144.946,460.404z" />
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M499.73,247.7c-12.301-9-29.401-7.2-39.6,3.9l-82,100.8c-5.7,6-16.5,9.6-22.2,9.6h-69.901c-8.401,0-15-6.599-15-15
                        s6.599-15,15-15c20.09,0,42.332,0,60,0c16.5,0,30-13.5,30-30s-13.5-30-30-30c-70.446,0-3.25,0-78.6,0
                        c-7.476,0-11.204-4.741-17.1-9.901c-23.209-20.885-57.949-30.947-93.119-22.795c-19.528,4.526-32.697,12.415-46.053,22.993
                        l-0.445-0.361L89.016,281.03L174.28,452h25.248h146.501c28.2,0,55.201-13.5,72.001-36l87.999-126
                        C515.929,276.799,513.229,257.601,499.73,247.7z" />
                                </g>
                            </g>
                        </svg>
                    </span><span class="nav-text">Price Management</span>
                </a>
            </li>



            <li> <a [routerLink]="'/admin/user-management'" title="Price Management"><span class="nav-icon"><svg
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users-cog" width="25"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor"
                                d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">User Management</span>
                </a>
            </li>

            <li> <a [routerLink]="'/admin/ad-management'" title="Classified Ads"><span class="nav-icon"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25">
                            <g>
                                <path d="M450.5,30H126.4c-1.7,0-3,1.3-3,3s1.3,3,3,3h324.2c14,0,25.5,11.4,25.5,25.5v106.4c0,14-11.4,25.5-25.5,25.5
                    H61.5c-14,0-25.5-11.4-25.5-25.5V61.5C36,47.4,47.4,36,61.5,36H99c1.7,0,3-1.3,3-3s-1.3-3-3-3H61.5C44.1,30,30,44.1,30,61.5v106.4
                    c0,17.3,14.1,31.5,31.5,31.5h389.1c17.3,0,31.5-14.1,31.5-31.5V61.5C482,44.1,467.9,30,450.5,30z" />
                                <path d="M96.3,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                    c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                    c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                    c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                    c-0.9-1.1-1.4-2.7-1.7-4.8l-13,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                    c1.6-2.4,2.3-5,2.3-7.8C98.1,114.9,97.5,112.7,96.3,110.8L96.3,110.8z" />
                                <path
                                    d="M133.6,87.6v26.9c0,2.4-0.7,4.3-2,5.7c-1.3,1.3-3.2,2-5.5,2c-2.4,0-4.2-0.7-5.5-2c-1.3-1.4-2-3.2-2-5.6V87.6
                    H105v26.2c0,2.2,0.4,4.6,1.3,7.4c0.5,1.7,1.5,3.4,2.9,5.1c1.4,1.6,3,2.9,4.7,3.8c1.7,0.9,3.8,1.5,6.4,1.8s4.9,0.5,7,0.5
                    c3.7,0,6.9-0.5,9.5-1.5c1.9-0.7,3.7-1.9,5.5-3.6c1.7-1.7,3-3.8,3.8-6.1s1.2-4.8,1.2-7.4V87.6L133.6,87.6z" />
                                <path d="M194.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                    c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                    c1.1-1.8,1.6-3.9,1.6-6.2C197,116.3,196.2,114,194.7,112.1L194.7,112.1z M170,96.5h6c2.1,0,3.5,0.4,4.3,1.1c0.8,0.7,1.2,1.7,1.2,3
                    c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H170L170,96.5L170,96.5z M181.9,120.9c-1,0.8-2.6,1.2-5,1.2H170v-8.9h6.9
                    c2.4,0,4.1,0.4,5,1.2c1,0.8,1.4,1.8,1.4,3.1C183.3,119,182.8,120.1,181.9,120.9L181.9,120.9z" />
                                <path d="M239.9,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                    c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                    c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                    c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                    c-0.9-1.1-1.4-2.7-1.7-4.8l-12.9,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                    c1.6-2.4,2.3-5,2.3-7.8C241.7,114.9,241.1,112.7,239.9,110.8L239.9,110.8z" />
                                <path
                                    d="M277.8,113.6c-0.6,2.8-1.6,4.9-2.9,6.4c-1.3,1.5-3.3,2.2-5.9,2.2c-2.7,0-4.8-0.9-6.3-2.7s-2.2-5.2-2.2-10
                    c0-3.9,0.6-6.8,1.9-8.7c1.6-2.5,4-3.7,7.1-3.7c1.4,0,2.6,0.3,3.7,0.8s2,1.4,2.8,2.4c0.5,0.6,0.9,1.6,1.3,2.9l12-2.7
                    c-1.5-4.6-3.9-8.1-7.1-10.3c-3.2-2.2-7.5-3.4-13.1-3.4c-7.1,0-12.6,2-16.5,5.9s-5.8,9.5-5.8,16.8c0,5.5,1.1,9.9,3.3,13.5
                    c2.2,3.5,4.8,6,7.9,7.3c3,1.4,6.9,2.1,11.7,2.1c3.9,0,7.2-0.6,9.8-1.7c2.6-1.1,4.7-2.8,6.4-5.1c1.7-2.2,3-5,3.8-8.4L277.8,113.6z" />
                                <path d="M333.3,118.9c-0.3-0.6-1-1.6-1.9-2.7c-1-1.2-1.7-2-2.2-2.3c-0.7-0.5-1.9-1.1-3.5-1.6c2-0.5,3.6-1,4.8-1.7
                    c1.8-1.1,3.3-2.5,4.3-4.3s1.6-3.9,1.6-6.3c0-2.8-0.7-5.2-2-7.1c-1.4-2-3.2-3.3-5.4-4s-5.4-1.1-9.6-1.1h-22.7v44.1h13.7v-17.9h1.2
                    c1.2,0,2.4,0.3,3.3,1c0.7,0.5,1.5,1.7,2.5,3.4l7.3,13.5h15.4L333.3,118.9z M321.8,103.5c-0.6,0.7-1.3,1.2-2.2,1.4
                    c-1.8,0.4-3,0.6-3.6,0.6h-5.7v-9h6c2.5,0,4.2,0.4,5.1,1.1c0.9,0.8,1.4,1.9,1.4,3.3C322.6,101.9,322.4,102.7,321.8,103.5
                    L321.8,103.5z" />
                                <path d="M344.8,87.6h13.6v44h-13.6V87.6z" />
                                <path d="M406.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                    c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                    c1.1-1.8,1.6-3.9,1.6-6.2C408.9,116.3,408.2,114,406.7,112.1z M382,96.5h6c2.1,0,3.5,0.4,4.3,1.1s1.2,1.7,1.2,3
                    c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H382L382,96.5L382,96.5z M393.9,120.9c-1,0.8-2.6,1.2-5,1.2H382v-8.9h6.9
                    c2.4,0,4,0.4,5,1.2s1.4,1.8,1.4,3.1C395.3,119,394.8,120.1,393.9,120.9z" />
                                <path d="M429.7,121.7V113h21.2v-9h-21.2v-7h22.8v-9.4H416v44.1h37.1v-10L429.7,121.7z" />
                            </g>
                            <path d="M96.1,254.4C94.1,277.5,70,281.2,70,281.2c20.7,1,25.8,28.1,25.8,28.1c0.4-19.4,26.6-28.4,26.6-28.4
                C102.9,279.7,96.1,254.4,96.1,254.4L96.1,254.4z" />
                            <path d="M122.7,277.9c-17.2-1-23.7-24-23.7-24.3c-0.4-1.4-1.7-2.3-3.2-2.2c-1.4,0.1-2.6,1.3-2.7,2.7
                c-1.7,20.4-22.6,23.9-23.5,24.1c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c18.1,0.9,23,25.4,23,25.7
                c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.4-17,24.4-25.6,24.6-25.6c1.3-0.5,2.2-1.8,2-3.2
                C125.3,279,124.1,278,122.7,277.9z M95.6,298.6c-2.7-6.3-7.4-13.8-15.2-17.8c5.9-2.8,12.8-7.8,16.4-16.5c3.1,6.1,8.4,13.8,16.7,17.4
                C107.5,285,99.6,290.5,95.6,298.6z" />
                            <path d="M52.7,288.3c-1.4,16.4-18.4,18.9-18.4,18.9c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
                C57.5,306.2,52.7,288.3,52.7,288.3z" />
                            <path d="M71.6,304.1c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
                c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
                c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
                S73,304.2,71.6,304.1L71.6,304.1z M52.4,317.7c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8c2.1,3.7,5.3,7.9,9.9,10.4
                C59.4,309.9,55.2,313.2,52.4,317.7L52.4,317.7z" />
                            <path d="M52.7,235C51.3,251.4,34.3,254,34.3,254c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
                C57.5,252.9,52.7,235,52.7,235z" />
                            <path d="M71.6,250.8c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
                c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
                c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
                C74.2,251.9,73,250.9,71.6,250.8L71.6,250.8z M52.4,264.4c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8
                c2.1,3.7,5.3,7.9,9.9,10.4C59.4,256.6,55.2,259.9,52.4,264.4z" />
                            <path
                                d="M349.3,245.3h-0.2c-10.2,0-18.5,8.3-18.5,18.5v-12.7c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10,0-18.2,7.9-18.5,17.9
                c0,0.2,0,10.8,0,12.3v-24.3c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10.2,0-18.5,8.3-18.5,18.5v21.1l0,0v-78.9c0-10.2-8.3-18.5-18.5-18.5
                h-0.2c-10.2,0-18.5,8.3-18.5,18.5v122.5c0,7.1-5.8,12.9-12.9,12.9h-42.1c-14.4,0-26.1,11.7-26.1,26.1c0,7.2,2.9,13.7,7.6,18.5
                c4.7,4.7,11.3,7.6,18.5,7.6h32.6c13.4,0,25.4,8.5,29.7,21.3l8.6,25.3c0.8-0.1,1.6-0.2,2.4-0.2H355c0.4,0,0.7,0,1,0l1.4-5.5
                c6.7-26.5,10.2-53.8,10.4-81.2c0-0.2,0-0.4,0-0.7v-63.7C367.8,253.5,359.6,245.3,349.3,245.3L349.3,245.3z" />
                            <path d="M349.3,242.3h-0.2c-6.1,0-11.7,2.6-15.6,6.7c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.2,0-11.7,2.6-15.6,6.7
                c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.1,0-11.6,2.6-15.5,6.6v-42.9c0-11.9-9.7-21.5-21.5-21.5h-0.2c-11.9,0-21.5,9.7-21.5,21.5
                v122.5c0,5.5-4.5,9.9-9.9,9.9h-42.1c-16.1,0-29.1,13.1-29.1,29.1c0,7.8,3,15.1,8.5,20.6c5.5,5.5,12.8,8.5,20.6,8.5h32.6
                c12.2,0,22.9,7.7,26.9,19.2l8.6,25.3c0.4,1.2,1.6,2,2.8,2c0.1,0,0.3,0,0.4,0c0.6-0.1,1.3-0.1,2-0.1H355l1,0c1.4,0,2.6-0.9,2.9-2.3
                l1.4-5.5c6.8-26.7,10.3-54.2,10.5-81.9l0-64.3C370.8,251.9,361.2,242.3,349.3,242.3L349.3,242.3z M364.8,326.4l0,1.7
                c-0.2,27.2-3.6,54.3-10.3,80.5l-0.8,3.2H236.9c-0.1,0-0.2,0-0.3,0l-7.9-23.2c-4.7-13.9-17.8-23.3-32.5-23.3h-32.6
                c-6.2,0-12-2.4-16.4-6.8c-4.4-4.4-6.8-10.2-6.8-16.3c0-12.7,10.4-23.1,23.1-23.1h42.1c8.8,0,15.9-7.1,15.9-15.9V180.6
                c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v78.9c0,0.8,0.3,1.6,0.9,2.1c0.9,0.9,2.1,1.1,3.3,0.6c1.1-0.5,1.9-1.6,1.9-2.8
                v-21.1c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v24.4c0,1.7,1.3,3,3,3s3-1.3,3-3v0c0-0.4,0-11.8,0-12.3
                c0.3-8.4,7.1-15,15.5-15h0.2c8.6,0,15.5,7,15.5,15.5v12.7c0,1.7,1.3,3,3,3s3-1.3,3-3c0-8.6,7-15.5,15.5-15.5h0.2
                c8.6,0,15.5,7,15.5,15.5L364.8,326.4L364.8,326.4z" />
                            <path d="M355,411.8H236.9c-12.4,0-22.5,10.1-22.5,22.5v25.7c0,12.2,9.9,22.1,22.1,22.1h26c1.7,0,3-1.3,3-3s-1.3-3-3-3
                h-26c-8.9,0-16.1-7.2-16.1-16.1v-25.7c0-9.1,7.4-16.5,16.5-16.5H355c9.1,0,16.5,7.4,16.5,16.5v25.7c0,8.9-7.2,16.1-16.1,16.1H290
                c-1.7,0-3,1.3-3,3s1.3,3,3,3h65.4c12.2,0,22.1-9.9,22.1-22.1v-25.7C377.5,421.8,367.4,411.8,355,411.8z" />
                            <ellipse transform="matrix(0.9871 -0.16 0.16 0.9871 -67.061 61.2394)" class="st3" cx="346.7"
                                cy="447" rx="11.8" ry="11.8" />
                            <path d="M346.8,432.2c-8.1,0-14.8,6.6-14.8,14.8c0,8.1,6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8
                C361.6,438.8,355,432.2,346.8,432.2z M346.8,455.8c-4.8,0-8.8-3.9-8.8-8.8c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8
                C355.6,451.8,351.7,455.8,346.8,455.8z" />
                        </svg>
                    </span><span class="nav-text">Ad Management</span></a></li>
            <li> <a [routerLink]="'/admin/cms-management'" title="Price Management"><span class="nav-icon"><svg
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users-cog" width="25"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor"
                                d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">Cms Management</span>
                </a>
            </li>


            <li> <a [routerLink]="'/admin/banner-ads'" title="Banner"><span class="nav-icon"><svg aria-hidden="true"
                            focusable="false" data-prefix="fas" data-icon="users-cog" width="25" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor"
                                d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">Banner</span>
                </a>
            </li>


            <li> <a [routerLink]="'/admin/faq'" title="Faq"><span class="nav-icon"><svg aria-hidden="true"
                            focusable="false" data-prefix="fas" data-icon="users-cog" width="25" role="img"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor"
                                d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">Faq</span>
                </a>
            </li>

            <li>
                <a [routerLink]="'/admin/contact-notification'" title="Contact from notification"><span
                        class="nav-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25">
                            <g>
                                <path d="M450.5,30H126.4c-1.7,0-3,1.3-3,3s1.3,3,3,3h324.2c14,0,25.5,11.4,25.5,25.5v106.4c0,14-11.4,25.5-25.5,25.5
                H61.5c-14,0-25.5-11.4-25.5-25.5V61.5C36,47.4,47.4,36,61.5,36H99c1.7,0,3-1.3,3-3s-1.3-3-3-3H61.5C44.1,30,30,44.1,30,61.5v106.4
                c0,17.3,14.1,31.5,31.5,31.5h389.1c17.3,0,31.5-14.1,31.5-31.5V61.5C482,44.1,467.9,30,450.5,30z" />
                                <path d="M96.3,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                c-0.9-1.1-1.4-2.7-1.7-4.8l-13,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                c1.6-2.4,2.3-5,2.3-7.8C98.1,114.9,97.5,112.7,96.3,110.8L96.3,110.8z" />
                                <path
                                    d="M133.6,87.6v26.9c0,2.4-0.7,4.3-2,5.7c-1.3,1.3-3.2,2-5.5,2c-2.4,0-4.2-0.7-5.5-2c-1.3-1.4-2-3.2-2-5.6V87.6
                H105v26.2c0,2.2,0.4,4.6,1.3,7.4c0.5,1.7,1.5,3.4,2.9,5.1c1.4,1.6,3,2.9,4.7,3.8c1.7,0.9,3.8,1.5,6.4,1.8s4.9,0.5,7,0.5
                c3.7,0,6.9-0.5,9.5-1.5c1.9-0.7,3.7-1.9,5.5-3.6c1.7-1.7,3-3.8,3.8-6.1s1.2-4.8,1.2-7.4V87.6L133.6,87.6z" />
                                <path d="M194.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                c1.1-1.8,1.6-3.9,1.6-6.2C197,116.3,196.2,114,194.7,112.1L194.7,112.1z M170,96.5h6c2.1,0,3.5,0.4,4.3,1.1c0.8,0.7,1.2,1.7,1.2,3
                c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H170L170,96.5L170,96.5z M181.9,120.9c-1,0.8-2.6,1.2-5,1.2H170v-8.9h6.9
                c2.4,0,4.1,0.4,5,1.2c1,0.8,1.4,1.8,1.4,3.1C183.3,119,182.8,120.1,181.9,120.9L181.9,120.9z" />
                                <path d="M239.9,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                c-0.9-1.1-1.4-2.7-1.7-4.8l-12.9,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                c1.6-2.4,2.3-5,2.3-7.8C241.7,114.9,241.1,112.7,239.9,110.8L239.9,110.8z" />
                                <path
                                    d="M277.8,113.6c-0.6,2.8-1.6,4.9-2.9,6.4c-1.3,1.5-3.3,2.2-5.9,2.2c-2.7,0-4.8-0.9-6.3-2.7s-2.2-5.2-2.2-10
                c0-3.9,0.6-6.8,1.9-8.7c1.6-2.5,4-3.7,7.1-3.7c1.4,0,2.6,0.3,3.7,0.8s2,1.4,2.8,2.4c0.5,0.6,0.9,1.6,1.3,2.9l12-2.7
                c-1.5-4.6-3.9-8.1-7.1-10.3c-3.2-2.2-7.5-3.4-13.1-3.4c-7.1,0-12.6,2-16.5,5.9s-5.8,9.5-5.8,16.8c0,5.5,1.1,9.9,3.3,13.5
                c2.2,3.5,4.8,6,7.9,7.3c3,1.4,6.9,2.1,11.7,2.1c3.9,0,7.2-0.6,9.8-1.7c2.6-1.1,4.7-2.8,6.4-5.1c1.7-2.2,3-5,3.8-8.4L277.8,113.6z" />
                                <path d="M333.3,118.9c-0.3-0.6-1-1.6-1.9-2.7c-1-1.2-1.7-2-2.2-2.3c-0.7-0.5-1.9-1.1-3.5-1.6c2-0.5,3.6-1,4.8-1.7
                c1.8-1.1,3.3-2.5,4.3-4.3s1.6-3.9,1.6-6.3c0-2.8-0.7-5.2-2-7.1c-1.4-2-3.2-3.3-5.4-4s-5.4-1.1-9.6-1.1h-22.7v44.1h13.7v-17.9h1.2
                c1.2,0,2.4,0.3,3.3,1c0.7,0.5,1.5,1.7,2.5,3.4l7.3,13.5h15.4L333.3,118.9z M321.8,103.5c-0.6,0.7-1.3,1.2-2.2,1.4
                c-1.8,0.4-3,0.6-3.6,0.6h-5.7v-9h6c2.5,0,4.2,0.4,5.1,1.1c0.9,0.8,1.4,1.9,1.4,3.3C322.6,101.9,322.4,102.7,321.8,103.5
                L321.8,103.5z" />
                                <path d="M344.8,87.6h13.6v44h-13.6V87.6z" />
                                <path d="M406.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                c1.1-1.8,1.6-3.9,1.6-6.2C408.9,116.3,408.2,114,406.7,112.1z M382,96.5h6c2.1,0,3.5,0.4,4.3,1.1s1.2,1.7,1.2,3
                c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H382L382,96.5L382,96.5z M393.9,120.9c-1,0.8-2.6,1.2-5,1.2H382v-8.9h6.9
                c2.4,0,4,0.4,5,1.2s1.4,1.8,1.4,3.1C395.3,119,394.8,120.1,393.9,120.9z" />
                                <path d="M429.7,121.7V113h21.2v-9h-21.2v-7h22.8v-9.4H416v44.1h37.1v-10L429.7,121.7z" />
                            </g>
                            <path d="M96.1,254.4C94.1,277.5,70,281.2,70,281.2c20.7,1,25.8,28.1,25.8,28.1c0.4-19.4,26.6-28.4,26.6-28.4
            C102.9,279.7,96.1,254.4,96.1,254.4L96.1,254.4z" />
                            <path d="M122.7,277.9c-17.2-1-23.7-24-23.7-24.3c-0.4-1.4-1.7-2.3-3.2-2.2c-1.4,0.1-2.6,1.3-2.7,2.7
            c-1.7,20.4-22.6,23.9-23.5,24.1c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c18.1,0.9,23,25.4,23,25.7
            c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.4-17,24.4-25.6,24.6-25.6c1.3-0.5,2.2-1.8,2-3.2
            C125.3,279,124.1,278,122.7,277.9z M95.6,298.6c-2.7-6.3-7.4-13.8-15.2-17.8c5.9-2.8,12.8-7.8,16.4-16.5c3.1,6.1,8.4,13.8,16.7,17.4
            C107.5,285,99.6,290.5,95.6,298.6z" />
                            <path d="M52.7,288.3c-1.4,16.4-18.4,18.9-18.4,18.9c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
            C57.5,306.2,52.7,288.3,52.7,288.3z" />
                            <path d="M71.6,304.1c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
            c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
            c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
            S73,304.2,71.6,304.1L71.6,304.1z M52.4,317.7c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8c2.1,3.7,5.3,7.9,9.9,10.4
            C59.4,309.9,55.2,313.2,52.4,317.7L52.4,317.7z" />
                            <path d="M52.7,235C51.3,251.4,34.3,254,34.3,254c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
            C57.5,252.9,52.7,235,52.7,235z" />
                            <path d="M71.6,250.8c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
            c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
            c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
            C74.2,251.9,73,250.9,71.6,250.8L71.6,250.8z M52.4,264.4c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8
            c2.1,3.7,5.3,7.9,9.9,10.4C59.4,256.6,55.2,259.9,52.4,264.4z" />
                            <path d="M349.3,245.3h-0.2c-10.2,0-18.5,8.3-18.5,18.5v-12.7c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10,0-18.2,7.9-18.5,17.9
            c0,0.2,0,10.8,0,12.3v-24.3c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10.2,0-18.5,8.3-18.5,18.5v21.1l0,0v-78.9c0-10.2-8.3-18.5-18.5-18.5
            h-0.2c-10.2,0-18.5,8.3-18.5,18.5v122.5c0,7.1-5.8,12.9-12.9,12.9h-42.1c-14.4,0-26.1,11.7-26.1,26.1c0,7.2,2.9,13.7,7.6,18.5
            c4.7,4.7,11.3,7.6,18.5,7.6h32.6c13.4,0,25.4,8.5,29.7,21.3l8.6,25.3c0.8-0.1,1.6-0.2,2.4-0.2H355c0.4,0,0.7,0,1,0l1.4-5.5
            c6.7-26.5,10.2-53.8,10.4-81.2c0-0.2,0-0.4,0-0.7v-63.7C367.8,253.5,359.6,245.3,349.3,245.3L349.3,245.3z" />
                            <path d="M349.3,242.3h-0.2c-6.1,0-11.7,2.6-15.6,6.7c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.2,0-11.7,2.6-15.6,6.7
            c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.1,0-11.6,2.6-15.5,6.6v-42.9c0-11.9-9.7-21.5-21.5-21.5h-0.2c-11.9,0-21.5,9.7-21.5,21.5
            v122.5c0,5.5-4.5,9.9-9.9,9.9h-42.1c-16.1,0-29.1,13.1-29.1,29.1c0,7.8,3,15.1,8.5,20.6c5.5,5.5,12.8,8.5,20.6,8.5h32.6
            c12.2,0,22.9,7.7,26.9,19.2l8.6,25.3c0.4,1.2,1.6,2,2.8,2c0.1,0,0.3,0,0.4,0c0.6-0.1,1.3-0.1,2-0.1H355l1,0c1.4,0,2.6-0.9,2.9-2.3
            l1.4-5.5c6.8-26.7,10.3-54.2,10.5-81.9l0-64.3C370.8,251.9,361.2,242.3,349.3,242.3L349.3,242.3z M364.8,326.4l0,1.7
            c-0.2,27.2-3.6,54.3-10.3,80.5l-0.8,3.2H236.9c-0.1,0-0.2,0-0.3,0l-7.9-23.2c-4.7-13.9-17.8-23.3-32.5-23.3h-32.6
            c-6.2,0-12-2.4-16.4-6.8c-4.4-4.4-6.8-10.2-6.8-16.3c0-12.7,10.4-23.1,23.1-23.1h42.1c8.8,0,15.9-7.1,15.9-15.9V180.6
            c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v78.9c0,0.8,0.3,1.6,0.9,2.1c0.9,0.9,2.1,1.1,3.3,0.6c1.1-0.5,1.9-1.6,1.9-2.8
            v-21.1c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v24.4c0,1.7,1.3,3,3,3s3-1.3,3-3v0c0-0.4,0-11.8,0-12.3
            c0.3-8.4,7.1-15,15.5-15h0.2c8.6,0,15.5,7,15.5,15.5v12.7c0,1.7,1.3,3,3,3s3-1.3,3-3c0-8.6,7-15.5,15.5-15.5h0.2
            c8.6,0,15.5,7,15.5,15.5L364.8,326.4L364.8,326.4z" />
                            <path d="M355,411.8H236.9c-12.4,0-22.5,10.1-22.5,22.5v25.7c0,12.2,9.9,22.1,22.1,22.1h26c1.7,0,3-1.3,3-3s-1.3-3-3-3
            h-26c-8.9,0-16.1-7.2-16.1-16.1v-25.7c0-9.1,7.4-16.5,16.5-16.5H355c9.1,0,16.5,7.4,16.5,16.5v25.7c0,8.9-7.2,16.1-16.1,16.1H290
            c-1.7,0-3,1.3-3,3s1.3,3,3,3h65.4c12.2,0,22.1-9.9,22.1-22.1v-25.7C377.5,421.8,367.4,411.8,355,411.8z" />
                            <ellipse transform="matrix(0.9871 -0.16 0.16 0.9871 -67.061 61.2394)" class="st3" cx="346.7"
                                cy="447" rx="11.8" ry="11.8" />
                            <path d="M346.8,432.2c-8.1,0-14.8,6.6-14.8,14.8c0,8.1,6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8
            C361.6,438.8,355,432.2,346.8,432.2z M346.8,455.8c-4.8,0-8.8-3.9-8.8-8.8c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8
            C355.6,451.8,351.7,455.8,346.8,455.8z" />
                        </svg>
                    </span><span class="nav-text">Contacts</span></a></li>


            <li>
                <a [routerLink]="'/admin/contact-monitoring'" title="Contact monitoring"><span class="nav-icon"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25">
                            <g>
                                <path
                                    d="M450.5,30H126.4c-1.7,0-3,1.3-3,3s1.3,3,3,3h324.2c14,0,25.5,11.4,25.5,25.5v106.4c0,14-11.4,25.5-25.5,25.5
                        H61.5c-14,0-25.5-11.4-25.5-25.5V61.5C36,47.4,47.4,36,61.5,36H99c1.7,0,3-1.3,3-3s-1.3-3-3-3H61.5C44.1,30,30,44.1,30,61.5v106.4
                        c0,17.3,14.1,31.5,31.5,31.5h389.1c17.3,0,31.5-14.1,31.5-31.5V61.5C482,44.1,467.9,30,450.5,30z" />
                                <path d="M96.3,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                        c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                        c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                        c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                        c-0.9-1.1-1.4-2.7-1.7-4.8l-13,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                        c1.6-2.4,2.3-5,2.3-7.8C98.1,114.9,97.5,112.7,96.3,110.8L96.3,110.8z" />
                                <path
                                    d="M133.6,87.6v26.9c0,2.4-0.7,4.3-2,5.7c-1.3,1.3-3.2,2-5.5,2c-2.4,0-4.2-0.7-5.5-2c-1.3-1.4-2-3.2-2-5.6V87.6
                        H105v26.2c0,2.2,0.4,4.6,1.3,7.4c0.5,1.7,1.5,3.4,2.9,5.1c1.4,1.6,3,2.9,4.7,3.8c1.7,0.9,3.8,1.5,6.4,1.8s4.9,0.5,7,0.5
                        c3.7,0,6.9-0.5,9.5-1.5c1.9-0.7,3.7-1.9,5.5-3.6c1.7-1.7,3-3.8,3.8-6.1s1.2-4.8,1.2-7.4V87.6L133.6,87.6z" />
                                <path d="M194.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                        c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                        c1.1-1.8,1.6-3.9,1.6-6.2C197,116.3,196.2,114,194.7,112.1L194.7,112.1z M170,96.5h6c2.1,0,3.5,0.4,4.3,1.1c0.8,0.7,1.2,1.7,1.2,3
                        c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H170L170,96.5L170,96.5z M181.9,120.9c-1,0.8-2.6,1.2-5,1.2H170v-8.9h6.9
                        c2.4,0,4.1,0.4,5,1.2c1,0.8,1.4,1.8,1.4,3.1C183.3,119,182.8,120.1,181.9,120.9L181.9,120.9z" />
                                <path d="M239.9,110.8c-1.2-1.9-3-3.6-5.6-4.9s-6.8-2.6-12.8-3.9c-2.4-0.5-3.9-1-4.6-1.6c-0.7-0.6-1-1.2-1-1.9
                        c0-1,0.4-1.8,1.2-2.4c0.8-0.7,2-1,3.6-1c1.9,0,3.4,0.5,4.5,1.4c1.1,0.9,1.8,2.3,2.1,4.3l12.8-0.8c-0.6-4.6-2.3-7.9-5.3-10
                        c-3-2.1-7.2-3.1-12.9-3.1c-4.6,0-8.2,0.6-10.8,1.7s-4.6,2.7-5.9,4.7s-2,4.2-2,6.4c0,3.4,1.3,6.3,3.8,8.5c2.5,2.2,6.8,4,12.8,5.3
                        c3.6,0.8,6,1.7,7,2.6c1,0.9,1.5,1.9,1.5,3.1c0,1.2-0.5,2.3-1.6,3.2s-2.6,1.4-4.5,1.4c-2.6,0-4.6-0.9-6-2.7
                        c-0.9-1.1-1.4-2.7-1.7-4.8l-12.9,0.8c0.4,4.4,2,8.1,4.9,11c2.9,2.9,8.1,4.3,15.6,4.3c4.3,0,7.8-0.6,10.6-1.9c2.8-1.2,5-3,6.6-5.4
                        c1.6-2.4,2.3-5,2.3-7.8C241.7,114.9,241.1,112.7,239.9,110.8L239.9,110.8z" />
                                <path
                                    d="M277.8,113.6c-0.6,2.8-1.6,4.9-2.9,6.4c-1.3,1.5-3.3,2.2-5.9,2.2c-2.7,0-4.8-0.9-6.3-2.7s-2.2-5.2-2.2-10
                        c0-3.9,0.6-6.8,1.9-8.7c1.6-2.5,4-3.7,7.1-3.7c1.4,0,2.6,0.3,3.7,0.8s2,1.4,2.8,2.4c0.5,0.6,0.9,1.6,1.3,2.9l12-2.7
                        c-1.5-4.6-3.9-8.1-7.1-10.3c-3.2-2.2-7.5-3.4-13.1-3.4c-7.1,0-12.6,2-16.5,5.9s-5.8,9.5-5.8,16.8c0,5.5,1.1,9.9,3.3,13.5
                        c2.2,3.5,4.8,6,7.9,7.3c3,1.4,6.9,2.1,11.7,2.1c3.9,0,7.2-0.6,9.8-1.7c2.6-1.1,4.7-2.8,6.4-5.1c1.7-2.2,3-5,3.8-8.4L277.8,113.6z" />
                                <path d="M333.3,118.9c-0.3-0.6-1-1.6-1.9-2.7c-1-1.2-1.7-2-2.2-2.3c-0.7-0.5-1.9-1.1-3.5-1.6c2-0.5,3.6-1,4.8-1.7
                        c1.8-1.1,3.3-2.5,4.3-4.3s1.6-3.9,1.6-6.3c0-2.8-0.7-5.2-2-7.1c-1.4-2-3.2-3.3-5.4-4s-5.4-1.1-9.6-1.1h-22.7v44.1h13.7v-17.9h1.2
                        c1.2,0,2.4,0.3,3.3,1c0.7,0.5,1.5,1.7,2.5,3.4l7.3,13.5h15.4L333.3,118.9z M321.8,103.5c-0.6,0.7-1.3,1.2-2.2,1.4
                        c-1.8,0.4-3,0.6-3.6,0.6h-5.7v-9h6c2.5,0,4.2,0.4,5.1,1.1c0.9,0.8,1.4,1.9,1.4,3.3C322.6,101.9,322.4,102.7,321.8,103.5
                        L321.8,103.5z" />
                                <path d="M344.8,87.6h13.6v44h-13.6V87.6z" />
                                <path d="M406.7,112.1c-1.5-1.9-3.8-3.2-7-3.9c2.1-0.7,3.7-1.7,4.7-2.9c1.6-1.9,2.4-4.1,2.4-6.7c0-3.1-1.1-5.7-3.4-7.8
                        c-2.3-2.1-5.5-3.2-9.8-3.2h-25.5v44.1h23.5c1,0,3-0.2,6.1-0.6c2.3-0.3,4-0.8,5.2-1.4c1.8-1.1,3.3-2.5,4.4-4.3
                        c1.1-1.8,1.6-3.9,1.6-6.2C408.9,116.3,408.2,114,406.7,112.1z M382,96.5h6c2.1,0,3.5,0.4,4.3,1.1s1.2,1.7,1.2,3
                        c0,1.4-0.4,2.4-1.2,3.2c-0.8,0.7-2.3,1.1-4.4,1.1H382L382,96.5L382,96.5z M393.9,120.9c-1,0.8-2.6,1.2-5,1.2H382v-8.9h6.9
                        c2.4,0,4,0.4,5,1.2s1.4,1.8,1.4,3.1C395.3,119,394.8,120.1,393.9,120.9z" />
                                <path d="M429.7,121.7V113h21.2v-9h-21.2v-7h22.8v-9.4H416v44.1h37.1v-10L429.7,121.7z" />
                            </g>
                            <path d="M96.1,254.4C94.1,277.5,70,281.2,70,281.2c20.7,1,25.8,28.1,25.8,28.1c0.4-19.4,26.6-28.4,26.6-28.4
                    C102.9,279.7,96.1,254.4,96.1,254.4L96.1,254.4z" />
                            <path d="M122.7,277.9c-17.2-1-23.7-24-23.7-24.3c-0.4-1.4-1.7-2.3-3.2-2.2c-1.4,0.1-2.6,1.3-2.7,2.7
                    c-1.7,20.4-22.6,23.9-23.5,24.1c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c18.1,0.9,23,25.4,23,25.7
                    c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.4-17,24.4-25.6,24.6-25.6c1.3-0.5,2.2-1.8,2-3.2
                    C125.3,279,124.1,278,122.7,277.9z M95.6,298.6c-2.7-6.3-7.4-13.8-15.2-17.8c5.9-2.8,12.8-7.8,16.4-16.5c3.1,6.1,8.4,13.8,16.7,17.4
                    C107.5,285,99.6,290.5,95.6,298.6z" />
                            <path d="M52.7,288.3c-1.4,16.4-18.4,18.9-18.4,18.9c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
                    C57.5,306.2,52.7,288.3,52.7,288.3z" />
                            <path d="M71.6,304.1c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
                    c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
                    c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
                    S73,304.2,71.6,304.1L71.6,304.1z M52.4,317.7c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8c2.1,3.7,5.3,7.9,9.9,10.4
                    C59.4,309.9,55.2,313.2,52.4,317.7L52.4,317.7z" />
                            <path d="M52.7,235C51.3,251.4,34.3,254,34.3,254c14.6,0.7,18.3,19.9,18.3,19.9c0.3-13.7,18.8-20.1,18.8-20.1
                    C57.5,252.9,52.7,235,52.7,235z" />
                            <path d="M71.6,250.8c-11.5-0.7-15.9-16.4-16-16.5c-0.4-1.4-1.7-2.3-3.2-2.2c-1.5,0.1-2.6,1.3-2.7,2.7
                    c-1.2,13.7-15.3,16.1-15.9,16.2c-1.5,0.2-2.6,1.6-2.5,3.1c0.1,1.5,1.3,2.8,2.9,2.8c12.1,0.6,15.4,17.3,15.5,17.5
                    c0.3,1.4,1.5,2.4,2.9,2.4c0.1,0,0.2,0,0.3,0c1.5-0.1,2.7-1.4,2.8-2.9c0.3-11.4,16.7-17.3,16.8-17.3c1.4-0.5,2.2-1.8,2-3.2
                    C74.2,251.9,73,250.9,71.6,250.8L71.6,250.8z M52.4,264.4c-1.8-3.8-4.7-7.9-8.9-10.6c3.7-2.1,7.4-5.3,9.7-9.8
                    c2.1,3.7,5.3,7.9,9.9,10.4C59.4,256.6,55.2,259.9,52.4,264.4z" />
                            <path
                                d="M349.3,245.3h-0.2c-10.2,0-18.5,8.3-18.5,18.5v-12.7c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10,0-18.2,7.9-18.5,17.9
                    c0,0.2,0,10.8,0,12.3v-24.3c0-10.2-8.3-18.5-18.5-18.5h-0.2c-10.2,0-18.5,8.3-18.5,18.5v21.1l0,0v-78.9c0-10.2-8.3-18.5-18.5-18.5
                    h-0.2c-10.2,0-18.5,8.3-18.5,18.5v122.5c0,7.1-5.8,12.9-12.9,12.9h-42.1c-14.4,0-26.1,11.7-26.1,26.1c0,7.2,2.9,13.7,7.6,18.5
                    c4.7,4.7,11.3,7.6,18.5,7.6h32.6c13.4,0,25.4,8.5,29.7,21.3l8.6,25.3c0.8-0.1,1.6-0.2,2.4-0.2H355c0.4,0,0.7,0,1,0l1.4-5.5
                    c6.7-26.5,10.2-53.8,10.4-81.2c0-0.2,0-0.4,0-0.7v-63.7C367.8,253.5,359.6,245.3,349.3,245.3L349.3,245.3z" />
                            <path d="M349.3,242.3h-0.2c-6.1,0-11.7,2.6-15.6,6.7c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.2,0-11.7,2.6-15.6,6.7
                    c-1.1-10.9-10.3-19.4-21.4-19.4h-0.2c-6.1,0-11.6,2.6-15.5,6.6v-42.9c0-11.9-9.7-21.5-21.5-21.5h-0.2c-11.9,0-21.5,9.7-21.5,21.5
                    v122.5c0,5.5-4.5,9.9-9.9,9.9h-42.1c-16.1,0-29.1,13.1-29.1,29.1c0,7.8,3,15.1,8.5,20.6c5.5,5.5,12.8,8.5,20.6,8.5h32.6
                    c12.2,0,22.9,7.7,26.9,19.2l8.6,25.3c0.4,1.2,1.6,2,2.8,2c0.1,0,0.3,0,0.4,0c0.6-0.1,1.3-0.1,2-0.1H355l1,0c1.4,0,2.6-0.9,2.9-2.3
                    l1.4-5.5c6.8-26.7,10.3-54.2,10.5-81.9l0-64.3C370.8,251.9,361.2,242.3,349.3,242.3L349.3,242.3z M364.8,326.4l0,1.7
                    c-0.2,27.2-3.6,54.3-10.3,80.5l-0.8,3.2H236.9c-0.1,0-0.2,0-0.3,0l-7.9-23.2c-4.7-13.9-17.8-23.3-32.5-23.3h-32.6
                    c-6.2,0-12-2.4-16.4-6.8c-4.4-4.4-6.8-10.2-6.8-16.3c0-12.7,10.4-23.1,23.1-23.1h42.1c8.8,0,15.9-7.1,15.9-15.9V180.6
                    c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v78.9c0,0.8,0.3,1.6,0.9,2.1c0.9,0.9,2.1,1.1,3.3,0.6c1.1-0.5,1.9-1.6,1.9-2.8
                    v-21.1c0-8.6,7-15.5,15.5-15.5h0.2c8.6,0,15.5,7,15.5,15.5v24.4c0,1.7,1.3,3,3,3s3-1.3,3-3v0c0-0.4,0-11.8,0-12.3
                    c0.3-8.4,7.1-15,15.5-15h0.2c8.6,0,15.5,7,15.5,15.5v12.7c0,1.7,1.3,3,3,3s3-1.3,3-3c0-8.6,7-15.5,15.5-15.5h0.2
                    c8.6,0,15.5,7,15.5,15.5L364.8,326.4L364.8,326.4z" />
                            <path
                                d="M355,411.8H236.9c-12.4,0-22.5,10.1-22.5,22.5v25.7c0,12.2,9.9,22.1,22.1,22.1h26c1.7,0,3-1.3,3-3s-1.3-3-3-3
                    h-26c-8.9,0-16.1-7.2-16.1-16.1v-25.7c0-9.1,7.4-16.5,16.5-16.5H355c9.1,0,16.5,7.4,16.5,16.5v25.7c0,8.9-7.2,16.1-16.1,16.1H290
                    c-1.7,0-3,1.3-3,3s1.3,3,3,3h65.4c12.2,0,22.1-9.9,22.1-22.1v-25.7C377.5,421.8,367.4,411.8,355,411.8z" />
                            <ellipse transform="matrix(0.9871 -0.16 0.16 0.9871 -67.061 61.2394)" class="st3" cx="346.7"
                                cy="447" rx="11.8" ry="11.8" />
                            <path d="M346.8,432.2c-8.1,0-14.8,6.6-14.8,14.8c0,8.1,6.6,14.8,14.8,14.8s14.8-6.6,14.8-14.8
                    C361.6,438.8,355,432.2,346.8,432.2z M346.8,455.8c-4.8,0-8.8-3.9-8.8-8.8c0-4.8,3.9-8.8,8.8-8.8s8.8,3.9,8.8,8.8
                    C355.6,451.8,351.7,455.8,346.8,455.8z" />
                        </svg>
                    </span><span class="nav-text">Contact monitoring</span></a></li>


            <li> <a [routerLink]="'/admin/service-product'" title="Service Product"><span class="nav-icon"><svg
                            aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users-cog" width="25"
                            role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor"
                                d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">Service Product</span>
                </a>
            </li>


            <!-- <li> <a [routerLink]="'/admin/service-product'" title="Service Product"><span class="nav-icon"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="users-cog" width="25" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                            <path fill="currentColor" d="M610.5 341.3c2.6-14.1 2.6-28.5 0-42.6l25.8-14.9c3-1.7 4.3-5.2 3.3-8.5-6.7-21.6-18.2-41.2-33.2-57.4-2.3-2.5-6-3.1-9-1.4l-25.8 14.9c-10.9-9.3-23.4-16.5-36.9-21.3v-29.8c0-3.4-2.4-6.4-5.7-7.1-22.3-5-45-4.8-66.2 0-3.3.7-5.7 3.7-5.7 7.1v29.8c-13.5 4.8-26 12-36.9 21.3l-25.8-14.9c-2.9-1.7-6.7-1.1-9 1.4-15 16.2-26.5 35.8-33.2 57.4-1 3.3.4 6.8 3.3 8.5l25.8 14.9c-2.6 14.1-2.6 28.5 0 42.6l-25.8 14.9c-3 1.7-4.3 5.2-3.3 8.5 6.7 21.6 18.2 41.1 33.2 57.4 2.3 2.5 6 3.1 9 1.4l25.8-14.9c10.9 9.3 23.4 16.5 36.9 21.3v29.8c0 3.4 2.4 6.4 5.7 7.1 22.3 5 45 4.8 66.2 0 3.3-.7 5.7-3.7 5.7-7.1v-29.8c13.5-4.8 26-12 36.9-21.3l25.8 14.9c2.9 1.7 6.7 1.1 9-1.4 15-16.2 26.5-35.8 33.2-57.4 1-3.3-.4-6.8-3.3-8.5l-25.8-14.9zM496 368.5c-26.8 0-48.5-21.8-48.5-48.5s21.8-48.5 48.5-48.5 48.5 21.8 48.5 48.5-21.7 48.5-48.5 48.5zM96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm224 32c1.9 0 3.7-.5 5.6-.6 8.3-21.7 20.5-42.1 36.3-59.2 7.4-8 17.9-12.6 28.9-12.6 6.9 0 13.7 1.8 19.6 5.3l7.9 4.6c.8-.5 1.6-.9 2.4-1.4 7-14.6 11.2-30.8 11.2-48 0-61.9-50.1-112-112-112S208 82.1 208 144c0 61.9 50.1 112 112 112zm105.2 194.5c-2.3-1.2-4.6-2.6-6.8-3.9-8.2 4.8-15.3 9.8-27.5 9.8-10.9 0-21.4-4.6-28.9-12.6-18.3-19.8-32.3-43.9-40.2-69.6-10.7-34.5 24.9-49.7 25.8-50.3-.1-2.6-.1-5.2 0-7.8l-7.9-4.6c-3.8-2.2-7-5-9.8-8.1-3.3.2-6.5.6-9.8.6-24.6 0-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h255.4c-3.7-6-6.2-12.8-6.2-20.3v-9.2zM173.1 274.6C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z">
                            </path>
                        </svg>
                    </span><span class="nav-text">Service Providers</span>
                </a>
            </li> -->

            <li>
                <a [routerLink]="'/admin/my-profile'" title="Contact monitoring"><span class="nav-icon">
                        <i class="pi pi-user"></i>
                    </span><span class="nav-text">
                        Change Password</span></a></li>
            <li>
                <a [routerLink]="'/admin/discount'" title="Discount"><span class="nav-icon">
                        <i class="pi pi-user"></i>
                    </span><span class="nav-text">
                        Discount Codes</span></a></li>

            <li class="show-mobile"><a href="#" title="Logout"><span class="nav-icon"><svg
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297" width="25">
                            <g>
                                <g>
                                    <g>
                                        <path d="M142,290.5c30.1,0,58.9-9.3,83.3-27c2.8-2,3.5-5.9,1.7-8.8l-22.9-37.1c-1-1.6-2.5-2.6-4.3-3c-1.8-0.4-3.7,0.1-5.1,1.1
                       c-14.2,10.3-31.1,15.8-48.7,15.8c-45.8,0-83-37.2-83-83s37.2-83,83-83c17.6,0,34.5,5.5,48.7,15.8c1.5,1.1,3.3,1.5,5.1,1.1
                       c1.8-0.4,3.4-1.4,4.3-3L227,42.3c1.8-2.9,1.1-6.8-1.7-8.8c-24.3-17.7-53.1-27-83.3-27C63.7,6.5,0,70.2,0,148.5
                       S63.7,290.5,142,290.5z" />
                                        <path d="M206.6,95.2c-1.1,2.1-1,4.7,0.3,6.8l15.5,23.2h-93.6c-12.8,0-23.3,10.5-23.3,23.3s10.5,23.3,23.3,23.3h93.6L206.9,195
                       c-1.3,2-1.5,4.6-0.3,6.8c1.1,2.1,3.4,3.5,5.8,3.5H257c2.2,0,4.3-1.1,5.5-2.9l33.4-50.1c1.5-2.2,1.5-5.1,0-7.3l-33.4-50.1
                       c-1.2-1.8-3.3-2.9-5.5-2.9h-44.6C210,91.8,207.7,93.1,206.6,95.2z" />
                                    </g>
                                </g>
                            </g>
                        </svg></span><span class="nav-text">Logout</span></a></li>

        </ul>
    </nav>
</aside>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#BA55D3" type="ball-triangle-path" [fullScreen]="true">
    <p style="color:#BA55D3"> Loading... </p>
</ngx-spinner>