<app-sidebar></app-sidebar>
<app-header></app-header>
<main class="main-content admin-page">
    <div class="alert-message">
        <p-messages [(value)]="msgs"></p-messages>
    </div>
    <div class="page-title">
        <h1 *ngIf="!userid">Ads Management</h1>
        <h1 *ngIf="userid">User Management</h1>

    </div>
    <div *ngIf="!userid" class="filter-box">
        <div class="title">Sort By</div>
        <div class="dropdown-box">
            <p-dropdown inputId="sort" (onChange)="changeSortBy($event)" [options]="sortlist" placeholder="Select"
                optionLabel="title"></p-dropdown>

        </div>
        <div class="title p-ml-5">Filter By</div>
        <div class="dropdown-box">
            <p-dropdown inputId="sort" (onChange)="changeFilterBy($event)" [options]="filterList" placeholder="Select"
                optionLabel="title"></p-dropdown>

        </div>
        <div class="search-box">
            <input type="search" [(ngModel)]="searchText" (ngModelChange)="searchTextChange($event)" pInputText
                placeholder="Keyword">
            <button pButton type="submit" label="Search" class="search-btn" (click)="searchTextChange()"></button>
        </div>
        <div class="add-category hide-mobile hide-ipad-portrait">
            <a class="btn btn-default btn-form"
                [routerLink]="'/admin/ad-management/add'+ (userid ? '/'+userid : '')">Add</a>
        </div>
    </div>
    <div class="bulk-action-parent">
        <!-- <div>
            <button pButton type="button" label="Publish All"  (click)="publishInBuilClick(1)" [disabled]="selectedValues.length==0"></button>
        </div>
        <div>
            <button pButton type="button" label="Unpublish All"  (click)="publishInBuilClick(0)" [disabled]="selectedValues.length==0"></button>
        </div>
        <div>
            <button pButton type="button" label="Feature All"  (click)="featureInBuldClick(1)" [disabled]="selectedValues.length==0"></button>
        </div>
        <div>
            <button pButton type="button" label="Unfeature All"  (click)="featureInBuldClick(0)" [disabled]="selectedValues.length==0"></button>
        </div>
        <div>
            <button pButton type="button" label="Delete All"  (click)="deleteInBulkClick()" [disabled]="selectedValues.length==0"></button>
        </div>    -->
        <div>
            <p-checkbox value="slectedall" title="Select All" [(ngModel)]="selectAllList" label="Select All"
                (click)="selectAll()"></p-checkbox>
        </div>
        <div class="dropdown-box">
            <p-dropdown [(ngModel)]="selectedActionType" inputId="actionId" [options]="actionlist" placeholder="Select"
                optionLabel="title"></p-dropdown>
        </div>
        <div>
            <button pButton type="button" label={{this.selectedActionType.title}} (click)="actionDropdown()"
                [disabled]="(selectedValues.length==0 || selectedActionType.value == -1)"></button>
        </div>
    </div>
    <div class="responsive-table card">
        <p-table class="table user-list-table" [value]="dataList" [paginator]="true" [rows]="rowsPerPage" [(first)]="first"  (ngModelChange)="paginator($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th class="name">Select</th>
                    <th class="name">Ad Title</th>
                    <th class="description">Ad Owner</th>
                    <th *ngIf="!userid" class="description">Category</th>
                    <th *ngIf="!userid" class="description">Sub Category</th>
                    <th *ngIf="userid" class="description">Date</th>
                    <th *ngIf="userid" class="description">Amount</th>
                    <th class="description">Published</th>
                    <th class="description">Featured</th>
                    <th class="description">Sold</th>
                    <th class="description">Views</th>
                    <th class="description">Edited Date</th>
                    <th class="description">Expiry Date</th>

                    <th class="action">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dataList>
                <tr>
                    <td class="name" [attr.data-id]="('id')">
                        <!-- <div class="content">{{dataList.id}}</div> -->
                        <p-checkbox value={{dataList.id}} [(ngModel)]="selectedValues" (onChange)="checkboxState($)">
                        </p-checkbox>

                    </td>
                    <td class="name" [attr.data-title]="('title')">
                        <div class="content">{{dataList.title}}</div>
                    </td>
                    <td class="name" [attr.data-title]="('username')">
                        <div class="content">
                            <div>
                                {{(dataList.firstname?dataList.firstname:'') +" "+(dataList.lastname ? dataList.lastname : '') }}
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!userid" class="description" [attr.data-title]="('maincatname')">
                        <div [innerHTML]="dataList.maincatname" class="content"></div>
                    </td>
                    <td *ngIf="!userid" class="description" [attr.data-title]="('submaincatname')">
                        <div [innerHTML]="dataList.submaincatname" class="content"></div>
                    </td>

                    <td *ngIf="userid" class="description" [attr.data-title]="('dateCreated')">
                        <div class="content">

                            {{ dataList.dateCreated |date : "dd/MM/yyyy" }}
                        </div>
                    </td>

                    <td *ngIf="userid" class="description" [attr.data-title]="('paymentAmount')">
                        <div class="content">
                            <!-- {{dataList.paymentAmount}} -->
                            {{dataList.paymentAmount | currency:'GBP'}}
                        </div>
                    </td>

                    <td class="name" [attr.data-title]="('isPublished')">
                        <div class="content">
                            <span *ngIf="dataList.isPublished == 1" style="color:green; font-weight: bold;">Yes</span>
                            <span *ngIf="dataList.isPublished != 1" style="color:red; font-weight: bold;">No</span>
                        </div>
                    </td>

                    <td class="name" [attr.data-title]="('isFeatured')">
                        <div class="content">
                            <span *ngIf="dataList.isFeatured == 1" style="color:green; font-weight: bold;">Yes</span>
                            <span *ngIf="dataList.isFeatured != 1" style="color:red; font-weight: bold;">No</span>
                        </div>
                    </td>
                    <td class="name" [attr.data-title]="('isSold')">
                        <div class="content">
                            <span *ngIf="dataList.isSold == 1" style="color:green; font-weight: bold;">Yes</span>
                            <span *ngIf="dataList.isSold != 1" style="color:red; font-weight: bold;">No</span>
                        </div>
                    </td>
                    <td class="name" [attr.data-title]="('visitedTotal')">
                        <div class="content">
                            <div>{{dataList.visitedTotal}}</div>
                        </div>
                    </td>
                    <td class="name" [attr.data-title]="('dateUpdated')">
                        <div class="content">
                            <div>{{getDateFormate(dataList.dateUpdated)}}</div>
                        </div>
                    </td>
                    <td class="name" [attr.data-title]="('isFeatured')">
                        <div class="content">
                            <div>{{getDateFormate(dataList.expireDate)}}</div>
                        </div>
                    </td>

                    <td class="action" [attr.data-title]="('Action')">
                        <div class="content">



                            <a [routerLink]="'/admin/ad-management/edit/' + dataList.id + (userid ? '/'+userid : '')"
                                class="btn-link edit"><svg viewBox="-18 -18 577.33246 577" width="22"
                                    xmlns="http://www.w3.org/2000/svg" fill="#767676">
                                    <path
                                        d="m473.847656 3.714844c-2.347656-2.347656-5.539062-3.652344-8.859375-3.6171878-3.308593.0234378-6.476562 1.3164058-8.855469 3.6171878l-178.664062 178.65625-.496094.503906-.25.25c-.246094.375-.621094.75-.871094 1.121094 0 .125-.125.125-.125.25-.25.371094-.378906.625-.625.996094-.128906.125-.128906.253906-.25.375-.125.375-.246093.625-.375 1 0 .125-.125.125-.125.246093l-31.816406 95.945313c-1.523437 4.464844-.363281 9.414062 3 12.726562 2.355469 2.324219 5.539063 3.625 8.851563 3.617188 1.355469-.027344 2.703125-.234375 3.996093-.625l95.695313-31.9375c.121094 0 .121094 0 .246094-.125.394531-.113282.777343-.28125 1.121093-.496094.097657-.015625.1875-.058594.253907-.128906.371093-.246094.871093-.496094 1.246093-.75.371094-.25.746094-.625 1.121094-.871094.128906-.121094.25-.121094.25-.25.125-.125.375-.25.503906-.496094l178.777344-178.785156c2.339844-2.335938 3.652344-5.5 3.652344-8.796875 0-3.300781-1.3125-6.46875-3.652344-8.796875zm-182.152344 210.597656 35.308594 35.308594-52.902344 17.589844zm58.390626 23.082031-46.164063-46.160156 161.066406-161.070313 46.160157 46.160157zm0 0" />
                                    <path
                                        d="m444.402344 233.277344c-6.882813.019531-12.457032 5.59375-12.476563 12.476562v233.183594c-.058593 20.644531-16.777343 37.363281-37.429687 37.429688h-332.113282c-20.644531-.066407-37.371093-16.785157-37.429687-37.429688v-332.121094c.058594-20.644531 16.785156-37.367187 37.429687-37.429687h233.175782c6.894531 0 12.476562-5.585938 12.476562-12.476563s-5.582031-12.476562-12.476562-12.476562h-233.175782c-34.449218.015625-62.367187 27.9375-62.382812 62.382812v332.121094c.015625 34.445312 27.933594 62.367188 62.382812 62.378906h332.113282c34.449218-.011718 62.371094-27.933594 62.382812-62.378906v-233.183594c-.019531-6.882812-5.59375-12.457031-12.476562-12.476562zm0 0" />
                                </svg></a>

                            <a (click)="deletemethod(dataList.id)" class="btn-link delete"><svg
                                    viewBox="-40 0 427 427.00131" width="20" xmlns="http://www.w3.org/2000/svg"
                                    fill="#767676">
                                    <path
                                        d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                    <path
                                        d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                    <path
                                        d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0" />
                                    <path
                                        d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0" />
                                </svg></a>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <div class="p-mb-3 admin-paginator">
            <p-button class="p-m-2" type="button" icon="pi pi-chevron-left" (click)="prev()" [disabled]="isFirstPage()"
                styleClass="p-button-text"></p-button>
            <!-- <p-button  class="p-m-2" type="button" icon="pi pi-refresh" (click)="reset()" styleClass="p-button-text"></p-button> -->
            <p-dropdown [style]="{'min-width':'0','font-size':'15px'}" (onChange)="pageNavigation()" [options]="pages" [(ngModel)]="selectedPage" optionLabel="title">
            </p-dropdown>
            <p-button  class="p-m-2" type="button" icon="pi pi-chevron-right" (click)="next()" [disabled]="isLastPage()"
                styleClass="p-button-text"></p-button>
            <div>Showing {{first+1}}-{{(first+10)>dataList.length ? dataList.length:(first+10)}} of {{dataList.length}} rows</div>
        </div>
    </div>
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
</main>