<app-header-front></app-header-front>
<nav aria-label="breadcrumb" class="purpleBreadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom">
      <li class="breadcrumb-item"><a href="#">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">
        Seller/Buyer Dashboard
      </li>
    </ol>
  </div>
</nav>
<main class="main-content front login-front mb-0 pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-4 mb-md-0 col-md-4 col-lg-3">
        <div class="myaccount-links">
          <ul>
            <app-sidemenu> </app-sidemenu>
          </ul>
        </div>
      </div>

      <div class="col-12 col-md-8 col-lg-9">
        <div
          [ngClass]="{
            '': !showThankyouForm,
            'd-none': showThankyouForm
          }"
          class="pl-0 pl-lg-4"
        >
          <div class="section-title">
            <h1 class="mt-0">Post Ads</h1>
          </div>
          <div class="alert-message">
            <p-messages [(value)]="msgs"></p-messages>
          </div>

          <ul class="nav nav-tabs border-bottom-0 mb-5">
            <li
              [ngClass]="{
                active: !showPaymentForm,
                '': showPaymentForm
              }"
            >
              <a href="javascript:void(0);" class="nav-link"
                >Enter Ad Information</a
              >
            </li>
            <li
              class="payment"
              [ngClass]="{
                '': !showPaymentForm,
                active: showPaymentForm
              }"
            >
              <a href="javascript:void(0);" class="nav-link">Payment</a>
            </li>
          </ul>
          <div
            [ngClass]="{
              '': !showPaymentForm,
              'd-none': showPaymentForm
            }"
            class="ad-information"
          >
            <form
              [formGroup]="postAdForm"
              (ngSubmit)="onSubmit(1)"
              class="input-lg"
            >
              <div class="row">
                <div
                  *ngIf="!hideCategory"
                  class="required p-field col-12 col-lg-6 input-field mb-4"
                >
                  <label>Category</label>
                  <p-dropdown
                    formControlName="categoryId"
                    inputId="category"
                    placeholder="Select Category"
                    optionLabel="title"
                    (onChange)="changeParentList($event)"
                    [options]="parentCatList"
                  ></p-dropdown>
                  <div
                    *ngIf="
                      f.categoryId.errors &&
                      (f.categoryId.dirty || categoryError)
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.categoryId.errors.required"
                      class="error-message-form"
                    >
                      Please select Category.
                    </div>
                  </div>
                </div>
                <div class="p-field required col-12 col-lg-6 input-field mb-4">
                  <label>{{ SubcategoryName }}</label>
                  <p-dropdown
                    formControlName="subCategoryId"
                    (onChange)="changeSubCatList($event)"
                    inputId="subCategoryId"
                    placeholder="Select {{ SubcategoryName }}"
                    optionLabel="title"
                    [options]="subCatList"
                  ></p-dropdown>

                  <div
                    *ngIf="
                      f.subCategoryId.errors &&
                      (f.subCategoryId.dirty || subCategoryIdError)
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.subCategoryId.errors.required"
                      class="error-message-form"
                    >
                      Please select Category.
                    </div>
                  </div>
                </div>

                <div
                  *ngIf="bikeSelected"
                  class="p-field col-12 col-lg-6 input-field mb-4"
                >
                  <label>Capacity</label>
                  <p-dropdown
                    formControlName="subSubCategoryId"
                    inputId="capacity"
                    placeholder="Select Capacity"
                    optionLabel="title"
                    [options]="subSubCatList"
                  >
                  </p-dropdown>
                </div>

                <div
                  *ngIf="showManufacturer"
                  class="p-field col-12 col-lg-6 input-field mb-4"
                >
                  <label>Manufacturer</label>

                  <p-dropdown
                    (onChange)="changemanufacturer($event)"
                    formControlName="manufacturer"
                    placeholder="Select Manufacturer"
                    optionLabel="name"
                    [options]="manufacturerList"
                  ></p-dropdown>
                </div>

                <div
                  *ngIf="manufacturerOthers"
                  class="p-field col-12 col-lg-6 input-field mb-4"
                >
                  <label>Make</label>
                  <input
                    formControlName="manufacturertext"
                    id="manufacturertext"
                    type="text"
                    placeholder="Make"
                    pInputText
                  />
                </div>

                <div
                  *ngIf="bikeSelected"
                  class="p-field col-12 col-lg-6 required input-field mb-4"
                >
                  <label>Year</label>

                  <input
                    (keypress)="allowInteger($event)"
                    maxlength="4"
                    formControlName="age"
                    id="age"
                    type="text"
                    placeholder="Year"
                    pInputText
                  />

                  <!-- <div *ngIf="f.age.errors && (f.age.dirty)" class="invalid-feedback">

                                        <div *ngIf="(f.age.errors.age || f.age.invalid) && f.age.value" class="error-message-form">
                                            Please enter valid Year.</div>
                                    </div> -->

                  <div
                    *ngIf="f.age.errors && f.age.dirty"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.age.errors.required"
                      class="error-message-form"
                    >
                      Please enter year value.
                    </div>
                    <div
                      *ngIf="
                        (f.age.errors.price || f.age.invalid) && f.age.value
                      "
                      class="error-message-form"
                    >
                      Please enter valid year.
                    </div>
                  </div>
                </div>

                <!-- 
                                <div *ngIf="bikeSelected" class="p-field col-6 col-lg-3 input-field mb-2">
                                    <label>&nbsp;</label>

                                    <input maxlength="2" formControlName="month" id="month" type="text" placeholder="Month" pInputText>

                                    <div *ngIf="f.month.errors && (f.month.dirty)" class="invalid-feedback">

                                        <div *ngIf="(f.month.errors.month || f.month.invalid) && f.month.value" class="error-message-form">
                                            Please enter valid month.</div>
                                    </div>


                                </div> -->

                <div class="p-field col-12 col-lg-6 input-field required mb-4">
                  <label>Ad Title</label>
                  <input
                    formControlName="title"
                    id="adTitle"
                    type="text"
                    placeholder="Race Bike"
                    pInputText
                  />
                  <div
                    *ngIf="f.title.errors && (f.title.dirty || titleError)"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.title.errors.required"
                      class="error-message-form"
                    >
                      Please enter Ad Title.
                    </div>
                  </div>
                </div>
                <div class="p-field col-12 col-lg-6 input-field required mb-4">
                  <label>Ad Price</label>
                  <input
                    formControlName="price"
                    id="adPrice"
                    type="number"
                    placeholder="£5,000"
                    pInputText
                  />
                  <div
                    *ngIf="f.price.errors && (f.price.dirty || priceError)"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.price.errors.required"
                      class="error-message-form"
                    >
                      Please enter price.
                    </div>
                    <div
                      *ngIf="
                        (f.price.errors.price || f.price.invalid) &&
                        f.price.value
                      "
                      class="error-message-form"
                    >
                      Please enter valid price.
                    </div>
                  </div>
                </div>
                <div class="p-field col-12 col-lg-6 input-field required mb-4">
                  <label>Postcode</label>
                  <input
                    maxlength="8"
                    formControlName="postcode"
                    id="postcode"
                    type="text"
                    placeholder="Enter Postcode"
                    pInputText
                  />

                  <div
                    *ngIf="f.postcode.errors && f.postcode.dirty"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.postcode.errors.required"
                      class="error-message-form"
                    >
                      Please enter postcode.
                    </div>
                    <div
                      *ngIf="
                        (f.postcode.errors.postcode || f.postcode.invalid) &&
                        f.postcode.value
                      "
                      class="error-message-form"
                    >
                      Please enter valid postcode.
                    </div>
                  </div>
                </div>
                <div class="p-field col-12 input-field required mb-4">
                  <label>Description</label>
                  <textarea
                    formControlName="description"
                    id="description"
                    class="ui-inputtext"
                    rows="7"
                    pinputtext
                  ></textarea>
                  <small class="help-block pull-right"
                    >50-2000 Characters</small
                  >

                  <div
                    *ngIf="
                      f.description.errors &&
                      (f.description.dirty || descriptionError)
                    "
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="f.description.errors.required"
                      class="error-message-form"
                    >
                      Please enter description.
                    </div>
                    <div
                      *ngIf="
                        (f.description.errors.description ||
                          f.description.invalid) &&
                        f.description.value
                      "
                      class="error-message-form"
                    >
                      Please enter between 50 and 2000 characters for your
                      description.
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-4">
                  <label class="upload-file">
                    <!-- <input multiple="multiple" size="4" type="file" chooseLabel="Image" accept="image/*"> -->

                    <p-fileUpload
                      class="fileUploadAd"
                      multiple="multiple"
                      size="4"
                      class="file-upload cancel"
                      #form
                      customUpload="true"
                      auto="auto"
                      mode="basic"
                      name="avatar"
                      (uploadHandler)="fileUploader($event, form)"
                      chooseLabel="Image"
                      accept="image/*"
                    ></p-fileUpload>

                    <strong
                      type="button"
                      class="btn btn-default btn-black px-30"
                    >
                      <span class="ui-button-text ui-clickable"
                        ><img
                          src="../../../../assets/images/camera-icon.png"
                          alt="add"
                        />
                        Add Photos</span
                      >
                    </strong>
                  </label>
                  <span class="ml-3">Min 2 - Max 12</span>

                  <div *ngIf="imgError" class="invalid-feedback">
                    <div class="error-message-form">
                      Please add atleast 2 Photos.
                    </div>
                  </div>
                </div>
                <!-- 
                                <div><input type="file" (change)="fileChangeEvent($event)" /></div> -->

                <ngx-photo-editor
                  [imageBase64]="imageBase64"
                  [imageURL]="imageURL"
                  [imageChanedEvent]="imageChangedEvent"
                  (imageCropped)="imageCropped($event)"
                  [aspectRatio]="4 / 3"
                  [viewMode]="1"
                ></ngx-photo-editor>

                <!-- <img [src]="base64" alt=""> -->

                <div class="col-12 mb-4">
                  <small
                    *ngIf="allImages.length > 0"
                    class="purpletext p-mb-2 p-d-flex p-jc-center p-ai-center"
                    >Click on photo to edit</small
                  >
                  <div class="row m-n2">
                    <div
                      *ngFor="let item of arrayOne(12); let i = index"
                      class="col-6 col-sm-4 col-md-3 col-lg-2 text-center p-2"
                    >
                      <div class="photo-item">
                        <img
                          (click)="openEditor(i)"
                          *ngIf="allImages[i]"
                          src="{{ allImages[i] }}"
                        />
                        <!-- {{i}} -->
                        <span>
                          <label class="upload-file">
                            <!-- <input type="file" /> -->

                            <!-- <p-fileUpload class="fileUploadAd" class="file-upload cancel" #form customUpload="true" auto="auto" mode="basic" name="avatar" (uploadHandler)="fileUploader($event, form)" chooseLabel="Image" accept="image/*"></p-fileUpload> -->

                            <input
                              class="fileUploadAd"
                              class="file-upload cancel"
                              accept="image/*"
                              #form
                              type="file"
                              id="file"
                              (change)="fileUploaderSingle($event.target, form)"
                            />
                          </label>
                        </span>
                        <div class="image-actions">
                          <label
                            (click)="deleteImage(i)"
                            class="delete"
                            title="Delete"
                            ><svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="minus-square"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"
                              ></path></svg
                          ></label>
                          <label class="edit upload-file" title="Edit">
                            <!-- <p-fileUpload #form mode="basic" customUpload="true" auto="auto" name="editimage" (uploadHandler)="fileChangeEvent($event, form, i)" chooseLabel="ImageEdit" accept="image/*"></p-fileUpload> -->

                            <input
                              class="fileUploadAd"
                              class="file-upload cancel"
                              accept="image/*"
                              #form
                              type="file"
                              id="file"
                              (change)="fileChangeEvent($event.target, form, i)"
                            />

                            <svg
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="pen-square"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path
                                fill="currentColor"
                                d="M400 480H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zM238.1 177.9L102.4 313.6l-6.3 57.1c-.8 7.6 5.6 14.1 13.3 13.3l57.1-6.3L302.2 242c2.3-2.3 2.3-6.1 0-8.5L246.7 178c-2.5-2.4-6.3-2.4-8.6-.1zM345 165.1L314.9 135c-9.4-9.4-24.6-9.4-33.9 0l-23.1 23.1c-2.3 2.3-2.3 6.1 0 8.5l55.5 55.5c2.3 2.3 6.1 2.3 8.5 0L345 199c9.3-9.3 9.3-24.5 0-33.9z"
                              ></path>
                            </svg>
                          </label>
                        </div>
                      </div>
                      <div>
                        <p-radioButton
                          class="purpletext"
                          *ngIf="allImages[i]"
                          name="groupname"
                          formControlName="featuredImageNo"
                          value="{{ i + 1 }}"
                          label="Featured"
                        ></p-radioButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <button
                    style="margin-right: 10px"
                    *ngIf="!hideCategory"
                    (click)="onSubmit(4)"
                    type="button"
                    class="btn btn-default pull-left px-30"
                  >
                    <span class="ui-button-text ui-clickable">Save Draft</span>
                  </button>

                  <button
                    *ngIf="!hideCategory"
                    (click)="onSubmit(2)"
                    type="button"
                    class="btn btn-default pull-left px-30"
                  >
                    <span class="ui-button-text ui-clickable">Preview Ad</span>
                  </button>

                  <button
                    *ngIf="!hideCategory"
                    (click)="onSubmit(1)"
                    type="button"
                    class="btn btn-default pull-right btn-black px-30"
                  >
                    <span class="ui-button-text ui-clickable">Next</span>
                  </button>

                  <button
                    *ngIf="hideCategory"
                    (click)="onSubmit(3)"
                    type="button"
                    class="btn btn-default pull-right btn-black px-30"
                  >
                    <span class="ui-button-text ui-clickable"
                      >Save Changes</span
                    >
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            [ngClass]="{
              'd-none': !showPaymentForm,
              '': showPaymentForm
            }"
            class="payment-sec"
          >
            <form
              #paymentForm="ngForm"
              (ngSubmit)="onPaymentSubmit(paymentForm)"
              class="input-lg"
            >
              <input
                type="hidden"
                name="postid"
                [(ngModel)]="postId"
                value="{{ postId }}"
              />
              <input
                type="hidden"
                name="amount"
                [(ngModel)]="amountPost"
                value="{{ amountPost }}"
              />

              <div class="row">
                <div class="p-field col-12 mb-4">
                  <label class="w-100 mb-4"
                    >THIS IS HOW IT WORKS BUY 30 DAYS AND GET 30 DAYS ....BUY 90
                    DAYS AND IF YOU SELL WITHIN 30 DAYS WE WILL REFUND THE
                    DIFFERENCE ......BUY THE UNTILL SOLD AND AGAIN IF YOU SELL
                    WITHIN 30 DAYS OR 90 DAYS WE WILL REFUND THE DIFFERENCE
                    .....SEE BELOW FOR HOW TO CLAIM THE REFUND</label
                  >
                  <div
                    class="form-check form-check-inline"
                    ng-init="paymentOption=untilSold"
                  >
                    <input
                      value="untilSold"
                      (click)="getRadioValue($event.target.value)"
                      [(ngModel)]="paymentOption"
                      class="form-check-input"
                      type="radio"
                      name="paymentOption"
                      [checked]="true"
                      id="untilSoldCost"
                    />
                    <label class="form-check-label" for="untilSoldCost"
                      ><span class="label-price">
                        <del *ngIf="olduntilSoldCost">{{
                          olduntilSoldCost | currency : "GBP"
                        }}</del
                        >&nbsp; {{ untilSoldCost | currency : "GBP" }}</span
                      >
                      Until Sold Cost</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="paymentOption"
                      type="radio"
                      name="paymentOption"
                      id="dayCost90"
                      value="90days"
                      (click)="getRadioValue($event.target.value)"
                    />
                    <label class="form-check-label" for="dayCost90">
                      <span class="label-price">
                        <del *ngIf="oldday90Cost">{{
                          oldday90Cost | currency : "GBP"
                        }}</del
                        >&nbsp; {{ day90Cost | currency : "GBP" }}</span
                      >
                      90 Days Cost</label
                    >
                  </div>
                  <!-- <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="paymentOption" id="dayCost60" value="3" (click)="getRadioValue($event.target.value);">
                                        <label class="form-check-label" for="dayCost60"><span class="label-price">£4.20</span> 60 Days Cost</label>
                                    </div> -->
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      [(ngModel)]="paymentOption"
                      type="radio"
                      name="paymentOption"
                      id="dayCost30"
                      value="30days"
                      (click)="getRadioValue($event.target.value)"
                    />
                    <label class="form-check-label" for="dayCost30"
                      ><span class="label-price">
                        <del *ngIf="oldday30Cost">{{
                          oldday30Cost | currency : "GBP"
                        }}</del
                        >&nbsp; {{ day30Cost | currency : "GBP" }}</span
                      >
                      30 Days Cost</label
                    >
                  </div>
                  <small class="form-text text-success mt-3"
                    ><span>
                      <!-- Your ad will expire on 19/09/2020 -->
                      {{ expireText }}
                    </span></small
                  >
                </div>

                <div
                  class="col-4 mt-2 input-field"
                  style="
                    border: 1px solid;
                    margin: 0 1em;
                    max-width: min-content;
                    border-radius: 20px;
                  "
                >
                  <input
                    name="couponCode"
                    id="couponCode"
                    [(ngModel)]="couponCode"
                    type="text"
                    placeholder="Enter coupon"
                  />
                </div>
                <div class="col-12 mt-2">
                  <button class="btn btn-default pull-left px-30">
                    <span class="ui-button-text ui-clickable"
                      >Pay by Credit or Debit Card</span
                    >
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div
          [ngClass]="{
            'd-none': !showThankyouForm,
            '': showThankyouForm
          }"
          class="pl-0 pl-lg-4"
        >
          <div class="section-title">
            <h1 class="mt-0">Post Ads</h1>
          </div>
          <h5>Thank you for posting your Ad.</h5>
          <a [routerLink]="'/ad-detail/' + slugName">View Your Ad</a>
        </div>
      </div>
    </div>
  </div>
</main>

<div
  class="modal upload-crop-cover-modal is-xxl has-light-bg"
  [ngClass]="{ 'is-active': showIframe }"
>
  <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
  <div style="height: 85%; width: 85%" class="modal-content">
    <div class="modal-header bg-dark py-2 px-3">
      <h6 class="modal-title text-white mt-0">Preview Here</h6>
      <button
        type="button"
        (click)="clickedOut($event)"
        class="close text-white"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <embed id="result" src="" width="100%" style="height: calc(100vh - 70px)" />
  </div>
</div>

<!-- Modal Starts-->
<div
  id="upload-crop-cover-modal"
  class="modal upload-crop-cover-modal is-xxl has-light-bg"
  [ngClass]="{ 'is-active': showImagePicker }"
>
  <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
  <div class="modal-content">
    <app-image-picker
      [(showImagePicker)]="showImagePicker"
      [(imagePickerImageUrl)]="imagePickerImageUrl"
      (imagePickerImageUrlChange)="fileUpload()"
      *ngIf="showImagePicker"
    ></app-image-picker>
  </div>
</div>

<app-footer-front></app-footer-front>
