<header class="header display-flex">
    <div class="logo">
        <a target="_blank" href="/"><img src="../../../../assets/images/logo.jpg" alt="logo"></a></div>
    <div class="header-nav align-right">
        <ul>
            <li (click)="expand('Submenu')"><a><img src="../../../../assets/images/setting.png" alt="setting"><img src="../../../../assets/images/down-arrow.png" class="down-arrow" alt="down-arrow"></a>
                <ul class="submenu" [ngClass]="{'open': toggleMenu}">
                    <li><a (click)="logout()">Logout</a></li>
                </ul>
            </li>
        </ul>
        <div class="avatar">
            <span class="text hide-mobile"></span>
            <div class="img">
                <!-- <img src="../../../../assets/images/bob-nilson.jpg" alt="bob-nilson"> -->
            </div>
            <span class="text show-mobile"></span>
        </div>
    </div>
</header>