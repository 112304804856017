import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../service/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public toggleMenu = false;
  constructor(private authService: AuthService) { }

  logout() {
    // alert('test');
    this.authService.adminlogout();
  }
  ngOnInit(): void {
  }
  /**** function to toggle filters ****/
  expand(type) {
    if (type === 'Submenu') {
      this.toggleMenu = !this.toggleMenu;
    }
  }
}
