import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { ConfirmPasswordValidator } from '../../../service/confirm_password.validator';
import { MyaccountService } from '../myaccount.service';

@Component({
  selector: 'app-account-info',
  styleUrls: [],
  templateUrl: './account-info.component.html',

})
export class AccountInfoComponent implements OnInit {
  public dataForm: FormGroup;
  public msgs = [];
  public countryCode: any = 'gb';
  constructor(
    private myaccountService: MyaccountService,
    private socialAuthService: SocialAuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.authService.isUserLoggedIn();
  }

  public ngOnInit(): void {
    this.setFormInitial();
    this.getUserData();
  }

  public setFormInitial(): any {
    this.dataForm = this.formBuilder.group({
      address: [''],
      address2: [''],
      companyName: [''],
      companyRegNo: [''],
      county: [''],
      email: [''],
      id: [''],
      password: ['',
        [
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.maxLength(50),
          Validators.minLength(6),
        ],
      ],
      phone: [],
      postcode: [''],
      town: [''],
      websiteUrl: [''],
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      });
  }

  public getUserData(): any {
    const req = {
      id: this.authService.getUserId(),
    };
    this.spinner.show();

    this.myaccountService.getuserdata(req).subscribe(
      (res: any) => {
        this.spinner.hide();
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res.data[0], 'getuserdata');
        if (res.status === 1) {

          if (res.data[0].countryCode && res.data[0].countryCode !== '' && res.data[0].countryCode != null) {
            this.countryCode = res.data[0].countryCode;
          }
          this.dataForm = this.formBuilder.group({
            address: [res.data[0].address],
            address2: [res.data[0].address2],
            companyName: [res.data[0].companyName],
            companyRegNo: [res.data[0].companyRegNo],
            county: [res.data[0].county],
            email: [
              res.data[0].email,
              [
                Validators.required,
                Validators.pattern('^([a-zA-Z0-9_.\-]+)@([a-zA-Z0-9_.\-]+)[.]([a-zA-Z]{2,5})$'),
                Validators.maxLength(255),
              ],
            ],
            id: [res.data[0].id],
            password: ['',
              [
                Validators.maxLength(50),
                Validators.minLength(6),
              ],
            ],
            confirm_password: [
              '',
              [
                Validators.maxLength(50),
                Validators.minLength(6),
              ],
            ],
            phone: [res.data[0].phone],
            postcode: [res.data[0].postcode],
            town: [res.data[0].town],
            websiteUrl: [res.data[0].websiteUrl],
          },
            {
              validator: ConfirmPasswordValidator.MatchPassword,
            });

        } else if (res.status === 2) {
        } else if (res.status === 0) {
        } else {
        }
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
      },
    );

  }

  public onSubmit(): any {
    this.spinner.show();

    if (this.dataForm.invalid) {
      this.spinner.hide();
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.myaccountService.saveSpDetail(this.dataForm.value).subscribe(
      (res: any) => {
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        if (res.status === 1) {
          window.scroll(0, 0);
          this.spinner.hide();
          // this.setFormInitial();
          this.showError('success', res.message);


        } else if (res.status === 2) {
          this.spinner.hide();
          this.showError('error', res.message);
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
        } else {
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', "There is some error. Please try again later.");
      },
    );

  }
  public showError(type, message): any {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
  }


  get f(): any {
    return this.dataForm.controls;
  }
}
