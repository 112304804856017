<app-header-front></app-header-front>
<nav aria-label="breadcrumb" class="purpleBreadcrumb">
  <div class="container">
    <ol class="breadcrumb border-bottom-0">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item">
        <a [routerLink]="'/adverts/search-ads'">Search Page</a>
      </li>
      <li *ngIf="getdata" class="breadcrumb-item active" aria-current="page">
        <span class="no-translate">{{ getdata.title }}</span>
      </li>
    </ol>
  </div>
</nav>
<main class="main-content front login-front">
  <div class="container pb-4 mb-5">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="section-title">
          <h1 *ngIf="getdata" class="mt-0 mb-4 no-translate">
            {{ getdata.title }}
          </h1>
        </div>
        <div *ngIf="getdata" class="bike-label">
          <h4>
            <span class="label-name">{{ getdata.submaincatname }}</span>
            <span class="flag">
              <span
                [ngClass]="
                  'fflag-' + (getdata.countryCode ? getdata.countryCode : 'GB')
                "
                class="fflag ff-round ff-xl"
              ></span>
            </span>
          </h4>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4 mb-md-0">
        <div *ngIf="imageData" class="product-img-slider">
          <img
            class="soldImage"
            *ngIf="getdata.isSold == 1"
            src="../../../../assets/images/sold-ribbon.png"
            alt="bike"
          />
          <div class="main-img mb-3">
            <img
              (click)="open(1)"
              src="{{
                websiteurl +
                  'image/' +
                  (getdata.featureImage ? getdata.featureImage : imageData[0])
              }}"
              alt="product image"
            />
            <div class="views">
              <span class="eye">
                <svg
                  width="1em"
                  height="1em"
                  viewBox="0 0 16 16"
                  class="bi bi-eye-fill"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                  <path
                    fill-rule="evenodd"
                    d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"
                  />
                </svg>
              </span>
              <!-- <span class="no-translate">{{ total_ad_visits }}</span> views -->
            </div>
          </div>
          <div class="pro-img-thumbnails">
            <ng-image-slider
              [imageSize]="{ width: '200px', height: '150px', space: 3.3 }"
              [images]="imageObject"
              #nav
            ></ng-image-slider>
          </div>
        </div>
      </div>
      <div *ngIf="getdata" class="col-12 col-md-6">
        <div class="product-description">
          <h6 class="mt-0 mb-3">Item Description</h6>
          <div style="text-indent: -4px; white-space: pre-wrap">
            {{ description }}
          </div>
          <p></p>

          <a
            *ngIf="showMoreLess"
            (click)="showDescription()"
            class="btn btn-default btn-black p12-40 btn-auto"
            ><span>Read {{ isLess ? "Less" : "More" }}</span></a
          >

          <p class="mt-4">
            <strong *ngIf="getdata.manufacturer">Manufacturer:</strong
            ><span class="no-translate">
              {{ getdata.manufacturer }}<br *ngIf="getdata.manufacturer"
            /></span>
            <strong>Price:</strong>
            <span class="no-translate">{{
              getdata.price | currency : "GBP"
            }}</span>
            <br />
            <strong *ngIf="getdata.age">Year:</strong
            ><span *ngIf="getdata.age" class="no-translate">
              {{ getdata.age }} </span
            ><br *ngIf="getdata.age" />
            <strong *ngIf="getdata.subsubmaincatname">Capacity:</strong
            ><span class="no-translate">
              {{ getdata.subsubmaincatname
              }}<br *ngIf="getdata.subsubmaincatname"
            /></span>
            <strong *ngIf="getdata.postcode">Postcode:</strong
            ><span class="no-translate">
              {{
                getdata.postcode &&
                getdata.postcode != null &&
                getdata.postcode != "" &&
                getdata.postcode.length > 4
                  ? getdata.postcode.substring(0, 4)
                  : getdata.postcode
              }}</span
            >
          </p>
          <a
            *ngIf="getdata.isSold == 0"
            [routerLink]="'/contact-us/' + getdata.id + '/' + getdata.createdBy"
            class="btn btn-default p12-40 btn-auto"
            ><span>Contact Seller</span></a
          >
          <a *ngIf="getdata.isSold == 1" class="btn btn-default p12-40 btn-auto"
            ><span>Sold</span></a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- <section class="sell-bike mt-5 pb-5">
    <div class="contact-form-section">
      <div class="container">
        <div class="row">
          <div class="col-md-5 bike-img">
            <img src="../../../../assets/images/footer-bike.png" alt="bike" />
          </div>
          <div class="col-md-7">
            <app-contact-form></app-contact-form>
          </div>
        </div>
      </div>
    </div>
  </section> -->
</main>
<app-footer-front></app-footer-front>
