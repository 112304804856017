import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import { AuthService } from '../../../service/auth.service';
import { ConfirmPasswordValidator } from '../../../service/confirm_password.validator';
import { LoginService } from '../login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  // Function and varuiables Decleration
  @ViewChild('emailFocus') public emailName: ElementRef;
  public resetPasswordForm: FormGroup;
  public submitted = false;
  public msgtype: Boolean = false;
  public displayLogout = false;
  public showMessageDiv: any;
  public showmessage: any;
  public contentLogout: any;
  public logoutUserId: any;
  public msgs = [];
  public token: any;
  public paramCode: any;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) {

    this.route.params.subscribe((params) => {
      this.paramCode = params['code']; // (+) converts string 'id' to a number

    });

    this.authService.moveToDashboardIfAdminLoggedIn();

  }

  // fn: show error
  public showError(type, message) {
    // console.log();
    this.msgs = [];
    this.msgs.push({ severity: type, summary: message });
    // console.log(this.msgs);
  }

  public checkIfCodeExist() {
    this.spinner.show();
    this.loginService.checkResetCode({ resetcode: this.paramCode }).subscribe(
      (res: any) => {
        this.submitted = false;
        // if the response status is 1 the user is sucessfully logged in else show error message
        console.log(res, 'res');
        // this.spinner.hide();
        if (res.status === 1) {
        } else if (res.status === 2) {
        } else if (res.status === 0) {
          this.spinner.hide();
          this.router.navigate(['admin/login']);
        } else {
          // this.spinner.hide();
        }
        this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }
  public ngOnInit() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(8),
        ],
      ],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.minLength(8),
        ],
      ],
      resetcode: [this.paramCode]
    },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      },
    );

    setTimeout(() => {
      // this.emailName.nativeElement.focus();
    }, 500);

    this.checkIfCodeExist();
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  // On secessfull form validation invoke this function
  public onSubmit() {
    this.submitted = true;
    // this.spinner.show();
    this.showmessage = '';
    this.showMessageDiv = false;
    this.msgs = [];
    this.msgtype = false;

    if (this.resetPasswordForm.invalid) {
      return;
    }
    // Method to validate user login
    this.spinner.show();
    this.loginService.changePassword(this.resetPasswordForm.value).subscribe(
      (res: any) => {
        this.submitted = false;
        console.log(res, 'res');
        // this.spinner.hide();
        if (res.status === 1) {
          this.showError('success', res.message);
          this.spinner.hide();
        } else if (res.status === 2) {

          this.displayLogout = true;
          this.submitted = false;
          this.spinner.hide();
        } else if (res.status === 0) {
          window.scroll(0, 0);
          this.showError('error', res.message);
          this.showmessage = res.message;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
        this.submitted = false;
        // this.spinner.hide();
      },
      // Log any error occured in logging in
      (error) => {
        this.spinner.hide();
        this.showError('error', error);
      },
    );
  }

}

