import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule, Routes } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as myGlobalsfunc from '../../../global.function';
import * as global_var from '../../../global.variable';
import { AuthService } from '../../../service/auth.service';
import { MyaccountService } from '../myaccount.service';

@Component({
  selector: 'app-messages',
  styleUrls: ['./messages.component.css'],
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {
  public websiteurl: any;
  public msgList: any[];
  private userId: any;
  constructor(
    private myaccountService: MyaccountService,
    private formBuilder: FormBuilder,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
  ) {
    this.authService.isUserLoggedIn();
  }
  public ngOnInit(): void {
    this.getAllMessages();
    this.updateContactView();
  }
  public getAllMessages(): any {
    this.userId = window.localStorage.getItem('user_id_user');
    const req = {
      user_id: this.userId,
    };
    this.websiteurl = global_var.webSiteUrl;
    this.spinner.show();
    this.myaccountService.getMessages(req).subscribe((res: any) => {
      if (res.status === 1) {
        console.log(res.data);
        const msgList = res.data;
        msgList.sort((a, b) => {
          const keyA = new Date(a.datecreated);
          const keyB = new Date(b.datecreated);
          // Compare the 2 dates
          if (keyA < keyB) { return 1; }
          if (keyA > keyB) { return -1; }
          return 0;
        });
        this.msgList = msgList;
      } else if (res.status === 2) {
        this.spinner.hide();
      } else if (res.status === 0) {
        window.scroll(0, 0);
        this.spinner.hide();
      } else {
        this.spinner.hide();
      }

    });
  }

  public calculateDiff(dateSent) {
    const currentDate = new Date();
    dateSent = new Date(dateSent);

    let responseString = '';
    let res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate())) / (1000 * 60 * 60 * 24));
    responseString = res + ' days';
    if (res === 0) {
      res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours())) / (1000 * 60 * 60));

      responseString = res + ' hours';

      if (res === 0) {
        res = Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate(), dateSent.getHours(), dateSent.getMinutes())) / (1000 * 60));

        responseString = res + ' mins';
      }
    }

    return responseString;

  }
  public updateContactView()
  {
    let req={
      userId:this.userId
    }
    this.myaccountService.updateContactView(req).subscribe((res:any)=>{
      
    },(err:any)=>{})
  }
}