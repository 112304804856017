<div class="view-wrapper" [ngClass]="{'darkmode': globals.darkmode}">
  <div class="container is-custom">
    <div id="pages-main" class="view-wrap is-headless">
      <div class="logo-area">
        <img *ngIf="!globals.darkmode" src="assets/images/global/pictor-logo.png" class="pictor-logo" />
        <img *ngIf="globals.darkmode" src="assets/images/global/pictor-logo-light.png" class="pictor-logo" />
      </div>
      <div class="columns has-portrait-padding mt-5 justify-content">

        <div class="column is-9">
          <div class="stats-wrapper" id="activity-feed">
            <div id="compose-card" class="card is-new-content create-post card-shadow">

              <div class="tabs-wrapper">
                <div class="stats-header-container">
                  <div class="stats-header create-post-space">

                    <div id="basic-options" class="compose-options most-right">
                      <div class="compose-option" (click)="openImagePicker('edit')" data-modal="upload-crop-cover-modal">
                        <i class="fas fa-image"></i>
                        <span>Edit an Image</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tab-content">
                  <div class="compose edited">
                    <!--Image Container Start-->
                    <div class="cp-image-container">
                      <div class="single-post-img-container black-overlay" *ngFor="let img of myPictureData, index as i">
                        <div class="loiku">
                          <img [src]="img" class="single-post-img" alt="">
                          <i class="fas fa-play-circle video-icn" *ngIf="files[i].type == 'video/mp4' || files[i].type == 'video/3gpp'"></i>
                          <i class="fas fa-times-circle single-clse" (click)="removeImage(i);"></i>
                          <!-- <a *ngIf="!(files[i].type == 'video/mp4' || files[i].type == 'video/3gpp')" [href]="img" download="edited-by-pictor" title="Download" class="final-download-btn">Download</a> -->
                        </div>
                      </div>
                    </div>
                    <!--Image Container End-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Starts-->
  <div id="upload-crop-cover-modal" class="modal upload-crop-cover-modal is-xxl has-light-bg" [ngClass]="{'is-active':showImagePicker}">
    <div class="modal-background" appOutside (click)="clickedOut($event)"></div>
    <div class="modal-content">
      <app-image-picker [(showImagePicker)]="showImagePicker" [(imagePickerImageUrl)]="imagePickerImageUrl" (imagePickerImageUrlChange)="fileUpload()" *ngIf="showImagePicker"></app-image-picker>
    </div>
  </div>

</div>