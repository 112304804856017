import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as global_var from '../../global.variable';

@Injectable({
    providedIn: 'root',
})
export class MyaccountService {
    // Constructor
    constructor(private http: HttpClient, private router: Router) { }

    public getAllCategory(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllCategory', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public updateSoldAd(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'postad/updatesoldad', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }


    public getSinglecms(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'getSinglecms', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getPriceManagement(formdata: any = null): any {

        return this.http.post(global_var.apiUrl + 'pricemanagement/getAll', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }



    public getAllcms(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'getAllcms', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }



    public getuserdata(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'users/getsingleuser', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSpData(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'users/getspdata', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSpProductData(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'getspproductdata', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveSpProductDetail(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'savespproductdetail', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getSingleCategory(req): any {
        return this.http.post(global_var.apiUrl + 'getSingleCategory', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getFeaturedAd(req): any {
        return this.http.post(global_var.apiUrl + 'postad/getfeaturedad', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveAd(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'postad/savead', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public saveSpDetail(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'savespdetail', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }


    public updateSpUserData(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'updatepaymentspuserdata', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }



    public saveSpDataDetail(formdata: any = { searchtext: '' }): any {

        return this.http.post(global_var.apiUrl + 'savespdatadetail', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }


    public contactSave(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'contact/save', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getWishlistPostAd(req): any {
        return this.http.post(global_var.apiUrl + 'postad/getwishlistadbyuserid', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getSavedPostAd(req): any {
        return this.http.post(global_var.apiUrl + 'postad/getSavedAdbyUserid', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public createWishlist(formdata: any): any {
        return this.http.post(global_var.apiUrl + 'adwishlist/create', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public removeFromWishlist(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'adwishlist/remove', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public updateAd(formdata: any): any {

        return this.http.post(global_var.apiUrl + 'postad/updatead', formdata).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }

    public getMessages(req): Observable<any> {
        return this.http.post(global_var.apiUrl + 'postad/getMessages', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public updateContactView(req): Observable<any> {
        return this.http.post(global_var.apiUrl + 'contact/updateContactView', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public getUnreadMsgCounts(req): Observable<any> {
        return this.http.post(global_var.apiUrl + 'contact/getUnreadMsgCounts', req).pipe(
            map((res: any) => {
                return res;
            }),
        );
    }
    public updateUserInfo(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'users/updateUserInfo', req);
    }
    public deactivateFrontUser(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'users/deactivateFrontUser', req);
    }
    public deleteProduct(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'users/deleteProduct ', req);
    }
    public getAllProducts(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'users/getAllServiceProduct ', req);
    }
    public checkPromo(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'users/checkPromo ', req);
    }
    public deleteAd(req: any): Observable<any> {
        return this.http.post(global_var.apiUrl + 'postad/deletead', req);
    }

}
