<app-header-front></app-header-front>

<main class="main-content front login-front mb-0 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-7 img-col">
                <div class="bike-img">
                    <img src="../../../../assets/images/bike.jpg" alt="bike">
                </div>
            </div>
            <div class="col-md-6 col-lg-5">
                <div class="login-form">
                    <div class="form-box">
                        <div class="alert-message">
                            <p-messages [(value)]="msgs"></p-messages>
                        </div>

                        <h1>Forgot-Password</h1>
                        <form [formGroup]="userForgotPasswordForm" (ngSubmit)="onSubmit()" id="userForgotPasswordForm">
                            <div class="input-row">
                                <input #emailFocus type="email" formControlName="email" name="email" placeholder="Username/Email" class="form-control">
                                <div *ngIf="f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required" class="error-message-form"> Please enter username/email.</div>
                                </div>
                            </div>

                            <!-- <div class="remember-and-forgot-password">

                                <div class="forgot-password-link">
                                    <a [routerLink]="'/login'">Login</a>
                                </div> 
                    </div> -->
                            <input type="submit" [disabled]="!(userForgotPasswordForm.valid)" value="Submit" class="btn btn-default">




                        </form>
                        <div class="register-link">
                            Don't have an account? <a [routerLink]="'/login'">Register here</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>

<app-footer-front></app-footer-front>